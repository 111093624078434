import React from 'react';
export function LockIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="14" height="14" rx="4" fill="white" fillOpacity="0.15" />
      <path
        d="M6.01337 6.25168H5.00267V5.15932C5.00267 4.92153 5.05348 4.69984 5.15508 4.49425C5.25668 4.28866 5.39706 4.10784 5.5762 3.95179C5.75535 3.79574 5.96658 3.67313 6.20989 3.58395C6.45321 3.49478 6.71925 3.4502 7.00802 3.4502C7.26471 3.4502 7.51203 3.49478 7.75 3.58395C7.98797 3.67313 8.1992 3.79574 8.38369 3.95179C8.56818 4.10784 8.71524 4.28866 8.82487 4.49425C8.93449 4.69984 8.98931 4.92153 8.98931 5.15932V6.25168H8.01872V5.36739C8.01872 5.05529 7.92513 4.81378 7.73797 4.64287C7.5508 4.47196 7.29679 4.3865 6.97594 4.3865C6.68717 4.3865 6.45455 4.47196 6.27807 4.64287C6.1016 4.81378 6.01337 5.05529 6.01337 5.36739V6.25168ZM9.47059 6.69754C9.61497 6.69754 9.7393 6.74584 9.84358 6.84245C9.94786 6.93905 10 7.05423 10 7.18799V9.4916C10 9.62535 9.9746 9.75044 9.9238 9.86686C9.87299 9.98328 9.80348 10.0848 9.71524 10.1715C9.62701 10.2582 9.52273 10.3263 9.40241 10.3759C9.28209 10.4254 9.14973 10.4502 9.00535 10.4502H4.95455C4.81016 10.4502 4.67914 10.4254 4.5615 10.3759C4.44385 10.3263 4.34358 10.2607 4.2607 10.179C4.17781 10.0972 4.11364 10.0019 4.06818 9.89287C4.02273 9.78388 4 9.66746 4 9.54361V7.18799C4 7.05423 4.0508 6.93905 4.15241 6.84245C4.25401 6.74584 4.37701 6.69754 4.52139 6.69754H5.00267H6.01337H8.01872H8.9893H9.47059Z"
        fill="white"
      />
    </svg>
  );
}
export function LockMiddleIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="22" height="22" rx="4" fill="white" fillOpacity="0.15" />
      <path
        d="M9.44902 9.82421H7.86079V8.10765C7.86079 7.73398 7.94062 7.3856 8.10028 7.06253C8.25995 6.73946 8.48053 6.45531 8.76205 6.21009C9.04356 5.96487 9.37549 5.77219 9.75784 5.63207C10.1402 5.49194 10.5583 5.42187 11.012 5.42188C11.4154 5.42188 11.8041 5.49194 12.178 5.63207C12.552 5.77219 12.8839 5.96487 13.1738 6.21009C13.4637 6.45531 13.6948 6.73946 13.8671 7.06253C14.0394 7.3856 14.1255 7.73398 14.1255 8.10765V9.82421H12.6003V8.43461C12.6003 7.94417 12.4532 7.56466 12.1591 7.29608C11.865 7.0275 11.4658 6.89321 10.9616 6.89321C10.5078 6.89321 10.1423 7.0275 9.86499 7.29608C9.58768 7.56466 9.44902 7.94417 9.44902 8.43461V9.82421ZM14.8818 10.5248C15.1087 10.5248 15.3041 10.6007 15.4679 10.7526C15.6318 10.9044 15.7137 11.0854 15.7137 11.2955V14.9155C15.7137 15.1257 15.6738 15.3223 15.594 15.5052C15.5141 15.6882 15.4049 15.8477 15.2662 15.984C15.1276 16.1202 14.9637 16.2273 14.7747 16.3051C14.5856 16.383 14.3776 16.4219 14.1507 16.4219H7.78516C7.55827 16.4219 7.35238 16.383 7.16751 16.3051C6.98264 16.2273 6.82507 16.1241 6.69482 15.9957C6.56457 15.8672 6.46373 15.7173 6.3923 15.5461C6.32087 15.3748 6.28516 15.1919 6.28516 14.9972V11.2955C6.28516 11.0854 6.36499 10.9044 6.52465 10.7526C6.68432 10.6007 6.87759 10.5248 7.10448 10.5248H7.86079H9.44902H12.6003H14.1255H14.8818Z"
        fill="white"
      />
    </svg>
  );
}
export function LockLargeIcon() {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.26327 6.4034H2.12314V3.90658C2.12314 3.36306 2.23072 2.85633 2.44586 2.38641C2.66101 1.91649 2.95824 1.50318 3.33758 1.1465C3.71691 0.789809 4.16419 0.509554 4.6794 0.305732C5.19462 0.101911 5.75796 -1.10691e-08 6.36943 0C6.91295 0 7.43666 0.101911 7.94055 0.305732C8.44444 0.509554 8.89172 0.789809 9.28238 1.1465C9.67304 1.50318 9.98443 1.91649 10.2166 2.38641C10.4487 2.85633 10.5648 3.36306 10.5648 3.90658V6.4034H8.50955V4.38217C8.50955 3.66879 8.31139 3.11677 7.91507 2.72611C7.51875 2.33546 6.98089 2.14013 6.30149 2.14013C5.69002 2.14013 5.19745 2.33546 4.82378 2.72611C4.45011 3.11677 4.26327 3.66879 4.26327 4.38217V6.4034ZM11.5839 7.4225C11.8896 7.4225 12.1529 7.53291 12.3737 7.75372C12.5945 7.97452 12.7049 8.23779 12.7049 8.54352V13.8089C12.7049 14.1146 12.6511 14.4006 12.5435 14.6667C12.436 14.9328 12.2887 15.1649 12.1019 15.3631C11.9151 15.5612 11.6943 15.7169 11.4395 15.8301C11.1847 15.9434 10.9045 16 10.5987 16H2.02123C1.7155 16 1.43807 15.9434 1.18896 15.8301C0.939844 15.7169 0.72753 15.5669 0.552017 15.38C0.376504 15.1932 0.240623 14.9752 0.144374 14.7261C0.0481244 14.477 0 14.2109 0 13.9278V8.54352C0 8.23779 0.107572 7.97452 0.322717 7.75372C0.537863 7.53291 0.798301 7.4225 1.10403 7.4225H2.12314H4.26327H8.50955H10.5648H11.5839Z"
        fill="white"
      />
    </svg>
  );
}

export function WarningIcon() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.3125 3.375H5.25V2.4375H4.3125V3.375ZM4.3125 7.125H5.25V3.9375H4.3125V7.125ZM4.78125 0C3.504 0 2.30325 0.497437 1.40044 1.40044C0.49725 2.30344 0 3.50419 0 4.78125C0 7.41769 2.14481 9.5625 4.78125 9.5625H4.782C6.05888 9.5625 7.25944 9.06506 8.16263 8.16206C9.06506 7.25906 9.5625 6.05831 9.5625 4.78125C9.5625 2.14481 7.41769 0 4.78125 0ZM7.52531 7.52512C7.16594 7.88651 6.73844 8.17302 6.26758 8.36806C5.79672 8.56309 5.29184 8.66279 4.78219 8.66138H4.78125C2.64188 8.66138 0.900938 6.92081 0.900938 4.78125C0.899546 4.27149 0.999252 3.76651 1.19429 3.29553C1.38932 2.82455 1.67581 2.39692 2.03719 2.03737C2.39674 1.67595 2.8244 1.38942 3.29541 1.19435C3.76642 0.999284 4.27144 0.899556 4.78125 0.900938C6.92062 0.900938 8.66156 2.64169 8.66156 4.78125C8.66294 5.29101 8.56323 5.79599 8.3682 6.26697C8.17316 6.73795 7.88668 7.16558 7.52531 7.52512Z"
        fill="#91A2AE"
      />
    </svg>
  );
}

export function ArrowIcon(props) {
  return (
    <svg
      {...props}
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.22929 5.22267C4.83426 5.64372 4.16574 5.64372 3.77072 5.22267L0.45095 1.68421C-0.148156 1.04564 0.30462 0 1.18024 0H7.81976C8.69538 0 9.14815 1.04564 8.54905 1.68421L5.22929 5.22267Z"
        fill="currentColor"
      />
    </svg>
  );
}
export function UnLockIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7458 0C13.2881 0 13.8107 0.101695 14.3136 0.305085C14.8164 0.508475 15.2627 0.788136 15.6525 1.14407C16.0424 1.5 16.3559 1.91525 16.5932 2.38983C16.8305 2.86441 16.9492 3.37288 16.9492 3.91525V6.42373H14.8983V4.38983C14.8983 3.67797 14.6977 3.12429 14.2966 2.72881C13.8955 2.33333 13.3559 2.13559 12.678 2.13559C12.0678 2.13559 11.5763 2.33333 11.2034 2.72881C10.8305 3.12429 10.6441 3.67797 10.6441 4.38983V6.45763H8.50847V3.91525C8.50847 3.37288 8.61582 2.86441 8.83051 2.38983C9.0452 1.91525 9.34181 1.5 9.72034 1.14407C10.0989 0.788136 10.5452 0.508475 11.0593 0.305085C11.5734 0.101695 12.1356 1.11118e-08 12.7458 0ZM12.7119 11.5593V13.0169V13.8136C12.7119 14.1186 12.6582 14.4068 12.5508 14.678C12.4435 14.9492 12.2966 15.1808 12.1102 15.3729C11.9237 15.565 11.7034 15.7175 11.4492 15.8305C11.1949 15.9435 10.9153 16 10.6102 16H2.0339C1.72881 16 1.45198 15.9463 1.20339 15.839C0.954802 15.7316 0.740113 15.5847 0.559322 15.3983C0.378531 15.2119 0.240113 14.9944 0.144068 14.7458C0.0480226 14.4972 -1.11118e-08 14.2316 0 13.9492V8.55932C0 8.24294 0.11017 7.9774 0.330509 7.76271C0.550847 7.54802 0.807909 7.44068 1.10169 7.44068H11.5932C11.9096 7.44068 12.1751 7.54802 12.3898 7.76271C12.6045 7.9774 12.7119 8.24294 12.7119 8.55932V11.5593Z"
        fill="white"
      />
    </svg>
  );
}
