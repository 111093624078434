const ja_in_risks_page = {
  risks: 'リスク',
  of_using_ref_finance: 'Ref.financeの利用',
  introduction:
    'Ref Financeにおける流動性の提供および/または取引は、リスクがないわけではありません。よく調べて、リスクを理解した上で、コントラクトとのインタラクションを実行してください。',
  general_risks_quiz: '一般的なリスクテスト',
  amm_core_design: 'AMMコア設計',
  audits: '監査',
  audits_paragraph_2:
    'セキュリティ監査は、リスクを完全に排除するものではありません。特に流動性供給者として、お客様の全財産をRef Financeに提供することはお控えください。',
  admin_keys: '管理者用セキリュティキー',
  admin_keys_paragraph_2:
    '現在のDAOのメンバーは26名です。Ref Financeは完全な分散型DAOに移行する予定です。',
  admin_keys_paragraph_3:
    'Ref Financeの取引を直接管理している、または現在管理しているコントラクトとアドレスの一覧は以下のとおりです。',
  address: 'アドレス',
  type: 'タイプ',
  mission: 'ミッション',
  Locked: 'ロックされている',
  exchange_contract: 'コントラクトの取り交わし',
  table_body_tr_1:
    '自動化されたマーケットメーカー機能の管理、流動性の交換と提供',
  farming_contract: 'ファーミング用コントラクト',
  table_body_tr_2: '流動性リワードの管理',
  staking_contract: 'ステーキングコントラクト',
  table_body_tr_3: 'xREFの鋳造と破壊、時間に応じたリワードの分配',
  sputnik_dao_contract: 'Sputnik DAOのコントラクト',
  table_body_tr_4:
    'スマートコントラクトの修正を含む戦略的な意思決定により、Refの成功を確かなものにする。',
  table_body_tr_5:
    '特定のコミュニティコントリビューターの資金を管理し、配分する',
  table_body_tr_6: '実装戦略およびロードマップ',
  vesting_contract: 'ベスティングコントラクト',
  table_body_tr_7: 'Dev DAOメンバーのREFの権利確定コントラクトの管理',
  airdrop_contract: 'エアドロップコントラクト',
  table_body_tr_8: 'REFの最初のドロップを管理',
  near_address: 'NEARのアドレス',
  table_body_tr_9: '1回限りのエラーリワードの支払い管理',
  fungible_token_contract: '代替トークンコントラクト',
  table_body_tr_10: 'REFトークンの鋳造',
  rug_pull: 'カーペットプル',
  rug_pull_paragraph_1:
    'もしトークンの背後にいるチーム（ホワイトリスト入りかどうかに関わらず）がプロジェクトを放棄し、投資家の資金を受領することを決めた場合、そのプロジェクトのトークンは0ドルの価値になる可能性があります。',
  rug_pull_paragraph_2:
    'Ref Financeでトークンがホワイトリストに掲載されても、プロジェクトの成功を意味するものではありません。プロジェクトに関するデューデリジェンスはお客様ご自身の責任で行っていただき、暗号資産は非常にリスクの高い投機的な投資であることをご認識ください。',
  rug_pull_paragraph_3:
    '投資資金の一部または全部を失う可能性があることを認識する必要があります。',
  divergence_loss: '分散損失',
  divergence_loss_paragraph_1:
    '流動性を提供する場合、流動性を提供しない方が儲かることに注意してください。',
  divergence_loss_paragraph_2:
    '分散損失は、しばしば不適切に「無常損失」と呼ばれる。この形容詞（impermanent）は、損失は単に無常であり、必ず回復するものであるというマーケティング上の認識を前提にしている、あるいは作り出す可能性があるが、これは正しくありません。',
  divergence_loss_paragraph_3: '分散損失について詳しくはこちら',
  staking_risks: 'ステーキングリスク',
  staking_risks_paragraph_1:
    'ステーキングの際には、様々なスマートコントラクト製品を使用することになり、それぞれにリスクがあります。',
  permanent_loss_of_a_peg: 'ペグの永久欠番',
  permanent_loss_of_a_peg_paragraph_1:
    'プール内の安定した通貨がペグ1.0を大幅に下回り、ペグに戻ることがない場合、プール流動性供給者がその通貨の流動性をほとんどすべて保有していることを事実上意味します。',
  systemic_issues: 'システム上の問題',
  systemic_issues_paragraph_1:
    '一般に、DeFiや貨幣型LEGOは高度に相互接続されているため、構成要素の1つが故障すると、連鎖的に故障が発生する可能性があります。',
  systemic_issues_paragraph_2:
    'システムリスクとは、失敗が投資・リスクと直接関係なくても、損をする可能性があるということです。',
  systemic_issues_paragraph_3:
    '流動性プールに影響を及ぼす可能性のあるリスクには以下のようなものがあります。',
  systemic_issues_paragraph_4:
    'レンディングコントラクトにおけるスマートコントラクトの問題点',
  systemic_issues_paragraph_5:
    'エンタイトルメントコントラクトにおけるスマートコントラクトの問題点',
  systemic_issues_paragraph_6:
    'これらのステーブルコインをプールした場合のシステム上の問題点',
  systemic_issues_paragraph_7:
    'これらのプールにおけるERC20ネイティブトークンに関するシステム上の問題点',
  crypto_trading_addiction: '暗号通貨取引依存症',
  crypto_trading_addiction_paragraph_1:
    '暗号通貨の取引は中毒性があり、多くの情報源によると、人生を破壊しかねないギャンブル中毒の一種であります。',
  crypto_trading_addiction_paragraph_2:
    'この問題に関連する記事を以下にまとめましたので、ご覧ください。',
  crypto_trading_addiction_paragraph_3: '"取引はギャンブルだ、間違いない"',
  crypto_trading_addiction_paragraph_4:
    '"ビットコインの取引で50万ポンドを失った"',
  crypto_trading_addiction_paragraph_5:
    '"暗号通貨取引中毒を治療するセラピストに話を聞く"',
  crypto_trading_addiction_paragraph_6:
    '"暗号通貨取引依存症で数百万円を失った"',
  no_in_progress: 'いいえ（進行中）',
  yes: 'はい',
  no: 'いいえ',
  NA: '該当事項はありません。',
  ref_finance_near: 'ref-finance.near',
  ref_finance_near_mission:
    'v1 (旧バージョン) - 自動マーケットメーカー機能の管理、流動性の交換と提供',
  token_ref_finance_near: 'token.ref-finance.near',
  token_ref_finance_near_type: '代替トークンコントラクト',
  token_ref_finance_near_mission: 'v1 (旧バージョン) - Mint REFトークン',
  refchef_near: 'refchef.near',
  simple_address: 'シンプルアドレス',
  refchef_near_mission: 'アカウント間取引の管理',
  ref_dev_teller_near: 'ref-dev-teller.near',
  Locked_title: 'ロックされている？インフォボックス：ホバー表示時。',
  Locked_paragraph_1:
    'コントラクトをロックすることは、コントラクトコードの再展開を可能にするアクセスキーがないことを意味します。',
  Locked_paragraph_2:
    '一般に、コードの再展開は以下の方法で行うことができます。',
  Locked_paragraph_3: '1. デプロイメントコード操作によるトランザクション',
  Locked_paragraph_4:
    '2. コントラクト自体が、コード操作の展開をトリガーする関数呼び出しを実装できる。',
  Locked_paragraph_5:
    'トランザクションを使用してコードを再展開するには、フルアクセスキーを使用してトランザクションに署名する必要があります。コントラクトにそのようなキーがない場合、コントラクトコード自体が特別にサポートされていない限り、コードを再展開することはできないので、そのようなコントラクトはロックされているとマークしています。',
  Locked_paragraph_6:
    'コントラクトアカウントに少なくとも1つのフルアクセスキーが登録されている場合、コントラクトはロックされません。',
  audited_first_sentence:
    'Ref Financeのスマートコントラクトは<a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/audits"' +
    '>監査</a>されています。',
  admin_first_sentence:
    'Ref Financeによる <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO</a> マネジメントを行う必要があります<a crel="noopener noreferrer nofollow" target="_blank" lass="underline cursor-pointer text-riskTextColor hover:text-white" href="https://gov.ref.finance/t/introducing-the-guardians/253" ' +
    '>ガーディアンズ</a>，特定のNEARアドレスはコントラクトを停止することができます。DAOだけが、いつでもコントラクトを再開することができます。',
  risks_of_using_ref_finance:
    'Ref. financeの利用<label class="text-greenColor">リスク</label>',
  admin_sentence_1:
    'Ref Financeは、<a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO</a> によって管理されており、完全な分散型DAOに移行する予定です。',
  admin_sentence_2:
    'Ref Financeの業務を直接管理している、または現在管理している契約および住所の詳細については、 <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/contracts"' +
    '>ドキュメント</a> を確認してください。',
  third_party_wallet: 'サードパーティのウォレット',
  third_party_wallet_1:
    'Ref Finance はサードパーティのウォレットを統合します。',
  third_party_wallet_2:
    '当社は信頼できるウォレットプロバイダーを選択するための措置を講じていますが、そのセキュリティやパフォーマンスを保証することはできません。 特定のウォレットプロバイダーに関連するリスクをよく理解し、サービス利用規約、プライバシーポリシー、およびセキュリティ慣行を理解する必要があります。',
  third_party_wallet_3:
    'これらのサービスはご自身の責任でご利用いただき、十分にご注意ください。',
};
const ja = {
  deposit: 'デポジット',
  Deposit: 'デポジット',
  withdraw: '抽出',
  Withdraw: '抽出',
  withdraw_token: 'トークンの払い戻し',
  swap: 'スワップ',
  Swap: 'スワップ',
  exchange_tokens: 'トークンのスワップ',
  pool: 'プール',
  Pool: 'プール',
  pools: 'プール',
  Pools: 'プール',
  Quiz: 'クイズ',
  no_tokens_deposited: 'トークンの預け入れがない',
  view_pools: 'プールを見る',
  view_pool: 'プールを見る',
  add_token: 'トークンの追加',
  Add_Token: 'トークンの追加',
  create_new_pool: 'フロープールの作成',
  Create_New_Pool: 'フロープールの作成',
  your_liquidity: 'あなたの流動性',
  Your_Liquidity: 'あなたの流動性',
  farms: 'ファーム',
  Farms: 'ファーム',
  airdrop: 'エアードロップ',
  Airdrop: 'エアードロップ',
  docs: 'ドキュメント',
  Docs: 'ドキュメント',
  community: 'コミュニティー',
  Community: 'コミュニティー',
  more: 'もっと見る',
  More: 'もっと見る',
  account: 'アカウント番号',
  Account: 'アカウント番号',
  New_ui: '新しいUI',
  Risks: 'リスク',
  Forum: 'フォーラム',
  Discord: 'Discord',
  Telegram: 'Telegram',
  Twitter: 'Twitter',
  Medium: 'Medium',
  move_assets_to_from_ethereum: 'イーサリアムをブリッジする',
  rainbow_bridge: 'イーサリアムをブリッジする',
  deposit_to_swap: 'トークンを預けてスワップする',
  connect_to_near: 'ウォレットの接続',
  slippage: 'スリッページ',
  slippage_title: 'トレーディングセットアップ',
  minimum_received: '受け取り可能な最低金額',
  swap_rate: '為替レート',
  swap_rate_pre: '為替レート',
  pool_fee: '取扱手数料',
  balance: 'バランス',
  from: 'から',
  to: 'まで',
  submit: '提出',
  save: 'Save',
  wrapnear: 'Wrap NEAR',
  wrapnear_tip_one: 'wNEARは、REFでの取引をサポートします。',
  wrapnear_tip_two: '最低でも0.5つはNEARにしてください',
  wrapnear_tip_three: 'ガス料金。',
  wrap_error_msg: 'バランス不足',
  sign_out: '退会',
  view_account: 'アカウントを見る',
  deposit_to_swap_and_add_liquidity:
    'スワップまたは流動性向上のためのトークンの預け入れ',
  small_storage_fee_is_applied_of: '保管料は',
  liquidity: '流動性',
  tokens: 'トークン',
  fee: '手数料',
  fees: '手数料',
  view_detail: '詳細を見る',
  pool_details: 'プールの詳細',
  details: '詳細',
  detail: '詳細',
  liquidity_pools: '流動性プール',
  minimum_tokens_out: '最低限必要なトークン数',
  pair: '取引ペア',
  tvl: 'TVL',
  search_pools: 'プールを探す',
  add_liquidity: '流動性の追加',
  remove_liquidity: '流動性の除去',
  total_liquidity: '流動性の総計',
  coming_soon: 'ご期待ください',
  accumulated_volume: '累計売上高',
  underlying_liquidity: '基礎的な流動性',
  total_shares: '総保有額',
  my_shares: '保有額',
  token: 'トークン',
  enter_token_address: 'トークンアドレスの入力',
  you_are_not_providing_liquidity_to_any_pools:
    'プールの流動性を提供していない',
  remove: '除く',
  select: 'トークンを選択する',
  select_token: 'トークンを選択する',
  basis_points: 'ベースポイント',
  total_fee: '総コスト率',
  protocol_fee_is: 'プロトコル料は',
  start_in: '開始する',
  start_at: 'で開始する',
  started_at: 'で開始しました',
  start_date: '開始時間',
  end_at: 'で終了',
  end_date: '閉館時間',
  earn: '稼ぐ',
  ended: '終了している',
  pending: 'ペンディング',
  pending_cap: 'Pending',
  pending_orders_title: 'Pending Close Orders',
  rewards_per_week: '毎週のリワーディング総額',
  apr: '年率換算',
  total_staked: 'ステーキングの合計金額',
  unclaimed_rewards: '未受領のリワード',
  claim: 'クレーム',
  claim_all: '全てクレームする',
  stake: 'ステーキング',
  unstake: 'ステーキングの取り消し',
  your_rewards: 'あなたのリワード',
  your_shares: '保有額',
  shares_owned: '現在の保有額',
  stake_your_liquidity_provider_LP_tokens: 'LPトークンをステーキングする',
  go_to_wallet: 'ウォレットにアクセス',
  recent_activity: '最近の操作',
  view_all: '全て表示',
  cancel: '取り消す',
  claim_ref_token: 'REFトークンを請求する',
  has_no_available_claim: 'エアードロップ額がない',
  sorry: '申し訳ありません',
  total_ref_token: 'REFトークンの合計金額',
  locking_ref_token: 'REFトークンのロック量',
  unclaim_ref_token: 'REFトークンの未クレーム額',
  unlocked: 'アンロック',
  locking: 'ロック中',
  you_can_claim_from: '以下の時間からクレームが可能になります。',
  you_have_claimed_all_your_rewards: 'すべてのリワードをクレームしました',
  ends_in: 'で終了',
  days: '日後',
  swap_successful_click_to_view:
    'スワップに成功しました、詳細はこちらをご覧ください。',
  claim_successful_click_to_view:
    'クレームに成功しました。詳細はこちらをご覧ください。',
  claim_failed_click_to_view:
    'クレームに失敗しました、詳細はこちらをご覧ください。',
  slippageCopyForMobile:
    'スリッページとは、他の取引が先に約定した結果、期待したものと実際に得られたものとの差のことです。',
  slippageCopy:
    'スリッページとは、他の取引が先に約定した結果、期待したものと実際に得られたものとの差のことです。',
  depositCopy:
    '交換または流動性プールに追加するために、選択したトークンを入金します。',
  nearDepositCopy:
    'パック Ⓝ 交換のために預けたり、流動性プールに追加したりすること。',
  nearWithdrawCopy: 'Ⓝを取り出し、トークンをウォレットに移動させます。',
  withdrawCopy: '好きなトークンを取り出して、ウォレットに預けます。',
  registerToken:
    'トークン一覧にないトークンを新規に登録する場合は申請してください。',
  whitelistTokenCopy: 'NEP-141 トークンを追加する。',
  addLiquidityPoolCopy:
    '選択した2つのトークンペアに対して、新しい流動性プールを作成します。<br>コンバージョンフィーとは、各取引から流動性プールが受け取る割合のことです。',
  swapCopy:
    '交換取引とは、第1選択トークンと第2選択トークンを交換することである。<br> 最も流動性が高く、取引コストが低いプールを使用します。',
  addTokenCopy: 'NEP-141 トークンを追加する。',
  getLPTokenCopy:
    '最も流動性が高く、取引コストが低いプールを使用します。 NEP-141 トークンを追加する。',
  airdropCopy:
    '締切日までに少なくとも一回エアドロップを回収してください。回収されない場合、残高はトレジャリーに寄付されます。',
  farmRewardsCopy: 'これは、実績ではなく、価格に基づいた指標値です。',
  totalValueLockedCopy: 'ロック額の合計',
  topPoolsCopy:
    '各ペアについて、最も高いロックポジションを持つ流動性プールのみが表示されます。',
  you_do_not_have_enough: '足りません',
  must_provide_at_least_one_token_for: 'Must provide greater than 0 token for',
  is_not_exist: '存在しません',
  must_input_a_value_greater_than_zero:
    '0より大きい値を入力する必要があります。',
  amount_must_be_not_greater_your_balance:
    '入力された値は、残高の金額より大きくはできません',
  no_pool_available_to_make_a_swap_from: 'どのプールも提供できません',
  for_the_amount: 'の量です',
  no_pool_eng_for_chinese: 'トランザクションの',
  is_not_a_valid_swap_amount: 'スワップ金額の誤り',
  not_nep_address:
    '入力されたアドレスはNEP-141のアドレスではありません。ご確認の上、再入力してください。 他のメインネットワークコントラクトであれば、レインボーブリッジでブリッジ処理を行います。',
  not_correct_address:
    '入力されたアドレスが正しくありません。確認後、再度入力してください。',
  popular_tokens: '一般的なトークン',
  asset_label: 'アセット',
  account_label: 'アカウント',
  total_label: '合計',
  top_pools: 'トッププール',
  pool_id: 'プールのID',
  more_pools: 'プールをもっと見る',
  my_watchlist: '私のコレクション',
  h24_volume: '24時間取引量',
  volume: '取引量',
  hide_low_tvl_pools: '低ロックアッププールを隠す',
  hide_low_tvl_pools_mobile: '低ロックアッププールを隠す',
  watchlist_title: '私のトップウォッチリスト',
  add_watchlist: 'ウォッチリストに追加',
  remove_watchlist: 'ウォッチリストから除外する',
  my_watchlist_copy: 'ブックマークされたプールはここに表示されます',
  search_token: 'トークンの検索',
  language: '言語',
  deposit_to_add_liquidity: 'トークンを預けて流動性を高める',
  slip_warn: '注意：受け取れる最低金額を確認してください',
  slip_invalid: '有効な値を入力してください',
  input_to_search: 'を入力して検索します。',
  click_search_bar_to_search: '検索アイコンをクリックすると検索できます',
  reward_tokens: 'リワードトークン',
  detail_tip: '詳細',
  no_data: 'データなし',
  stable_swap: 'StableSwap',
  StableSwap: 'StableSwap',
  exchange_rate: 'Exchange rate',
  including_fees: '(料金込)',
  standard_gas: '基本的なガスフィー',
  rst_token: 'RSTトークン',
  remove_tip: '流動性除去のための株式選択手数料は無料',
  share_liquidity: '流動性のシェア',
  remove_token_confirm: '3枚のトークンの受け取り数',
  remove_as_one_token: '1種類のトークンしか受け取らないように選択する',
  flexible_tip: '各トークンを何個ずつ受け取るか選択',
  add_type_all: 'フロープールに比例してトークンを追加する',
  add_type_max: 'すべての残高をフロープールに追加する',
  select_balance: '資産の出所を選択する',
  selectBalanceCopy:
    '[NEAR Wallet]を選択すると、ウォレット内の資産で直接取引することができます',
  near_wallet: 'NEARウォレット',
  ref_account: 'REFアカウント',
  swap_tip:
    'REFアカウントから交換する場合は、設定からREFアカウントを選択します。',
  attention: 'ご注意ください',
  live: '実行',
  ended_search: '検索の終了',
  my_farms: 'あなたのファーム',
  staked_only: 'ステーキングのみ',
  sort_by: '並び替え',
  your_farms_rewards: 'あなたのファームリワード',
  your_farms: 'あなたのファーム',
  new: '最新',
  unstake_tip_t: '未受領のリワードがある...',
  unstake_tip_m:
    'ステーキングのキャンセルにより、同じペアのアクティブおよび非アクティブのすべてのファームからすべてのステーキングが取り消されます。',
  unstake_tip_b: 'ステーキングをキャンセルしますか？',
  value_rewards: 'リワード',
  stake_unstake_tip:
    'ステーキングやステーキングをキャンセルすると、自動的にリワードが回収されます',
  over_tip: 'ガスフィーの最適化のため、一度に4トークンまで引き出しが可能です',
  no_token_tip: '出金可能なトークンはありません',
  getToken_tip:
    '今すぐリワードやステーキングトークンを請求することができます。',
  in_farm: 'ファームの中で',
  swap_rate_including_fee: '為替レート(手数料込み)',
  by_share: 'シェア',
  by_token: 'トークン',
  your_positions_will_be_displayed_here: '参加したプールはここに表示されます。',
  price_impact: '価格への影響',
  shares_left: '残額',
  total_stable_coins: 'ステーブルコインの総数',
  daily_volume: '24時間取引量',
  liquidity_utilisation: '取引活発度',
  token_reserves: '統計',
  roi_calculator: 'ROI計算機',
  lp_staked: 'リキッドトークンのステーキング',
  usd: '米ドル',
  lp_token: '流動性トークン',
  stake_for: 'ステーキング時間',
  day_1: '1日',
  day_2: '7日',
  day_3: '30日',
  day_4: '90日',
  day_5: '1年',
  cur_apr: 'ROI',
  reward_token: 'リワードトークン',
  get_lp_token: '流動性トークンへのアクセス',
  calculate_roi: 'ROI計算機',
  rewards_claimed: '受領可能なリワードあり',
  value_rewards_token: 'リワードトークンの価値',
  all: 'すべて選択',
  all_5: '一度に4種類までのトークン',
  insufficient_shares: '使用可能な額を超えています',
  shares_removed: '流動性',
  shares_avaliable: '取得可能額',
  minimum_shares: 'ミニマム額',
  shares_tip: '利用可能株式数/総株式数',
  stablecoin_only: 'ステーブルコイン',
  more_than: '0.01以上の数値を入力してください。',
  less_than: '20未満の数字を入力してください',
  no_valid: '有効な番号を入力してください',
  lp_fee: '流動性供給者',
  protocol_fee: 'プロトコルフィー',
  referral_fee: 'リファラルフィー',
  protocol_fee_referral_fee: 'プロトコル料金と紹介料',
  total_fee_create: 'トータルコスト',
  xref_title: 'より多くの収益を得るため',
  xref_title1: 'Earn more',
  xref_title2: 'by staking REF for xREF',
  xref_introdution:
    'REFを担保にすることで、コントラクトによって発生する手数料を得る機会があり、REF保有者は誰でもREFファイナンスの収入を共有することができます。',
  staking_apr: 'ステーキングARR',
  view_stats: '統計デーたを見る',
  xref: 'xREF',
  ref: 'REF',
  more_than_general_seed: '0.000001以上の数値を入力してください。',
  more_than_stable_seed: '1以上の数値を入力してください。',
  are_you_sure: '確定しますか',
  price_impact_is_about: '価格への影響は約',
  yes_swap: '確定、スワップします',
  amount_must_be_greater_than_0: '数量は0より大きい必要があります',
  volume_ratio: '24時間取引量／流動性比率',
  go_to_near_wallet: 'NEAR Walletへスキップする',
  into_ref_account: 'をREFアカウントに登録します。',
  deposit_into_ref_account: 'REFのアカウントにアクセスして、流動性を追加する',
  deposit_near_tip:
    '預け入れ時のガソリン代として、少なくとも1枚のNEARを保管すること',
  input_greater_than_available_shares: '入力が利用可能なシェアより大きい',
  number_of_unique_stakers: 'ステーキング人数',
  total_ref_staked: 'ステーキングしたREFの総数',
  total_xref_minted: 'xREFの総生産数',
  protocol_projected_revenue: 'xREFホルダーとの収入共有',
  total_fee_Revenue_shared_with_xref_holders:
    'xREFオーナーに配分される手数料の合計',
  provision_treasury: 'トレジャリーの収益',
  protocol_projected_revenue_tip:
    'この数字は、xREFホルダーの共有取引手数料の累積収入に相当します。 これは、プラットフォームの手数料収入全体の75%に相当します。 価格変動により、実際のREFトークン買戻し額とは異なります。',
  stake_ref_to_xref_for_earning_more:
    'REFを寄付してxREFと交換することで、より多くの収入を得ることができます。',
  auto_router: 'トランザクションルート',
  auto_router_detail_sub_1:
    'このルートは、このトランザクションのために最適化されています。',
  auto_router_detail_sub_2: '現在のペアのすべてのプールを考慮します。',
  auto_router_detail_sub_3: '詳細',
  introducing_parallel_swap: 'パラレルスワップの導入',
  at_least: '少なくとも',
  xref_start_time: '2022年1月18日午前1時（UTC）より開始。',
  xref_to_receive: 'xREF受信',
  ref_to_receive: 'REF受信',
  more_expensive_than_best_rate_en: 'ベストレートより高い',
  optimal_path_found_by_our_solution: '私たちが見つけた最適のルート',
  check_to_stable_banner: '表示',
  rates_to_stable_banner: '為替レート',
  make_sure_you_understand_what_you_do: '現在行っていることをご確認ください',
  go_to_your_account_to: 'xアカウントにアクセスする',
  deposit_l: '預ける',
  withdraw_l: '抽出',
  got_it: '了解です',
  check_the_transaction_settings: 'トランザクションの設定を見る',
  filter_by: 'フィルタリング',
  allOption: '全て',
  stablecoin: 'ステーブルコイン',
  near_ecosystem: 'Nearエコシステム',
  bridged_tokens: 'ブリッジされたトークン',
  gaming: 'ゲーム',
  nft: 'NFT',
  bridge: 'ブリッジング　まで／から',
  from_ethereum: 'イーサリアム',
  from_aurora: 'Aurora',
  from_solana: 'Solana',
  from_terra: 'Terra',
  from_celo: 'Celo',
  no_result: 'ファームが見つかりませんでした',
  multiple: '倍率',
  default: 'デフォルト',
  vi_go_live: 'ベトナム版を公開しました',
  do_not_need_to_deposit_in_ref: 'Ref Financeのデポジットが不要になりました!',
  you_can_add_liquidity_directly:
    'NEARウォレットを使用して直接流動性を追加できるようになりました。',
  do_not_need_deposit_anymore: 'もう入金する必要はありません！',
  lightning_swaps: '軽量の交換作業',
  frictionless_add_remove_liquidity: '可動性の追加・削除がスムーズ',
  create: '作成',
  select_a: '1つ選択してください',
  wallet: 'ウォレット',
  Wallet: 'Wallet',
  to_use_ref_finance: 'Ref.Financeを利用すると',
  not_supported: '非対応',
  installed: 'インストール済み',
  installe_now: '今すぐインストール',
  extension: 'プラグイン',
  first_time_using_ref: '初めてRefを使用する',
  learn_more: 'もっと見る',
  install_sender_now: '今すぐSenderをインストールする',
  connect_to_dapps_with_one_click: 'ワンクリックでdAppに接続',
  install: 'インストール',
  Connecting: '接続中',
  check_sender_wallet_extension: 'Sender walletプラグインをご確認ください。',
  having_trouble: '問題点は？',
  go_back: '戻る',
  web: 'Web',
  Type: 'タイプ',
  click_to_view: '詳細はこちら',
  transaction_failed: '失敗したトランザクション',
  wallet_vi: ' ',
  value: '額',
  usd_value: 'ドル換算額',
  sauce_note:
    'SAUCEは、資産が固定された流動性プール向けに設計されており、最適な価格を提供します。',
  support_ledger: 'Ledgerをサポートする',
  initialize_account_tip: 'Please initialize your account.',
  support_ledger_tip:
    'Ledgerの設計によると、メモリの制限のため、Ledgerの台帳が大口取引に対応できない（自動ルーティングなど:複数のプールにまたがって同時に取引する）。 起動後,Ledgerをサポートする台帳は、トランザクションを次のように制限します。最もシンプルな形で（価格向上に寄与しない）。そのため、それなりの規模のコントラクトを結ぶことが可能です。',
  start: 'スタート',
  aprTip: '全リワード製品（未着手・進行中）を対象とした指標値です。',
  half: '半分',
  max: '最大',
  ref_account_balance_tip: 'モビリティの追加/削除でエラーが発生したようです。',
  ref_account_tip_2: 'REFのアカウントにトークンがある場合',
  ref_account_tip_top: 'お客様のREFアカウントにトークンを反映しております',
  click_here: 'ここをクリック',
  to_recover_them: '修復',
  ref_account_tip_3:
    'REFアカウントからトークンを引き出し、自分のNEARウォレットへ送金',
  aurora_account_tip_4:
    'マッピングアカウントからNEARウォレットにトークンを引き出すには、選択して引き出してください',
  pool_fee_cross_swap: 'プール/チェーン間 手数料',
  mapping_account: '口座をマッピングする',
  mapping_account_tip: 'マッピングアカウントにトークンがあります',
  cumulative_ref_buyback: 'Cumulative REF Buyback',
  yearly_revenue_booster: 'Yearly Revenue Booster',
  Confirm: '確認',
  Request_for_Quote: '見積もり依頼',
  mapping_account_explanation:
    "A mapping address is a 'proxy address', which maps the user's NEAR account with a corresponding ETH address on Aurora.",
  buy: '買う',
  sell: '卖',
  buy_nsn: '買う USN',
  buy_nsn_tip:
    'USNは、NEARエコノミーに支えられたネイティブのステーブルコインです',
  usn_tip_one: 'USNを使用すると、REFで取引できます。 必ず ',
  rate: 'レート',
  name: '名前',
  diff: '差分',
  best: '一番',
  trading_fee: '取引手数料',
  usn_successful_click_to_view: '取引は成功しました。 クリックして表示',
  awesomeNear_verified_token: 'AwesomeNear検証済みトークン',
  usn_fee_tip:
    'Refによって請求されるゼロ料金、ユーザーはUSN料金のみを支払います!',
  total_bitcoins: '総ビットコイン',
  total_usd_value: '合計米ドル値',
  bitcoin_value: 'ビットコインの価値',
  stable_coin_value: 'ステーブルコイン価値',
  pool_fee_apr: 'Pool fee APY',
  ref_account_balance_tip_mobile: 'REFアカウントにトークンがあります。',
  click: 'クリック',
  to_recover: '回復する。',
  rewards_week: 'Rewards/week',
  rewards: 'Rewards',
  claimed_Rewards: 'Claimed Rewards',
  POOL: 'プール',
  STAKE: 'STAKE',
  lp_Token: 'LP Tokens',
  booster: 'Booster',
  slash_policy: 'Slash Policy',
  stake_period: 'Stake Period',
  append: 'Append',
  append_staking: 'Append Staking',
  unstake_time: 'Unstake Time',
  farm: 'Farm',
  reward_Token: 'Reward Token',
  status: 'Status',
  value_of_rewards: 'Value of Rewards',
  Rewards: 'Rewards',
  free: 'Free',
  lp_tokens: 'LP tokens',
  connect_to_wallet: 'Connect Wallet',
  anytime: 'Anytime',
  slash_policy_content:
    'Each seed has its own slash rate. And it will decreases linearly over time until be 0 at the unlock time of this CD Account.',
  not_expired: 'Not expired',
  accept_pay_slash_tip: 'I accept to pay slash',
  i_will_pay: 'I will pay',
  lp_token_slash: 'LP token exit fee.',
  booster_change_reason: 'Booster will change due to appending time',
  initial_booster: 'Initial Booster',
  append_timing: 'Append timing',
  cd_limit: 'more than 16 cd account limit',
  expired: 'Expired',
  all_5_v2: '5 tokens/time',
  all_4_v2: '4 tokens/time',
  more_than_seed: 'Input must be greater than or equal to ',
  how_to_earn_more: 'How to earn more?',
  you_shares: 'Your Shares',
  lock_your_lp_tokens_with_booster: 'Lock your LP tokens for boosted rewards',
  unstaked_anytime_no_booster: 'Unstaked anytime, no booster',
  ordinary_stake: 'Ordinary Stake',
  locking_stake: 'Lock-up staking',
  change_to_lock: 'Change to Lock-up staking',
  estimated_rewards: 'Estimated rewards',
  final_booster: 'Final Booster',
  existing_amount: 'Existing amount',
  append_amount: 'Append amount',
  will_be_able_to_unstaked_after: 'will be able to unstaked after',
  expected_reward: 'Expected reward',
  you_have: 'You have',
  tokens_small: 'tokens',
  reward_apr: 'Rewards APR',
  price_board: 'Price board',
  close: 'Close',
  stake_min_deposit: 'Input must be greater than or equal to ',
  unlock: 'Unlock',
  exit_fee: 'Exit fee',
  end_locking_period: 'End of locking period',
  add_lp_tokens_tip:
    'You need LP tokens to stake into the corresponding farm. First, add liquidity to the pool to get LP tokens.',
  slash_tip: 'Exit fee = Exit fee rate * End of locking period * Unlock amount',
  nonBoosted: 'Non-boosted',
  exit_fee_tip: 'Estimated amount you would pay to break your lock-up period',
  forbiddenTip: 'This farm does not support this duration option',
  unstakeTip: 'Staking or unstaking will automatically claim your rewards.',
  endedFarmsUnstakeTip:
    'Unstaking will remove the stake from all active and pending farms of the same pair. ',
  comimg: 'Coming',
  appendTip: 'Cannot be earlier than current locking time',
  week: 'week',
  yours: 'Yours',
  boost: 'Boost',
  near: 'NEAR',
  eth: 'ETH',
  others: 'Others',
  stakeLove: 'Stake LOVE',
  unstakeLove: 'Unstake LOVE',
  get_love: 'Get LOVE',
  swap_successful: 'スワップが成功しました。 ',
  trading_successfull: '取引は成功しました。 ',
  total_near_amount: '合計NEAR量',
  near_value: 'NEAR価値',
  borrow: '借りる',
  burrow_usn_tip: 'あなたはBurrowでUSNを借りることができます。',
  migrate: 'Migrate',
  go_to_new_farm: 'Go to New Farms',
  voting_gauge_share_tip:
    'The proportion of the REF used for voting allocation to the total REF reward in this period',
  swap_capital: 'スワップ',
  farm_capital: 'ファーム',
  sauce_capital: 'SAUCE',
  dao_capital: 'DAO',
  available_to_stake: 'available to stake',
  recent_one_mounth_activity: '1ヶ月の活動',
  your_assets: 'あなたの資産',
  connect_wallet: 'ウォレットを接続する',
  disconnect: '切断する',
  change: '変化する',
  wallets_below_supports_on_PC: '以下のウォレットはPCでサポートされています',
  connect: '接続',
  make_sure_device_plugged_in: 'デバイスが接続されていることを確認してください',
  then_enter_an_account_id_to_connect: '次に、接続するアカウントIDを入力します',
  veTip: 'veLP tokens are not transferable, veLPT represent your voting power ',
  loveTip:
    'LOVE stands for Liquidity Of veToken. LOVE, like any NEP-141 token, is transferable and can be swapped, pooled, staked and farmed. LOVE represents the underlying liquidity of your veTokens',
  lock_your_lp_tokens: 'Lock Your LP Tokens',
  unlock_your_defi_power: 'Unlock Your DeFi Power',
  unlock_tip_mobile: 'Unlocking is available now!',
  unlock_tip_top: 'Your locking has been expired, unlocking is available now!',
  bonus_to_be_withdraw: 'bonus to be withdraw',
  keep_ve: 'keep',
  months: 'months',
  month: 'month',
  lock_lp_tokens: 'Lock LP Tokens',
  get_lptoken: 'Get LP Tokens',
  duration: 'Duration',
  ve_lock_tip: 'Cannot be earlier than current unlocking date',
  get: 'Get',
  locking_ve: 'Locking',
  in_addition_to_the: 'In addition to the',
  already_locked: 'already locked',
  unlocking_is: 'Unlocking is',
  you_have_no_lp_share: 'You have no LP Tokens',
  lock: 'Lock',
  unlock_lptoken: 'Unlock LP Tokens',
  locked: 'Locked',
  available: 'Available',
  balance_lowercase: 'balance',
  voting_power: 'Voting Power',
  lock_lp_tokens_first: 'Lock LP Tokens first!',
  farm_booster: 'Farm Booster',
  go_to_farm: 'Go to Farm',
  in: 'in',
  more_lowercase: 'more',
  avaliable_to_lock: 'Available to lock',
  lock_lptoken: 'Lock LP Tokens',
  lptoken_locker: 'LP Token Locker',
  filter: 'Filter',
  bonus: 'Bonus',
  your_shares_ve: 'Your Shares',
  voted: 'Voted',
  my_voting_power: 'My Voting Power',
  new_voting_ratio: 'New Voting Ratio',
  REF_allocation: 'REF allocation',
  vote: 'Vote',
  preview: 'Preview',
  options: 'options',
  ratio: 'Ratio',
  forum_discussion: 'Forum Discussion',
  voted_veLPT: 'Voted veLPT',
  your_contribution: 'Your contribution',
  delete: 'Delete',
  not_start: 'Not start',
  no_veLPT: 'No veLPT',
  claim_bonus: 'Claim Bonus',
  ended_ve: 'Ended',
  proposal_details: 'Proposal Details',
  back: 'Back',
  pending_ve: 'Warming up',
  you_voted: 'You voted',
  add_bonus: 'Add Bonus',
  you_vote: 'You vote',
  Creator: 'Creator',
  turn_out: 'Turnout',
  top_answer: 'Top Answer',
  your_bonus: 'Your bonus',
  gauge_weight_vote: 'Gauge Weight Vote',
  governance: 'Governance',
  confirm: 'Confirm',
  voting_period: 'Voting Period',
  applying_period: 'Applying Period',
  community_gauge_share: 'Community Gauge Share',
  qualified_pools: 'Qualified Pools',
  total: 'Total',
  supply_voted: 'Supply voted',
  proposed: 'Proposed',
  farm_reward: 'Farm Reward',
  check_last_round: 'Check Last Round',
  last_round_voting_result: 'Last Round Voting Result',
  create_proposal: 'Create Proposal',
  type: 'Type',
  add_option: 'Add Option',
  lock_lp_terms:
    "I understand that I won't be able to remove my LP Tokens for the entire duration of the agreed locking period",
  connect_to_near_wallet: 'Connect to NEAR Wallet',
  only_for_whitelist_address: 'Only for whitelist address',
  v1Legacy: 'Legacy',
  v2New: 'Classic-New',
  v2_new_farms: 'Classic farms update',
  migrate_now: 'Migrate Now!',
  v2_boost_tip:
    'The updated classic farms will support boosted farms. The Legacy farms will run dry of rewards on the <span class="font-bold">1st of August 2022</span>.',
  v2_boost_tip2: 'Classic ファームはブースト ファームをサポートします。',
  v2_boost_no_tip:
    'The Legacy farms will run dry of rewards on the <span class="font-bold">1st of August 2022</span>.',
  no_farm_need_to_migrate: 'No Farm need to Migrate',
  v1_farms: 'Legacy Farms',
  has_rewards_tip:
    'You have claimed rewards in <a rel="noopener noreferrer nofollow" class="text-greenColor text-base cursor-pointer underline hover:text-senderHot"  href="/farms" >Legacy Farms</a>. You can withdraw them at any time.</div>',
  love: 'LOVE',
  Available_to_stake: 'Available to stake',
  you_staked: 'You staked',
  has_rewards_tip_in_v2:
    'Withdraw legacy rewards from <a rel="noopener noreferrer nofollow" href="/farms" class="text-sm text-greenColor cursor-pointer underline ml-1 hover:text-senderHot"> Legacy Farms</a>.',
  how_to_get: 'How to get?',
  get_booster: 'Get Booster',
  ended_farms: 'ended farms',
  boosted_farms: 'Boosted farms',
  your_farm: 'Your farm',
  your_boosted_farms: 'Your boosted farms',
  search_farms_by_token: 'Search farms by token',
  search_farms: 'Search farms',
  more_lock_ve_popup: 'more ',
  more_lock_ve_popup2: 'more ',
  more_in: 'more in',
  starts: 'starts',
  left: 'left',
  proposal_title: 'Proposal Title',
  share_forum_discussion_link_here: 'Share forum discussion link here',
  required_field: 'Required field',
  voting_power_and_extra_earnings: 'Voting power & Extra earnings',
  bonus_tip:
    'Voting bonus is designed to encourage users to vote. Your bonus depends on your number of shares',
  yes_continue: 'Yes, continue',
  unlocking_tip:
    'Unlocking LP Tokens will automatically cancel your vote (and associated bonus) in the following proposal(s)',
  create_pool: 'Create Pool',
  search_by_token: 'Search by token',
  your_power: 'Your Power',
  farm_has_boost_tip:
    'Your Power = Your staked LP Tokens * booster (by staking LOVE)',
  farm_no_boost_tip: 'Your Power = Your staked LP Tokens',
  more_than_a_simple_pool: 'More than a simple pool',
  ended_Farms: 'Ended Farms',
  vote_capital: 'VOTE',
  starting_time: 'Starting Time',
  ending_time: 'Ending Time',
  please_choose_voting_duration: 'Please choose voting duration',
  please_choose_proposal_voting_duration:
    'Please choose proposal voting duration',
  days_ve: 'days',
  vote_mobile_capital: 'VOTE',
  risks_capital: 'リスク',
  view_on: 'View on',
  boostFarmTip: 'Get booster by staking LOVE',
  boosted: 'Boosted',
  love_staked: 'Staked LOVE',
  your_apr: 'Your APR',
  range_apr: 'Range or reference APR',
  no_pair_found: 'ペアが見つかりません',
  found_stable_pool_in_sauce: 'SAUCE に安定したプールが見つかりました',
  lp_tokens_locking: 'LP Tokens',
  you_currently_have: '現在持っている',
  scheduled_to_be_unlocked: '解禁予定',
  locking_more_lp_tokens: 'もっとロックする LP Tokens',
  will_mean_these: 'これらを意味します',
  lp_tokens_will_not_be_unlocked_until: 'LP Tokens までロック解除されません',
  "I_understand_that_I_won't_be_able_to_remove_my":
    '削除できないことを理解しています',
  until: 'それまで',
  continue: '継続する',
  then_enter_a_derivation_path_to_connect: '次に、派生パスを入力して接続します',
  account_identification_failed:
    'アカウントの識別に失敗しました! 手動で入力してください',
  input_account_id: 'アカウントIDを入力',
  we_found: '見つけた',
  accounts_on_your_device: 'デバイス上のアカウント',
  select_the_accounts_you_wish_to_connect: '接続したいアカウントを選択',
  can_not_find_any_accounts:
    "Can't found any account associated with this Ledger. Please create a new NEAR account on",
  or_connect_another_ledger: 'or connect an another Ledger',
  add: '追加',
  finish: '終了',
  add_custom_network: 'カスタム ネットワークを追加',
  network_name: 'ネットワークネーム',
  rpc_name_taken_tip: 'ネットワークネームは既に使用されています',
  network_invalid: 'ネットワークが無効でした',
  fobidden_testnet_rpc_tip:
    'RPC サーバーのネットワーク (テストネット) は、このネットワーク (メインネット) とは異なります',
  no_support_testnet_rpc_tip:
    'Testnet はカスタム RPC の追加をサポートしていません',
  stable: 'Stable',
  trade_capital: 'トレード',
  trade_capital_hump: 'トレード',
  pro: 'プロ',
  limit: '指値',
  make_an_order: '注文する',
  your_active_orders_will_appear_here: 'アクティブな注文はここに表示されます',
  your_history_orders_will_appear_here: '注文履歴はここに表示されます',
  liquidity_capital: '流動性',
  goes_to_limit_order: '指値注文に行く.',
  farm_only: '農場のみ',
  please_confirm_this_transaction_on_ledger:
    'Ledgerでこのトランザクションを確認して下さい',
  limit_order: '指値注文',
  newText: '新しい',
  farm_stop_tip: 'このファームは停止されました.',
  pool_stop_tip: 'このプールは停止されました.',
  volume_24h: '24時間ボリューム',
  add_to_watchlist: 'ウォッチリストに追加',
  remove_from_watchlist: 'ウォッチリストから削除',
  create_order: '注文する',
  filled: '約定',
  canceled: 'キャンセルした',
  partially_filled: '部分的に約定',
  pool_composition: 'プールの構成',
  amount: '総額',
  h24_volume_bracket: '24時間ボリューム',
  fee_24h: '24時間手数料',
  cross_chain_swap: 'ブロックチェーンスワップ',
  orders: '注文',
  your_orders: 'ご注文',
  active: 'アクティブ',
  history: '履歴',
  you_sell: '売却',
  you_buy: '購入',
  fee_tiers: '手数料のティア',
  order_rates: '注文レート',
  created: '注文した',
  executed: '約定した',
  actions: 'アクション',
  claimed_upper: 'クレーム',
  open_my_order: 'オープン',
  initial_order: '初期の注文',
  this_order_has_been_partially_filled: 'この注文は部分的に約定された',
  filled_via_swap: 'スワップで約定された',
  state: 'ステート',
  partially: '部分的',
  near_validation_error: 'Must have 0.5N or more left in wallet for gas fee.',
  near_min_validation_error:
    'Must have 0.2N or more left in wallet for gas fee.',
  no_positons_in_this_pool_yet: 'No positons in this pool yet',
  no_claimed_rewards_yet: 'No claimed rewards yet',
  out_of: 'out of',
  value_must_be_less_than_or_equal_to: 'Value must be less than or equal to',
  select_tokens: 'トークンを選択する',
  fee_Tiers: '手数料のティア',
  select_s: '選択',
  no_pool: 'プールなし',
  input_amount: 'インプット金額',
  update_range: '範囲アップデート',
  not_enough_balance: '残高不足',
  set_price_range: '設定価格帯',
  current_price: '現在の価格',
  pool_creation: 'プール作成',
  pool_creation_tip:
    '選択したトークンの既存のプールはありません. プールを作成するには, 対応する金額を指定して, プールレートを設定する必要があります.',
  starting_price: 'スタート価格',
  create_a_pool: 'プールを作成する',
  add_single_price_tip:
    '成行価格が範囲内に移動するまで, ポジションは手数料を獲得したり, 取引に使用されたりしません。',
  invalid_range_tip:
    '無効な範囲が選択されました. 最低価格は最高価格より低くなければなりません.',
  maket_price_outside_tip: '成行価格が価格帯外です.',
  maket_price_outside_single_only_tip:
    '成行価格が価格帯外です. シングルアセットの入金のみ.',
  min_price: '最低価格',
  max_price: '最高価格',
  min: '最低',
  out_of_range: '範囲外',
  in_range: '範囲内',
  unclaimed_fees: '請求されていない手数料',
  your_position_will_be: 'あなたのポジションは',
  at_this_price: 'この価格で',
  price_range: '価格範囲',
  positions: 'ポジション',
  position: 'Position',
  add_new_position: '新しいポジションを追加',
  range: '範囲',
  unclaimed_fee: '請求されていない手数料',
  login_risk_tip:
    'By checking this box and moving forward, you confirm that you fully understand the <a rel="noopener noreferrer nofollow" target="_blank" class="text-greenColor text-sm font-bold cursor-pointer hover:underline" href="/risks" )">risks</a> of using Ref Finance.',
  no_pool_tip: 'Oops! The pool does not exist.',
  total_ref_week: 'Total REF/week',
  ref_week_you_will_get: 'REF/week you will get',
  stable_pool: 'Stable Pool',
  market_rate: 'Market Rate',
  limit_price_tip:
    'To improve deal the efficiency, your price should be in one slot nearby automatically',
  no_token_found: 'No token found',
  search_name_or_address: 'Search name or paste address...',
  token_address_invalid: 'The token address was invalid',
  price_impact_warning: 'Price Impact Warning',
  confirm_order: 'Confirm Order',
  you_Sell: 'You Sell',
  to_Buy: 'to Buy',
  at_Price: 'at Price',
  insufficient_balance: 'Insufficient Balance',
  MORE: 'MORE',
  limitTip: 'Your price is automatically set to the closest price slot',
  v2PoolTip: 'You can have up to 16 positions in DCL pools',
  orderTip: 'You can have up to 16 active/history limit orders',
  v2_pools: 'DCL Pools',
  minimum_received_dot: '受け取り可能な最低金額',
  v2_your_pool_introduction: 'Your discretized concentrated liquidity',
  v1_your_pool_introduction: 'Your liquidity in classic pools',
  estimate_value: 'Estimate Value',
  price: 'Price',
  '@price': '@Price',
  h24_high: '24h High',
  h24_low: '24h Low',
  h24_Volume: '24h Volume',
  orderbook: 'Order Book',
  orderbook_mobile: 'Orderbook',
  trades: 'Trades',
  qty: 'Qty',
  total_orderly: 'Total',
  time: 'Time',
  balances: 'Balances',
  asset: 'Asset',
  wallet_up: 'Wallet',
  account_in_order: 'Account: in Order',
  source_address: 'Source Address',
  action: 'Action',
  market: 'Market',
  order_type: 'Order Type',
  order_type_limit_tip:
    'A limit order is an order to buy or sell at a specific price, or better. Limit orders are not guaranteed to execute',
  order_type_market_tip:
    'A market order is immediately matched to the best available market price, and executed',
  quantity: 'Quantity',
  advanced: 'Advanced',
  tip_fok:
    'Fill-Or-Kill is an order to buy or sell that must be executed immediately in its entirety; otherwise, the entire order will be cancelled.',
  tip_post_only:
    'Post Only ensures that traders can only place an order if it would be posted to the orderbook as a Maker order. An order which would be posted as a Taker order will be cancelled.',
  tip_ioc:
    'Immediate-Or-Cancel is an order to buy or sell that must be filled immediately. Any portion of an IOC order that cannot be filled will be cancelled.',
  Taker: 'Taker',
  Maker: 'Maker',
  see_all: 'See all',
  h24Vol: '24h Vol',
  h24Range: '24h Range',
  chart: 'Chart',
  deposit_assets_to_begin_your_trading_journey:
    'Deposit assets to begin your {br} trading journey.',
  open_orders: 'Open Orders',
  ref_order_only: 'Order on REF only',
  ref_order: 'REF Dex only',
  orderly_portfolio_table_tips:
    'The data provided herein includes all assets and records in your account, not limited to those generated through REF.',
  orderly_ws_refresh: 'Please refresh the data to continue',
  refresh: 'Refresh',
  settled_balance: 'Settled Balance',
  settled_amount: 'Settlement Amount',
  Side: 'Side',
  all_side: 'All Side',
  both_side: 'Both Side',
  both: 'Both',
  filled_qty: 'Filled / Qty',
  fill_qty: 'Fill / Qty',
  est_total: 'Est.Total',
  avg_price: 'Avg.Price',
  create_time: 'Create Time',
  spot: 'Spot',
  futures: 'Futures',
  All: 'All',
  Filled: 'Filled',
  Fees: 'Fees',
  Cancelled: 'Cancelled',
  Rejected: 'Rejected',
  all_instrument: 'All Instrument',
  available_orderly: 'Available',
  history_orderly: 'History',
  settle_pnl: 'Settle PnL',
  settle_pnl_tips:
    'By doing this, we’ll move your profit or loss from perp markets into the USDC.e token balance. This has no impact on your open positions or health.',
  total_unsettled_pnl: 'Total unsettled PnL',
  fut_unreal_pnl: 'Fut. Unreal. PnL',
  fut_daily_real: 'Fut. Daily Real.',
  fut_notional: 'Fut. Notional',
  fut_unsettle_pnl: 'Unsettle PnL',
  settle: 'Settle',
  mark_orderly: 'Mark',
  avg_open: 'Avg. Open',
  liq_price: 'Liq. Price',
  unreal_pnl: 'Unreal. PnL',
  daily_real: 'Daily Real',
  market_price: 'Market Price',
  NEW: 'New',
  PARTIAL_FILLED: 'Partial Filled',
  limit_price: 'Limit Price',
  unsettled_pnl: 'Unsettled PnL',
  notional: 'Notional',
  assets: 'Assets',
  limit_orderly: 'Limit',
  'qty.': 'Qty.',
  no_orders_found: 'No orders found',
  no_records_found: 'No records found',
  its_empty: "It's Empty!",
  current_orderly: 'Current',
  cancel_order: 'Cancel Order',
  post_only: 'Post Only',
  fok: 'FOK',
  ioc: 'IOC',
  instrument: 'Instrument',
  open_order_detail: 'Open Order Detail',
  history_order_detail: 'History Order Detail',
  select_orderly: 'Select ',
  change_orderly: 'Change',
  token_orderly: 'Token',
  user_foot_tip:
    '* This Orderbook page is a graphical user interface for trading on Orderly Network, and is provided as a convenience to users of Ref Finance.',
  learn_more_orderly: 'Learn more',
  more_order_book_page_detail:
    'This Orderbook page is powered by Orderly Network, users are strongly encouraged to do their own research before connecting their wallet and/or placing any orders.{br} Ref Finance does not guarantee the security of the systems, smart contracts, and any funds deposited or sent to those systems and contracts.{br} Neither Ref Finance nor Orderly Network is responsible for any profit or loss of investment users made through this Orderbook page.',
  learn_more_about: 'Learn more about',
  learn_more_about_zh: ' ',
  by_click_confirm:
    'By clicking "Confirm", you confirm that you have comprehensively reviewed and comprehended the contents aforementioned',
  connect_to_orderly_account:
    "You need to (re)connect your Orderly account to use Ref's Orderbook.",
  first_register_orderly_tip:
    'Your wallet must first be registered with Orderly in order to use the Orderbook.',
  must_register_tip:
    'Your wallet must be registered with Orderly to trade on their system.',
  register_deposit_tip: 'Registering will require a storage deposit',
  increase_storage_deposit:
    'You may need to increase the storage deposit on your Orderly account.',
  deposit_storage_fee: 'Deposit storage fee',
  register_orderly_account: 'Register Orderly Account',
  register: 'Register',
  connect_to_orderly: 'Connect to Orderly',
  order_cancelled: 'Order Cancelled.',
  order_edit_success: 'Order Edit Successfully!',
  created_orderly: 'Created',
  open_orderly: 'Open',
  dex: 'Dex',
  min_price_should_be_higher_than_or_equal_to:
    'Min price should be higher than or equal to',
  price_should_be_lower_than_or_equal_to:
    'Price should be lower than or equal to',
  price_should_be_a_multiple_of: 'Price should be a multiple of',
  price_should_be_greater_than_or_equal_to:
    'Price should be greater than or equal to',
  the_order_value_should_be_be_greater_than_or_equal_to:
    'The order value should be greater than or equal to',
  quantity_to_buy_should_be_greater_than_or_equal_to:
    'Quantity to buy should be greater than or equal to',
  quantity_to_sell_should_be_greater_than_or_equal_to:
    'Quantity to sell should be greater than or equal to',
  quantity_to_buy_should_be_less_than_or_equal_to:
    'Quantity to buy should be less than or equal to',
  quantity_to_sell_should_be_less_than_or_equal_to:
    'Quantity to sell should be less than or equal to',
  quantity_should_be_a_multiple_of: 'Quantity should be a multiple of',
  at_least_one_of_order_quantity_or_price_has_to_be_changed:
    'At least one of order quantity or price has to be changed',
  quantity_should_be_higher_than: 'Quantity should be higher than',
  price_should_be_higher_than: 'Price should be higher than',
  insufficient_en: 'Insufficient',
  the_all_data_orderly_tip:
    'The data provided herein includes all assets and records in your account, not limited to those generated through REF.',
  at_orderly: 'at',
  change_the_quantity_of: 'Change the quantity of',
  change_the_price_of: 'Change the price of',
  market_order_filled: 'Market Order Filled',
  limit_order_created: 'Limit Order Created',
  limit_order_filled: 'Limit Order Filled',
  open: 'Open',
  to_buy: 'To Buy',
  to_sell: 'To Sell',
  in_open_orders: 'In Open Orders',
  your_investment: 'Your Investment',
  your_investment_tip:
    'USD value of your investment on Ref:Classic pools + DCL pools (including staked in farms) + xREF',
  classic_pools: 'Classic Pools',
  xref_staking: 'xREF Staking',
  token_balances: 'Token Balances',
  unclaimed_earnings: 'Unclaimed Earnings',
  unclaimed_earnings_tip:
    'USD value of unclaimed fees from DCL pools, and unclaimed farm rewards.',
  unclaimed_pool_fees: 'Unclaimed Pool Fees',
  unclaimed_farm_rewards: 'Unclaimed Farm Rewards',
  active_orders: 'Active Orders',
  yield_farming: 'Yield Farming',
  execute_status: 'Execute Status',
  your_liquidity_usd_value: 'Your Liquidity (USD value)',
  usage: 'Usage',
  holding: 'Holding',
  your_lp_tokens_and_shares: 'Your LP Tokens(Shares)',
  usd_value_staked: 'USD Value Staked',
  instant_swap: 'Instant Swap',
  swapped: 'Swapped',
  created_s: 'created',
  order_progress: 'Order Progress',
  dcl_pool: 'DCL Pool',
  classic: 'Classic',
  classic_farms: 'Classic Farms',
  unstaked: 'Unstaked',
  staked_in_farm: 'Staked in farm',
  farm_reward_range: 'Farm Reward Range',
  your_positions: 'Your Positions',
  your_price_range: 'Your Price Range',
  locked_in: 'locked in',
  near_locked_in_wallet_for_covering:
    '0.5 NEAR locked in wallet for covering transaction fees',
  account_assets: 'Account Assets',
  open_qty: 'Open Qty.',
  actions_orderly: 'Actions',
  records: 'Records',
  buy_pop: 'Buy',
  sell_pop: 'Sell',
  fee_orderly: 'Fee',
  executing: 'Executing',
  from_2: 'from',
  to_2: 'to',
  your_orders_2: 'Your Orders',
  in_farm_2: 'in farm',
  your_liquidity_2: 'Your Liquidity',
  welcome: 'Welcome',
  welcome_mobile: 'Connect your wallet to start',
  select_token_orderly: 'Select Token',
  overview: 'Overview',
  positions_2: 'Positions',
  'deposit_near_tip_0.5': 'To deposit, keep at least 0.5 NEAR to cover gas fee',
  trade: 'Trade',
  earn_2: 'Earn',
  portfolio: 'Portfolio',
  more_2: 'More',
  liquidity_pools_2: 'Liquidity Pools',
  business_inquiries: 'Business Inquiries',
  stable_pools: 'Stable Pools',
  pool_detail: 'Pool Detail',
  new_contract_deploy_tip:
    'A new contract has been deployed! Please remove your liquidity from the old contract',
  remove_will_automatically_claim:
    'Removing will automatically claim your unclaimed fees.',
  legacy_tip: 'Found unstaked LP tokens or rewards in',
  legacy_farms: 'Legacy Farms',
  all_farms: 'All Farms',
  your_farms_2: 'Your Farms',
  dcl_farms: 'DCL Farms',
  farm_type: 'Farm type',
  farm_2: 'Farm',
  to_claim: 'To Claim',
  liquidity_staked: 'Liquidity staked',
  dcl_pool_detail: 'DCL Pool Detail',
  reward_range: 'Reward Range',
  reward_range_tip: 'Farm reward within this range',
  ending_soon: 'Ending soon',
  farm_ended_tip: 'This farm has ended.',
  farm_will_ended_tip: 'This farm will end soon.',
  new_farm: 'New Farm',
  is_coming: 'is coming',
  est_apr: 'Est. APR',
  state_2: 'State',
  farming: 'Farming',
  unavailable: 'Unavailable',
  liquidity_detail: 'Liquidity Detail',
  hide: 'Hide',
  show: 'Show',
  unavailable_positions: 'unavailable positions',
  your_price_range_tip: 'Your price range is out of reward range',
  position_has_staked_tip: 'This position has been staked in another farm',
  minimum_tip: 'The minimum staking amount is',
  your_liquidity_3: 'your liquidity',
  position_has_staked_pre: 'This position has been staked in',
  another_farm: 'another farm',
  add_position: 'Add Position',
  full_range: 'Full Range',
  your: 'Your',
  max_apr: 'Max.APR',
  faming_positions: 'Farming Positions',
  you_can_earn_tip: 'Farm available, farm APR up to',
  you_can_earn_current_tip:
    'Your current staked farm ended, and new farm is coming, est. APR is',
  go_new_farm: 'Go New Farm',
  go_farm: 'Go Farm',
  earn_rewards: 'You can earn rewards',
  est_apr_is: 'est. APR is',
  new_farm_2: 'new farm',
  you_can_earn_current_pre_tip: 'Your current staked farm ended, and',
  in_ended_farm: 'in ended farm',
  staked: 'Staked',
  in_farm_3: 'in farm',
  estimation: 'Estimation Value',
  no_dcl_position_tip:
    "You don't have any DCL Liquidity position for now, click 'AddPosition' to start farming.",
  price_on_slot_tip: 'The price should be in one slot nearby.',
  position_will_appear_here: 'Your liquidity positions will appear here.',
  by_farming: 'by farming',
  this_staked_tip: 'This NFT has been staked',
  dcl_liquidity: 'DCL Liquidity',
  classic_liquidity: 'Classic Liquidity',
  will_appear_here_tip: 'Your liquidity positions will appear here.',
  dcl_will_appear_here_tip: 'Your DCL liquidity positions will appear here.',
  classic_will_appear_here_tip:
    'Your Classic liquidity positions will appear here.',
  yield_farming_appear_here_tip: 'Your yield farming will appear here.',
  active_order_appear_here_tip: 'Your active order(s) will appear here.',
  account_appear_here_tip: 'Your wallet/account assets will appear here.',
  farm_state: 'Farm State',
  farm_detail: 'Farm Detail',
  unstaked_2: 'Unstaked',
  unstaked_positions: ' Unstaked Positions',
  price_range_overlap_ratio: ' Price Range Overlap Ratio',
  swapped_history: 'swap history',
  real_time_executed_orders: 'real-time executed orders',
  real_time_executed_orders_tip:
    'When placing a sell order above market price or a buy order below market price using limit order function, Ref will attempt to execute a swap-with-stop-point action first, and if there is any remaining amount, a limit order will be created to fill the rest.<br> Real-time executed orders refer to orders placed using the limit order function but executed through swapping. In the "History" section, you can view real-time executed orders between the earliest displayed limit order in History up to the present time.',
  swapped_history_order: 'Swapped',
  ledger_guide_tip_1:
    'To successfully use your Ledger wallet for transactions, the ',
  ledger_guide_tip_2: 'feature has been automatically activated.',
  ledger_guide_tip_3: 'Please be aware',
  ledger_guide_tip_quote: '"',
  support_ledger_tip_new: '"Support Ledger"',
  ledger_guide_tip_4:
    'that due to Ledger wallet constraints, the current rate provided by the swap function',
  ledger_guide_tip_5: 'may not be the best price.',
  ledger_guide_tip_6: '.',
  view_dcl_pool: 'View DCL Pool',
  your_trade_route: 'Your Trade Route',
  markets: 'Markets',
  exchanges: 'Exchanges',
  output_est: 'Output (est.)',
  go_to_orderbook: 'Go to Orderbook',
  new_swap_order_tip:
    'The price is from the Ref AMM offer and for reference only. There is no guarente that your limit order will be immediately filled. ',
  instants_swap: 'Instant Swap',
  swappped: 'Swapped',
  claimed: 'Claimed',
  executing_capital: 'Executing',
  record: 'Record',
  trade_route: 'Trade Route',
  settings: 'Settings',
  cross_chain_options: 'Cross-chain Options',
  no_trade_routes: 'No Trade Routes',
  steps_in_the_route: 'Steps in the Route',
  swap_chart_no_data: 'Not enough data for the chart right now.',
  go_to_orderbook_tip:
    'This price is for reference only. {br} Please proceed to <strong>Orderbook</strong> to place the order.',
  last_updated: 'Last Updated',
  steps_in_the_route_zh: '',
  has_tri_pool_tip:
    ' in Ref. Enable Trisolaris in Cross-chain Options to find more.',
  Overview: 'Overview',
  RefFinance: 'Ref.finance',
  Orderly: 'Orderly',
  Burrow: 'Burrow',
  Supplied: 'Supplied',
  Borrowed: 'Borrowed',
  NetAPY: 'Net APY',
  UnclaimedRewards: 'Unclaimed Rewards',
  YouSupplied: 'You Supplied',
  SupplyMarket: 'Supply Market',
  YouBorrowed: 'You Borrowed',
  BorrowMarket: 'Borrow Market',
  NetAPYTip:
    'Net APY of all supply and borrow positions, including base APYs and incentives',
  Market: 'Market',
  TotalSupplied: 'Total Supplied',
  TotalBorrowed: 'Total Borrowed',
  AvailableLiquidities: 'Available Liquidity',
  DailyRewards: 'Daily Rewards',
  SupplyAPY: 'Supply APY',
  BorrowAPY: 'Borrow APY',
  Collateral: 'Collateral',
  Adjust: 'Adjust',
  Supply: 'Supply',
  Repay: 'Repay',
  Borrow: 'Borrow',
  HealthFactor: 'Health Factor',
  CollateralFactor: 'Collateral Factor',
  AdjustCollateral: 'Adjust Collateral',
  UseAsCollateral: 'Use as Collateral',
  RemainingCollateral: 'Remaining Collateral',
  RemainingBorrow: 'Remaining Borrow',
  NoSupplyTip: 'Your supplied assets will apear here.',
  NoBorrowTip: 'Your borrowed assets will apear here.',
  APY: 'APY',
  CF: 'C.F.',
  burrowErrorTip:
    'The current balance is below the minimum token decimals, so that it cannot be processed by the contract.',
  healthErrorTip:
    "Your health factor will be dangerously low and you're at risk of liquidation.",
  Summary: 'Summary',
  Welcome: 'Welcome!',
  ConnectView: 'Connect your wallet to view',
  TotalSuppliedMarket: 'Total Supplied',
  NetWorth: 'Net Worth',
  Claimable: 'Claimable',
  TotalDebts: 'Total Debt',
  WalletAssets: 'Wallet Assets',
  TotalInvested: 'Total Invested',
  TotalAssets: 'Total Assets',
  TokenAllocation: 'Token Allocation',
  perpTip:
    'Welcome to Perpetual Futures trading! Please read {perpTipLink} to get started!',
  perpTipLink: '[Perpetual Futures Introduction]  ',
  perpetual: 'Perpetual',
  pred_funding_rate: 'Pred. Funding Rate',
  open_interest: 'Open Interest',
  bridge_pure: 'ブリッジング',
  pool_refresh: 'Something wrong with the server, please try again later.',
  netWorthTip:
    'Total value of investments in Ref (including claimable rewards) + Total value of Orderly assets + Total value of Burrow assets + Total assets in wallet',
  top_bin_apr: 'Top Bin APR (24h)',
  mark_price: 'Mark Price',
  last_price: 'Last Price',
  mark_price_tip: 'Mark price is used for PnL calculating and liquidation.',
  total_collateral: 'Total Collateral',
  free_collateral: 'Free Collateral',
  total_upnl: 'Total uPnL',
  perps: 'Perps',
  margin_ratio: 'Margin Ratio',
  total_npnl_tip:
    'Estimated unrealized profit and loss across all open positions',
  low_risk: 'Low Risk',
  mid_risk: 'Medium Risk',
  high_risk: 'High Risk',
  liquidations: 'Liquidations',
  leverage_max_leverage: 'Max Account Leverage:',
  marker_taker_fee_rate: 'Maker/Taker Fee Rate',
  liquidation_price: 'Liquidation Price',
  margin_required: 'Margin Required',
  perp_buy_limit_order_range:
    'The price of buy limit orders should be less than or equal to',
  perp_sell_limit_order_range:
    'The price of sell limit orders should be greater than or equal to',
  perp_buy_limit_order_scope:
    'The price of a buy limit order cannot be lower than',
  perp_sell_limit_order_scope:
    'The price of a sell limit order cannot be higher than',
  liquidations_fee: 'Liquidations Fee',
  more_trading_price: 'More Trading Price',
  unsettle_pnl_tip:
    'Move a profit or loss from perp markets into the USDC.e token balance. This has no impact on your open positions or health.',
  margin_ratio_tip:
    'The margin ratio of an account is equal to the total collateral value (ie the USDC.e balance + any unrealized profit/loss) divided by the total open notional of the account (the sum of the absolute notional value of all positions)',
  portfolio_total_est_tip: 'Portfolio value in USD.',
  size: 'Size',
  closing_1: 'Closing',
  closing_2: 'position at {type} price.',
  buy_long: 'Buy / Long',
  sell_short: 'Sell / Short',
  unsettle_pnl: 'Unsettle PnL',
  the_margin_will_be_insufficient: 'The margin will be insufficient',
  exceed_rate_limit:
    'You have exceeded the rate limit, please try again in 60 seconds',
  est_liquidation_price: 'Est. Liquidation Price',
  liquidation_price_tip:
    'This price is for reference only. You can see the liquidation price in your Orderly portfolio after your order is filled.',
  futures_leverage_saved: 'Futures leverage saved',
  maintenance_margin_ratio: 'Maintenance Margin Ratio',
  liquidation_fee: 'Liquidation Fee',
  ins_fund_transfer: 'Ins. Fund Transfer',
  no_liquidation_yet: 'No liquidation yet',
  funding_fee: 'Funding Fee',
  settlements: 'Settlements',
  funding_annual_rate: 'Funding Rate / Annual Rate',
  connect_to_near_tip:
    'ウォレットを接続して流動性を提供し、預金を確認することができる',
  myWatchList: 'お気に入りリスト',
  total_platform_fee_revenue: 'プラットフォーム合計収益',
  more_expensive_than_best_rate_zh_cn: '最適レートより高い',
  more_expensive_than_best_rate: '最適な為替レートより高い',
  risks: 'リスク',
  insufficient_margin: 'Insufficient Margin',
};
export default Object.assign(ja, ja_in_risks_page);
