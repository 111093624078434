const ko_in_risks_page = {
  risks: '리스크',
  of_using_ref_finance: 'Ref. finance의 사용시의',
  introduction:
    'Ref. finance에서의 유동성 제공 및 거래에는 리스크가 따릅니다. 이 프로토콜을 쓰기 전에 충분히 연구를 하시고 리스크를 이해하신 후 진행하십시오. ',
  general_risks_quiz: '일반적엔 리스크에 대한 퀴즈',
  amm_core_design: 'AMM 코어 설계',
  audits: '감사',
  audits_paragraph_2:
    '보안 감사는 리스크를 완전히 제거하지 않습니다. 당신의 인생 저축이나 잃으면 안되는 돈을 Ref. Finance에 넣지 마세요. 특히 유동성 공급은 더욱 위험합니다.',
  admin_keys: '관리자 키',
  admin_keys_paragraph_2:
    '현재 DAO에는 26명의 멤버가 있습니다. Ref Finance는 온전히 탈중앙화된 DAO로 전환해갈 예정입니다.',
  admin_keys_paragraph_3:
    '아래에는 Ref Finance를 현재 관리중인, 혹은 예전에 관리했었던 이들의 연락처와 주소가 적혀 있습니다. ',
  address: '주소',
  type: '종류',
  mission: '미션',
  Locked: '잠김',
  exchange_contract: '거래 계약',
  table_body_tr_1:
    '자동화된 market maker 기능을 관리하시고, 유동성을 공급하며 교환(스왑)하세요',
  farming_contract: '농사(파밍) 계약',
  table_body_tr_2: '유동성 인센티브 관리',
  staking_contract: '스테이킹 계약',
  table_body_tr_3:
    'xREF를 민트하고 소각하시고, "시간 대비" 보상을 받아가세요. ',
  sputnik_dao_contract: 'Sputnik DAO 계약',
  table_body_tr_4: '전략, 의사결정에 참여하셔서 Ref의 성공에 기여하세요. ',
  table_body_tr_5: '기금을 관리하거나, 특정 커뮤니티 기여자에게 보내세요',
  table_body_tr_6: '전략과 로드맵 실행',
  vesting_contract: '베스팅(vesting) 계약',
  table_body_tr_7: 'DAO 멤버들에게 베스팅 된 REF를 관리하세요 ',
  airdrop_contract: '에어드랍 계약',
  table_body_tr_8: 'Ref의 첫 에어드랍을 관리하세요',
  near_address: 'NEAR 주소',
  table_body_tr_9: '일회성 버그 현상금 관리',
  fungible_token_contract: '대체가능 토큰 계약',
  table_body_tr_10: 'REF 토큰 민팅하기',
  rug_pull: '러그 풀',
  rug_pull_paragraph_1:
    '토큰 발행한 개발팀(화이트리스트를 받았건 아니건)이 당 프로젝트를 버리고 투자금만 챙겨 도주할 경우, 그 프로젝트의 토큰 가격은 아마 0달러가 될 것입니다. ',
  rug_pull_paragraph_2:
    'Ref Finance가 토큰을 화이트리스트 했다 해도, 꼭 프로젝트가 성사되리라는 법은 없습니다. 당신은 스스로 프로젝트를 실사할 책임이 있으며, 암호화폐가 매우 위험성이 높은, 투기적인 자산 투자임을 인지하고 있어야 합니다.  ',
  rug_pull_paragraph_3:
    '투자한 돈 일부, 혹은 전부를 잃을 가능성을 당신은 인지하고 대비하여야 합니다.',
  divergence_loss: '다이버전스 손실',
  divergence_loss_paragraph_1:
    '유동성을 공급하는 사람들에게: 유동성을 공급하지 않는게 돈을 더 벌 수 있는 길일수도 있습니다',
  divergence_loss_paragraph_2:
    '다이버전스 손실은 보통 (부정확하게도) "비영구손실" 이라고 불립니다. "비영구"라는 형용사는 이 손실이 일시적이며 곧 역전되어 이익으로 돌아올거란 느낌을 주는데, 이는 사실이 아닙니다.',
  divergence_loss_paragraph_3: '다이버전스 손실에 대해 좀 더 알아봅시다.',
  staking_risks: '스테이킹 리스크',
  staking_risks_paragraph_1:
    '당신이 스테이킹을 할 경우, 당신은 여러 개의 스마트 계약 프로덕트를 사용합니다. 이 프로덕트들은 각각 다른 리스크가 있습니다. ',
  permanent_loss_of_a_peg: '통화 페그의 영구 손실',
  permanent_loss_of_a_peg_paragraph_1:
    '만약 풀의 어떤 스테이블코인이 1.0 한참 이하로 페그가 내려간 후 다시 올라오지 못하면, 그 풀의 유동성 제공자들의 유동성 대부분이 그 화폐에 담겨 있다는 뜻입니다. ',
  systemic_issues: '시스템적인 문제',
  systemic_issues_paragraph_1:
    '디파이 및 "머니 레고"는 결합성이 보통 높은 편입니다. 말인 즉슨, 한 프로그램의 장애가 다른 프로그램들을 연쇄적으로 망가트릴 수 있다는 뜻입니다',
  systemic_issues_paragraph_2:
    '여기서 "시스템적인 문제"라는 뜻은, 설령 당신의 투자 및 리스크와 직접적 연관이 없는 장애가 발생한 상황에서도 당신은 돈을 잃을 수 있다는 뜻입니다.',
  systemic_issues_paragraph_3:
    '아래 나열된 리스크들은 유동성 풀에 영향을 미칠 수도 있습니다.',
  systemic_issues_paragraph_4: '렌딩 프로토콜과 관련된 스마트 계약 문제들',
  systemic_issues_paragraph_5: '스테이킹 프로토콜과 관련된 스마트 계약 문제들',
  systemic_issues_paragraph_6:
    '이 풀에 있는 스테이블코인들의 시스템적인 문제들',
  systemic_issues_paragraph_7:
    '이 풀에 있는 ERC20-native 토큰들의 시스템적인 문제들',
  crypto_trading_addiction: '암호화폐 거래 중독',
  crypto_trading_addiction_paragraph_1:
    '암호화폐 거래는 매우 중독성이 높습니다. 여러 전문가들에 따르면 이는 도박 중독의 한 형태일 수 있습니다. 도박 중독은 인생을 망칠 수 있습니다.  ',
  crypto_trading_addiction_paragraph_2:
    '아래에는 이 문제와 관련된 이야기들입니다.',
  crypto_trading_addiction_paragraph_3: "'코인은 도박이지, 물론'",
  crypto_trading_addiction_paragraph_4:
    "'비트코인 굴리다가 오십만 파운드를 날렸습니다'",
  crypto_trading_addiction_paragraph_5: "'코인 중독 전문상담가를 만났습니다.'",
  crypto_trading_addiction_paragraph_6:
    "'암호 화폐 거래 중독으로 수백만 달러를 잃었습니다'",
  no_in_progress: '아니오(진행중)',
  yes: '예',
  no: '아니오',
  NA: 'N/A',
  ref_finance_near: 'ref-finance.near',
  ref_finance_near_mission:
    'v1 (depreciated) Automated Market Maker를 관리하세요; 스왑하고 유동성을 제공하세요',
  token_ref_finance_near: 'token.ref-finance.near',
  token_ref_finance_near_type: '대체가능토큰 계약',
  token_ref_finance_near_mission: 'v1 (depreciated) - REF 토큰을 민팅하세요',
  refchef_near: 'refchef.near',
  simple_address: '간편 주소',
  refchef_near_mission: '계정간 트랜잭션을 관리하세요',
  ref_dev_teller_near: 'ref-dev-teller.near',
  Locked_title: '잠김? information box: when hovering to display:',
  Locked_paragraph_1:
    '"잠긴 계약"의 뜻은 이 계약 코드를 재배포할 권한이 있는 "접근 키"가 이제 더 없다는 뜻입니다.',
  Locked_paragraph_2:
    '이 코드의 재배포는, 일반적으로 아래 경우중 하나일 때 가능합니다: ',
  Locked_paragraph_3: '1. 코드 배포 액션이 있는 트랜잭션이 있을 경우',
  Locked_paragraph_4:
    '2. 계약 내에 deploy-code action을 발동시키는 트리거가 내장되어 있는 경우',
  Locked_paragraph_5:
    '이 코드를 트랜잭션을 통해 재배포하려면, 그 트랜잭션은 풀 액세스 키로 서명되어야 합니다. 만약 계약 내에 그런 키가 아예 없고, 계약 코드 내에 관련 지원이 따로 없다면, 이 코드는 재배포가 불가능합니다. 이 때 우리는 이 계약을 "잠김" 표시합니다 ',
  Locked_paragraph_6:
    '만약 하나라도 풀 액세스 키가 계약 계정에 등록되어 있는 경우, 그 계약은 잠기지 않습니다. ',
  audited_first_sentence:
    'Ref Finance 스마트 계약은 <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/audits"' +
    '>감사</a> 되었습니다.',
  admin_first_sentence:
    'Ref Finance 는 이 사람들이 관리합니다 <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO</a>. Ref에는 <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://gov.ref.finance/t/introducing-the-guardians/253"' +
    '>가디언</a>,이라는게 있으며, 이 특정 NEAR 주소들(=가디언)들은 계약을 멈추는게 가능합니다. 이렇게 멈춘 계약은 DAO에 의해서만, 시간에 구애받지 않고 재개 될 수 있습니다. ',
  risks_of_using_ref_finance:
    '<label class="text-greenColor">리스크</label> , Ref. finance 사용시의',
  admin_sentence_1:
    'Ref Finance는 <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO</a> 가 운영합니다. 우리 DAO는 완전 탈중앙화 DAO로 전환할 예정입니다.',
  admin_sentence_2:
    'Ref Finance의 전/현 담당자들의 계약 및 연락 정보를 알고 싶으시다면, <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/contracts"' +
    '>문서</a> 쪽에서 확인해 주세요.',
  third_party_wallet: '타사 지갑',
  third_party_wallet_1: 'Ref Finance는 타사 지갑을 통합합니다.',
  third_party_wallet_2:
    '평판이 좋은 지갑 공급자를 선택하기 위한 조치를 취했지만 보안이나 성능을 보장할 수는 없습니다. 특정 지갑 공급자와 관련된 위험을 숙지하고 서비스 약관, 개인 정보 보호 정책 및 보안 관행을 이해해야 합니다.',
  third_party_wallet_3:
    '이러한 서비스를 자신의 책임 하에 사용하고 주의를 기울여 주십시오.',
};
const ko = {
  deposit: '입금',
  Deposit: '입금',
  withdraw: '출금',
  Withdraw: '출금',
  withdraw_token: '토큰 출금',
  swap: '스왑',
  Swap: '스왑',
  exchange_tokens: '토큰 거래',
  pool: '풀',
  Pool: '풀',
  pools: '풀들',
  Pools: '풀들',
  Quiz: '퀴즈',
  no_tokens_deposited: '예금된 토큰 없음',
  view_pools: '풀들 보기',
  view_pool: '풀 보기',
  add_token: '토큰 더하기',
  Add_Token: '토큰 더하기',
  create_new_pool: '새로운 풀 만들기',
  Create_New_Pool: '새로운 풀 만들기',
  your_liquidity: '당신의 유동성',
  Your_Liquidity: '당신의 유동성',
  farms: '농장',
  Farms: '농장',
  airdrop: '에어드랍',
  Airdrop: '에어드랍',
  docs: '문서',
  Docs: '문서',
  community: '커뮤니티',
  Community: '커뮤니티',
  more: '더',
  More: '더',
  account: '계정',
  Account: '계정',
  New_ui: '새 UI',
  Risks: '리스크',
  Forum: '포럼',
  Discord: '디스코드',
  Telegram: '텔레그렘',
  Twitter: '트위터',
  Medium: '미디움',
  move_assets_to_from_ethereum: '이더리움으로/부터 자산 옮기기',
  rainbow_bridge: 'Rainbow Bridge',
  deposit_to_swap: '입금 후 스왑',
  connect_to_near: 'NEAR에 연결하기',
  slippage: '슬리피지(미끄러짐) 허용치 ',
  slippage_title: '트랜잭션 설정',
  minimum_received: '최소 수령양',
  swap_rate: '교환율',
  swap_rate_pre: '교환율',
  pool_fee: '풀 수수료',
  balance: '잔액',
  from: '(로)부터',
  to: '에게',
  submit: '제출',
  save: 'Save',
  wrapnear: 'NEAR 래핑',
  wrapnear_tip_one:
    'NEAR 래핑을 해야 REF를 거래할 수 있게 됩니다. 반드시 확인하시고 ',
  wrapnear_tip_two: 'NEAR 0.5개는 남기셔야지',
  wrapnear_tip_three: '가스 요금',
  wrap_error_msg: '잔액이 부족합니다',
  sign_out: '로그아웃',
  view_account: '계정 보기',
  deposit_to_swap_and_add_liquidity: '입금 후 교환/유동성 공급하기',
  small_storage_fee_is_applied_of: '소정의 보관비용이 적용됩니다',
  liquidity: '유동성',
  tokens: '토큰',
  fee: '요금',
  fees: '요금',
  view_detail: '세부정보 확인',
  pool_details: '풀 세부정보',
  details: '세부 정보',
  detail: '세부 정보',
  liquidity_pools: '유동성 풀',
  minimum_tokens_out: '최소 입금량',
  pair: '쌍',
  tvl: 'TVL',
  search_pools: '풀 찾기',
  add_liquidity: '유동성 더하기',
  remove_liquidity: '유동성 빼기',
  total_liquidity: '유동성 총합',
  coming_soon: '곧 추가됨',
  accumulated_volume: '누적 거래량',
  underlying_liquidity: '*기초자산 유동성',
  total_shares: '총 지분',
  my_shares: '지분',
  token: '토큰',
  enter_token_address: '토큰 주소 입력',
  you_are_not_providing_liquidity_to_any_pools:
    '현재 당신은 어디에도 유동성을 공급하고 있지 않습니다',
  remove: '제거',
  select: '선택',
  select_token: '토큰 선택',
  basis_points: '기본 포인트',
  total_fee: '총 수수료% ',
  protocol_fee_is: '프로토콜 요금은 ',
  start_in: '내에 시작',
  start_at: '에 시작',
  started_at: '에 시작됨',
  start_date: '시작 일시',
  end_at: '에 종료',
  end_date: '종료 일시',
  earn: '얻음',
  ended: '끝남',
  pending: '대기중',
  pending_cap: 'Pending',
  pending_orders_title: 'Pending Close Orders',
  rewards_per_week: '주당 보상',
  apr: '연간이율',
  total_staked: '스테이킹 총합',
  unclaimed_rewards: '미수령 보상',
  claim: '수령',
  claim_all: '전부 수령',
  stake: '스테이킹 고정',
  unstake: '스테이킹 해제',
  your_rewards: '당신의 보상',
  your_shares: '지분',
  shares_owned: '소유중인 지분',
  stake_your_liquidity_provider_LP_tokens:
    '당신의 유동성 공급(LP) 토큰을 스테이킹 하세요',
  go_to_wallet: '지갑으로 이동',
  recent_activity: '최근 활동내역',
  view_all: '전부 보기',
  cancel: '취소',
  claim_ref_token: 'Ref 토큰 받기',
  has_no_available_claim: '받을 토큰 없음',
  sorry: '죄송합니다',
  total_ref_token: 'Ref 토큰 합계',
  locking_ref_token: 'Ref 토큰 잠그기',
  unclaim_ref_token: 'Ref 토큰 수령 취소',
  unlocked: '잠금 해제',
  locking: '잠그기',
  you_can_claim_from: '로부터 수령할 수 있습니다',
  you_have_claimed_all_your_rewards: '모든 보상을 수령했습니다',
  ends_in: '에 끝남',
  days: '일',
  swap_successful_click_to_view: '교환 성공. 클릭하여 확인',
  claim_successful_click_to_view: '수령 성공. 클릭하여 확인',
  claim_failed_click_to_view: '수령 실패. 클릭하여 확인',
  slippageCopyForMobile:
    '슬리피지란 당신이 처음에 기대한 수익과, 다른 사람이 우선 실행되는 바람에 달라져버린 실제 수익의 차이를 말합니다. ',
  slippageCopy:
    '슬리피지란 당신이 처음 기대한 수익과 다른 사람이 우선 실행되는 바람에 달라져버린 실제 수익의 차이를 말합니다 ',
  depositCopy:
    '거래소에 선택한 토큰을 입금해 <br> 유동성 풀에 투입 및 교환(스왑)에 씁니다. ',
  nearDepositCopy:
    '니어를 래핑한 다음 거래소에 입금하여 <br>  유동성 풀에 투입 및 교환(스왑)에 씁니다.',
  nearWithdrawCopy:
    '니어를 래핑 해제하고 거래소에서 출금하여 <br> 당신의 지갑으로 옮깁니다.',
  withdrawCopy:
    '선택한 토큰을 거래소에서 출금하여 <br> 당신의 지갑으로 옮깁니다',
  registerToken: '현재 거래소 목록에 없는<br> 새로운 토큰을 등록합니다',
  whitelistTokenCopy: 'NEP-141 토큰을 더하세요',
  addLiquidityPoolCopy:
    '두 토큰을 선택하면 새로운 유동성 풀이 만들어집니다. 수수료는 각 트랜잭션 때마다 풀에 지급되는 퍼센티지를 말합니다.',
  swapCopy:
    '교환(스왑)은 첫 번째 선택된 토큰을 두 번째 선택된 토큰과 바꿉니다. 가장 유동성이 높고 수수료가 낮은 풀이 선택됩니다.',
  addTokenCopy: 'NEP-141 토큰을 더하세요',
  getLPTokenCopy: '해당 풀로 이동하려면 클릭하세요.',
  airdropCopy:
    '에어드롭을 제때 수령해주세요. 기간 지난 토큰 잔액은 트레져리로 기부됩니다. ',
  farmRewardsCopy:
    '지표 현 가격에 기반하였으며, 실제로 실행시 달라질 수 있습니다. ',
  totalValueLockedCopy: '잠금 총액',
  topPoolsCopy: '매 쌍마다 가장 잠금 총액(TVL)이 높은 풀만을 표기합니다.',
  you_do_not_have_enough: '양이 부족합니다',
  must_provide_at_least_one_token_for: '최소한 0보다 큰 토큰을 제공해야 합니다',
  is_not_exist: '(은)는 존재하지 않습니다',
  must_input_a_value_greater_than_zero: '0보다 큰 값을 입력해야 합니다.',
  amount_must_be_not_greater_your_balance:
    '잔액보다 선택된 양이 커서는 안됩니다.  ',
  no_pool_available_to_make_a_swap_from:
    '교환(스왑) 가능한 풀이 존재하지 않습니다',
  for_the_amount: '에 해당하는 양',
  no_pool_eng_for_chinese: ' ',
  is_not_a_valid_swap_amount: '적합한 교환(스왑) 양이 아닙니다',
  not_nep_address:
    '입력한 주소가 NEP-141 주소가 아닙니다. 확인 후 다시 입력해 주세요. 만약 그 주소가 다른 메인넷의 계약을 가리킨다면, 브릿지 프로세싱을 위해 Rainbow Bridge로 이동하세요',
  not_correct_address:
    '당신이 입력한 주소는 잘못되었습니다. 확인 후 다시 입력해 주세요.',
  popular_tokens: '많이 쓰이는 토큰',
  asset_label: '자산',
  account_label: '계정',
  total_label: '총',
  top_pools: '순위권 풀',
  pool_id: '풀 ID',
  more_pools: '더 많은 풀',
  my_watchlist: '나의 워치리스트',
  h24_volume: '24시간 거래량',
  volume: '거래량',
  hide_low_tvl_pools: '잠금총액(TVL) 낮은 풀 숨기기',
  hide_low_tvl_pools_mobile: '잠금총액(TVL) 낮은 풀 숨기기',
  watchlist_title: '나의 워치리스트 상단 ',
  add_watchlist: '워치리스트에 추가하기',
  remove_watchlist: '워치리스트에서 제거하기',
  my_watchlist_copy: '워치리스트 된 유동성 풀은 여기에 등장합니다 ',
  search_token: '토큰 검색',
  language: '언어',
  deposit_to_add_liquidity: '유동성 추가를 위해 입금',
  slip_warn: '입금 가능한 최소량을 확인 후 신중히 결정하세요.',
  slip_invalid: '슬리피지(미끄러짐) 허용치가 적절한 수치가 아닙니다.',
  input_to_search: '검색내용을 입력하십시오',
  click_search_bar_to_search: '검색바를 눌러 검색하십시오',
  reward_tokens: '보상 토큰',
  detail_tip: '세부사항',
  no_data: '데이터 없음',
  stable_swap: '스테이블스왑',
  StableSwap: '스테이블스왑',
  exchange_rate: '교환비',
  including_fees: '(수수료 포함)',
  standard_gas: '표준 수수료(가스):',
  rst_token: '당신의 RST 토큰',
  remove_tip: '공유하여 유동성을 제거하면 수수료가 들지 않습니다',
  share_liquidity: '유동성 share',
  remove_token_confirm: 'RUST 토큰을 풀에서 꺼냅니다remove',
  remove_as_one_token: '하나의 토큰으로 꺼내기remove',
  flexible_tip: '토큰당 얼만큼씩 꺼낼remove지 정하세요',
  add_type_all: '모든 토큰을 균형에 맞게 입금합니다.',
  add_type_max: '가능한 토큰 전부를 사용합니다',
  select_balance: '잔고 선택',
  selectBalanceCopy:
    '[Near Wallet]을 선택하여, 당신의 니어 지갑에 있는 토큰을 직접 교환(스왑)하세요.',
  near_wallet: 'NEAR 월렛',
  ref_account: 'REF 계정',
  swap_tip:
    '설정에서 Ref 계정을 선택하면 그 계정으로부터 직접 교환(스왑)할 수 있습니다.',
  attention: '*attention',
  live: '라이브',
  ended_search: '끝',
  my_farms: '당신의 이자 농장',
  staked_only: '스테이킹 된 것만',
  sort_by: '(으)로 정렬',
  your_farms_rewards: '농장에서 나온 보상',
  your_farms: '당신의 농장들',
  new: '최신',
  unstake_tip_t: '미수령 보상이 있습니다',
  unstake_tip_m:
    '스테이킹을 해제하면, 이 쌍과 관련된 현재의 활성화된 (혹은 예정된)농장들이 없어집니다',
  unstake_tip_b: '스테이킹을 해제하겠습니까?',
  value_rewards: '수령한 보상',
  stake_unstake_tip: '스테이킹 및 해제시 당신의 보상은 자동으로 수령됩니다',
  over_tip:
    '가스 수수료를 최적화하려면 최대 4개씩 토큰을 인출하는 것이 바람직합니다.',
  no_token_tip: '보상 토큰 없음',
  getToken_tip:
    '보상 토큰을 수령하거나, 유동성 풀의 토큰을 스테이킹 할 수 있습니다!.',
  in_farm: '농장 안',
  swap_rate_including_fee: '교환 비율 (수수료 포함)',
  by_share: '지분에 의해',
  by_token: '토큰에 의해',
  your_positions_will_be_displayed_here: '당신의 포지션은 여기에 표시됩니다',
  price_impact: '가격 영향',
  shares_left: '남은 shares',
  total_stable_coins: '스테이블코인 총합',
  daily_volume: '24시간 거래량',
  liquidity_utilisation: '유동성 활용',
  token_reserves: '토큰 예비고',
  roi_calculator: 'ROI 계산기',
  lp_staked: 'LP 스테이킹 된',
  usd: 'USD',
  lp_token: 'LP 토큰',
  stake_for: '기간',
  day_1: '1일',
  day_2: '7일',
  day_3: '30일',
  day_4: '90일',
  day_5: '1년',
  cur_apr: 'ROI',
  reward_token: '토큰으로 보상하기',
  get_lp_token: 'LP 지분 받기',
  calculate_roi: 'ROI 계산기',
  rewards_claimed: '수령된 보상',
  value_rewards_token: '보상액',
  all: '전부',
  all_5: '한번에 최대 4토큰씩만',
  insufficient_shares: '지분이 불충분함',
  shares_removed: '지분',
  shares_avaliable: '가능한 지분',
  minimum_shares: '최소 shares',
  shares_tip: '가용주식수 / 총주식수',
  stablecoin_only: '스테이블코인 전용',
  more_than: '0.01 이상의 숫자를 입력해 주세요',
  less_than: '20 이상의 숫자를 입력해 주세요',
  no_valid: '적절한 숫자를 입력해 주세요',
  lp_fee: 'LP 수수료',
  protocol_fee: '프로토콜 수수료',
  referral_fee: '추천 수수료 ',
  protocol_fee_referral_fee: '의정서 수수료 및 추천 수수료',
  total_fee_create: '총 수수료',
  xref_title: '로 더 많은 수익 벌기',
  xref_title1: '더 많은 수익',
  xref_title2: 'REF로 xREF에 스테이킹하기',
  xref_introdution:
    'REF를 staking하게 되면, 당신은 프로토콜로이 생성한 수수료를 지급받을 기회를 얻습니다. REF 홀더들은 REF Finance가 얻은 수익의 일정 지분을 가져갈 수 있습니다.',
  staking_apr: '스테이킹 연이율(APR)',
  view_stats: '통계 보기',
  xref: 'xREF',
  ref: 'REF',
  more_than_general_seed: '입력값은 0.000001보다 커야 합니다',
  more_than_stable_seed: '입력값은 1보다 커야 합니다',
  are_you_sure: '확실히 실행하시겠습니까',
  price_impact_is_about: '가격 영향은 다음과 같은 사항에 관한 것입니다',
  yes_swap: '예, 스왑합니다',
  amount_must_be_greater_than_0: '양은 0보다 커야 한다',
  volume_ratio: '24H 거래량 / 유동성 비율',
  go_to_near_wallet: 'NEAR 지갑으로 이동',
  into_ref_account: 'REF 계정으로',
  deposit_into_ref_account: 'REF 계정으로 하여 유동성을 더하세요.',
  deposit_near_tip: '입금시 수수료를 위해 최소 1 NEAR는 남기세요.',
  input_greater_than_available_shares: '가능한 지분보다 입력이 큽니다.',
  number_of_unique_stakers: '스테이킹중인 사람 수',
  total_ref_staked: '스테이킹 된 REF 총량',
  total_xref_minted: '민트된 xREF 총량',
  protocol_projected_revenue: 'xRef 보유자에게 배당된 이익',
  total_fee_Revenue_shared_with_xref_holders:
    'xRef 보유자에게 배당하는 수수료 수익 총액',
  provision_treasury: '준비금',
  protocol_projected_revenue_tip:
    '이 숫자는 xREF 홀더들에게 지급 예상되는 수수료 누적 금액입니다. 이는 플랫폼 수수료의 75%에 해당합니다. 이 숫자는 실제 REF 토큰 재구입 시기의 가격에 따릅니다.',
  stake_ref_to_xref_for_earning_more:
    'xREF에 REF를 스테이크해서 더 큰 수익을 올리세요!',
  auto_router: '자동 라우터',
  auto_router_detail_sub_1: '이 루트는 당신의 교환(스왑)을 최적화합니다',
  auto_router_detail_sub_2: '해당하는 쌍의 풀 전부를 고려해서요 ',
  auto_router_detail_sub_3: '상세내역:',
  introducing_parallel_swap: '병렬 교환(스왑)을 소개합니다',
  at_least: '최소한',
  xref_start_time: '1am UTC, 2022년 1월 18일에 시작합니다.',
  xref_to_receive: '받을 xREF',
  ref_to_receive: '받을 REF',
  more_expensive_than_best_rate_en: '최고의 교환비율보다 더 비쌉니다. ',
  optimal_path_found_by_our_solution: '우리 솔루션으로 찾은 최적의 경로',
  check_to_stable_banner: '확인',
  rates_to_stable_banner: '교환비',
  make_sure_you_understand_what_you_do:
    '당신이 무엇을 하는지 확실히 이해하기 바랍니다',
  go_to_your_account_to: '당신의 계정으로 이동해서',
  deposit_l: '입금',
  withdraw_l: '출금',
  got_it: '확인했습니다',
  check_the_transaction_settings: '트랜잭션 설정을 확인하세요',
  filter_by: '(으)로 필터함',
  allOption: '전부',
  stablecoin: '스테이블코인',
  near_ecosystem: 'NEAR 생태계',
  bridged_tokens: '브릿지된 토큰',
  gaming: '게이밍',
  nft: 'NFT',
  bridge: '(으)로부터/(으로) 브릿지',
  from_ethereum: 'Ethereum',
  from_aurora: 'Aurora',
  from_solana: 'Solana',
  from_terra: 'Terra',
  from_celo: 'Celo',
  no_result: '죄송합니다, 어떤 농장을 찾을 수 없었습니다.',
  multiple: '복수의',
  default: '기본값',
  vi_go_live: '베트남어 라이브 시작!',
  do_not_need_to_deposit_in_ref: '이제 Ref Finance에 예금할 필요 없습니다!',
  you_can_add_liquidity_directly:
    'NEAR wallet을 사용해서 직접 유동성을 공급하세요 ',
  do_not_need_deposit_anymore: '이제 예금할 필요 없습니다!',
  lightning_swaps: '라이트닝 교환(스왑)',
  frictionless_add_remove_liquidity: '마찰 없이 유동성을 더하고/빼는 액션',
  create: '생성',
  select_a: '선택',
  wallet: '지갑',
  Wallet: 'Wallet',
  to_use_ref_finance: 'Ref Finance를 사용하기 위해서는',
  not_supported: '지원되지 않음',
  installed: '설치됨',
  installe_now: '지금 설치',
  extension: '익스텐션',
  first_time_using_ref: 'Ref가 처음이신가요?',
  learn_more: '더 알아보기',
  install_sender_now: 'Sender를 설치하세요',
  connect_to_dapps_with_one_click: '클릭 한번에 dApps로 연결하세요',
  install: '설치',
  Connecting: '연결중',
  check_sender_wallet_extension: 'Sender Wallet extension을 다시 확인해 주세요',
  having_trouble: '문제가 있나요?',
  go_back: '뒤로가기',
  web: '웹',
  Type: '타입',
  click_to_view: '클릭하여 확인',
  transaction_failed: '트랜잭션 실패',
  wallet_vi: ' ',
  value: '가격',
  usd_value: 'USD 가격',
  sauce_note:
    'SAUCE는 페그된 자산의 유동성 풀을 대상으로 최적의 값을 찾아내 줍니다.',
  support_ledger: '보조 장부(레져)',
  initialize_account_tip: '당신의 계정을 초기화해 주세요.',
  support_ledger_tip:
    "레져는 메모리가 제한되는 구조 탓에 거대한 트랜잭션 양을 감당할 수 없습니다. (예: 오토 라우터 - 여러 풀에서 한번에 거래) 활성화시, '보조 장부' 옵션은 가장 간단한 형상으로 트랜잭션을 제한해서 합리적인 크기의 트랜잭션이 서명 될 수 있게 합니다. (오토 라우터에서 찾은 최적의 값보다 약간 손해를 볼 수 있습니다) ",
  start: '시작',
  aprTip: '모든 보상(보류중/실행중) 총합 지표 ',
  half: '절반',
  max: '최대',
  ref_account_balance_tip:
    '유동성 풀에 추가하는/인출하는 과정에서 에러가 발생한 것 같습니다',
  ref_account_tip_2: 'Ref 계정에 토큰이 있습니다',
  ref_account_tip_top: '당신의 토큰은 아마 REF 내부 계정에 있을지도 모릅니다',
  click_here: '여기를 눌러',
  to_recover_them: '그것들을 회수하세요',
  ref_account_tip_3:
    'Ref 계정으로부터 NEAR 월렛으로 토큰을 인출하시려면 선택 후 인출을 눌러주세요',
  aurora_account_tip_4:
    '매핑 계정에서 NEAR 지갑으로 토큰을 인출하려면 선택하고 인출하십시오',
  pool_fee_cross_swap: '풀/크로스 체인 수수료',
  mapping_account: '매핑 계정',
  mapping_account_tip: '매핑 계정에 토큰이 있습니다',
  cumulative_ref_buyback: '누적 REF 바이백Buyback',
  yearly_revenue_booster: '연간 수익revenue 부스터',
  Confirm: '확인하다',
  Request_for_Quote: '견적 요청',
  mapping_account_explanation:
    "맵핑 주소는 '프록시 주소'인데, 이는 유저의 NEAR 계정을 유저의 오로라 ETH 주소와 대응시켜준다는 뜻입니다.",
  buy: '구매',
  sell: '매각',
  buy_nsn: 'USN 구매',
  buy_nsn_tip: 'USN은 NEAR가 보증하는 네이티브 스테이블코인입니다. ',
  usn_tip_one:
    'USN은 REF에서 거래가 가능합니다. 반드시 다음 수수료를 지갑에 남기세요. ',
  rate: '요율',
  name: '이름',
  diff: '차이',
  best: '최상의',
  trading_fee: '거래 수수료',
  usn_successful_click_to_view: '거래 성공. 클릭해서 확인하세요',
  awesomeNear_verified_token: 'AwesomeNear 인증된 토큰입니다',
  usn_fee_tip: 'Ref에 의해 부과되는 수수료 없음, 사용자는 USN 수수료만 지불!',
  total_bitcoins: '총 비트코인',
  total_usd_value: '총 미국 달러 가치',
  bitcoin_value: '비트코인 가치',
  stable_coin_value: '스테이블코인 가치',
  pool_fee_apr: '풀 수수료 APY',
  ref_account_balance_tip_mobile: 'REF 계정에 토큰이 있습니다.',
  click: '딸깍 하는 소리',
  to_recover: '회복.',
  rewards_week: '주 당 보상',
  rewards: '보상',
  claimed_Rewards: '획득된 보상',
  POOL: '풀들',
  STAKE: 'STAKE',
  lp_Token: 'LP Tokens',
  booster: 'Booster',
  slash_policy: '블랙리스트(Slash) 정책',
  stake_period: '스테이킹 기간',
  append: '추가',
  append_staking: '스테이킹 추가하기',
  unstake_time: '스테이킹 해제 시간',
  farm: '이자 농사짓기',
  reward_Token: '토큰 보상하기',
  status: '상태',
  value_of_rewards: '보상의 가치',
  Rewards: '보상',
  free: '공짜',
  lp_tokens: 'LP 토큰',
  connect_to_wallet: '지갑과 연결하기',
  anytime: '언제든',
  slash_policy_content:
    '모든 시드는 각각의 슬래시 레이트가 있습니다. 이것은 시간이 지날수록 선형적으로 감소하다가, CD 계정의 unlock time에 0이 됩니다.',
  not_expired: '유효기간 남음',
  accept_pay_slash_tip: 'slash 지불에 동의합니다',
  i_will_pay: '지불하겠습니다',
  lp_token_slash: 'LP 토큰 엑싯 수수료.',
  booster_change_reason: 'Booster는 appending time에 맞춰 변화합니다.',
  initial_booster: '최초 사용 부스터',
  append_timing: 'Append timing',
  cd_limit: '계정당 16개의 cd 제한을 넘어섰습니다.',
  expired: '만료됨',
  all_5_v2: '타임당 5 토큰',
  all_4_v2: '타임당 4 토큰',
  more_than_seed: '입력값은 다음보다 크거나 같아야 합니다:',
  how_to_earn_more: '보상을 복리로 받는 법?',
  you_shares: '당신의 지분',
  lock_your_lp_tokens_with_booster:
    '당신의 LP 토큰을 잠가서(lock) 보상을 더 받으세요.',
  unstaked_anytime_no_booster: '언제든 언스테이킹 가능하지만, 부스터 없음',
  ordinary_stake: '일반적인 스테이킹',
  locking_stake: '잠금(Lock-up) 스테이킹',
  change_to_lock: '잠금(Lock-up) 스테이킹으로 변환',
  estimated_rewards: '예상된 보상 수치',
  final_booster: '최종 부스터',
  existing_amount: '존재하는 수량',
  append_amount: '수량 추가',
  will_be_able_to_unstaked_after: '다음 시간 뒤에 언스테이킹 가능합니다:',
  expected_reward: '예상되는 보상',
  you_have: '당신이 가진 양: ',
  tokens_small: '토큰들',
  reward_apr: '보상 APR',
  price_board: '가격 게시판',
  close: '닫기',
  stake_min_deposit: '입력된 값은 다음보다 크거나 같아야 합니다: ',
  unlock: '열기',
  exit_fee: '엑싯 수수료',
  end_locking_period: '잠금(locking) 기간 종료',
  add_lp_tokens_tip:
    '특정 농장에 스테이킹하려면 LP 토큰이 필요합니다. 우선 풀에 유동성을 공급하여 LP 토큰을 받으세요.',
  slash_tip:
    '엑싯 수수료 = 엑싯 수수료 요금rate * 잠금 종료까지의 기간* 잠금 수량',
  nonBoosted: '부스트 없음',
  exit_fee_tip: '잠금(lock-up)을 해제하는데 지불 비용 추정치',
  forbiddenTip: '이 농장은 이 기간 유형이 안됩니다.',
  unstakeTip:
    '스테이킹을 하면, 혹은 스테이킹을 해제하면 보상은 자동으로 획득됩니다.',
  endedFarmsUnstakeTip:
    '스테이킹 해제 시 모든 활성화된/펜딩된 농장에서 해당 쌍의 스테이킹이 해제됩니다.',
  comimg: '오고 있음',
  appendTip: '락킹 시점보다 더 이르게 할 수 없습니다.',
  week: '주',
  yours: '당신의',
  boost: '부스트',
  near: 'NEAR',
  eth: 'ETH',
  others: '기타',
  stakeLove: 'LOVE 스테이킹 하기',
  unstakeLove: 'LOVE 스테이킹 해제하기',
  get_love: 'LOVE 받기',
  swap_successful: '스왑에 성공했습니다. ',
  trading_successfull: '거래 성공. ',
  total_near_amount: '총 NEAR 금액',
  near_value: 'NEAR 값',
  borrow: '빌리다',
  burrow_usn_tip: 'Burrow에서 USN을 빌릴 수 있습니다.',
  migrate: 'Migrate',
  go_to_new_farm: '새로운 농장으로 이동',
  voting_gauge_share_tip:
    '투표 할당에 사용된 REF의 지분에 대비한 현 시점의 REF 보상 총량',
  swap_capital: '스왑',
  farm_capital: '농장',
  sauce_capital: 'SAUCE',
  dao_capital: 'DAO',
  available_to_stake: '만큼 스테이킹이 가능',
  recent_one_mounth_activity: '최근 한 달 사용내역',
  your_assets: '당신의 자산',
  connect_wallet: '지갑 연결',
  disconnect: '연결 해제',
  change: '변경하기',
  wallets_below_supports_on_PC: '아래의 지갑은 PC에서 지원 됩니다',
  connect: '연결하기',
  make_sure_device_plugged_in: '디바이스 연결을 확인하십시오',
  then_enter_an_account_id_to_connect: 'ID를 입력하시고 엔터를 눌러 연결하세요',
  veTip:
    'veLP 토큰은 거래/증여가 불가능합니다. veLPT는 당신의 투표권(voting power)을 나타냅니다 ',
  loveTip:
    'LOVE는 veToken의 유동성을 나타냅니다. 다른 NEP-141 토큰과 마찬가지로 LOVE는 거래/스왑/풀링/스테이킹/이자 농사 등이 다 가능합니다. LOVE는 당신의 veToken들의 기초 유동성(underlying liquidity)을 대표합니다 ',
  lock_your_lp_tokens: '당신의 LP Tokens 잠그세요',
  unlock_your_defi_power: '당신의 DeFi Power를 잠금 해제하세요',
  unlock_tip_mobile: '이제 잠금 해제가 가능합니다!',
  unlock_tip_top: '당신의 잠금은 만료되었습니다. 지금 잠금 해제가 가능합니다!',
  bonus_to_be_withdraw: '출금 가능한 보너스',
  keep_ve: '킵해놓기 (keep)',
  months: '월',
  month: '월',
  lock_lp_tokens: 'LP Token 잠금',
  get_lptoken: 'LP Token 받기',
  duration: '기간',
  ve_lock_tip: '현재 잠금 해제일보다 더 이를 수 없음',
  get: '받기',
  locking_ve: '잠금',
  in_addition_to_the: '-에 더해',
  already_locked: '이미 잠김',
  unlocking_is: '잠금 해제란',
  you_have_no_lp_share: '당신은 LP Token이 없습니다',
  lock: '잠금',
  unlock_lptoken: 'LP Tokens 잠금 해제',
  locked: '잠김',
  available: '가능함',
  balance_lowercase: '잔액',
  voting_power: '투표권',
  lock_lp_tokens_first: 'LP Token을 우선 잠가놔야 합니다!',
  farm_booster: '이자 농사 부스터',
  go_to_farm: '농장으로 이동',
  in: 'in',
  more_lowercase: 'more',
  avaliable_to_lock: '만큼 잠금 가능합니다',
  lock_lptoken: '잠금 LP Tokens',
  lptoken_locker: 'LP Token 잠금기',
  filter: '필터',
  bonus: '보너스',
  your_shares_ve: '당신의 지분',
  voted: '투표됨',
  my_voting_power: '나의 투표권',
  new_voting_ratio: '새로운 투표 비율',
  REF_allocation: 'REF 배당',
  vote: '투표하기',
  preview: '미리보기',
  options: '옵션',
  ratio: '비율',
  forum_discussion: 'Forum 토론',
  voted_veLPT: '투표에 쓰인 veLPT',
  your_contribution: '당신의 기여',
  delete: '삭제',
  not_start: '시작 안함',
  no_veLPT: 'veLPT 없음',
  claim_bonus: 'Bonus 받기',
  ended_ve: '끝남',
  proposal_details: '프로포절 세부사항',
  back: '뒤로',
  pending_ve: '대기중',
  you_voted: '당신이 투표함',
  add_bonus: 'Bonus 추가',
  you_vote: '당신의 투표:',
  Creator: '제작자',
  turn_out: '투표율',
  top_answer: 'Top Answer',
  your_bonus: '당신의 보너스',
  gauge_weight_vote: 'Gauge Weight 투표',
  governance: '거버넌스',
  confirm: '확인',
  voting_period: '투표 기간',
  applying_period: '적용 기간',
  community_gauge_share: '커뮤니티 Gauge 지분',
  qualified_pools: '자격 인증된 풀들',
  total: '총합',
  supply_voted: '공급 투표됨',
  proposed: '프로포절 제출됨',
  farm_reward: '농사 보상',
  check_last_round: '결선 투표 확인하기',
  last_round_voting_result: '결선 투표 결과',
  create_proposal: '프포로절 만들기',
  type: '타입',
  add_option: '옵션 더하기',
  lock_lp_terms:
    '나는 내가 잠근 LP 토큰을 동의한 기간 전까지 꺼낼 수 없다는 점을 이해합니다',
  connect_to_near_wallet: 'NEAR Wallet에 연결하기',
  only_for_whitelist_address: 'whitelist된 주소에만 허용',
  v1Legacy: '구버전',
  v2New: 'Classic-신버전',
  v2_new_farms: 'Classic 신버전 농장',
  migrate_now: '지금 이주하세요!',
  v2_boost_tip:
    'Classic 농장에선 부스팅이 가능합니다. Legacy 농장들은 다음 날자 이후로 보상을 지급하지 않습니다: <span class="font-bold">2022년 8월 1일</span> ',
  v2_boost_tip2: 'Classic 농장에선 부스팅이 가능합니다.',
  v2_boost_no_tip:
    'Legacy 농장들은 다음 날자 이후로 보상을 지급하지 않습니다: <span class="font-bold">2022년 8월 1일</span> ',
  no_farm_need_to_migrate: '이주해야하는 농장은 없습니다.',
  v1_farms: 'Classic 농장',
  has_rewards_tip:
    '출금할 리워드가 다음 농장들에 남아있습니다: <a rel="noopener noreferrer nofollow" class="text-greenColor text-base cursor-pointer underline hover:text-senderHot"  href="/farms" >Legacy Farms</a>. 보상이 사라지진 않습니다. 언제든 출금할 수 있습니다.</div>',
  love: 'LOVE',
  Available_to_stake: '스테이킹 가능한 양은:',
  you_staked: '당신이 스테이킹한 양은:',
  has_rewards_tip_in_v2:
    '출금가능한 레거시 보상들이 기다립니다 <a rel="noopener noreferrer nofollow"  href="/farms" class="text-sm text-greenColor cursor-pointer underline ml-1 hover:text-senderHot"> Legacy Farms</a>.',
  how_to_get: '어떻게 받나요?',
  get_booster: '부스터 받기',
  ended_farms: '부스팅 종료된 농장',
  boosted_farms: '부스팅된 농장',
  your_farm: '당신의 농장',
  your_boosted_farms: '당신의 부스팅된 농장',
  search_farms_by_token: '토큰에 의해 농장 검색하기',
  search_farms: '농장 검색',
  more_lock_ve_popup: 'more',
  more_lock_ve_popup2: 'more',
  more_in: 'more in',
  starts: '시작',
  left: '남음',
  proposal_title: '프로포절 제목',
  share_forum_discussion_link_here: '포럼 토론 링크를 여기에 남겨주세요',
  required_field: '필수 입력 항목',
  voting_power_and_extra_earnings: '투표권 & 추가 수익',
  bonus_tip:
    '투표 보너스는 유저들의 투표를 독려하게끔 설계되었습니다. 보너스는 당신의 지분에 따라 달라집니다',
  yes_continue: '예, 계속합니다',
  unlocking_tip:
    'LP 토큰을 잠금 해제하면 다음 프로포절들에 대한 당신의 투표는 자동으로 취소됩니다. (관련 보너스도 사라집니다)',
  create_pool: '풀 만들기',
  search_by_token: '토큰으로 검색',
  your_power: '당신의 "파워"',
  farm_has_boost_tip:
    '당신의 "파워" = 당신이 스테이킹한 LP 토큰 * 부스터 (부스터는 LOVE를 스테이킹하면 나옴)',
  farm_no_boost_tip: '당신의 "파워" = 당신이 스테이킹한 LP 토큰',
  more_than_a_simple_pool: '단순한 풀이 아니다',
  ended_Farms: '종료된 농장들',
  vote_capital: 'VOTE',
  starting_time: 'Starting Time',
  ending_time: 'Ending Time',
  please_choose_voting_duration: '투표 기간을 설정해 주세요!',
  please_choose_proposal_voting_duration: '프로포절 투표 기간을 정해주세요',
  days_ve: '날자들',
  vote_mobile_capital: 'VOTE',
  risks_capital: '리스크',
  view_on: '보기',
  boostFarmTip: 'LOVE를 스테이킹하고 부스터를 받으세요',
  boosted: '부스트 있음',
  love_staked: '스테이킹 된 LOVE',
  your_apr: '당신의 APR',
  range_apr: '범위, 혹은 참조 APR',
  no_pair_found: '쌍을 찾을 수 없습니다',
  found_stable_pool_in_sauce: 'SAUCE에서 안정적인 풀을 찾았습니다',
  lp_tokens_locking: 'LP 토큰',
  you_currently_have: '현재 가지고 있는',
  scheduled_to_be_unlocked: '잠금 해제 예정',
  locking_more_lp_tokens: '더 많은 LP 토큰 잠금',
  will_mean_these: '이것들을 의미할 것이다',
  lp_tokens_will_not_be_unlocked_until:
    'LP 토큰은 다음 시간까지 잠금 해제되지 않습니다',
  "I_understand_that_I_won't_be_able_to_remove_my":
    '내 계정을 삭제할 수 없음을 이해합니다',
  until: '~까지',
  continue: '계속하다',
  then_enter_a_derivation_path_to_connect:
    '그런 다음 연결할 파생 경로를 입력하십시오',
  account_identification_failed:
    '계정 식별에 실패했습니다! 수동으로 제공하십시오',
  input_account_id: '계정 아이디 입력',
  we_found: '우리는 발견',
  accounts_on_your_device: '기기의 계정',
  select_the_accounts_you_wish_to_connect: '연결하려는 계정을 선택하십시오',
  can_not_find_any_accounts:
    '이 Ledger와 연관된 계정을 찾을 수 없습니다. 새로운 NEAR 계정을 만드시거나',
  or_connect_another_ledger: '새로운 Ledger를 연결해 주세요',
  add: '추가하다',
  finish: '마치다',
  add_custom_network: '사용자 지정 네트워크 추가',
  network_name: '네트워크 이름',
  rpc_name_taken_tip: '네트워크 이름은 이미 사용 중입니다',
  network_invalid: '네트워크가 잘못되었습니다',
  fobidden_testnet_rpc_tip:
    'RPC 서버의 네트워크(테스트넷)가 이 네트워크(메인넷)와 다릅니다',
  no_support_testnet_rpc_tip:
    '테스트넷은 사용자 지정 RPC 추가를 지원하지 않습니다',
  stable: 'Stable',
  trade_capital: '거래',
  trade_capital_hump: '거래',
  pro: '프로',
  limit: '한계',
  make_an_order: '주문하기',
  your_active_orders_will_appear_here: '당신의 현재 주문이 여기 있습니다',
  your_history_orders_will_appear_here: '당신의 과거 주문 내역이 여기 있습니다',
  liquidity_capital: '리퀴디티',
  goes_to_limit_order: '주문 제한에 걸렸습니다',
  farm_only: '농장만',
  please_confirm_this_transaction_on_ledger:
    'Ledger에서 이 거래를 확인하십시오.',
  limit_order: '주문 제한',
  newText: 'New',
  farm_stop_tip: '이 농장은 중단되었습니다',
  pool_stop_tip: '이 풀은 중단되었습니다',
  volume_24h: '총 거래량 (24h)',
  add_to_watchlist: '와치리스트에 추가',
  remove_from_watchlist: '와치리스트에서 삭제',
  create_order: '오더 생성',
  filled: '처리됨',
  canceled: '취소됨',
  partially_filled: '일부 처리됨',
  pool_composition: '풀의 구성',
  amount: '수량',
  h24_volume_bracket: '총 거래량(24h)',
  fee_24h: '수수료 (24h)',
  cross_chain_swap: '체인 간 거래',
  orders: '명령',
  your_orders: '당신의 주문',
  active: '현재 진행중',
  history: '과거 내역',
  you_sell: '당신이 판',
  you_buy: '당신이 산',
  fee_tiers: '수수료 등급',
  order_rates: '주문 rates',
  created: '생성됨',
  executed: '실행됨',
  actions: '액션들',
  claimed_upper: 'Claimed',
  open_my_order: '나의 오픈 오더',
  initial_order: '최초 주문',
  this_order_has_been_partially_filled: '이 주문은 일부 처리되었습니다.',
  filled_via_swap: '스왑으로 주문 처리됨',
  state: '상태',
  partially: '부분적으로',
  near_validation_error: '가스비를 예비해서 0.5N 이상이 지갑에 있어야합니다.',
  near_min_validation_error:
    '가스비를 예비해서 0.2N 이상이 지갑에 있어야합니다.',
  no_positons_in_this_pool_yet: '이 풀에는 아직 포지션이 없습니다',
  no_claimed_rewards_yet: '수령된 보상이 없습니다',
  out_of: 'out of',
  value_must_be_less_than_or_equal_to: '값은 다음보다 적거나 같아야 합니다:',
  select_tokens: '토큰 선택',
  fee_Tiers: '수수료 등급',
  select_s: '선택',
  no_pool: '풀 없음',
  input_amount: '입력 양',
  update_range: '업데이트 범위',
  not_enough_balance: '잔고 부족',
  set_price_range: '가격대 설정',
  current_price: '현재 가격',
  pool_creation: '풀 생성',
  pool_creation_tip:
    '그 토큰들을 위한 풀은 현재 없습니다. 적절한 토큰들의 비율을 맞춰 제공하면 풀을 생성할 수 있습니다. ',
  starting_price: '시작가',
  create_a_pool: '풀 생성하기',
  add_single_price_tip:
    '시장 가격이 당신의 가격대에 들어오지 않는 한, 당신의 포지션은 수수료를 벌지도 않고 트레이드에 쓰이지도 않을 것입니다.',
  invalid_range_tip:
    '부적절한 가격대 선택됨. 최소 가격이 최대 가격보다 낮아야 합니다. ',
  maket_price_outside_tip: '시장 가격이 당신이 선택한 가격대 밖입니다.',
  maket_price_outside_single_only_tip:
    '시장 가격이 당신이 선택한 가격대 밖입니다. 자산 한개의 예치만 가능합니다.',
  min_price: '최소 가격',
  max_price: '최대 가격',
  min: '최소',
  out_of_range: '범위 넘김',
  in_range: '범위 맞음',
  unclaimed_fees: '안 찾아간 수수료들',
  your_position_will_be: '이 가격에서는: ',
  at_this_price: '이 당신의 포지션입니다',
  price_range: '가격대',
  positions: '포지션',
  position: '포지션',
  add_new_position: '새로운 포지션 추가',
  range: '범위',
  unclaimed_fee: '안 찾아간 수수료',
  login_risk_tip:
    '여기에 체크하시면 이 뒤에 설명된, Ref Finance 사용시의 리스크를 완전히 숙지하시고 진행하는 것으로 간주됩니다: <a rel="noopener noreferrer nofollow" target="_blank" class="text-greenColor text-sm font-bold cursor-pointer hover:underline" href="/risks")">리스크 설명</a> .',
  no_pool_tip: '아이쿠! 그 풀은 존재하지 않습니다',
  total_ref_week: '총 합계: REF/week',
  ref_week_you_will_get: '당신이 받게 될 REF/week ',
  stable_pool: '스테이블 풀',
  market_rate: '시장 요율',
  limit_price_tip:
    '더 효과적인 거래를 위해, 당신의 가격은 한 칸 옆의 가까운 슬롯에 자동적으로 있어야 합니다', //
  no_token_found: '해당되는 토큰 없음',
  search_name_or_address: '이름을 검색하거나 주소를 붙여넣으세요...',
  token_address_invalid: '토큰 주소가 올바르지 않습니다',
  price_impact_warning: '가격 영향 경고',
  confirm_order: '구매 확정',
  you_Sell: '판매합니다',
  to_Buy: 'to Buy',
  at_Price: '이 가격에',
  insufficient_balance: '잔고 불충분',
  MORE: '더 보기',
  limitTip: '당신의 가격은 가장 가까운 가격 슬롯으로 맞춰집니다.',
  v2PoolTip: 'DCL 풀에서 16개의 포지션을 가질 수 있습니다',
  orderTip: '당신은 16개의 활성화된/과거의 리미트 오더를 가질 수 있습니다',
  v2_pools: 'DCL 풀들',
  minimum_received_dot: '최소 수령 양',
  v2_your_pool_introduction: '당신의 분할된 집중화 유동성',
  v1_your_pool_introduction: '기존의 풀에 들어간 유동성',
  estimate_value: '가치 예상하기',
  price: '가격',
  '@price': '@Price',
  h24_high: '24h 최고점',
  h24_low: '24h 최저점',
  h24_Volume: '24h 총량',
  orderbook: '오더북',
  orderbook_mobile: '오더북',
  trades: '거래들',
  qty: '양',
  total_orderly: '총합',
  time: '시각',
  balances: '잔고',
  asset: '자산',
  wallet_up: '지갑',
  account_in_order: '계정: 순서대로 정렬',
  source_address: '소스 주소',
  action: '행위',
  market: '시장',
  order_type: '오더 타입',
  order_type_limit_tip:
    '리미트 오더는 미리 정해놓은 가격 이상으로 좋은 가격에 사거나 파는 행동입니다. 리미트 오더는 거래가 수행되지 않을 수 있습니다',
  order_type_market_tip:
    '마켓 오더는 가장 나은 시장 가격을 검색, 그 가격으로 즉시 거래가 수행됩니다',
  quantity: '양',
  advanced: '고급 옵션',
  tip_fok:
    '전량충족조건(Fill-Or-Kill)은 모든 거래량을 한번에 즉시 전량 다 처리할 수 있으면 거래가 성사되고, 그게 안 되면 아예 거래를 취소하는 옵션입니다.',
  tip_post_only:
    '지정가 주문 (Post Only)는 내가 제출한 주문이 Maker 주문으로만 실행되게 해 주는 기능입니다. 오더북에 Maker 주문으로만 올라가고, Taker 주문으로 올라가는 경우 취소됩니다',
  tip_ioc:
    '즉시 혹은 취소(Immediate-Or-Cancel, IOC) 주문은 조건에 맞는 체결가능 수량만 즉각 체결하는 주문입니다, 체결 불가능한 조건의 주문 잔량은 취소됩니다.',
  Taker: '테이커',
  Maker: '메이커',
  see_all: '전부 보기',
  h24Vol: '24h 거래량',
  h24Range: '24h 거래 범위',
  chart: '차트',
  deposit_assets_to_begin_your_trading_journey:
    '당신의 거래 여정을 {br} 자산 예치로 시작하세요',
  open_orders: '주문 열기',
  ref_order_only: 'REF로만 주문하기',
  ref_order: 'REF Dex 전용',
  orderly_portfolio_table_tips:
    '여기 보이는 데이터는 당신 계정의 모든 자산과 기록을 포함합니다. Ref에서 생성되지 않은 데이터들도 다 포함해서 보여집니다.',
  orderly_ws_refresh: '계속 진행하려면 데이터를 새로고침하세요',
  refresh: '새로고침',
  settled_balance: '정산 후 잔액',
  settled_amount: '정산으로 지불된 금액',
  Side: '사이드',
  all_side: '모든 사이드',
  both_side: '양 사이드 다',
  both: '둘 다',
  filled_qty: '체결 됨 / 양',
  fill_qty: '체결 희망 / 양',
  est_total: '예상 총액',
  avg_price: '평균 가격',
  create_time: '생성 시각',
  spot: 'Spot', //
  futures: '미래의',
  All: '전부',
  Filled: '체결됨',
  Fees: '수수료',
  Cancelled: '취소됨',
  Rejected: '거부됨',
  all_instrument: '모든 Instrument', // all instrument
  available_orderly: '가능한',
  history_orderly: '과거 내역',
  settle_pnl: 'PnL 정산하기',
  settle_pnl_tips:
    '이걸 하는 경우, 우리는 당신의 수익이나 손해를 perp market으로부터 USDC 토큰 잔액으로 이동시킵니다. 이는 당신의 포지션이나 헬스 팩터에 영향을 주지 않습니다. ',
  total_unsettled_pnl: '총 미정산 PnL',
  fut_unreal_pnl: '미래 미실현 PnL',
  fut_daily_real: '미래 일간 실현',
  fut_notional: '미래 명목Notional',
  fut_unsettle_pnl: 'PnL 정산 안하기', //
  settle: '정산하기',
  mark_orderly: '표시가',
  avg_open: '평균 열기',
  liq_price: '유동성 가격',
  unreal_pnl: '비실현 Pnl', //'Unreal. PnL',
  daily_real: '일간 실현', //'Daily Real',
  market_price: '시장 가격',
  NEW: 'New',
  PARTIAL_FILLED: '일부 체결됨',
  limit_price: '리미트 가격',
  unsettled_pnl: 'Unsettled PnL',
  notional: '명목',
  limit_orderly: '리미트',
  'qty.': 'Qty.',
  no_orders_found: '발견된 주문 없음',
  no_records_found: '발견된 기록 없음',
  its_empty: '텅 빔!',
  current_orderly: '현재',
  cancel_order: '주문 취소',
  post_only: '지정가 주문Post Only',
  fok: '전량충족조건(FOK)',
  ioc: '즉시 혹은 취소(IOC)',
  instrument: 'Instrument', // Instrument
  open_order_detail: '주문 상세 열기', //
  history_order_detail: '주문 내역 열기',
  select_orderly: '선택 ',
  change_orderly: '교체',
  token_orderly: '토큰',
  user_foot_tip:
    '* 이 오더북 페이지는 Orderly Network에서 쓰이는 UI지만, Ref Finance 사용자들의 편의를 위해 특별히 제공되었습니다.',
  learn_more_orderly: '더 알아보기',
  more_order_book_page_detail:
    '이 오더북 페이지는 Orderly Network에서 개발했으며, 사용자들은 지갑을 연결하거나 주문하기 전에 꼭 주의할 사항이 있는지를 스스로 찾아봐야 합니다. {br} Ref Finance는 이 시스템과 스마트 컨트랙트 등의 보안에 대해 책임지지 않으며, 마찬가지로 이를 통해 예치하거나 송금된 돈에 대해서도 책임지지 않습니다.{br} Ref Finance 및 Orderly Network 는 이 오더북 페이지를 통한 유저의 투자의 손익 결과에 책임이 없습니다.',
  learn_more_about: '더 알아보기',
  learn_more_about_zh: ' ',
  by_click_confirm:
    '"확인" 버튼을 누른 유저는 위에 언급된 내용을 종합적으로 이해하고 검토했다고 간주됩니다.',
  connect_to_orderly_account:
    'Orderly 계정에 다시 연결해야 Ref의 오더북Orderbook을 쓸 수 있습니다',
  first_register_orderly_tip:
    '당신의 월렛이 우선 Orderly에 등록되어야 오더북Orderbook을 쓸 수 있습니다.',
  must_register_tip:
    '당신의 월렛이 우선 Orderly에 등록되어야 그 쪽 시스템에서 거래가 가능합니다.',
  register_deposit_tip: '등록하려면 보관 보증금(storage deposit)이 필요합니다',
  increase_storage_deposit:
    '=Orderly account의 보관 보증금(storage deposit) 양을 늘려야 하는 것 같습니다',
  deposit_storage_fee: '보관 보증금 예치하기',
  register_orderly_account: 'Orderly 계정을 등록하기',
  register: '등록하기',
  connect_to_orderly: 'Orderly에 연결하기',
  order_cancelled: '주문 취소됨',
  order_edit_success: '주문 변경 성공!',
  created_orderly: '생성됨',
  open_orderly: '열기',
  dex: 'Dex',
  min_price_should_be_higher_than_or_equal_to:
    '최소 가격은 다음보다 높거나 같아야 합니다:',
  price_should_be_lower_than_or_equal_to:
    '가격은 다음보다 낮거나 같아야 합니다:',
  price_should_be_a_multiple_of: '가격은 다음 수의 배수인게 좋습니다:',
  price_should_be_greater_than_or_equal_to:
    '가격은 다음보다 높거나 같아야 합니다:',
  the_order_value_should_be_be_greater_than_or_equal_to:
    '주문 값은 다음보다 높거나 같아야 합니다: ',
  quantity_to_buy_should_be_greater_than_or_equal_to:
    '구매 수량은 다음보다 크거나 같아야 합니다:',
  quantity_to_sell_should_be_greater_than_or_equal_to:
    '판매 수량은 다음보다 크거나 같아야 합니다:',
  quantity_to_buy_should_be_less_than_or_equal_to:
    '구매 수량은 다음보다 작거나 같아야 합니다:',
  quantity_to_sell_should_be_less_than_or_equal_to:
    '판매 수량은 다음보다 작거나 같아야 합니다:',
  quantity_should_be_a_multiple_of: '수량은 다음 수의 배수인게 좋습니다:',
  at_least_one_of_order_quantity_or_price_has_to_be_changed:
    '최소 한 개 이상의 수량, 가격 설정이 잘못되어 수정되어야 합니다',
  quantity_should_be_higher_than: '수량은 다음보다 높아야 합니다: ',
  price_should_be_higher_than: '가격은 다음보다 높아야 합니다: ',
  insufficient_en: '불충분합니다',
  the_all_data_orderly_tip:
    '여기 보이는 데이터는 당신 계정의 모든 자산과 기록을 포함합니다. Ref에서 생성되지 않은 데이터들도 다 포함해서 보여집니다.', //check
  at_orderly: 'at',
  change_the_quantity_of: '여기 수량을 바꾸세요: ',
  change_the_price_of: '여기 가격을 바꾸세요: ',
  market_order_filled: 'Market Order 체결됨',
  limit_order_created: 'Limit Order 생성됨',
  limit_order_filled: 'Limit Order 체결됨',
  open: '열기',
  to_buy: '구매',
  to_sell: '판매',
  in_open_orders: '열린 주문에서:',
  your_investment: '당신의 투자금',
  your_investment_tip:
    'Ref에 예치한 투자금을 USD로 계산하려면: 기존의 Classic 풀 + (농장 스테이킹 포함한) DCL 풀  + xRef를 하면 됩니다',
  classic_pools: 'Classic 풀',
  xref_staking: 'xREF 스테이킹',
  token_balances: '토큰 잔고',
  unclaimed_earnings: '미수령 수입',
  unclaimed_earnings_tip: '미수령된 수입(DCL 풀과 농장 보상)의 USD 기준 가격.',
  unclaimed_pool_fees: '미수령된 풀 수수료',
  unclaimed_farm_rewards: '미수령된 농장 보상',
  active_orders: '활성화된 주문들',
  yield_farming: '이자 농사',
  execute_status: '상태 실행',
  your_liquidity_usd_value: '당신의 유동성 (USD 기준)',
  usage: '사용',
  holding: '홀딩',
  your_lp_tokens_and_shares: '당신의 LP 토큰(지분)',
  usd_value_staked: 'USD 기준 스테이킹 금액',
  instant_swap: '즉시 스왑',
  swapped: '스왑됨',
  created_s: '생성됨',
  order_progress: '주문 진행사항',
  dcl_pool: 'DCL 풀',
  classic: 'Classic',
  classic_farms: 'Classic 농장들',
  unstaked: '스테이킹 해제된',
  staked_in_farm: '농장에 스테이킹 됨',
  farm_reward_range: '농장 보상 범위',
  your_positions: '당신의 포지션',
  your_price_range: '당신의 가격 범위',
  locked_in: '다음에 잠겨 있음:',
  near_locked_in_wallet_for_covering:
    '0.5 NEAR가 지갑에 든 채로 잠깁니다. 이는 트랜잭션 수수료를 보장하기 위해 필요합니다.',
  account_assets: '계정 내 자산',
  open_qty: 'Qty. 열기',
  actions_orderly: '행동',
  records: '기록',
  buy_pop: '구매하기',
  sell_pop: '판매하기',
  fee_orderly: '수수료',
  executing: '실행중인',
  from_2: '로 부터',
  to_2: '까지',
  your_orders_2: '당신의 주문들',
  in_farm_2: '농장 안에',
  your_liquidity_2: '당신의 유동성',
  welcome: '환영합니다!',
  welcome_mobile: '지갑을 연결하시고 시작하세요! ',
  select_token_orderly: '토큰 선택',
  overview: '전체 둘러보기',
  positions_2: '포지션',
  'deposit_near_tip_0.5':
    '예치하기 위해서는 가스 비용 처리를 위해 최소 0.5NEAR를 지갑에 남겨 두세요.',
  trade: '거래',
  earn_2: '얻다',
  portfolio: '포트폴리오',
  more_2: '더 보기',
  liquidity_pools_2: '유동성 풀',
  business_inquiries: '사업자 문의',
  stable_pools: '스테이블 풀들',
  pool_detail: '풀 상세내역',
  new_contract_deploy_tip:
    '새로운 컨트랙트가 배포되었습니다! 이전 컨트랙트에서 유동성을 제거해주세요.',
  remove_will_automatically_claim:
    '유동성을 제거하면 미수령된 보상 수수료가 자동으로 수령됩니다.',
  legacy_tip: '스테이킹 안 된 LP 토큰 혹은 보상을 여기서 찾았습니다:',
  legacy_farms: '레거시 농장들',
  all_farms: '모든 농장들',
  your_farms_2: '당신의 농장들',
  dcl_farms: 'DCL 농장들',
  farm_type: '농장 유형',
  farm_2: '농사', //
  to_claim: '수령하려면',
  liquidity_staked: '유동성 스테이킹 됨',
  dcl_pool_detail: 'DCL Pool 상세',
  reward_range: '보상 범위',
  reward_range_tip: '농장 보상은 다음 범위 내입니다:',
  ending_soon: '곧 끝납니다',
  farm_ended_tip: '이 농장은 끝났습니다.',
  farm_will_ended_tip: '이 농장은 곧 끝납니다.',
  new_farm: '새 농장',
  is_coming: '개봉박두',
  est_apr: '예상 연이율(APR)',
  state_2: '상태',
  farming: '농사 중',
  unavailable: '불가능unavailable',
  liquidity_detail: '유동성 상세내역',
  hide: '숨기기',
  show: '보이기',
  unavailable_positions: '불가능한 포지션들',
  your_price_range_tip: '당신의 가격 범위는 보상 범위 밖입니다. ',
  position_has_staked_tip:
    '이 포지션은 다른 농장이 이미 스테이킹 하고 있습니다.',
  minimum_tip: '최소 스테이킹 수량: ',
  your_liquidity_3: '당신의 유동성',
  position_has_staked_pre: '이 포지션은 다음 장소에 스테이킹 되어 있습니다: ',
  another_farm: '다른 농장',
  add_position: '포지션 더하기',
  full_range: '전체 범위',
  your: '당신의',
  max_apr: '최대 연이율(APR)',
  faming_positions: '농사 포지션들',
  you_can_earn_tip: '이자 농사로 보상을 얻을 수 있습니다! 예상되는 연 이율:',
  you_can_earn_current_tip:
    '당신이 스테이킹한 농장이 끝났습니다. 곧 새로운 농장이 옵니다. 예상되는 연 이율:',
  go_new_farm: '새 농장 가보기',
  go_farm: '농사하러 가기',
  earn_rewards: '보상을 얻을 수 있습니다',
  est_apr_is: '예상 연 이율은 : ',
  new_farm_2: '새로운 농장',
  you_can_earn_current_pre_tip: '당신이 스테이킹한 농장이 끝났습니다, 그리고',
  in_ended_farm: '끝난 농장 안에',
  staked: '스테이킹 됨',
  in_farm_3: '농장 안에',
  estimation: '예상 가치',
  no_dcl_position_tip:
    "당신은 현재 DCL 유동성 포지션이 없ㅅ브니다. 'AddPosition'을 클릭해서 농사를 시작하세요.",
  price_on_slot_tip: '가격은 가까운 한 칸 이내여야 합니다.',
  position_will_appear_here: '당신의 유동성 포지션이 여기 나타납니다.',
  by_farming: '농사를 통해',
  this_staked_tip: '이 NFT는 여기에 스테이킹 되었습니다',
  dcl_liquidity: 'DCL 유동성',
  classic_liquidity: 'Classic 농장 유동성',
  will_appear_here_tip: '당신의 유동성 포지션이 여기 나타납니다.',
  dcl_will_appear_here_tip: '당신의 DCL 유동성 포지션이 여기 나타납니다.',
  classic_will_appear_here_tip:
    '당신의 Classic 유동성 포지션이 여기 나타납니다.',
  yield_farming_appear_here_tip: '당신의 이자 농사 내용이 여기 나타납니다.',
  active_order_appear_here_tip: '당신의 활성화된 주문 내용이 여기 나타납니다.',
  account_appear_here_tip: '당신의 지갑/계정 자산들이 여기 나타납니다.',
  farm_state: '농장 상태',
  farm_detail: '농장 상세내역',
  unstaked_2: '스테이킹 해제됨',
  unstaked_positions: ' 스테이킹 해제된 포지션들',
  price_range_overlap_ratio: ' 가격 범위 중첩 비율',
  swapped_history: '스왑 히스토리',
  real_time_executed_orders: '실시간 실행된 주문들',
  real_time_executed_orders_tip:
    '리미트 오더 기능으로 시장가 이상으로 판매 주문을 넣거나 시장가 이하로 구매 주문을 넣을 경우 Ref는 우선 스톱포인트 있는 스왑(swap-with-stop-point) 액션을 먼저 실행한 뒤에, 남는 양이 있을 경우 리미트 오더를 체결할 것입니다. "실시간으로 실행된 주문(real time executed order)"은, 리미트 오더 기능을 통해 만들어졌으나 실제 실행은 스왑을 통해 거래된 주문을 말합니다. "과거 내역" 섹션에서, 당신은 최초의 표기된 리미트 오더부터 현재까지의 실시간 실행 주문을 볼 수 있습니다. ',
  swapped_history_order: '스왑됨?',
  ledger_guide_tip_1: 'Ledger 지갑을 트랜잭션에 성공적으로 사용하기 위해,',
  ledger_guide_tip_2: '이 기능이 자동으로 활성화 되었습니다.',
  ledger_guide_tip_3: '염두해 주세요',
  ledger_guide_tip_quote: '"',
  support_ledger_tip_new: '"Support Ledger"',
  ledger_guide_tip_4:
    'Ledger 지갑의 제약 때문에, 스왑 기능에서 현재 제공하는 요금이',
  ledger_guide_tip_5: '최상의 가격이 아닐 수 있습니다',
  ledger_guide_tip_6: '.',
  view_dcl_pool: 'DCL Pool 보기',
  your_trade_route: '당신의 거래 루트',
  markets: '시장들',
  exchanges: '거래소들',
  output_est: 'Output (예상치.)',
  go_to_orderbook: 'Orderbook으로 가기',
  new_swap_order_tip:
    '가격의 출처는 Ref AMM의 offer이며, 참고자료일 뿐입니다. 당신의 리미트 오더가 즉시 체결되리라는 보장은 없습니다',
  instants_swap: '즉시 스왑',
  swappped: '스왑됨',
  claimed: '수령됨',
  executing_capital: '실행중',
  record: '기록',
  trade_route: '거래 경로',
  settings: '설정',
  cross_chain_options: '크로스 체인 옵션들',
  no_trade_routes: '거래 루트 안 사용하기',
  steps_in_the_route: '루트 내 각 단계들',
  swap_chart_no_data: '차트로 보일 데이터가 충분하지 않습니다.',
  go_to_orderbook_tip:
    '이 가격은 참고용일 뿐입니다. {br} <strong>Orderbook</strong>으로 넘어가셔서 주문해 주세요',
  last_updated: '최종 업데이트 일:',
  steps_in_the_route_zh: '',
  has_tri_pool_tip:
    ' Ref.의 크로스 체인 옵션에서 Trisolaris를 활성화하여 더 알아보세요',
  Overview: '전체 보기',
  RefFinance: 'Ref.finance',
  Orderly: 'Orderly',
  Burrow: 'Burrow',
  Supplied: '공급됨',
  Borrowed: '빌림',
  NetAPY: '순 연이율 (APY)',
  UnclaimedRewards: '미수령 보상',
  YouSupplied: '당신의 공급 내역:',
  SupplyMarket: '공급 시장',
  YouBorrowed: '당신의 대출 내역:',
  BorrowMarket: '대출 시장',
  NetAPYTip:
    '공급, 대출 포지션의 모두에 대한 순수 연 이율 APY입니다. 기본 APYs와 인센티브를 포함합니다',
  Market: '시장',
  TotalSupplied: '총 공급액',
  TotalBorrowed: '총 대출액',
  AvailableLiquidities: '가용 유동성',
  DailyRewards: '일일 보상',
  SupplyAPY: '공급 연 이율 (APY)',
  BorrowAPY: '대출 연 이율 (APY)',
  Collateral: '담보',
  Adjust: '조정',
  Supply: '공급',
  Repay: '상환',
  Borrow: '대출',
  HealthFactor: '헬스 팩터',
  CollateralFactor: '담보 요인',
  AdjustCollateral: '담보 조정',
  UseAsCollateral: '담보로 사용하기',
  RemainingCollateral: '남은 담보',
  RemainingBorrow: '남은 대출',
  NoSupplyTip: '당신이 공급한 자산이 여기 나타납니다.',
  NoBorrowTip: '당신이 대출한 자산이 여기 나타납니다.',
  APY: 'APY',
  CF: 'C.F.',
  burrowErrorTip:
    '현재 잔액이 소수점 이하로 너무 적어서, 컨트랙트에 의해 처리될 수 없습니다',
  healthErrorTip:
    '당신의 헬스 팩터가 위험할 정도로 낮으며, 이대로는 청산 위험이 있습니다.',
  Summary: '요약',
  Welcome: '환영합니다!',
  ConnectView: '당신의 지갑을 연결하신 후 볼 수 있습니다',
  TotalSuppliedMarket: '총 공급량',
  NetWorth: '순자산',
  Claimable: '수령 가능',
  TotalDebts: '총 부채',
  WalletAssets: '지갑의 자산',
  TotalInvested: '총 투자액',
  TotalAssets: '총 자산',
  TokenAllocation: '토큰 할당',
  perpTip:
    '영구 선물 트레이딩에 오신 걸 환영합니다! 시작 전 {perpTipLink}를 확인해주세요! ',
  perpTipLink: '[영구 선물 소개]  ',
  perpetual: 'Perpetual',
  pred_funding_rate: 'Pred. Funding Rate',
  open_interest: '이자 창 열기',
  bridge_pure: '브릿지',
  pool_refresh: '서버에 이상이 있습니다. 다시 시도해 주세요.',
  netWorthTip:
    'Ref에 투자된 자산 (수령가능한 보상 포함) + Orderly 자산 + Burrow 자산 + 지갑 내 자산',
  mark_price: '표시가',
  last_price: '최종가',
  mark_price_tip: '손익분석(PnL) 계산과 청산에는 표시가를 사용합니다.',
  total_collateral: '총 담보',
  current_leverage: '현재 레버리지',
  free_collateral: '남은Free 담보',
  total_upnl: '총 미실현손익 (uPnL)',
  perps: 'Perps',
  margin_ratio: '마진 비율',
  total_npnl_tip:
    '열려 있는 모든 포지션에서의 미실현된 수익과 손해를 추정한 값입니다.',
  low_risk: '리스크 낮음',
  mid_risk: '리스크 중간',
  high_risk: '리스크 높음',
  liquidations: '청산',
  leverage_max_leverage: '게정 최대 레버리지',
  leverage_max_leverage_raw: '게정 최대 레버리지',
  marker_taker_fee_rate: 'Maker/Taker 수수료 요율',
  liquidation_price: '청산 가격',
  margin_required: '마진 필요함',
  perp_buy_limit_order_range:
    '매수 limit order의 가격은 다음보다 낮거나 같아야 합니다:',
  perp_sell_limit_order_range:
    '매도 limit order의 가격은 다음보다 높거나 같아야 합니다: ',
  perp_buy_limit_order_scope:
    '매수 limit order의 가격은 다음보다 낮을 수 없습니다: ',
  perp_sell_limit_order_scope:
    '매도 limit order의 가격은 다음보다 높을 수 없습니다: ',
  liquidations_fee: '청산 수수료',
  more_trading_price: '거래 가격 더 보기',
  unsettle_pnl_tip:
    '영속 선물(perp) 시장에서 USDC로 수익, 손해를 옮기세요. 이것은 당신의 포지션이나 헬스 팩터에 영향을 주지 않습니다.',
  margin_ratio_tip:
    '계정의 마진 비율은 총 담보 가치(예를 들어 USDC 잔액 + 미실현 수익/손해)를 open notional of the account로 나눈 값과 같습니다.',
  portfolio_total_est_tip: '포트폴리오 가치 (USD 기준)',
  size: '사이즈',
  closing_1: 'Closing',
  closing_2: 'position at {type} price.',
  buy_long: '매수 / 롱',
  sell_short: '매도 / 숏',
  unsettle_pnl: '정산 취소(Unsettle) PnL',
  the_margin_will_be_insufficient: '마진이 불충분하리라 예상됩니다',
  exceed_rate_limit: '요율rate 한계를 넘었습니다. 60초 후에 시도해주세요',
  est_liquidation_price: '예상 청산가',
  liquidation_price_tip:
    '이 값은 참고자료일 뿐입니다. 주문이 체결 된 이후에 Orderly 포트폴리오에서 당신의 청산 가격을 볼 수 있습니다.',
  futures_leverage_saved: '레버리지 선물 저장됨',
  maintenance_margin_ratio: '유지증거금 비율',
  liquidation_fee: '청산 수수료',
  ins_fund_transfer: 'Ins. Fund Transfer',
  no_liquidation_yet: '현재 청산 없음',
  funding_fee: '펀딩 요금',
  settlements: '정산',
  funding_annual_rate: '펀딩 요금 / 연간 요금',
  insufficient_margin: 'Insufficient Margin',
  taker_maker_fee: 'Taker/Maker Fee',
  funding: 'Funding',
  taker_maker_fee_rate: 'Taker/Maker Fee Rate',
  spot_nav: 'Spot',
  perps_nav: 'Perps',
  set: 'Set',
  max_leverage_tip:
    'Max account leverage cannot be lower than your current leverage.',
  to_begin_your_trading_journey: '를 예치하여 거래를 시작해 봅시다',
  to_begin_your_trading_journey_2: '을 예치하여 거래를 시작해 봅시다',
  orderly_new_usr_spot_tip: '자산을 예치하여 거래를 시작해 봅시다',
  orderly_new_user_tip:
    '잔고를 확인하고 <strong>USDC</strong>를 예치하여 거래를 시작해 봅시다',
  deposit_on_tip: '자',
  assets: '산',
  display_all_orders_dexes:
    'Display orders placed through all channels on Orderly.',
};
export default Object.assign(ko, ko_in_risks_page);
