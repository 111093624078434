// risk
const es_in_risks_page = {
  risks: 'Riesgos',
  of_using_ref_finance: 'al utilizar Ref. finance',
  introduction:
    'Proporcionar liquidez y/o negociar en Ref Finance no está exento de riesgos. Antes de interactuar con el protocolo, investigue y comprenda los riesgos involucrados.',
  general_risks_quiz: 'Quiz para determinar riesgos',
  amm_core_design: 'Diseño AMM Core ',
  audits: 'Auditores',
  audits_paragraph_2:
    'Las auditorías de seguridad no eliminan los riesgos completamente. Se recomienda no hacer uso de los ahorros o activos, en Ref Finance, que no pueda permitirse perder, para porveerse de liquidez.',
  admin_keys: 'Admin llaves',
  admin_keys_paragraph_2:
    'El actual DAO está constituido por 26 miembros. Ref Finance irá evolucionando hasta ser una DAO totalmente descentralizada.',
  admin_keys_paragraph_3:
    'A continuación encontrará la lista de contratos y direcciones que han gestionando directamente o actualmente se está haciendo para los asuntos de Ref Finance.',
  address: 'Dirección',
  type: 'Tipo',
  mission: 'Misión',
  Locked: 'Bloqueado',
  exchange_contract: 'Contrato de intercambio',
  table_body_tr_1:
    'Administrar las funciones de Creador de Mercado Automatizado; Intercambiar y proporcionar liquidez',
  farming_contract: 'Contratos de Farming',
  table_body_tr_2: 'Gestionar incentivos de liquidez',
  staking_contract: 'Contratos de Staking',
  table_body_tr_3:
    'Mintear y burn xREF, y distribuir recompensas basadas en el tiempo',
  sputnik_dao_contract: 'Contrato Sputnik DAO',
  table_body_tr_4:
    'Asegure el éxito en Ref tomando decisiones estratégicas (incl. smart contract amendments)',
  table_body_tr_5:
    'Administrar y asignar fondos a contribuyentes comunitarios específicos',
  table_body_tr_6: 'Ejecutar la estrategia y la hoja de ruta',
  vesting_contract: 'Contrato de adjudicación',
  table_body_tr_7:
    'Administrar los contratos de concesión de REF de los miembros de Dev DAO',
  airdrop_contract: 'Contrato Airdrop',
  table_body_tr_8: 'Gestionar el primer REF airdrop',
  near_address: 'Dirección NEAR',
  table_body_tr_9: 'Manejo de pagos únicos de recompensas por errores (bugs)',
  fungible_token_contract: 'Contrato de Token Fungible',
  table_body_tr_10: 'Mint REF token',
  rug_pull: 'Rug pull',
  rug_pull_paragraph_1:
    'Si el equipo detrás de un token, ya sea que esté en el whitelist o no, decide abandonar su proyecto y toma el dinero de los inversionistas, el token del proyecto probablemente valdrá $0.',
  rug_pull_paragraph_2:
    'Si el token está en el whitelist  de Ref Finance, eso no significa que el proyecto tendrá éxito. Usted es responsable de hacer su propia diligencia debida del proyecto y debe comprender que las criptomonedas son inversiones especulativas de muy alto riesgo.',
  rug_pull_paragraph_3:
    'Debe ser consciente y estar preparado para perder potencialmente parte o la totalidad del dinero invertido.',
  divergence_loss: 'Divergence Loss',
  divergence_loss_paragraph_1:
    'Si proporciona liquidez, tenga en cuenta que puede ganar más dinero al no proporcionar liquidez.',
  divergence_loss_paragraph_2:
    'La denominada Divergence Loss a menudo se le llama“impermanent loss”. El adjetivo (impermanent) puede suponer o crear la sensación de que las pérdidas en el mercado son solo temporales, lo que significa que se garantiza que las pérdidas se revertirán, lo cual no es cierto.',
  divergence_loss_paragraph_3: 'Aprenda más acerca de la  Divergence Loss',
  staking_risks: 'Riesgos en el Staking',
  staking_risks_paragraph_1:
    'Al hacer Staking, se utilizan múltiples productos de contratos inteligentes, cada uno de los cuales tiene sus propios riesgos.',
  permanent_loss_of_a_peg: 'Pérdida permanente de PEG (PER)',
  permanent_loss_of_a_peg_paragraph_1:
    'Si uno de los stablecoins en el pool cae significativamente por debajo del PEG (PER) de 1.0 y nunca regresa al PEG (PER), siignificará que los proveedores de liquidez mantienen casi toda su liquidez en esa moneda.',
  systemic_issues: 'Problemas sistémicos',
  systemic_issues_paragraph_1:
    'En general, los legos del ecosistema de las finanzas descentralizadas (DeFi) están sumamente conectados, lo que significa que una falla de su componente puede desencadenar una sucesión de fallas.',
  systemic_issues_paragraph_2:
    'Un riesgo sistemático significa que se puede perder dinero, incluso si la falla no afecta directamente a su inversión/exhibición.',
  systemic_issues_paragraph_3:
    'Los siguientes riesgos pueden tener un impacto en los fondos de liquidez:',
  systemic_issues_paragraph_4:
    'Problemas de contratos inteligentes con protocolos de préstamo',
  systemic_issues_paragraph_5:
    'Problemas de contratos inteligentes con protocolos de participación',
  systemic_issues_paragraph_6:
    'Problemas sistémicos con los stablecoins en los pools',
  systemic_issues_paragraph_7:
    'Problemas sistémicos con ERC20-native tokens en los pools',
  crypto_trading_addiction: 'Adicción a la compra- venta de Cryptomonedas',
  crypto_trading_addiction_paragraph_1:
    'El comercio de criptomonedas puede ser muy adictivo y, según varias fuentes, puede ser una forma de adicción al juego que puede destruir vidas.',
  crypto_trading_addiction_paragraph_2:
    'A continuación encontrará una colección de historias relacionadas con ese asunto.',
  crypto_trading_addiction_paragraph_3:
    "'Negociar es apostar, no hay duda al respecto,'",
  crypto_trading_addiction_paragraph_4:
    "'Perdí medio millón de libras comerciando con Bitcoin'",
  crypto_trading_addiction_paragraph_5:
    "'Hablamos con un terapeuta que trata la adicción al comercio de criptomonedas'",
  crypto_trading_addiction_paragraph_6:
    "'Perdí millones a través de la adicción al comercio de criptomonedas'",
  no_in_progress: 'No (En plena marcha)',
  yes: 'Sí',
  no: 'No',
  NA: 'N/A',
  ref_finance_near: 'ref-finance.near',
  ref_finance_near_mission:
    'v1 (depreciated)- Manejo de las funciones de la Automated Market Maker functions; Swap (Intercambio) y Provide Liquidity (Proveer liquidez)',
  token_ref_finance_near: 'token.ref-finance.near',
  token_ref_finance_near_type: 'Contrato del Token Fungible',
  token_ref_finance_near_mission: 'v1 (depreciated) - Mint REF token',
  refchef_near: 'refchef.near',
  simple_address: 'Simple Address',
  refchef_near_mission:
    'Manejo de la transacciones entre cuentas (inter-account transactions)',
  ref_dev_teller_near: 'ref-dev-teller.near',
  Locked_title:
    'Bloqueado? cuadro de información: pasar el cursor para mostrar:',
  Locked_paragraph_1:
    'Contrato bloqueado significa que no hay claves de acceso que permitan volver a implementar el código del contrato',
  Locked_paragraph_2: 'Por lo general, el código puede ser reimplementado por:',
  Locked_paragraph_3:
    '1. una transacción con una acción de implementación de código',
  Locked_paragraph_4:
    '2. el contrato en sí puede implementar una llamada de la función que activará la acción de código de implementación',
  Locked_paragraph_5:
    'Para volver a implementar el código con una transacción, la transacción debe firmarse con una clave de acceso completo.  Si no hay dicha clave en el contrato, no hay forma de volver a implementar el código a menos que haya un soporte dedicado en el propio código del contrato y, por lo tanto, marcamos dichos contratos como bloqueados.',
  Locked_paragraph_6:
    'Si hay al menos una clave de acceso completo registrada en la cuenta del contrato, el contrato no está bloqueado.',
  audited_first_sentence:
    'Los contratos inteligentes de Ref Finance son etiquetados como <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/audits"' +
    '>audited</a>.',

  admin_first_sentence:
    'Ref Finance es gestionado por la etiqueta  <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO</a>. There are <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://gov.ref.finance/t/introducing-the-guardians/253"' +
    '>Guardians</a>, son las direcciones específicas de NEAR specific NEAR, que son capaces de pausar el contrato. Solo el DAO puede reanudar el contrato, en cualquier momento.',
  risks_of_using_ref_finance:
    '<label class="text-greenColor">Riesgos</label> de Usar Ref.finance',
  admin_sentence_1:
    'Ref Finance es gestionado por la etiqueta  <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO</a> y hará la transición a un DAO completamente descentralizado.',
  admin_sentence_2:
    'Para obtener más información relacionada con los contratos y las direcciones que han gestionado directamente o gestionan actualmente los asuntos de Ref Finance, consulte nuestro <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/contracts"' +
    '>Documentation</a>.',
  third_party_wallet: 'Monedero de terceros',
  third_party_wallet_1: 'Ref Finance integra carteras de terceros.',
  third_party_wallet_2:
    'Si bien hemos tomado medidas para seleccionar proveedores de billeteras de buena reputación, no podemos garantizar su seguridad o rendimiento. Debe familiarizarse con los riesgos asociados con el proveedor de billetera específico que tenemos y comprender sus términos de servicio, política de privacidad y prácticas de seguridad.',
  third_party_wallet_3:
    'Utilice estos servicios bajo su propio riesgo y tenga cuidado.',
};
// Main-page
const es = {
  deposit: 'Depósito',
  Deposit: 'Depósito',
  withdraw: 'Retiro',
  Withdraw: 'Retiro',
  withdraw_token: 'Retido de Token',
  swap: 'Swap',
  Swap: 'Intercambio',
  exchange_tokens: ' Intercambio de Tokens',
  pool: 'Pool',
  Pool: 'Pool',
  pools: 'Pools',
  Pools: 'Pools',
  Quiz: 'Prueba',
  no_tokens_deposited: 'No hay Tokens Depositados',
  view_pools: 'Ver Pools',
  view_pool: 'Ver Pool',
  add_token: 'Añadir Token',
  Add_Token: 'Añadir Token',
  create_new_pool: 'Crear un nuevo pool',
  Create_New_Pool: 'Crear un nuevo Pool',
  your_liquidity: 'Tu Liquidez',
  Your_Liquidity: 'Tu Liquidez',
  farms: 'Farms',
  Farms: 'Farms',
  airdrop: 'Airdrop',
  Airdrop: 'Airdrop',
  docs: 'Docs',
  Docs: 'Docs',
  community: 'Comunidad',
  Community: 'Comunidad',
  more: 'Más',
  More: 'Más',
  account: 'Cuenta',
  Account: 'Cuenta ',
  New_ui: 'Nueva UI',
  Risks: 'Riesgos',
  Forum: 'Foro',
  Discord: 'Discord',
  Telegram: 'Telegram',
  Twitter: 'Twitter',
  Medium: 'Medium',
  move_assets_to_from_ethereum: 'Mover activos hacia/desde Ethereum',
  rainbow_bridge: 'Rainbow Bridge',
  deposit_to_swap: 'Depositar para Intercambiar (Swap)',
  connect_to_near: 'Conectarse a NEAR',
  slippage: 'Tolerancia al deslizamiento',
  slippage_title: 'Configuración de la Transacción',
  minimum_received: 'Mínimo recibido',
  swap_rate: 'Tasa de intercambio',
  swap_rate_pre: 'Tasa de intercambio',
  pool_fee: 'Pago por Pool',
  balance: 'Balance',
  from: 'Desde',
  to: 'para',
  submit: 'Enviar',
  save: 'Save',
  wrapnear: 'Wrap NEAR',
  wrapnear_tip_one:
    'Wrapping NEAR permite hacer los intercambios en REF. Asegúrate de hacerlo ',
  wrapnear_tip_two: 'deja 0.5 NEAR',
  wrapnear_tip_three: ' para el pago del gas .',
  wrap_error_msg: 'No dispones de lo suficiente Not enough balance',
  sign_out: 'Desconectar',
  view_account: 'Ver tu cuenta',
  deposit_to_swap_and_add_liquidity:
    'Deposita para intercambiar y añadir liquidez',
  small_storage_fee_is_applied_of:
    'Se aplica una pequeña tarifa por el almacenamiento ',
  liquidity: 'Liquidez',
  tokens: 'Tokens',
  fee: 'Tarifa',
  fees: 'Tarifas',
  view_detail: 'Ver el detalle',
  pool_details: 'Detalles del Pool',
  details: 'Detalles',
  detail: 'Detalle',
  liquidity_pools: 'Pools de Liquidez',
  minimum_tokens_out: 'Mínimo recibido',
  pair: 'Par',
  tvl: 'TVL',
  search_pools: 'Search Pool',
  add_liquidity: 'Añadir Liquidez',
  remove_liquidity: 'Remover Liquidez',
  total_liquidity: 'Total de  liquidez',
  coming_soon: 'Próximamente',
  accumulated_volume: 'Volumen Acumulado',
  underlying_liquidity: 'Liquidez subyacente',
  total_shares: 'Acciones Totales',
  my_shares: 'Acciones',
  token: 'Token',
  enter_token_address: 'Ingrese la dirección del token',
  you_are_not_providing_liquidity_to_any_pools:
    'No estás proporcionando liquidez a ningún Pool',
  remove: 'Retirar',
  select: 'Seleccionar',
  select_token: 'Seleccionar el Token',
  basis_points: 'Basis points',
  total_fee: '% total de la tarifa ',
  protocol_fee_is: 'la tarifa del protocolo es ',
  start_in: 'Empezar en',
  start_at: 'Empezó a las',
  started_at: 'Empezó a las',
  start_date: 'Fecha de inicio',
  end_at: 'Termina en',
  end_date: 'fecha final',
  earn: 'Ganancia',
  ended: 'Terminada',
  pending: 'Pendiente',
  pending_cap: 'Pending',
  pending_orders_title: 'Pending Close Orders',
  rewards_per_week: 'Recompensas por semana',
  apr: 'APR',
  total_staked: 'Total staked',
  unclaimed_rewards: 'Recompensas no cobradas',
  claim: 'Cobradas',
  claim_all: 'Cobrado todo',
  stake: 'Stake',
  unstake: 'Unstake',
  your_rewards: 'Tus recompensas',
  your_shares: 'Acciones',
  shares_owned: 'Acciones propias',
  stake_your_liquidity_provider_LP_tokens:
    'Stake your Liquidity Provider (LP) tokens',
  go_to_wallet: 'Ve a tu Wallet',
  recent_activity: 'Actividad reciente',
  view_all: 'Ver todo',
  cancel: 'Cancelar',
  claim_ref_token: 'Recamar el Ref Token',
  has_no_available_claim: 'no hay nada que reclamar',
  sorry: 'Lo sentimos',
  total_ref_token: 'Total Ref Token',
  locking_ref_token: 'Ref Token Bloqueados',
  unclaim_ref_token: 'Ref Token no reclamados',
  unlocked: 'Unlocked',
  locking: 'Bloqueados',
  you_can_claim_from: 'Puedes reclamar de',
  you_have_claimed_all_your_rewards: 'Has reclamado todas tus recompensas',
  ends_in: 'Termina en',
  days: 'dias',
  swap_successful_click_to_view: 'Swap realizada exitosamente. Click para ver',
  claim_successful_click_to_view:
    'Reclamado (Claim) realizado con éxito. Click para ver',
  claim_failed_click_to_view:
    'Hubo una falla al realizar el reclamo (Claim) . Click para ver',
  slippageCopyForMobile:
    'Deslizamiento (Slippage) significa la diferencia entre lo que espera obtener y lo que realmente obtiene es debido a que otros ejecutan primero',
  slippageCopy:
    'Deslizamiento (Slippage) significa la diferencia entre lo que espera obtener y lo que realmente obtiene debido a que otros ejecutan primero',
  depositCopy:
    'Esto deposita sus tokens seleccionados en el intercambio de <br> para intercambiar o agregar a un grupo de liquidez',
  nearDepositCopy:
    'Esto primero envuelve y luego deposita su Ⓝ en el intercambio de <br> para intercambiar o agregar a un grupo de liquidez',
  nearWithdrawCopy:
    'Esto primero desenvolverá su Ⓝ, luego lo retirará de <br> el intercambio y moverá los tokens a su billetera',
  withdrawCopy:
    'Esto retirará sus tokens seleccionados de <br> el intercambio y los depositará en su billetera',
  registerToken:
    'Esto registra un nuevo token con el intercambio de <br> que aún no figura en la lista',
  whitelistTokenCopy: 'Añade cualquier NEP-141 token',
  addLiquidityPoolCopy:
    'Esto crea un nuevo Pool de liquidez para los dos tokens seleccionados. La tarifa es el porcentaje que el Pool toma de cada transacción.',
  swapCopy:
    'Swap intercambia el primer token seleccionado con el segundo <br> token seleccionado. Se utilizarán los Pools con la liquidez más alta disponible y la tarifa de intercambio más baja',
  addTokenCopy: 'Añade cualquier NEP-141 token',
  getLPTokenCopy: 'Haga clic aquí para ir al Pool correspondiente',
  airdropCopy:
    'Solicite su Airdrop al menos una vez antes de la fecha de vencimiento; de lo contrario, su saldo se donará a la tesorería',
  farmRewardsCopy: 'Valor indicativo basado en precios y no en ejecución real',
  totalValueLockedCopy: 'Valor total Bloqueado',
  topPoolsCopy: 'Para cada par, solo muestra el TVL más alto del grupo',
  you_do_not_have_enough: 'no tienes suficientes fondos',
  must_provide_at_least_one_token_for:
    'Debe proporcionar un token superior a 0 para',
  is_not_exist: 'no existe',
  must_input_a_value_greater_than_zero: 'Must input a value greater than 0',
  amount_must_be_not_greater_your_balance:
    'El monto no debe ser mayor que su saldo',
  no_pool_available_to_make_a_swap_from:
    'No hay Pool disponible para hacer un intercambio',
  for_the_amount: 'Por la cantidad',
  no_pool_eng_for_chinese: ' ',
  is_not_a_valid_swap_amount: 'No es un monto válido para hacer Swap',
  not_nep_address:
    'La dirección que ingresó no es una dirección NEP-141, verifíquela e ingrésela nuevamente. Si se trata de otro contrato en red principal (mainnet), vaya a Rainbow Bridge para el procesamiento de puente',
  not_correct_address:
    'La dirección que ingresó es incorrecta, verifique e ingrese',
  popular_tokens: 'Tokens comunes',
  asset_label: 'Activos',
  account_label: 'Cuenta',
  total_label: 'Total',
  top_pools: 'Top pools',
  pool_id: 'Pool ID',
  more_pools: 'Más pools',
  my_watchlist: 'Mi lista de interés a revisar',
  h24_volume: '24h volumen',
  volume: 'Volumen',
  hide_low_tvl_pools: 'Ocultar Pools de TVL bajos,',
  hide_low_tvl_pools_mobile: 'Ocultar TVL bajos',
  watchlist_title: 'Mi lista de interés en la parte superior',
  add_watchlist: 'Añadir una lista de interés',
  remove_watchlist: 'Remover lista de interés',
  my_watchlist_copy: 'Las Pools seleccionadas aparecerán aquí',
  search_token: 'Buscar token',
  language: 'Idioma',
  deposit_to_add_liquidity: 'Depositar para agregar Liquidez',
  slip_warn: 'Tenga cuidado, por favor marque el mínimo que puede recibir',
  slip_invalid: 'La tolerancia de deslizamiento (slippage) no es válida',
  input_to_search: 'Entrada para buscar',
  click_search_bar_to_search: 'Haga clic en la barra de búsqueda para buscar',
  reward_tokens: 'Recompensa de tokens',
  detail_tip: 'Detalle',
  no_data: 'No hay Data',
  stable_swap: 'StableSwap',
  StableSwap: 'StableSwap',
  exchange_rate: 'Tarifa de Intercambio',
  including_fees: '(incluye las tarifas)',
  standard_gas: 'Standard Gas tarifa:',
  rst_token: 'Tu RST token',
  remove_tip: 'No hay comisión en la eliminación de liquidez en esta acción',
  share_liquidity: 'Cuota de liquidez',
  remove_token_confirm: 'Eliminarás el token RUST',
  remove_as_one_token: 'Eliminar como un token',
  flexible_tip: 'Quita cuanto quieras por token',
  add_type_all: 'Agregue todas los tokens en una proporción equilibrada',
  add_type_max: 'Use la cantidad máxima de tokens disponibles',
  select_balance: 'Seleccionar saldo',
  selectBalanceCopy:
    'Seleccione [NEAR wallet] para intercambiar directamente tokens disponibles en su NEAR wallet',
  near_wallet: 'NEAR wallet',
  ref_account: 'Cuenta REF',
  swap_tip:
    'Swap desde su cuenta REF seleccionando la cuenta REF en la configuración',
  attention: 'Atención',
  live: 'En vivo',
  ended_search: 'Terminada',
  my_farms: 'Tus Farms',
  staked_only: 'Staked',
  sort_by: 'Ordenada por ',
  your_farms_rewards: 'Tus recompensas de los Farms',
  your_farms: 'Tus farms',
  new: 'Más reciente',
  unstake_tip_t: 'tienes recompensas sin reclamar....',
  unstake_tip_m:
    'Unstaking eliminará la participación en todas las farms activas y pendientes del mismo par.',
  unstake_tip_b: '¿Quieres dejar de participar (unstake)?',
  value_rewards: 'Reclamar las recompensas',
  stake_unstake_tip:
    'Staking o Unstaking eliminará la participación de todas las farms activas y pendientes del mismo par.',
  over_tip:
    'Para optimizar la tarifa de gas, puede retirar hasta 4 tokens a la vez',
  no_token_tip: 'Sin token de recompensa',
  getToken_tip:
    '¡Puede reclamar sus recompensas o apostar sus tokens LP ahora!',
  in_farm: 'en Farm',
  swap_rate_including_fee: 'Tasa de swap (incluidas las comisiones)',
  by_share: 'Por Share',
  by_token: 'Por Token',
  your_positions_will_be_displayed_here:
    'Su(s) posición(es) se mostrará(n) aquí',
  price_impact: 'Impacto en el Precio',
  shares_left: 'Shares left',
  total_stable_coins: 'Total de stablecoins',
  daily_volume: 'Volumen diario',
  liquidity_utilisation: 'Utilización de liquidez',
  token_reserves: 'Stats',
  roi_calculator: 'Calculadora ROI ',
  lp_staked: 'LP Tokens Staked',
  usd: 'USD',
  lp_token: 'LP Tokens',
  stake_for: 'Duración',
  day_1: '1D',
  day_2: '7D',
  day_3: '30D',
  day_4: '90D',
  day_5: '1Y',
  cur_apr: 'ROI',
  reward_token: 'Recompensa de tokens',
  get_lp_token: 'Aduiere LP Tokens',
  calculate_roi: 'Calculadora ROI',
  rewards_claimed: 'Recompensas reclamadas',
  value_rewards_token: 'Valor de las recompensas',
  all: 'Todo',
  all_5: 'Maximo 4 tokens a la vez',
  insufficient_shares: 'Insufficient shares',
  shares_removed: 'Shares',
  shares_avaliable: 'Shares disponibles',
  minimum_shares: 'Minimum shares',
  shares_tip: 'Shares disponibles / Total de shares',
  stablecoin_only: 'Solo Stablecoin',
  more_than: 'Ingrese un número mayor o igual a 0.01',
  less_than: 'Por favor ingrese un número menor a 20',
  no_valid: 'Por favor ingrese un número válido',
  lp_fee: 'Tarifa del LP',
  protocol_fee: 'Tarifa del Protocol',
  referral_fee: 'Tarifa de referencia',
  protocol_fee_referral_fee: 'Tarifa de protocolo y tarifa de referencia',
  total_fee_create: 'Total de las tarifas',
  xref_title: 'GANE MÁS POR',
  xref_title1: 'Gane más',
  xref_title2: 'al hacer staking REF por xREF',
  xref_introdution:
    'Al hacer staking REF, tiene la oportunidad de ganar tarifas generadas por el protocolo. Cualquier titular de REF puede tener una participación en los ingresos obtenidos por Ref Finance.',
  staking_apr: 'Staking APR',
  view_stats: 'Ver Stats',
  xref: 'xREF',
  ref: 'REF',
  more_than_general_seed: 'La entrada debe ser mayor o igual a 0.000001',
  more_than_stable_seed: 'La entrada debe ser mayor o igual a 1',
  are_you_sure: 'Está usted seguro?',
  price_impact_is_about: 'El impacto en el  precio se trata de',
  yes_swap: 'Sí, swap',
  amount_must_be_greater_than_0: 'la cantidad debe ser mayor que 0',
  volume_ratio: '24h Volumen/ Relación de Liquidez',
  go_to_near_wallet: 'Ir a la NEAR Wallet',
  into_ref_account: 'a tu cuenta REF.',
  deposit_into_ref_account: 'a tu cuenta REF para añadir liquidez',
  deposit_near_tip:
    'Para depositar, mantenga al menos 1 CERCA para cubrir la tarifa de gas',
  input_greater_than_available_shares:
    'Entrada mayor que las acciones disponibles',
  number_of_unique_stakers: 'Número de participantes únicos',
  total_ref_staked: 'Total de  REF Staked',
  total_xref_minted: 'Total de xREF Minted',
  protocol_projected_revenue: 'Ganancias compartidas con los xREF Holders',
  total_fee_Revenue_shared_with_xref_holders:
    'Total de las cuotas compartidas con los xREF Holders',
  provision_treasury: 'Provisión para la Tesorería',
  protocol_projected_revenue_tip:
    'Este número corresponde a los ingresos acumulados de la tarifa de negociación compartida para los xREF holders.Es igual al 75% de los ingresos totales por tarifas de la plataforma. Será diferente del monto real de la recompra del token REF debido a las fluctuaciones de precios.',
  stake_ref_to_xref_for_earning_more: '¡Stake REF a xREF para ganar más!',
  auto_router: 'Enrutador automático',
  auto_router_detail_sub_1: 'Esta ruta optimiza su swap por',
  auto_router_detail_sub_2: 'considerando todos los pools para el mismo par.',
  auto_router_detail_sub_3: 'Detalles:',
  introducing_parallel_swap: 'Introduciendo el Swap Paralelo',
  at_least: 'Al menos',
  xref_start_time: 'Empieza a la 1am UTC, Enero 18 del 2022.',
  xref_to_receive: 'xREF para recibir',
  ref_to_receive: 'REF para recibir',
  more_expensive_than_best_rate_en: 'Más caro que la mejor tarifa',
  optimal_path_found_by_our_solution:
    'Ruta óptima encontrada por nuestra solución',
  check_to_stable_banner: 'Verificar',
  rates_to_stable_banner: 'Tarifas',
  make_sure_you_understand_what_you_do: 'Asegúrate de entender lo que haces',
  go_to_your_account_to: 'Ve a tu cuenta para',
  deposit_l: 'depositar',
  withdraw_l: 'retirar',
  got_it: 'Got it',
  check_the_transaction_settings:
    'Verifique la configuración de la transacción',
  filter_by: 'Filtrar por',
  allOption: 'Todo',
  stablecoin: 'Stable',
  near_ecosystem: 'Near-eco',
  bridged_tokens: 'Bridged',
  gaming: 'Gaming',
  nft: 'NFT',
  bridge: 'Bridge to/from',
  from_ethereum: 'Ethereum',
  from_aurora: 'Aurora',
  from_solana: 'Solana',
  from_terra: 'Terra',
  from_celo: 'Celo',
  no_result: 'Lo sentimos, no pudimos encontrar ninguna farm',
  multiple: 'Múltiple',
  default: 'Por defecto',
  vi_go_live: 'Vietnamese va en vivo!',
  do_not_need_to_deposit_in_ref: 'Ya no necesita depositar en Ref Finance!',
  you_can_add_liquidity_directly:
    'Ahora puede agregar liquidez directamente usando su billetera NEAR.',
  do_not_need_deposit_anymore: 'Ya no necesita depositar en Ref Finance!',
  lightning_swaps: 'Swap relámpagos (Lightning Swaps)',
  frictionless_add_remove_liquidity:
    'Acciones simples para Añadir/Remover Liquidez',
  create: 'Crear',
  select_a: 'Seleccione un',
  wallet: 'wallet',
  Wallet: 'Wallet',
  to_use_ref_finance: 'usar Ref.Finance',
  not_supported: 'no suportado',
  installed: 'instalado',
  installe_now: 'instalar ahora',
  extension: 'extensión',
  first_time_using_ref: 'Primera vez usando Ref',
  learn_more: 'Aprenda más',
  install_sender_now: 'Instalar remitente ahora',
  connect_to_dapps_with_one_click: 'Conéctese a un dApps con un clic',
  install: 'instalar',
  Connecting: 'Conectando',
  check_sender_wallet_extension:
    'Verifique la extensión de la billetera del remitente',
  having_trouble: 'Tienes algún problema?',
  go_back: 'Regresa',
  web: 'web',
  Type: 'Tipo',
  click_to_view: 'Click para ver',
  transaction_failed: 'Transacción fallida',
  wallet_vi: ' ',
  value: 'Valor',
  usd_value: 'Valor USD',
  sauce_note:
    'SAUCE está diseñado para fondos (pools) de liquidez con activos fijos, ofreciendo precios óptimos.',
  support_ledger: 'Soporte Ledger',
  initialize_account_tip: 'Inicializa tu cuenta.',
  support_ledger_tip:
    "Por diseño, Ledger no puede manejar grandes transacciones (i.e. Auto Router: operar en varias pools a la vez) debido a su limitación de memoria. Cuando está activada, la opción 'Support Ledger' limitará las transacciones a su forma más simple (en detrimento de los precios óptimos potenciales encontrados por nuestro enrutador automático), por lo que se pueden firmar transacciones de un tamaño razonable..",
  start: 'Empezar',
  aprTip:
    'Valor indicativo basado en todas las recompensas (pendientes y en curso)',
  half: 'Mitad',
  max: 'Máximo',
  ref_account_balance_tip:
    'Parece que ocurrió un error al agregar/quitar liquidez al pool',
  ref_account_tip_2: 'Tienes token(s) en tu cuenta REF',
  ref_account_tip_top:
    'Tu(s) token(s) puede estar ahora en su cuenta interna REF',
  click_here: 'Dar Click aquí',
  to_recover_them: 'Para recuperarlos',
  ref_account_tip_3:
    'Para retirar tokens de su cuenta REF a su billetera NEAR, seleccione y retire',
  aurora_account_tip_4:
    'Para retirar tokens de su Mapping Account a su billetera NEAR, seleccione y retire',
  pool_fee_cross_swap: 'Tarifa Pool/Cross-chain',
  mapping_account: 'Mapping Account',
  mapping_account_tip: 'Tiene token(s) en su  Mapping Account',
  cumulative_ref_buyback: 'Acumulado REF Buyback',
  yearly_revenue_booster: 'Impulsor de ingresos anuales',
  Confirm: 'Confirme',
  Request_for_Quote: 'Requerimiento para cotizar (Quote)',
  mapping_account_explanation:
    "Una mapping address es una 'proxy address', que asigna la cuenta NEAR del usuario con una dirección ETH correspondiente en Aurora.",
  buy: 'Buy',
  sell: 'Sell',
  buy_nsn: 'Comprar USN',
  buy_nsn_tip: 'USN es el stablecoin nativo respaldado por el Procolo NEAR',
  usn_tip_one: 'USN te permite operar en REF. Asegúrate que ',
  rate: 'Tarifa',
  name: 'Nombre',
  diff: 'Diff',
  best: 'Mejor',
  trading_fee: 'Tarifa por la transacción',
  usn_successful_click_to_view: 'Transacción exitosa. Para ver dar Click',
  awesomeNear_verified_token: 'AwesomeNear Token verificado',
  usn_fee_tip:
    'Tarifa cero cargada por Ref, los usuarios solo pagan tarifa USN!',
  total_bitcoins: 'Total de Bitcoins',
  total_usd_value: 'Total del valor USD',
  bitcoin_value: 'Valor del Bitcoin',
  stable_coin_value: 'Valor del StableCoin',
  pool_fee_apr: 'Tarifa del Pool por APY',
  ref_account_balance_tip_mobile: 'Tiene tokens en su cuenta REF.',
  click: 'Dar Click',
  to_recover: 'para recuperar.',
  rewards_week: 'Recompensas/semana',
  rewards: 'Recompensas',
  claimed_Rewards: 'Recompensas reclamadas',
  POOL: 'POOL',
  STAKE: 'STAKE',
  lp_Token: 'LP Tokens',
  booster: 'Booster',
  slash_policy: 'Política de Slash',
  stake_period: 'Perídi de Stake',
  append: 'Añadir (Adjuntar)',
  append_staking: 'Añadir Staking',
  unstake_time: 'Tiempo para hacer el Unstake',
  farm: 'Farm',
  reward_Token: 'Token de recompensa',
  status: 'Status',
  value_of_rewards: 'Valor de las recompensas',
  Rewards: 'Recompensas',
  free: 'Gratuito',
  lp_tokens: 'LP tokens',
  connect_to_wallet: 'Conectarse a la Wallet',
  anytime: 'En cualquier momento',
  slash_policy_content:
    'Cada seed tiene su propia slash rate. Y disminuirá linealmente con el tiempo hasta que sea 0 en el momento de desbloqueo de esta cuenta de CD.',
  not_expired: 'no caducado',
  accept_pay_slash_tip: 'Acepto pagar el slash',
  i_will_pay: 'Yo pagaré',
  lp_token_slash: 'Tarifa de salida del token LP.',
  booster_change_reason: 'El Booster cambiará en función del appending time',
  initial_booster: 'Booster Inicial',
  append_timing: 'Append timing',
  cd_limit: 'más de 16 cd (límite de la cuenta)',
  expired: 'Caducado',
  all_5_v2: '5 tokens/tiempo',
  all_4_v2: '4 tokens/tiempo',
  more_than_seed: 'La entrada debe ser mayor o igual que ',
  how_to_earn_more: 'Cómo combinar las recompensas?',
  you_shares: 'Tus Shares',
  lock_your_lp_tokens_with_booster:
    'Bloquea tus LP tokens para que puedas hacer un boost a tus recompensas',
  unstaked_anytime_no_booster:
    'En cualquier momento puedes hacer Unstaked, no se realiza el  booster',
  ordinary_stake: 'Stake ordinario',
  locking_stake: 'Mantener el staking',
  change_to_lock: 'Cambiar para mantener el staking',
  estimated_rewards: 'Recompensas estimadas',
  final_booster: 'Booster Final',
  existing_amount: 'Cantidad exitente',
  append_amount: 'Anexar cantidad',
  will_be_able_to_unstaked_after:
    'estará disponible para después hacer el unstaked',
  expected_reward: 'Recompensa esperada',
  you_have: 'Tú tienes',
  tokens_small: 'tokens',
  reward_apr: 'Recompensa por APR',
  price_board: 'Tablero de precios',
  close: 'Cerrar',
  stake_min_deposit: 'La entrada debe ser mayor o igual que ',
  unlock: 'Unlock',
  exit_fee: 'Tarifa por salir',
  end_locking_period: 'Fin del peródo de bloqueo',
  add_lp_tokens_tip:
    'Necesita tokens LP para hacer el pool en el farm  correspondiente. Primero agregue liquidez al pool para obtener tokens LP.',
  slash_tip:
    'Tarifa de salida = Tasa de tarifa de salida * Fin del período de bloqueo * Cantidad a desbloqueo',
  nonBoosted: 'No-boosted',
  exit_fee_tip:
    'Cantidad estimada que pagaría para romper su período de bloqueo',
  forbiddenTip: 'Este farm no admite esta opción de duración',
  unstakeTip:
    'Hacer Staking o unstaking automáticamente reclamará sus recompensas.',
  endedFarmsUnstakeTip:
    'Hacer Unstaking eliminará la participación de todas las farms activas y pendientes del mismo par. ',
  comimg: 'Próximamente',
  appendTip: 'No puede ser anterior a la hora de bloqueo actual',
  week: 'semana',
  yours: 'Suyos',
  boost: 'Boost',
  near: 'NEAR',
  eth: 'ETH',
  others: 'otros',
  stakeLove: 'Stake LOVE',
  unstakeLove: 'Unstake LOVE',
  get_love: 'Obtén LOVE',
  swap_successful: 'Swap realizado con éxito. ',
  trading_successfull: 'Intercambio exitoso. ',
  total_near_amount: 'Cantidad Total de NEAR',
  near_value: 'Valor de NEAR',
  borrow: 'Borrow',
  burrow_usn_tip: 'Puedes pedir un préstamo de USN en Burrow.',
  migrate: 'Migrar',
  go_to_new_farm: 'Ir a New Farms',
  voting_gauge_share_tip:
    'La proporción del REF utilizado para la asignación de votos a la recompensa total del REF en este período.',
  swap_capital: 'SWAP',
  farm_capital: 'FARM',
  sauce_capital: 'SAUCE',
  dao_capital: 'DAO',
  available_to_stake: 'disponible para hacer  stake',
  recent_one_mounth_activity: 'Actividades realizadas en el mes',
  your_assets: 'Tus activos',
  connect_wallet: 'Conectarse a la Wallet',
  disconnect: 'Desconectar',
  change: 'Cambiar',
  wallets_below_supports_on_PC:
    'Las Wallets listadas pueden ser soportadas en una PC',
  connect: 'Conectar',
  make_sure_device_plugged_in: 'Asegúrate de que tu dispositivo esté conectado',
  then_enter_an_account_id_to_connect:
    'luego ingrese una ID de cuenta para conectarse',
  veTip:
    'Los veLP tokens no son transferibles, los veLPT representan su poder de voto ',
  loveTip:
    'LOVE significa Liquidez de veToken. LOVE, como cualquier token NEP-141, es transferible y se puede intercambiar, agrupar, hacer pool y farm. LOVE representa la liquidez subyacente de sus veTokens',
  lock_your_lp_tokens: 'Bloquear los  LP Tokens',
  unlock_your_defi_power: 'Desbloquer tu  DeFi Power',
  unlock_tip_mobile: 'El desbloqueo está disponible ahora!',
  unlock_tip_top:
    'Su bloqueo ha caducado, ¡el desbloqueo está disponible ahora!',
  bonus_to_be_withdraw: 'bono para ser retirado',
  keep_ve: 'mantener',
  months: 'meses',
  month: 'mes',
  lock_lp_tokens: 'Bloquear LP Tokens',
  get_lptoken: 'Get LP Tokens',
  duration: 'Duration',
  ve_lock_tip: 'No puede ser anterior a la fecha de desbloqueo actual',
  get: 'Obtener',
  locking_ve: 'Bloqueando',
  in_addition_to_the: 'Además de ',
  already_locked: 'ya bloqueado',
  unlocking_is: 'Desbloquear es',
  you_have_no_lp_share: 'No tienes LP Tokens',
  lock: 'Bloquear',
  unlock_lptoken: 'Desbloquear LP Tokens',
  locked: 'Bloqueado',
  available: 'Disponible',
  balance_lowercase: 'balance',
  voting_power: 'Voting Power',
  lock_lp_tokens_first: 'Primero bloquea los LP Tokens!',
  farm_booster: 'Farm Booster',
  go_to_farm: 'Ir a la Farm',
  in: 'en',
  more_lowercase: 'más',
  avaliable_to_lock: 'Disponible para bloquear',
  lock_lptoken: 'Bloquear LP Tokens',
  lptoken_locker: 'Bloqueador de LP Token Locker',
  filter: 'Filtro',
  bonus: 'Bono',
  your_shares_ve: 'Tus Shares',
  voted: 'Votados',
  my_voting_power: 'Mi poder para Votar',
  new_voting_ratio: 'Nuevo índice de votación',
  REF_allocation: 'Asignación de REF',
  vote: 'Votados',
  preview: 'Vista previa',
  options: 'opciones',
  ratio: 'Relación',
  forum_discussion: 'Foro de  Discusión',
  voted_veLPT: 'veLPT votados',
  your_contribution: 'Tu contribución',
  delete: 'Borrar',
  not_start: 'No comenzar',
  no_veLPT: 'No veLPT',
  claim_bonus: 'Reclamar Bono',
  ended_ve: 'Terminado',
  proposal_details: 'Detalles de la propuesta',
  back: 'Regresar',
  pending_ve: 'Calentando',
  you_voted: 'Tu votaste',
  add_bonus: 'Agregar bono',
  you_vote: 'Tu voto',
  Creator: 'Creador',
  turn_out: 'Apagar',
  top_answer: 'respuesta superior',
  your_bonus: 'Tus bonuos',
  gauge_weight_vote: 'Medidor del Peso del  Voto',
  governance: 'Governancia',
  confirm: 'Confirme',
  voting_period: 'Periodo de Votación',
  applying_period: 'Período de aplicación',
  community_gauge_share: 'Medidor del Share de laCommunidad',
  qualified_pools: 'Pools calificadas',
  total: 'Total',
  supply_voted: 'Suministro votado',
  proposed: 'Propuesto',
  farm_reward: 'Recompensa del farm',
  check_last_round: 'Revisar la última ronda',
  last_round_voting_result: 'Resultado de la última ronda de votación',
  create_proposal: 'Crear propuesta',
  type: 'Tipo',
  add_option: 'Añadir Opción',
  lock_lp_terms:
    'Entiendo que no podré remover mis tokens LP durante todo el período de bloqueo acordado.',
  connect_to_near_wallet: 'Conectar a la Wallet de NEAR ',
  only_for_whitelist_address: 'Solo para la dirección de la whitelist',
  v1Legacy: 'Legacy',
  v2New: 'Classic-New',
  v2_new_farms: 'Classic farms update',
  migrate_now: 'Migrar ahora!',
  v2_boost_tip:
    'Las farms Classic admitirán boosted farms. Las farms Legacy se quedarán sin recompensas el <span class="font-bold">1 de agosto de 2022</span>.',
  v2_boost_tip2: 'Las Classic farms soportan las  boosted farms.',
  v2_boost_no_tip:
    'Las farms Legacy se quedarán sin recompensas el <span class="font-bold">1 de agosto de 2022</span>..',
  no_farm_need_to_migrate: 'Ninguna farms necesita migrar',
  v1_farms: 'Legacy Farms',
  has_rewards_tip:
    'Has reclamado recompensas en <a rel="noopener noreferrer nofollow" class="text-green Color text-based cursor-pointer underline hover:text-sender Hot" href="/farms" >Legacy Farms</a>. Puede retirarlos en cualquier momento.</div>',
  love: 'LOVE',
  Available_to_stake: 'Disponibe para hacer stake',
  you_staked: 'Ha hecho un stake',
  has_rewards_tip_in_v2:
    'Retire las recompensas heredadas de <a rel="noopener noreferrer nofollow"  href="/farms" class="text-sm text-greenColor cursor-pointer underline ml-1 hover:text-senderHot"> Legacy Farms</a>.',
  how_to_get: 'Cómo obtener?',
  get_booster: 'Obtener Booster',
  ended_farms: 'Farms finalizadas',
  boosted_farms: 'Boosted farms',
  your_farm: 'Tus farm',
  your_boosted_farms: 'Tus boosted farms',
  search_farms_by_token: 'Buscar farms por token',
  search_farms: 'Buscar farms',
  more_lock_ve_popup: 'más ',
  more_lock_ve_popup2: 'más',
  more_in: 'más en',
  starts: 'empieza',
  left: 'dejado (left)',
  proposal_title: 'Título de la propuesta',
  share_forum_discussion_link_here:
    'Compartir enlace del foro de discusión  aquí',
  required_field: 'Campo Requerido',
  voting_power_and_extra_earnings: 'Poder de Voto  & ganancias adicionales',
  bonus_tip:
    'El bono de votación está diseñado para animar a los usuarios a votar. Su bono depende de su número de acciones',
  yes_continue: 'Sí, continuar',
  unlocking_tip:
    'El desbloqueo de tokens LP cancelará automáticamente su voto (y la bonificación asociada) en las siguientes propuestas',
  create_pool: 'Crear un Pool',
  search_by_token: 'Buscar por token',
  your_power: 'Tu Power',
  farm_has_boost_tip:
    'Tu Power = Tud staked LP Tokens * booster (por staking LOVE)',
  farm_no_boost_tip: 'Tu Power = Tu staked LP Tokens',
  more_than_a_simple_pool: 'Más que una simple pool',
  ended_Farms: 'Farms finalizadas ',
  vote_capital: 'VOTAR',
  starting_time: 'Tiempo de inicio',
  ending_time: 'Tiemo de finalización',
  please_choose_voting_duration: 'Elija la duración de la votación',
  please_choose_proposal_voting_duration:
    'Elija la duración de la votación de la propuesta',
  days_ve: 'días',
  vote_mobile_capital: 'VOTAR',
  risks_capital: 'RIESGOS',
  view_on: 'Ver en',
  boostFarmTip: 'Obtener booster haciendo staking en LOVE',
  boosted: 'Boosted',
  love_staked: 'Staked LOVE',
  your_apr: 'Tu APR',
  range_apr: 'Rango o referencia APR',
  no_pair_found: 'No se encontró ningún par',
  found_stable_pool_in_sauce: 'Pool stable pool en SAUCE',
  lp_tokens_locking: ' LP Tokens ',
  you_currently_have: 'Tu Actualmente tienes ',
  scheduled_to_be_unlocked: 'Programado para ser desbloqueado',
  locking_more_lp_tokens: 'Bloquear más LP Tokens ',
  will_mean_these: 'significará esto ',
  lp_tokens_will_not_be_unlocked_until:
    ' Los tokens LP no se desbloquearán hasta',
  "I_understand_that_I_won't_be_able_to_remove_my":
    "I understand that I won't be able to remove my ",
  until: 'hasta que',
  continue: 'Continuar',
  then_enter_a_derivation_path_to_connect:
    'luego ingrese una ruta de derivación para conectarse',
  account_identification_failed:
    '¡Error en la identificación de la cuenta! Proporcione manualmente',
  input_account_id: 'Ingrese la identificación de la cuenta (ID)',
  we_found: 'Nosotras la encontramos ',
  accounts_on_your_device: 'cuentas en tu dispositivo',
  select_the_accounts_you_wish_to_connect:
    'Seleccione la cuenta que desea conectar',
  can_not_find_any_accounts:
    'No se puede encontrar ninguna cuenta asociada con este Ledger. Cree una nueva cuenta NEAR en',
  or_connect_another_ledger: 'or connect an another Ledger',
  add: 'Aãdir',
  finish: 'Finalizar',
  add_custom_network: 'Agregar red personalizada',
  network_name: 'Nombre de red',
  rpc_name_taken_tip: 'El nombre de la red ya estaba tomado',
  network_invalid: 'La red no era válida',
  fobidden_testnet_rpc_tip:
    'La red del servidor RPC (testnet) es diferente con esta red (mainnet)',
  no_support_testnet_rpc_tip: 'Testnet no admite agregar una RPC personalizado',
  stable: 'Stable',
  trade_capital: 'COMERCIO',
  trade_capital_hump: 'comercio',
  pro: 'Pro',
  limit: 'Limite',
  make_an_order: 'Hacer un pedido',
  your_active_orders_will_appear_here: 'Sus pedidos activos apareceran aqui',
  your_history_orders_will_appear_here:
    'Sus historial de ordenes apareceran aqui',
  liquidity_capital: 'LIQUIDEZ',
  goes_to_limit_order: 'pasa a dorden limitada.',
  farm_only: 'Solamnte Farm',
  please_confirm_this_transaction_on_ledger:
    'Confirme esta transacción en Ledger',
  limit_order: 'Orden de limite',
  newText: 'Nuevo',
  farm_stop_tip: 'Esta granja ha sido detenida.',
  pool_stop_tip: 'Este pool ha sido detenido.',
  volume_24h: 'Volume (24h)',
  add_to_watchlist: 'Anadir a la lista de vigilancia',
  remove_from_watchlist: 'Eliminar de la lista de vigilancia',
  create_order: 'Crear orden',
  filled: 'Llenado',
  canceled: 'Cancelado',
  partially_filled: 'Parcialmente lleno',
  pool_composition: 'Composicion del grupo',
  amount: 'Importe',
  h24_volume_bracket: 'Volume(24h)',
  fee_24h: 'Tasa(24h)',
  cross_chain_swap: 'Intercambio en cadena',
  orders: 'Ordenes',
  your_orders: 'Tus ordenes',
  active: 'Activo',
  history: 'Historia',
  you_sell: 'Tu venta',
  you_buy: 'Tu compra',
  fee_tiers: 'Niveles de tarifas',
  order_rates: 'Tarifas de los pedidos',
  created: 'Creado',
  executed: 'Ejecutado',
  actions: 'Acciones',
  claimed_upper: 'Reclamado',
  open_my_order: 'abierto',
  initial_order: 'Orden inicial',
  this_order_has_been_partially_filled:
    'Este pedido ha sido parcialmente ejecutado',
  filled_via_swap: 'Llenado a traves de un intercambio',
  state: 'Estado',
  partially: 'Parcialmente',
  near_validation_error: 'Must have 0.5N or more left in wallet for gas fee.',
  near_min_validation_error:
    'Must have 0.2N or more left in wallet for gas fee.',
  no_positons_in_this_pool_yet: 'No positons in this pool yet',
  no_claimed_rewards_yet: 'Recompensas por reclamar',
  out_of: 'de',
  value_must_be_less_than_or_equal_to: 'Value must be less than or equal to',
  select_tokens: 'Selecionar Tokens',
  fee_Tiers: 'Niveles de tasas',
  select_s: 'seleccione',
  no_pool: 'No hay pool',
  input_amount: 'Introduzca el importe',
  update_range: 'Actualizar Rango',
  not_enough_balance: 'Saldo insuficiente',
  set_price_range: 'Establecer rango de precios',
  current_price: 'Precio actual',
  pool_creation: 'Creacion del pool',
  pool_creation_tip:
    'No existe un pool para las fichas seleccionadas. Para crear el pool, hay que establecer la tarifa del pool, proporcionando las cantidades correspondientes.',
  starting_price: 'Precio inicial',
  create_a_pool: 'Crear Pool',
  add_single_price_tip:
    'Su posicion no devengara comisiones ni se utilizara en las operaciones hasta que el precio del mercado entre en su rango.',
  invalid_range_tip:
    'Rango seleccionado no valido. El precio minimo debe ser inferior al precio maximo.',
  maket_price_outside_tip: 'The maket price is outside your price range.',
  maket_price_outside_single_only_tip:
    'El precio del mercado esta fuera de su rango de precios.',
  min_price: 'Precio minimo',
  max_price: 'Precio maximo',
  min: 'Minimo',
  out_of_range: 'Fuera de rango',
  in_range: 'En el rango',
  unclaimed_fees: 'Tarifas no reclamadas',
  your_position_will_be: 'tu posicion sera',
  at_this_price: 'a este precio',
  price_range: 'Rango de precios',
  positions: 'posiciones',
  position: 'Position',
  add_new_position: 'Agregar nueva posicion',
  range: 'Rango',
  unclaimed_fee: 'Tarifa no reclamada',
  login_risk_tip:
    'Al marcar esta casilla y continuar, confirmas que comprendes completamente los <a rel="noopener noreferrer nofollow" target="_blank" class="text-greenColor text-sm font-bold cursor-pointer hover:underline" href="/risks">riesgos</a> de usar Ref Finance.',
  no_pool_tip: 'El pool no existe.',
  total_ref_week: 'Total REF/semana',
  ref_week_you_will_get: 'REF/semana que recibirás',
  stable_pool: 'Pool Estable',
  market_rate: 'Tasa de Mercado',
  limit_price_tip:
    'Para mejorar la eficiencia de la transacción, tu precio debe estar en una ranura cercana automáticamente',
  o_token_found: 'Token no encontrado',
  search_name_or_address: 'Buscar nombre o pegar dirección...',
  token_address_invalid: 'La dirección del token no es válida',
  price_impact_warning: 'Advertencia de Impacto en el Precio',
  confirm_order: 'Confirmar Orden',
  you_Sell: 'Tú Vendes',
  to_Buy: 'para Comprar',
  at_Price: 'a Precio',
  insufficient_balance: 'Saldo Insuficiente',
  MORE: 'MÁS',
  limitTip:
    'Tu precio se establece automáticamente en la ranura de precio más cercana',
  v2PoolTip: 'Puedes tener hasta 16 posiciones en los pools DCL',
  rderTip: 'Puedes tener hasta 16 órdenes límite activas/históricas',
  v2_pools: 'Pools DCL',
  minimum_received_dot: 'Mínimo recibido',
  v2_your_pool_introduction: 'Tu liquidez discretizada concentrada',
  v1_your_pool_introduction: 'Tu liquidez en pools clásicos',
  estimate_value: 'Valor Estimado',
  price: 'Precio',
  '@price': '@Precio',
  h24_high: 'Máximo en 24h',
  h24_low: 'Mínimo en 24h',
  h24_Volume: 'Volumen en 24h',
  orderbook: 'Libro de Órdenes',
  orderbook_mobile: 'Libro de Órdenes móvil',
  trades: 'Trades',
  qty: 'Cant.',
  total_orderly: 'orderly Total',
  time: 'Tiempo',
  balances: 'Saldos',
  asset: 'Activo',
  wallet_up: 'Wallet',
  account_in_order: 'Cuenta: en Orden',
  source_address: 'Dirección de Origen',
  action: 'Acción',
  market: 'Mercado',
  order_type: 'Tipo de Orden',
  order_type_limit_tip:
    'Una orden limitada es una orden para comprar o vender a un precio específico o mejor. Las órdenes limitadas no están garantizadas para ejecutarse.',
  order_type_market_tip:
    'Una orden de mercado se empareja inmediatamente con el mejor precio de mercado disponible y se ejecuta.',
  quantity: 'Cantidad',
  advanced: 'Avanzado',
  tip_fok:
    'Fill-Or-Kill es una orden de compra o venta que debe ejecutarse de inmediato en su totalidad; de lo contrario, la orden completa se cancelará.',
  tip_post_only:
    'Post Only garantiza que los traders solo pueden realizar una orden si se publica en el libro de órdenes como una orden de Maker. Una orden que se publicaría como una orden de Taker se cancelará.',
  tip_ioc:
    'Immediate-Or-Cancel es una orden de compra o venta que debe llenarse de inmediato. Cualquier parte de una orden IOC que no se pueda llenar se cancelará.',
  Taker: 'Taker',
  Maker: 'Maker',
  see_all: 'Ver todo',
  h24Vol: 'Vol 24h',
  chart: 'Gráfico',
  deposit_assets_to_begin_your_trading_journey:
    'Deposita activos para comenzar tu {br} viaje de trading.',
  open_orders: 'Órdenes Abiertas',
  ref_order_only: 'Orden solo en REF',
  ref_order: 'Solo REF Dex',
  orderly_portfolio_table_tips:
    'Los datos proporcionados aquí incluyen todos los activos y registros en tu cuenta, no se limitan a los generados a través de REF.',
  orderly_ws_refresh: 'Por favor, actualiza los datos para continuar',
  refresh: 'Actualizar',
  settled_balance: 'Saldo Liquidado',
  settled_amount: 'Cantidad Liquidada',
  Side: 'Lado',
  all_side: 'Todo el Lado',
  both_side: 'Ambos Lados',
  both: 'Ambos',
  filled_qty: 'Lleno / Cant.',
  fill_qty: 'Llenar / Cant.',
  est_total: 'Total Est.',
  avg_price: 'Precio Prom.',
  create_time: 'Hora de Creación',
  spot: 'Contado',
  futures: 'Futuros',
  All: 'Todos',
  illed: 'Lleno',
  Fees: 'Comisiones',
  Cancelled: 'Cancelado',
  Rejected: 'Rechazado',
  all_instrument: 'Todo Instrumento',
  available_orderly: 'Disponible',
  history_orderly: 'Historial',
  settle_pnl: 'Liquidar PnL',
  settle_pnl_tips:
    'Al hacer esto, moveremos tus ganancias o pérdidas de los mercados perpetuos al saldo de tokens USDC. Esto no tiene impacto en tus posiciones abiertas o salud.',
  total_unsettled_pnl: 'Total PnL no liquidado',
  fut_unreal_pnl: 'PnL no real. Fut.',
  fut_daily_real: 'Real Diario Fut.',
  fut_notional: 'Nominal Fut.',
  fut_unsettle_pnl: 'PnL no liquidado Fut.',
  settle: 'Liquidar',
  mark_orderly: 'Marca',
  avg_open: 'Prom. Abierto',
  liq_price: 'Precio Liquid.',
  unreal_pnl: 'PnL no Real',
  daily_real: 'Real Diario',
  market_price: 'Precio de Mercado',
  NEW: 'Nuevo',
  PARTIAL_FILLED: 'Parcialmente Lleno',
  limit_price: 'Precio Límite',
  unsettled_pnl: 'PnL no Liquidado',
  notional: 'Nominal',
  assets: 'Activos',
  limit_orderly: 'Orden Límite',
  'qty.': 'Cant.',
  no_orders_found: 'No se encontraron órdenes',
  no_records_found: 'No se encontraron registros',
  its_empty: '¡Está vacío!',
  current_orderly: 'Actual',
  cancel_order: 'Cancelar Orden',
  post_only: 'Sólo Publicar',
  fok: 'FOK',
  ioc: 'IOC',
  instrument: 'Instrumento',
  open_order_detail: 'Detalle de Órdenes Abiertas',
  history_order_detail: 'Detalle de Órdenes de Historial',
  select_orderly: 'Seleccionar ',
  change_orderly: 'Cambiar',
  token_orderly: 'Token',
  user_foot_tip:
    '* Esta página de Libro de Órdenes es una interfaz gráfica de usuario para operar en Orderly Network, y se proporciona como una conveniencia para los usuarios de Ref Finance.',
  learn_more_orderly: 'Más información',
  more_order_book_page_detail:
    'Esta página de Libro de Órdenes es impulsada por Orderly Network; se recomienda encarecidamente que los usuarios investiguen por sí mismos antes de conectar su billetera y/o realizar órdenes.{br} Ref Finance no garantiza la seguridad de los sistemas, contratos inteligentes y fondos depositados o enviados a esos sistemas y contratos.{br} Ni Ref Finance ni Orderly Network son responsables de ninguna ganancia o pérdida de inversión realizada por los usuarios a través de esta página de Libro de Órdenes.',
  learn_more_about: 'Obtén más información sobre',
  learn_more_about_zh: ' ',
  by_click_confirm:
    'Al hacer clic en "Confirmar", confirmas que has revisado y comprendido exhaustivamente los contenidos mencionados anteriormente.',
  connect_to_orderly_account:
    'Necesitas (re)conectar tu cuenta de Orderly para usar el Libro de Órdenes de Ref.',
  first_register_orderly_tip:
    'Tu billetera debe registrarse primero en Orderly para poder usar el Libro de Órdenes.',
  must_register_tip:
    'Tu billetera debe estar registrada en Orderly para operar en su sistema.',
  register_deposit_tip: 'El registro requerirá un depósito de almacenamiento',
  increase_storage_deposit:
    'Es posible que necesites aumentar el depósito de almacenamiento en tu cuenta de Orderly.',
  deposit_storage_fee: 'Tarifa de depósito de almacenamiento',
  register_orderly_account: 'Registrar Cuenta Orderly',
  register: 'Registrar',
  connect_to_orderly: 'Conectar a Orderly',
  order_cancelled: 'Orden Cancelada.',
  order_edit_success: '¡Orden Editada Exitosamente!',
  created_orderly: 'Creado',
  open_orderly: 'Abierto',
  dex: 'Dex',
  min_price_should_be_higher_than_or_equal_to:
    'El precio mínimo debe ser mayor o igual a',
  price_should_be_lower_than_or_equal_to: 'El precio debe ser menor o igual a',
  price_should_be_a_multiple_of: 'El precio debe ser un múltiplo de',
  price_should_be_greater_than_or_equal_to:
    'El precio debe ser mayor o igual a',
  the_order_value_should_be_be_greater_than_or_equal_to:
    'El valor de la orden debe ser mayor o igual a',
  quantity_to_buy_should_be_greater_than_or_equal_to:
    'La cantidad a comprar debe ser mayor o igual a',
  quantity_to_sell_should_be_greater_than_or_equal_to:
    'La cantidad a vender debe ser mayor o igual a',
  quantity_to_buy_should_be_less_than_or_equal_to:
    'La cantidad a comprar debe ser menor o igual a',
  quantity_to_sell_should_be_less_than_or_equal_to:
    'La cantidad a vender debe ser menor o igual a',
  quantity_should_be_a_multiple_of: 'La cantidad debe ser un múltiplo de',
  at_least_one_of_order_quantity_or_price_has_to_be_changed:
    'Al menos una de las cantidades de la orden o el precio debe cambiarse',
  quantity_should_be_higher_than: 'La cantidad debe ser mayor que',
  price_should_be_higher_than: 'El precio debe ser mayor que',
  insufficient_en: 'Insuficiente',
  the_all_data_orderly_tip:
    'Los datos proporcionados aquí incluyen todos los activos y registros en tu cuenta, no se limitan a los generados a través de REF.',
  at_orderly: 'en',
  change_the_quantity_of: 'Cambiar la cantidad de',
  change_the_price_of: 'Cambiar el precio de',
  market_order_filled: 'Orden de Mercado Llenada',
  limit_order_created: 'Orden Límite Creada',
  limit_order_filled: 'Orden Límite Llenada',
  open: 'Abierto',
  to_buy: 'Comprar',
  to_sell: 'Vender',
  in_open_orders: 'En Órdenes Abiertas',
  your_investment: 'Tu Inversión',
  your_investment_tip:
    'Valor en USD de tu inversión en Ref: Pools Clásicos + Pools DCL (incluyendo staked en farms) + xREF',
  classic_pools: 'Pools Clásicos',
  xref_staking: 'Staking xREF',
  token_balances: 'Saldos de Tokens',
  unclaimed_earnings: 'Ganancias no Reclamadas',
  unclaimed_earnings_tip:
    'Valor en USD de las comisiones no reclamadas de Pools DCL y recompensas no reclamadas de farms.',
  unclaimed_pool_fees: 'Comisiones no Reclamadas de Pools',
  unclaimed_farm_rewards: 'Recompensas no Reclamadas de Farms',
  active_orders: 'Órdenes Activas',
  yield_farming: 'Yield Farming',
  execute_status: 'Estado de Ejecución',
  your_liquidity_usd_value: 'Tu Liquidez (valor en USD)',
  usage: 'Uso',
  holding: 'Tenencia',
  your_lp_tokens_and_shares: 'Tus Tokens de LP (Shares)',
  usd_value_staked: 'Valor en USD Staked',
  instant_swap: 'Intercambio Instantáneo',
  swapped: 'Intercambiado',
  created_s: 'creado',
  order_progress: 'Progreso de la Orden',
  dcl_pool: 'Pool DCL',
  classic: 'Clásico',
  classic_farms: 'Farms Clásicos',
  unstaked: 'Desstakeado',
  staked_in_farm: 'Stakeado en farm',
  farm_reward_range: 'Rango de Recompensa de Farm',
  your_positions: 'Tus Posiciones',
  your_price_range: 'Tu Rango de Precio',
  locked_in: 'bloqueado en',
  near_locked_in_wallet_for_covering:
    '0.5 NEAR bloqueado en la billetera para cubrir las tarifas de transacción',
  account_assets: 'Activos de la Cuenta',
  open_qty: 'Cant. Abierta',
  actions_orderly: 'Acciones',
  records: 'Registros',
  buy_pop: 'Comprar',
  sell_pop: 'Vender',
  fee_orderly: 'Tarifa',
  executing: 'Ejecutando',
  from_2: 'de',
  to_2: 'a',
  your_orders_2: 'Tus Órdenes',
  in_farm_2: 'en farm',
  your_liquidity_2: 'Tu Liquidez',
  welcome: 'Bienvenido',
  welcome_mobile: 'Conecta tu billetera para empezar',
  select_token_orderly: 'Selecciona Token',
  overview: 'Resumen',
  positions_2: 'Posiciones',
  'deposit_near_tip_0.5':
    'Para depositar, mantén al menos 0.5 NEAR para cubrir la tarifa de gas',
  trade: 'Operar',
  earn_2: 'Ganar',
  portfolio: 'Portafolio',
  more_2: 'Más',
  liquidity_pools_2: 'Pools de Liquidez',
  business_inquiries: 'Consultas Comerciales',
  stable_pools: 'Pools Estables',
  pool_detail: 'Detalle del Pool',
  new_contract_deploy_tip:
    '¡Se ha implementado un nuevo contrato! Por favor, retira tu liquidez del contrato antiguo',
  remove_will_automatically_claim:
    'Al retirar, automáticamente reclamarás tus comisiones no reclamadas.',
  legacy_tip: 'Se encontraron tokens de LP no staked o recompensas en',
  legacy_farms: 'Farms Antiguos',
  all_farms: 'Todas las Farms',
  your_farms_2: 'Tus Farms',
  dcl_farms: 'Farms DCL',
  farm_type: 'Tipo de Farm',
  farm_2: 'Farm',
  to_claim: 'Reclamar',
  liquidity_staked: 'Liquidez staked',
  dcl_pool_detail: 'Detalle de Pool DCL',
  reward_range: 'Rango de Recompensa',
  reward_range_tip: 'Recompensa de Farm dentro de este rango',
  ending_soon: 'Finalizando pronto',
  farm_ended_tip: 'Esta farm ha finalizado.',
  farm_will_ended_tip: 'Esta farm finalizará pronto.',
  new_farm: 'Nueva Farm',
  is_coming: 'está llegando',
  est_apr: 'Est. APR',
  state_2: 'Estado',
  farming: 'Farm',
  unavailable: 'No disponible',
  liquidity_detail: 'Detalle de Liquidez',
  hide: 'Ocultar',
  show: 'Mostrar',
  unavailable_positions: 'posiciones no disponibles',
  your_price_range_tip: 'Tu rango de precio está fuera del rango de recompensa',
  position_has_staked_tip: 'Esta posición ha sido staked en otra farm',
  minimum_tip: 'El monto mínimo de staking es',
  your_liquidity_3: 'tu liquidez',
  position_has_staked_pre: 'Esta posición ha sido staked en',
  another_farm: 'otra farm',
  add_position: 'Agregar Posición',
  full_range: 'Rango Completo',
  your: 'Tu',
  max_apr: 'Max. APR',
  faming_positions: 'Posiciones Farming',
  you_can_earn_tip: 'Puedes ganar recompensas haciendo farming, el est. APR es',
  you_can_earn_current_tip:
    'Tu farm staked actual ha finalizado y una nueva farm está llegando, el est. APR es',
  go_new_farm: 'Ir a Nueva Farm',
  go_farm: 'Ir a Farm',
  earn_rewards: 'Puedes ganar recompensas',
  est_apr_is: 'el est. APR es',
  new_farm_2: 'nueva farm',
  you_can_earn_current_pre_tip: 'Tu farm staked actual ha finalizado y',
  in_ended_farm: 'en la farm finalizada',
  staked: 'Stakeado',
  in_farm_3: 'en farm',
  estimation: 'Valor Estimado',
  no_dcl_position_tip:
    'Actualmente no tienes ninguna posición de Liquidez DCL, haz clic en "Agregar Posición" para empezar a hacer farming.',
  price_on_slot_tip: 'El precio debe estar en una ranura cercana.',
  position_will_appear_here: 'Tus posiciones de liquidez aparecerán aquí.',
  by_farming: 'haciendo farming',
  this_staked_tip: 'Este apostado',
  dcl_liquidity: 'Liquidez DCL',
  classic_liquidity: 'Liquidez Clásica',
  will_appear_here_tip: 'Tus posiciones de liquidez aparecerán aquí.',
  dcl_will_appear_here_tip: 'Tus posiciones de liquidez DCL aparecerán aquí.',
  classic_will_appear_here_tip:
    'Tus posiciones de liquidez Clásica aparecerán aquí.',
  yield_farming_appear_here_tip: 'Tu yield farming aparecerá aquí.',
  active_order_appear_here_tip: 'Tus órdenes activas aparecerán aquí.',
  account_appear_here_tip:
    'Los activos de tu billetera/cuenta aparecerán aquí.',
  farm_state: 'Estado de Farm',
  farm_detail: 'Detalle de Farm',
  unstaked_2: 'Desstakeado',
  unstaked_positions: 'Posiciones Desstakeadas',
  price_range_overlap_ratio: 'Ratio de Superposición de Rango de Precio',
  swapped_history: 'historial de intercambio',
  real_time_executed_orders: 'órdenes ejecutadas en tiempo real',
  real_time_executed_orders_tip:
    'Cuando colocas una orden de venta por encima del precio de mercado o una orden de compra por debajo del precio de mercado utilizando la función de orden límite, Ref intentará ejecutar primero una acción de intercambio con punto de parada y si queda algún monto restante, se creará una orden límite para completar el resto.<br> Las órdenes ejecutadas en tiempo real se refieren a las órdenes realizadas utilizando la función de orden límite, pero ejecutadas a través del intercambio. En la sección "Historial", puedes ver las órdenes ejecutadas en tiempo real entre la orden límite mostrada más temprana en el Historial hasta el tiempo presente.',
  swapped_history_order: 'Orden de historial de intercambio',
  ledger_guide_tip_1:
    'Para usar exitosamente tu billetera Ledger para transacciones, la ',
  ledger_guide_tip_2: 'característica se ha activado automáticamente.',
  ledger_guide_tip_3: 'Por favor ten en cuenta',
  ledger_guide_tip_quote: '"',
  support_ledger_tip_new: '"Soporte para Ledger"',
  ledger_guide_tip_4:
    'que debido a las limitaciones de la billetera Ledger, la tasa actual proporcionada por la función de intercambio',
  ledger_guide_tip_5: 'puede que no sea el mejor precio.',
  ledger_guide_tip_6: '.',
  view_dcl_pool: 'Ver Pool DCL',
  your_trade_route: 'Tu Ruta de Operación',
  markets: 'Mercados',
  exchanges: 'Exchanges',
  output_est: 'Salida (est.)',
  go_to_orderbook: 'Ir a Orderbook',
  new_swap_order_tip:
    'El precio es de la oferta de Ref AMM y solo es de referencia. No hay garantía de que tu orden límite se complete de inmediato.',
  instants_swap: 'Intercambio Instantáneo',
  swappped: 'Intercambiado',
  claimed: 'Reclamado',
  executing_capital: 'Ejecutando',
  record: 'Registro',
  trade_route: 'Ruta de Operación',
  settings: 'Configuraciones',
  cross_chain_options: 'Opciones Inter-chain',
  no_trade_routes: 'No hay Rutas de Operación',
  steps_in_the_route: 'Pasos en la Ruta',
  swap_chart_no_data:
    'No hay suficientes datos para el gráfico en este momento.',
  go_to_orderbook_tip:
    'Este precio es solo de referencia. {br} Por favor, procede a <strong>Orderbook</strong> para realizar la orden.',
  last_updated: 'Última Actualización',
  steps_in_the_route_zh: '',
  has_tri_pool_tip:
    'en Ref. Habilita Trisolaris en Opciones Inter-chain para encontrar más.',
  Overview: 'Resumen',
  RefFinance: 'Ref.finance',
  Orderly: 'Orderly',
  Burrow: 'Burrow',
  Supplied: 'Suministrado',
  Borrowed: 'Prestado',
  NetAPY: 'APY Neto',
  UnclaimedRewards: 'Recompensas no Reclamadas',
  YouSupplied: 'Tú Suministraste',
  SupplyMarket: 'Mercado de Suministro',
  YouBorrowed: 'Tú Prestaste',
  BorrowMarket: 'Mercado de Préstamo',
  NetAPYTip:
    'APY Neto de todas las posiciones de suministro y préstamo, incluyendo APY base e incentivos',
  Market: 'Mercado',
  TotalSupplied: 'Total Suministrado',
  TotalBorrowed: 'Total Prestado',
  AvailableLiquidities: 'Liquidez Disponible',
  DailyRewards: 'Recompensas Diarias',
  SupplyAPY: 'APY de Suministro',
  BorrowAPY: 'APY de Préstamo',
  Collateral: 'Colateral',
  Adjust: 'Ajustar',
  Supply: 'Suministrar',
  Repay: 'Repagar',
  Borrow: 'Pedir Prestado',
  HealthFactor: 'Factor de Salud',
  CollateralFactor: 'Factor de Colateral',
  AdjustCollateral: 'Ajustar Colateral',
  UseAsCollateral: 'Usar como Colateral',
  RemainingCollateral: 'Colateral Restante',
  RemainingBorrow: 'Prestamo Restante',
  NoSupplyTip: 'Tus activos suministrados aparecerán aquí.',
  NoBorrowTip: 'Tus activos prestados aparecerán aquí.',
  APY: 'APY',
  CF: 'C.F.',
  burrowErrorTip:
    'El saldo actual está por debajo del número mínimo de decimales del token, por lo que no puede ser procesado por el contrato.',
  healthErrorTip:
    'Tu factor de salud será peligrosamente bajo y estás en riesgo de liquidación.',
  Summary: 'Resumen',
  Welcome: '¡Bienvenido!',
  ConnectView: 'Conecta tu billetera para ver',
  TotalSuppliedMarket: 'Total Suministrado',
  NetWorth: 'Valor Neto',
  Claimable: 'Reclamable',
  TotalDebts: 'Deuda Total',
  WalletAssets: 'Activos de la Billetera',
  TotalInvested: 'Inversión Total',
  TotalAssets: 'Activos Totales',
  TokenAllocation: 'Asignación de Tokens',
  perpTip:
    '¡Bienvenido al trading de Futuros Perpetuos! Por favor, lee {perpTipLink} para empezar.',
  perpTipLink: '[Introducción a los Futuros Perpetuos]  ',
  perpetual: 'Perpetuo',
  pred_funding_rate: 'Tasa de Financiamiento Pred.',
  open_interest: 'Interés Abierto',
  bridge_pure: 'Puente',
  pool_refresh:
    'Hubo un problema con el servidor, por favor intenta de nuevo más tarde.',
  netWorthTip:
    'Valor total de inversiones en Ref (incluyendo recompensas reclamables) + Valor total de activos en Orderly + Valor total de activos en Burrow + Activos totales en la billetera',
  top_bin_apr: 'Top Bin APR (24h)',
  mark_price: 'Precio de Marca',
  last_price: 'Último Precio',
  mark_price_tip:
    'El precio de marca se utiliza para calcular las ganancias y pérdidas no realizadas y para liquidación.',
  total_collateral: 'Colateral total',
  current_leverage: 'Apalancamiento actual',

  free_collateral: 'Colateral Libre',
  total_upnl: 'uPnL Total',
  perps: 'Perpetuos',
  margin_ratio: 'Ratio de Margen',
  total_npnl_tip:
    'Estimación de ganancias y pérdidas no realizadas en todas las posiciones abiertas',
  low_risk: 'Bajo Riesgo',
  mid_risk: 'Riesgo Medio',
  high_risk: 'Alto Riesgo',
  liquidations: 'Liquidaciones',
  leverage_max_leverage: 'Apalancamiento Máximo de la Cuenta',
  leverage_max_leverage_raw: 'Apalancamiento Máximo de la Cuenta',
  marker_taker_fee_rate: 'Tasa de Comisión Maker/Taker',
  liquidation_price: 'Precio de Liquidación',
  margin_required: 'Margen Requerido',
  perp_buy_limit_order_range:
    'El precio de las órdenes límite de compra debe ser menor o igual a',
  perp_sell_limit_order_range:
    'El precio de las órdenes límite de venta debe ser mayor o igual a',
  perp_buy_limit_order_scope:
    'El precio de una orden límite de compra no puede ser menor que',
  perp_sell_limit_order_scope:
    'El precio de una orden límite de venta no puede ser mayor que',
  liquidations_fee: 'Cuota de Liquidación',
  more_trading_price: 'Más Precios de Trading',
  unsettle_pnl_tip:
    'Mueve una ganancia o pérdida de los mercados perpetuos al saldo de tokens USDC. Esto no afecta tus posiciones abiertas ni tu salud.',
  margin_ratio_tip:
    'El ratio de margen de una cuenta es igual al valor total del colateral (es decir, el saldo en USDC + cualquier ganancia/pérdida no realizada) dividido por el valor total del nocional abierto de la cuenta (la suma del valor nocional absoluto de todas las posiciones)',
  portfolio_total_est_tip: 'Valor del portafolio en USD.',
  size: 'Tamaño',
  closing_1: 'Cerrando',
  closing_2: 'posición a un precio {type}.',
  buy_long: 'Buy / Long',
  sell_short: 'Sell / Short',
  unsettle_pnl: 'PnL No Asentado',
  the_margin_will_be_insufficient: 'El margen será insuficiente',
  exceed_rate_limit:
    'Has excedido el límite de tasa, por favor intenta de nuevo en 60 segundos',
  est_liquidation_price: 'Est. Precio de Liquidación',
  liquidation_price_tip:
    'Este precio es solo de referencia. Puedes ver el precio de liquidación en tu portafolio de Orderly después de que se complete tu orden.',
  futures_leverage_saved: 'Apalancamiento de Futuros guardado',
  maintenance_margin_ratio: 'Ratio de Margen de Mantenimiento',
  liquidation_fee: 'Liquidation Fee',
  ins_fund_transfer: 'Ins. Fund Transfer',
  no_liquidation_yet: 'Aún no hay liquidación',
  funding_fee: 'Comisión de Financiamiento',
  settlements: 'Liquidaciones',
  funding_annual_rate: 'Tasa de Financiamiento / Tasa Anual',
  Entiendo_que_no_podré_quitar_mi: 'Entiendo que no podré quitar mi ',
  insufficient_margin: 'Insufficient Margin',
  taker_maker_fee: 'Taker/Maker Fee',
  funding: 'Funding',
  taker_maker_fee_rate: 'Taker/Maker Fee Rate',
  spot_nav: 'Spot',
  perps_nav: 'Perps',
  set: 'Set',

  to_begin_your_trading_journey: 'para comenzar tu experiencia de trading.',
  max_leverage_tip:
    'Max account leverage cannot be lower than your current leverage.',
  orderly_new_usr_spot_tip:
    'Deposita activos para comenzar tu experiencia de trading.',
  orderly_new_user_tip:
    'Verifica el saldo y deposita <strong>USDC</strong>  to para comenzar.',
  deposit_on_tip: 'Depósito',
  display_all_orders_dexes:
    'Display orders placed through all channels on Orderly.',
};
export default Object.assign(es, es_in_risks_page);
