const vi_in_risks_page = {
  risks: 'Rủi ro',
  of_using_ref_finance: 'khi sử dụng Ref Finance',
  introduction:
    'Cung cấp thanh khoản và/hoặc trading/giao dịch trên Ref.finance sẽ không thể có chuyện không có rủi ro. Trước khi tương tác với giao thức, vui lòng tìm hiểu kĩ những rủi ro đi kèm.',
  general_risks_quiz: 'Quiz Test cơ bản',
  amm_core_design: 'Thiết kế AMM',
  audits: 'Kiểm định',
  audits_paragraph_2:
    'Những cuộc kiểm định không thể loại trừ hết rủi ro bảo mật. Vì lẽ đó, xin đừng cung cấp tiền tiết kiệm , hay bất cứ loại tài sản nào mà bạn không thể mất, đặc biệt khi bạn là một người cung cấp thanh khoản.',
  admin_keys: 'Khóa quản trị',
  admin_keys_paragraph_2:
    'HIện tại Ref.finance DAO đang có 26 thành viên. Trong tương lai, chúng tôi sẽ chuyển đổi thành 1 DAO hoàn toàn phi tập trung.',
  admin_keys_paragraph_3:
    'Dưới đây là một số các contracts và địa chỉ ví có tác động trực tiếp đến các vấn đề quản trị, hoặc hiện đang quản trị Ref.finance.',
  address: 'Địa chỉ',
  type: 'Loại',
  mission: 'Nhiệm vụ',
  Locked: 'Đã khóa',
  exchange_contract: 'Exchange Contract',
  table_body_tr_1:
    'Quản lý các chức năng của AMM, cững như việc giao dịch và cung cấp thanh khoản',
  farming_contract: 'Farming Contract',
  table_body_tr_2: 'Quản lý các phần thưởng thanh khoản',
  staking_contract: 'Staking Contract',
  table_body_tr_3:
    'Tạo thêm/ đốt bỏ xRef, cùng với đó là phân phối phần thưởng theo chu kì',
  sputnik_dao_contract: 'Sputnik DAO Contract',
  table_body_tr_4:
    'Đảm bảo cho sự thành công của Ref bằng cách đưa ra những quyết định mang tính chiến lược',
  table_body_tr_5:
    'Quản trị và phân bổ vốn đến những   cộng tác viên trong  cộng đồng',
  table_body_tr_6: 'Thực hiện theo chiến lược và roadmap',
  vesting_contract: 'Vesting Contract',
  table_body_tr_7: 'Quản lý REF vesting contracts của cộng đồng DAO',
  airdrop_contract: 'Airdrop Contract',
  table_body_tr_8: 'Quản trị lần airdrop đầu của REF',
  near_address: 'Địa chỉ ví NEAR',
  table_body_tr_9: 'Quản trị những chi trả cho bug bounty',
  fungible_token_contract: 'Fungible Token Contract',
  table_body_tr_10: 'Tạo Ref Token',
  rug_pull: 'Rug pull',
  rug_pull_paragraph_1:
    'Nếu đội ngũ đứng sau token quyết định bỏ rơi dự án và rời đi với tiền của nhà đầu tư thì gần như chắc chắn là token của dự án sẽ rơi về $0  (mặc cho dự án có được whitelist bởi Ref. hay không).',
  rug_pull_paragraph_2:
    'Việc một token được whitelist bởi Ref.finance không đảm bảo là dự án sẽ thành công. Nhà đầu tư khi trước khi đặt tiền cho 1 đồng token nào đó nên hiểu rõ rằng thị trường tiền số là một thị trường rủi ro rất cao.',
  rug_pull_paragraph_3:
    'Bạn nên nhận thức được và chuẩn bị tinh thần rằng 1 phần hoặc tất cả số tài sản bạn đầu tư có thể bị mất.',
  divergence_loss: 'Tổn thất tạm thời',
  divergence_loss_paragraph_1:
    'Khi cung cấp thanh khoản, xin lưu ý rằng lựa chọn không cung cấp thanh khoản (không làm gì cả) vẫn  có thể đem lại nhiều lợi nhuận hơn.',
  divergence_loss_paragraph_2:
    'Divergence Loss còn có một cái tên khác là  “impermanent loss”- Tổn thất tạm thời. Tính từ (impermanent) có thể bị nhầm thành việc bị mất đi tài sản trong tạm thời, đồng nghĩa với với việc các khoản lỗ được đảm bảo là được hoàn lại, điều mà hoàn toàn không hề đúng.',
  divergence_loss_paragraph_3: 'Tìm hiểu thêm về Tổn thất tạm thời',
  staking_risks: 'Rủi ro trong việc staking',
  staking_risks_paragraph_1:
    'Khi staking bạn phải sử dụng một cơ số smart contracts, mỗi smart contract đều có rủi ro riêng.',
  permanent_loss_of_a_peg: 'Tổn thất dài hạn',
  permanent_loss_of_a_peg_paragraph_1:
    'Nếu một trong những loại stablecoins trong bể đột nhiên giảm sâu hơn tỷ giá hối đoái neo là 1.0 và không bao giờ quay lại giá neo đó. Điều đó chứng tỏ tài sản của những nhà cung cấp thanh khoản hầu hết đều là loại stablecoin đó.',
  systemic_issues: 'Lỗi hệ thống',
  systemic_issues_paragraph_1:
    'Về cơ bản, các mảnh của DeFi đều có sự kết nối với nhau, vì vậy một lỗi trong 1 mảnh nhỏ của Defi cũng có thể dẫn đến một chuỗi lỗi của nhiều giao thức.',
  systemic_issues_paragraph_2:
    'Một lỗi hệ thống có thể làm cho bạn mất tiền ngay cả khi lỗi hệ thống đó không tác động trực tiếp đến khoản đầu tư của bạn.',
  systemic_issues_paragraph_3:
    'Những lỗi sau có thể tác động đến bể thanh khoản:',
  systemic_issues_paragraph_4: 'Smart contract của giao thức cho vay bị lỗi',
  systemic_issues_paragraph_5: 'Smart contract trong quá trình staking bị lỗi',
  systemic_issues_paragraph_6:
    'Lỗi hệ thống của stablecoins trong các bể thanh khoản',
  systemic_issues_paragraph_7:
    'Lỗi hệ thống với các ERC20 - native tokens ở trong các bẻ thanh khoản',
  crypto_trading_addiction: 'Nghiện giao dịch crypto',
  crypto_trading_addiction_paragraph_1:
    'Giao dịch tiền điện tử có thể rất dễ gây nghiện và theo nhiều nguồn tin, là một dạng nghiện cờ bạc, có thể hủy hoại cuộc sống.',
  crypto_trading_addiction_paragraph_2:
    'Vui lòng tìm dưới đây một bộ sưu tập các câu chuyện liên quan đến vấn đề đó.',
  crypto_trading_addiction_paragraph_3:
    "'Trading là cờ bạc, không còn nghi ngờ gì nữa'",
  crypto_trading_addiction_paragraph_4:
    "'Tôi đã mất nửa triệu bảng Anh khi giao dịch Bitcoin’'",
  crypto_trading_addiction_paragraph_5:
    "'Chúng tôi nói chuyện với một nhà trị liệu chuyên điều trị chứng nghiện giao dịch tiền điện tử’'",
  crypto_trading_addiction_paragraph_6:
    "''Tôi đã mất hàng triệu USD do nghiện giao dịch tiền điện tử'",
  no_in_progress: 'Không (Đang xử lý)',
  yes: 'Vâng',
  no: 'Không',
  NA: 'N/A',
  ref_finance_near: 'ref-finance.near',
  ref_finance_near_mission:
    'v1 (đã khấu hao) - Quản lý các chức năng của Nhà tạo thị trường tự động; Hoán đổi và cung cấp tính thanh khoản',
  token_ref_finance_near: 'token.ref-finance.near',
  token_ref_finance_near_type: 'Hợp đồng Fungible Token',
  token_ref_finance_near_mission: 'v1 (mất giá) - Mã thông báo REF của Mint',
  refchef_near: 'refchef.near',
  simple_address: 'Địa chỉ đơn giản',
  refchef_near_mission: 'Quản lý các giao dịch liên tài khoản',
  ref_dev_teller_near: 'ref-dev-teller.near',
  Locked_title: 'Bị khóa? hộp thông tin: khi di chuột để hiển thị:',
  Locked_paragraph_1:
    'Hợp đồng bị khóa có nghĩa là không có khóa truy cập nào cho phép triển khai lại mã hợp đồng.',
  Locked_paragraph_2:
    'Trong trường hợp chung, mã có thể được triển khai lại bằng cách:',
  Locked_paragraph_3: '1. một giao dịch với một hành động mã triển khai',
  Locked_paragraph_4:
    '2. bản thân hợp đồng có thể triển khai một lệnh gọi hàm sẽ kích hoạt hành động mã triển khai',
  Locked_paragraph_5:
    'Để triển khai lại mã với một giao dịch, giao dịch phải được ký bằng khóa toàn quyền. Nếu không có khóa như vậy trên hợp đồng, không có cách nào để triển khai lại mã trừ khi có hỗ trợ chuyên dụng trong chính mã hợp đồng và do đó chúng tôi đánh dấu các hợp đồng đó là đã khóa.',
  Locked_paragraph_6:
    'Nếu có ít nhất một khóa toàn quyền truy cập được đăng ký trên tài khoản hợp đồng, hợp đồng sẽ không bị khóa.',
  audited_first_sentence:
    'Các hợp đồng thông minh của Ref Finance đã được <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/audits"' +
    '>kiểm toán</a>.',
  admin_first_sentence:
    'Ref finance được quản trị bởi <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO.</a> Thêm vào đó, sẽ có những địa chỉ ví NEAR nhất định <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://gov.ref.finance/t/introducing-the-guardians/253"' +
    '>(được gọi là Guardians)</a>có khả năng dừng contract của Ref.finance lại. Trong trường họp contract bị ngưng lại, chỉ có Ref.finance DAO mới có khả năng mở lại contract.',
  risks_of_using_ref_finance:
    '<label class="text-greenColor">Rủi ro</label> khi sử dụng Ref Finance',
  third_party_wallet: 'Ví của bên thứ ba',
  third_party_wallet_1: 'Ref Finance tích hợp ví của bên thứ ba.',
  third_party_wallet_2:
    'Mặc dù chúng tôi đã thực hiện các biện pháp để lựa chọn các nhà cung cấp ví có uy tín, nhưng chúng tôi không thể đảm bảo tính bảo mật hoặc hiệu suất của họ. Bạn nên tự làm quen với các rủi ro liên quan đến nhà cung cấp ví cụ thể mà chúng tôi cung cấp và hiểu các điều khoản dịch vụ, chính sách quyền riêng tư và thực tiễn bảo mật của họ.',
  third_party_wallet_3:
    'Vui lòng tự chịu rủi ro khi sử dụng các dịch vụ này và thận trọng.',
};
const vi = {
  deposit: 'Nạp',
  Deposit: 'Nạp',
  withdraw: 'Rút',
  Withdraw: 'Rút',
  withdraw_token: 'Rút Token',
  swap: 'Giao dịch',
  Swap: 'Giao dịch',
  exchange_tokens: 'Exchange Tokens',
  pool: 'Pool',
  Pool: 'Pool',
  pools: 'Pools',
  Pools: 'Pools',
  Quiz: 'Câu hỏi',
  no_tokens_deposited: 'Không có token nào được nạp',
  view_pools: 'Xem pools',
  view_pool: 'Xem pools',
  add_token: 'Thêm Token',
  Add_Token: 'Thêm Token',
  create_new_pool: 'Tạo pool mới',
  Create_New_Pool: 'Tạo pool mới',
  your_liquidity: 'Pool của bạn',
  Your_Liquidity: 'Pool của bạn',
  farms: 'Farms',
  Farms: 'Farms',
  airdrop: 'Airdrop',
  Airdrop: 'Airdrop',
  docs: 'Tài liệu',
  Docs: 'Tài liệu',
  community: 'Cộng Đồng',
  Community: 'Cộng Đồng',
  more: 'Thêm',
  More: 'Thêm',
  account: 'Tài khoản',
  Account: 'Tài khoản',
  New_ui: 'Giao diện UI Mới',
  Risks: 'Rủi ro',
  Forum: 'Diễn Đàn',
  Discord: 'Discord',
  Telegram: 'Telegram',
  Twitter: 'Twitter',
  Medium: 'Medium',
  move_assets_to_from_ethereum: 'Chuyển tài sản từ/đến Ethereum',
  rainbow_bridge: 'Rainbow Bridge',
  deposit_to_swap: 'Gửi tài sản để giao dịch',
  connect_to_near: 'Kết nối ví NEAR',
  slippage: 'Độ trượt giá tối đa',
  slippage_title: 'Cài đặt giao dịch',
  minimum_received: 'Tối thiểu nhận',
  swap_rate: 'Tỉ giá giao dịch',
  swap_rate_pre: 'Tỉ giá giao dịch',
  pool_fee: 'Phí giao dịch',
  balance: 'Số dư',
  from: 'Từ',
  to: 'Đến',
  submit: 'Xác nhận',
  save: 'Save',
  wrapnear: 'Wrap NEAR',
  wrapnear_tip_one:
    'Wrapping NEAR cho phép bạn giao dịch trên REF. Hãy chắc chắn là ',
  wrapnear_tip_two: 'để lại 0.5 NEAR',
  wrapnear_tip_three: ' làm gas fee.',
  wrap_error_msg: 'Tài khoản không đủ',
  sign_out: 'Đăng xuất',
  view_account: 'Theo dõi tài khoản',
  deposit_to_swap_and_add_liquidity:
    'Nạp thêm để giao dịch và thêm thanh khoản',
  small_storage_fee_is_applied_of: 'Một lượng phí nhỏ được áp dụng khoảng',
  liquidity: 'Thanh khoản',
  tokens: 'Tokens',
  fee: 'Phí',
  fees: 'Phí',
  view_detail: 'Xem chi tiết',
  pool_details: 'Chi tiết Pool',
  details: 'Chi tiết',
  detail: 'Chi tiết',
  liquidity_pools: 'Bể thanh khoản',
  minimum_tokens_out: 'Tối thiểu nhận được ',
  pair: 'Cặp thanh khoản',
  tvl: 'TVL',
  search_pools: 'Tìm Pool',
  add_liquidity: 'Thêm',
  remove_liquidity: 'Gỡ',
  total_liquidity: 'Total thanh khoản',
  coming_soon: 'Coming soon',
  accumulated_volume: 'Volume được tích lũy',
  underlying_liquidity: 'Thanh khoản cơ bản',
  total_shares: 'Tổng số shares',
  my_shares: 'Shares',
  token: 'Token',
  enter_token_address: 'Thêm địa chỉ token',
  you_are_not_providing_liquidity_to_any_pools:
    'Bạn đang không cung cấp thanh khoản cho bất kì pool nào',
  remove: 'Gỡ',
  select: 'Chọn',
  select_token: 'Chọn token',
  basis_points: 'Basis points',
  total_fee: 'Tổng phí % ',
  protocol_fee_is: 'Phí protocol là ',
  start_in: 'Bắt đầu trong',
  start_at: 'Bắt đầu vào',
  started_at: 'Đã bắt đầu vào',
  start_date: 'Ngày bắt đầu',
  end_at: 'Kết thúc lúc',
  end_date: 'Ngày kết thúc',
  earn: 'Earn',
  ended: 'Kết thúc',
  pending: 'PENDING',
  pending_cap: 'Pending',
  pending_orders_title: 'Pending Close Orders',
  rewards_per_week: 'Token thưởng mỗi tuần',
  apr: 'APR',
  total_staked: 'Tổng staked',
  unclaimed_rewards: 'Phần thưởng chưa nhận',
  claim: 'Nhận',
  claim_all: 'Nhận tất cả',
  stake: 'Stake',
  unstake: 'Unstake',
  your_rewards: 'Phần thưởng của bạn',
  your_shares: 'Shares',
  shares_owned: 'Lượng Shares sở hữu',
  stake_your_liquidity_provider_LP_tokens: 'Stake LP tokens của bạn',
  go_to_wallet: 'Đến ví',
  recent_activity: 'Hoạt động gần đây',
  view_all: 'Xem tất cả',
  cancel: 'Hủy',
  claim_ref_token: 'Nhận Ref Token',
  has_no_available_claim: 'không nhận được airdrop',
  sorry: 'Xin lỗi',
  total_ref_token: 'Tổng Ref Token',
  locking_ref_token: 'Ref Token bị khóa',
  unclaim_ref_token: 'Ref Token chưa được nhận',
  unlocked: 'Mở khóa',
  locking: 'Khóa',
  you_can_claim_from: 'Bạn có thể claim từ',
  you_have_claimed_all_your_rewards: 'Bạn đã claim tất cả reward',
  ends_in: 'Kết thúc trong',
  days: 'ngày',
  swap_successful_click_to_view: 'Giao dịch thành công. Click để xem',
  claim_successful_click_to_view: 'Nhận thành công. Click để xem',
  claim_failed_click_to_view: 'Nhân thất bại. Click để xem',
  slippageCopyForMobile:
    'Độ trượt giá có nghĩa là sự thay đổi giữa số lượng token bạn nhìn trên màn hình với tỉ giá bạn nhận được do có người thực hiện giao dịch trước',
  slippageCopy:
    'Độ trượt giá có nghĩa là sự thay đổi giữa số lượng token bạn nhìn trên màn hình với tỉ giá bạn nhận được do có người thực hiện giao dịch trước',
  depositCopy:
    'Giao dịch này giúp nạp tài sản của bạn <br> vào trong Ref Finance để thực hiện giao dịch hoặc thêm thanh khoản',
  nearDepositCopy:
    'Cái này đầu tiên sẽ wraps sau đó nạp Ⓝ của bạn vào <br> Ref Finance để giao dịch hoặc thêm vào một bể thanh khoản',
  nearWithdrawCopy:
    'Cái này đầu tiên sẽ unwrap Ⓝ của bạn sau đó rút nó ra từ <br> Ref Finance và chuyển token đến ví của bạn',
  withdrawCopy:
    'Cái này sẽ rút token mà bạn đã chọn từ <br> Ref Finance và nạp chúng vào ví của bạn',
  registerToken:
    'Cái này đăng kí một token mới với <br> Ref Finance mà chưa được list',
  whitelistTokenCopy: 'Thêm bất kì NEP-141 token',
  addLiquidityPoolCopy:
    'Cái này tạo ra một pool cho hai tokens được chọn. Phí là phần trăm pool lấy từ mỗi giao dịch',
  swapCopy:
    'Giao dịch trao đổi token được chọn đầu tiên với token <br> thứ hai được chọn. Bể với độ thanh khoản khả dụng cao nhất và phí trao đổi thấp nhất sẽ được sử dụng',
  addTokenCopy: 'Thêm bất kì NEP-141 token',
  getLPTokenCopy: 'Ấn vào đây để chuyển tới pool tương ứng',
  airdropCopy:
    ' Hãy nhận airdrop của bạn ít nhất một lần trước khi hết hạn nếu không số dư của bạn sẽ bị cho vào quỹ treasury',
  farmRewardsCopy:
    'Giá trị biểu thị dựa trên giá và sự thực hiện không thực tế',
  totalValueLockedCopy: 'Tổng giá trị bị khóa',
  topPoolsCopy:
    'Với mỗi cặp thanh khoản, chỉ hiển thị bể thanh khoản có Tổng giá trị tài sản khóa (TVL) lớn nhất',
  you_do_not_have_enough: 'Bạn không có đủ',
  must_provide_at_least_one_token_for: 'Phải cung cấp nhiều hơn 1 token cho',
  is_not_exist: 'không tồn tại',
  must_input_a_value_greater_than_zero: 'Phải đưa vào một giá trị lớn hơn 0',
  amount_must_be_not_greater_your_balance:
    'Khối lượng phải không được lớn hơn số dư của bạn ',
  no_pool_available_to_make_a_swap_from:
    'Không có pool nào khả dụng để thực hiện 1 giao dịch',
  for_the_amount: 'cho khối lượng',
  no_pool_eng_for_chinese: ' ',
  is_not_a_valid_swap_amount: 'Khối lượng giao dịch không hợp lệ',
  not_nep_address:
    'Địa chỉ bạn nhập vào không phải là địa chỉ NEP-141, hãy kiểm tra và nhập lại. Nếu đó là một mainnet contract khác, đi tới Rainbow Bridge để thực hiện quá trình chuyển',
  not_correct_address:
    'Địa chỉ mà bạn nhập vào không đúng, hãy kiểm tra và nhập lại',
  popular_tokens: 'Token nổi bật',
  asset_label: 'Tài sản',
  account_label: 'Tài khoản',
  total_label: 'Tổng',
  top_pools: 'Bể thanh khoản nổi bật',
  pool_id: 'Pool ID',
  more_pools: 'Nhiều pool hơn',
  my_watchlist: 'Danh sách theo dõi',
  h24_volume: 'Khối lượng giao dịch 24h',
  volume: 'Volume',
  hide_low_tvl_pools: 'Ẩn TVL thấp',
  hide_low_tvl_pools_mobile: 'Ẩn TVL thấp',
  watchlist_title: 'Danh sách xem của tôi ở trên đầu',
  add_watchlist: 'Thêm vào danh sách yêu thích',
  remove_watchlist: 'Xóa danh sách xem',
  my_watchlist_copy: 'Pool được đánh dấu sẽ xuất hiện ở đây',
  search_token: 'Tìm token',
  language: 'Ngôn Ngữ',
  deposit_to_add_liquidity: 'Nạp để thêm thanh khoản',
  slip_warn: 'Hãy cẩn thận, kiểm tra tối thiểu bạn có thể nhận',
  slip_invalid: 'Slippage không hộp lệ',
  input_to_search: 'Tìm kiếm',
  click_search_bar_to_search: 'Ấn vào thanh tìm kiếm để tìm',
  reward_tokens: 'Token thưởng',
  detail_tip: 'Chi tiết',
  no_data: 'Không có dữ liệu',
  stable_swap: 'StableSwap',
  StableSwap: 'StableSwap',
  exchange_rate: 'Tỷ lệ trao đổi',
  including_fees: '(bao gồm phí)',
  standard_gas: 'Tiêu chuẩn phí gas:',
  rst_token: 'RST token của bạn',
  remove_tip: 'Không có phí khi gỡ thanh khoản tính theo share',
  share_liquidity: 'thanh khoản share',
  remove_token_confirm: 'Bạn sẽ xóa RUST token',
  remove_as_one_token: 'Remove as one token',
  flexible_tip: 'Xóa bao nhiêu bạn muốn với mỗi token',
  add_type_all: 'Thêm các token với giá trị ngang bằng',
  add_type_max: 'Thêm tối đa số token khả dụng',
  select_balance: 'Chọn loại ví',
  selectBalanceCopy:
    'Chọn (Ví Near) để giao dịch trực tiếp token có sẵn trong ví NEAR',
  near_wallet: 'Ví NEAR',
  ref_account: 'Ví Ref',
  swap_tip:
    'Giao dịch từ tài khoản REF bằng cách chọn tài khoản REF ở trong cài đặt',
  attention: 'Chú ý',
  live: 'Hiện có ',
  ended_search: 'Kết thúc',
  my_farms: 'Farms của bạn',
  staked_only: 'Đã stake',
  sort_by: 'Sắp theo',
  your_farms_rewards: 'Phần thưởng farms của bạn',
  your_farms: 'Hiện Farm',
  new: 'Pool mới',
  unstake_tip_t: 'bạn có phần thưởng chưa nhận...',
  unstake_tip_m:
    'Unstaking sẽ gỡ stake từ tất cả các farm đang hoạt động và chưa quyết định của cùng một cặp.',
  unstake_tip_b: 'Bạn có muốn unstake?',
  value_rewards: 'phần thưởng',
  stake_unstake_tip:
    'Staking hay unstaking sẽ tự động nhận phần thưởng của bạn.',
  over_tip:
    'Để tối ưu hóa phí gas, bạn có thể rút tối đa 4 token cùng một lúc.',
  no_token_tip: 'Không có token thưởng',
  getToken_tip:
    'Bạn có thể nhận token thưởng hoặc  stake LP token của bạn ngay bây giờ!',
  in_farm: 'trong Farm',
  swap_rate_including_fee: 'Tỷ lệ giao dịch (bao gồm phí)',
  by_share: 'Tính theo Share',
  by_token: 'Tính theo Token',
  your_positions_will_be_displayed_here:
    'Số thanh khoản của bạn sẽ xuất hiện ở đây .',
  price_impact: 'Tác động giá',
  shares_left: 'Shares left',
  total_stable_coins: 'Tổng stablecoins',
  daily_volume: 'Khối lượng giao dịch theo ngày',
  liquidity_utilisation: 'Hiệu quả sử dụng thanh khoản',
  token_reserves: 'thống kê',
  roi_calculator: 'Tính ROI',
  lp_staked: 'LP Tokens Staked',
  usd: 'USD',
  lp_token: 'LP token',
  stake_for: 'Thời hạn',
  day_1: '1D',
  day_2: '7D',
  day_3: '30D',
  day_4: '90D',
  day_5: '1Y',
  cur_apr: 'ROI',
  reward_token: 'Token thưởng',
  get_lp_token: 'Lấy LP Shares',
  calculate_roi: 'Tính ROI',
  rewards_claimed: 'Phần thưởng đã được nhận',
  value_rewards_token: 'Gía trị thưởng',
  all: 'Tất cả',
  all_5: '4 tokens một lần',
  insufficient_shares: 'Không đủ shares',
  shares_removed: 'Shares',
  shares_avaliable: 'Shares khả dụng',
  minimum_shares: 'Số shares tối thiểu (nhận được)',
  shares_tip: 'Cổ phần có sẵn / Tổng số cổ phần',
  stablecoin_only: 'Chỉ Stablecoin',
  more_than: 'Hãy nhập vào số lớn hơn hoặc bằng 0,01',
  less_than: 'Hãy nhập vào số nhỏ hơn 20',
  no_valid: 'Hãy nhập vào số hợp lệ',
  lp_fee: 'Phí cho LP',
  protocol_fee: 'Phí giao thức',
  referral_fee: 'Phí giới thiệu',
  protocol_fee_referral_fee: 'Phí giao thức và phí giới thiệu',
  total_fee_create: 'Tổng phí',
  xref_title: 'Kiếm thêm bằng',
  xref_title1: 'Kiếm thêm',
  xref_title2: 'bằng staking REF cho xREF',
  xref_introdution:
    'Bằng cách stake REF, bạn có cơ hội để nhận thêm doanh thu từ phí giao dịch của giao thức. Mọi holders của Ref đều được lời từ  phần doanh thu này.',
  staking_apr: 'Staking APR',
  view_stats: 'Xem thông số',
  xref: 'xREF',
  ref: 'REF',
  more_than_general_seed: 'Hãy nhập vào số lớn hơn hoặc bằng 0.000001',
  more_than_stable_seed: 'Hãy nhập vào số lớn hơn hoặc bằng 1',
  are_you_sure: 'Bạn đã chắc chưa',
  price_impact_is_about: 'Ảnh hưởng của giá là do',
  yes_swap: 'Trao đổi',
  amount_must_be_greater_than_0: 'khối lượng phải lớn hơn 0',
  volume_ratio: 'Khối lượng 24h/ Tỷ lệ thanh khoản',
  go_to_near_wallet: 'Ví NEAR',
  into_ref_account: 'vào tài khoản REF của bạn.',
  deposit_into_ref_account: 'vào tài khoản REF của bạn để thêm thanh khoản',
  deposit_near_tip: 'Để nạp, giữ ít nhất 1 NEAR dành cho phí gas',
  input_greater_than_available_shares: 'Nhập vào nhiều hơn số shares khả dụng',
  number_of_unique_stakers: 'Số cá nhân đã stake',
  total_ref_staked: 'Tổng số ref đã stake',
  total_xref_minted: 'Tổng xREF được tạo',
  protocol_projected_revenue: 'Doanh thu chia sẻ với xRef holders',
  total_fee_Revenue_shared_with_xref_holders:
    'Total Fee Revenue Shared with xREF Holders',
  provision_treasury: 'Kho dự phòng',
  protocol_projected_revenue_tip:
    'Con số này tương ứng với doanh thu phí giao dịch được chia sẻ tích lũy cho người nắm giữ REF. Con số trên bằng 75% tổng doanh thu phí nền tảng. Nó sẽ khác với số tiền mua lại token REF thực tế do biến động giá.',
  stake_ref_to_xref_for_earning_more: 'Stake REF, nhận xREF để earn thêm REF!',
  auto_router: 'Router tự động',
  auto_router_detail_sub_1: 'Tuyến này giúp tối ưu giao dịch của bạn bằng',
  auto_router_detail_sub_2:
    'cân nhắc tất cả các pool xuất hiện trong một pair.',
  auto_router_detail_sub_3: 'Chi tiết:',
  introducing_parallel_swap: 'Giới thiệu Parallel Swap',
  at_least: 'Ít nhất',
  xref_start_time: 'Bắt đầu vào 1am UTC, 18th Jan, 2022.',
  xref_to_receive: 'xREF nhận được',
  ref_to_receive: 'REF nhận được ',
  more_expensive_than_best_rate_en: 'đắt hơn tỉ lệ tốt nhất hiện tại',
  optimal_path_found_by_our_solution: 'Tuyến tối ưu nhất đã được tìm thấy',
  check_to_stable_banner: 'Check',
  rates_to_stable_banner: 'Rates',
  make_sure_you_understand_what_you_do:
    'Hãy đảm bảo rằng bạn hiểu những gì bạn sắp làm',
  go_to_your_account_to: 'Go to your account to',
  deposit_l: 'Nạp',
  withdraw_l: 'Rút',
  got_it: 'Hiểu rồi',
  check_the_transaction_settings: 'Kiểm tra cài đặt giao dịch',
  filter_by: 'Bộ lọc',
  allOption: 'Tất cả',
  stablecoin: 'Stablecoin',
  near_ecosystem: 'Hệ sinh thái NEAR',
  bridged_tokens: 'Bridged tokens',
  gaming: 'Gaming',
  nft: 'NFT',
  bridge: 'Bridge đến/tới',
  from_ethereum: 'Ethereum',
  from_aurora: 'Aurora',
  from_solana: 'Solana',
  from_terra: 'Terra',
  from_celo: 'Celo',
  no_result: 'Xin lỗi, chúng tôi không tìm thấy cặp farm nào của bạn',
  multiple: 'Cặp được nhân',
  default: 'mặc định',
  vi_go_live: 'Việt phát trực tiếp!',
  do_not_need_to_deposit_in_ref: 'Bạn không cần nạp tiền vào Ref Finance nữa!',
  you_can_add_liquidity_directly:
    'Bây giờ bạn có thể thêm thanh khoản trực tiếp bằng ví NEAR Wallet.',
  do_not_need_deposit_anymore: 'Bạn không cần phải đặt cọc nữa!',
  lightning_swaps: 'Giao dịch tức thời',
  frictionless_add_remove_liquidity: 'Thêm/ Xóa thanh khoản mượt mà hơn',
  create: 'tạo ra',
  select_a: 'Chọn một',
  wallet: ' ',
  Wallet: 'Wallet',
  to_use_ref_finance: 'để sử dụng Ref.Finance',
  not_supported: 'không hỗ trợ',
  installed: 'đã cài đặt',
  installe_now: 'cài đặt ngay',
  extension: 'mở rộng',
  first_time_using_ref: 'Lần đầu sử dụng Ref',
  learn_more: 'Tìm hiểu ngay',
  install_sender_now: 'Cài đặt Sender ngay',
  connect_to_dapps_with_one_click: 'Kết nối tới dApps với một cú click',
  install: 'Cài đặt',
  Connecting: 'Đang kết nối',
  check_sender_wallet_extension: 'Hãy kiểm tra Sender Wallet Extention',
  having_trouble: 'Gặp lỗi?',
  go_back: 'Quay lại',
  web: 'Web',
  Type: 'Kiểu',
  click_to_view: 'Nhấn để xem',
  transaction_failed: 'Giao dịch thất bại',
  wallet_vi: 'ví',
  value: 'giá trị',
  usd_value: 'USD Value',
  sauce_note:
    'SAUCE được thiết kế cung cấp các cặp tài sản pegged với nhau, đưa đến mức giá tối ưu nhất.',
  support_ledger: 'ủng hộ Ledger',
  initialize_account_tip: 'Vui lòng khởi tạo tài khoản của bạn.',
  support_ledger_tip:
    "Ở bản thiết kế ban đầu, Ledger không thể thực hiện số lượng lớn giao dịch cùng lúc(i.e. Auto Router: trao đổi qua nhiều pool trong một lần) bởi vì sự giới hạn về bộ nhớ. Khi được kích hoạt, tuỳ chọn 'Support Ledger' sẽ giới hạn các giao dịch tới sự đơn giản nhất (dù không có được giá thành tốt nhất), nên các giao dịch của có khối lượng hợp lí mới có thể được thực hiện",
  start: 'bắt đầu',
  aprTip: 'Giá trị được tính dựa trên tổng phần thưởng hiện có (chưa/đã nhận)',
  half: 'Half',
  max: 'Max',
  ref_account_balance_tip:
    'Có vẻ như đã có một lỗi xảy ra trong quá trình thêm / xóa các pool thanh khoản',
  ref_account_tip_2: 'Bạn có mã thông báo trong Tài khoản REF của mình',
  ref_account_tip_top: 'token(s) của bạn có thể hiện ở trong phần REF Account',
  click_here: 'Bấm vào đây',
  to_recover_them: 'để khôi phục chúng',
  ref_account_tip_3:
    'Để rút token từ Tài khoản REF của bạn vào Ví GẦN của bạn, vui lòng chọn và rút tiền',
  aurora_account_tip_4:
    'Để rút token từ Tài khoản Bản đồ của bạn vào Ví GẦN của bạn, vui lòng chọn và rút tiền',
  pool_fee_cross_swap: 'Phí pool/cross-chain',
  mapping_account: 'Lập bản đồ tài khoản',
  mapping_account_tip: 'Bạn có (các) token trong Tài khoản Bản đồ',
  cumulative_ref_buyback: 'Cumulative REF Buyback',
  yearly_revenue_booster: 'Yearly Revenue Booster',
  Confirm: 'Xác nhận',
  Request_for_Quote: 'Yêu cầu báo giá',
  mapping_account_explanation:
    'Địa chỉ ví mapping là một proxy address giúp kết nối đồng thời tài khoản trên NEAR với một địa chỉ ví ETH trên Aurora',
  buy: 'Mua',
  sell: 'Bán',
  buy_nsn: 'Mua USN',
  buy_nsn_tip: 'USN là một stablecoin được hỗ trợ bởi hệ sinh thái NEAR. ',
  usn_tip_one: 'USN cho phép bạn giao dịch trên REF. Hãy chắc chắn rằng ',
  rate: 'tỉ lệ',
  name: 'Tên',
  diff: 'Khác biệt',
  best: 'Tốt nhất',
  trading_fee: 'Phí giao dịch',
  usn_successful_click_to_view: 'Giao dịch thành công. Nhấn để xem',
  awesomeNear_verified_token: 'token được xác nhận bởi AwesomeNear',
  usn_fee_tip: 'Không tính phí theo Ref, người dùng chỉ phải trả phí USN!',
  total_bitcoins: 'Tổng số Bitcoin',
  total_usd_value: 'Tổng giá trị đô la Mỹ',
  bitcoin_value: 'Giá trị Bitcoin',
  stable_coin_value: 'Giá trị của StableCoin',
  pool_fee_apr: 'Pool fee APY',
  ref_account_balance_tip_mobile:
    'Bạn có mã thông báo trong tài khoản REF của mình.',
  click: 'Nhấp chuột',
  to_recover: 'để phục hồi.',
  rewards_week: 'Rewards/week',
  rewards: 'Rewards',
  claimed_Rewards: 'Nhận phần thưởng',
  POOL: 'POOL',
  STAKE: 'STAKE',
  lp_Token: 'LP Tokens',
  booster: 'Booster',
  slash_policy: 'Slash Policy',
  stake_period: 'Stake Period',
  append: 'Append',
  append_staking: 'Append Staking',
  unstake_time: 'Unstake Time',
  farm: 'Farm',
  reward_Token: 'Reward Token',
  status: 'Status',
  value_of_rewards: 'Value of Rewards',
  Rewards: 'Rewards',
  free: 'Free',
  lp_tokens: 'LP tokens',
  connect_to_wallet: 'Kết nối Wallet',
  anytime: 'Anytime',
  slash_policy_content:
    'Each seed has its own slash rate. And it will decreases linearly over time until be 0 at the unlock time of this CD Account.',
  not_expired: 'Not expired',
  accept_pay_slash_tip: 'I accept to pay slash',
  i_will_pay: 'I will pay',
  lp_token_slash: 'LP token exit fee.',
  booster_change_reason: 'Booster will change due to appending time',
  initial_booster: 'Initial Booster',
  append_timing: 'Append timing',
  cd_limit: 'more than 16 cd account limit',
  expired: 'Expired',
  all_5_v2: '5 tokens/lần',
  all_4_v2: '4 tokens/lần',
  more_than_seed: 'Input must be greater than or equal to ',
  how_to_earn_more: 'Làm thế nào để gộp phần thưởng?',
  you_shares: 'Your Shares',
  lock_your_lp_tokens_with_booster: 'Lock your LP tokens for boosted rewards',
  unstaked_anytime_no_booster: 'Unstaked anytime, no booster',
  ordinary_stake: 'Ordinary Stake',
  locking_stake: 'Lock-up staking',
  change_to_lock: 'Change to Lock-up staking',
  estimated_rewards: 'Estimated rewards',
  final_booster: 'Final Booster',
  existing_amount: 'Existing amount',
  append_amount: 'Append amount',
  will_be_able_to_unstaked_after: 'will be able to unstaked after',
  expected_reward: 'Expected reward',
  you_have: 'Bạn có',
  tokens_small: 'tokens',
  reward_apr: 'Rewards APR',
  price_board: 'Price board',
  close: 'Close',
  stake_min_deposit: 'Lượng nhập vào phải lớn hơn hoặc bằng ',
  unlock: 'Unlock',
  exit_fee: 'Exit fee',
  end_locking_period: 'End of locking period',
  add_lp_tokens_tip:
    'Bạn cần LP tokens để stake vào một farm tương ứng. Đầu tiên, thê thanh khoản vào pool để nhận LP tokens.',
  slash_tip: 'Exit fee = Exit fee rate * End of locking period * Unlock amount',
  nonBoosted: 'Không được boost ',
  exit_fee_tip: 'Estimated amount you would pay to break your lock-up period',
  forbiddenTip: 'This farm does not support this duration option',
  unstakeTip: 'Staking hoặc unstaking sẽ tự động nhận phần thưởng của bạn.',
  endedFarmsUnstakeTip:
    'Unstaking sẽ xóa phần stake từ tất cả các farms cùng môt cặp đang hoạt động hoặc đang xét duyệt. ',
  comimg: 'Đang đến',
  appendTip: 'Không thể sớm hơn thời gian khóa hiện tại',
  week: 'tuần',
  yours: 'của bạn',
  boost: 'Boost',
  near: 'NEAR',
  eth: 'ETH',
  others: 'Khác',
  stakeLove: 'Stake LOVE',
  unstakeLove: 'Unstake LOVE',
  get_love: 'Nhận LOVE',
  swap_successful: 'Hoán đổi thành công. ',
  trading_successfull: 'Giao dịch thành công. ',
  total_near_amount: 'Số lượng tất cả NEAR',
  near_value: 'NEAR giá trị',
  borrow: 'Vay',
  burrow_usn_tip: 'Bạn có thể mượn USN trên Burrow.',
  migrate: 'Migrate',
  go_to_new_farm: 'đi tới Farm mới',
  voting_gauge_share_tip:
    'Một phần REF được sử dụng để bầu chọn cho tổng lượng phần thưởng REF trong giai đoạn này',
  swap_capital: 'Giao dịch',
  farm_capital: 'FARM',
  sauce_capital: 'SAUCE',
  dao_capital: 'DAO',
  available_to_stake: 'Lượng stake khả dụng',
  recent_one_mounth_activity: 'Hoạt động trong một tháng',
  your_assets: 'Tài sản của bạn',
  connect_wallet: 'Kết nối ví',
  disconnect: 'Ngắt kết nối',
  change: 'Thay đổi',
  wallets_below_supports_on_PC: 'Các ví dưới đây hỗ trợ trên PC',
  connect: 'Kết nối',
  make_sure_device_plugged_in:
    'Hãy chắc chắn rằng thiết bị của bạn được kết nối',
  then_enter_an_account_id_to_connect: 'sau đó nhập ID tài khoản để kết nối',
  veTip:
    'veLP tokens không thể sử dụng để trao đổi, veLPT đại diện cho sức mạnh bình chọn của bạn ',
  loveTip:
    'LOVE tưởng trưng cho thanh khoản veToken. LOVE, giống như bất kì NEP-141 token, trao đổi được và có thể chuyển đổi, pooled, staked and farmed. LOVE đại diện cho thanh khoản cơ bản veTokens của bạn',
  lock_your_lp_tokens: 'Khóa LP Tokens của bạn',
  unlock_your_defi_power: 'Mở khóa DeFi Power của bạn',
  unlock_tip_mobile: 'Sẵn sàng để mở khóa!',
  unlock_tip_top:
    'Phần đang khóa của bạn đã hết hạn, mở khóa đang khả dụng ngay bây giờ!',
  bonus_to_be_withdraw: 'Phần bonus sẵn sàng để rút',
  keep_ve: 'giữ',
  months: 'các tháng',
  month: 'tháng',
  lock_lp_tokens: 'Khóa LP Tokens',
  get_lptoken: 'Nhận LP Tokens',
  duration: 'Thời gian',
  ve_lock_tip: 'Không thê sớm hơn thời gian khóa hiện tại',
  get: 'Nhận',
  locking_ve: 'Đang khóa',
  in_addition_to_the: 'Bên cạnh',
  already_locked: 'Đã khóa',
  unlocking_is: 'Mở khóa là',
  you_have_no_lp_share: 'Bạn không có LP Tokens',
  lock: 'Khóa',
  unlock_lptoken: 'Mở LP Tokens',
  locked: 'Đã khóa',
  available: 'Khả dụng',
  balance_lowercase: 'số dư',
  voting_power: 'Lực vote',
  lock_lp_tokens_first: 'Khóa LP Tokens trước!',
  farm_booster: 'Farm Booster',
  go_to_farm: 'Đi tới Farm',
  in: 'trong',
  more_lowercase: 'nhiều hơn',
  avaliable_to_lock: 'khả dụng để khóa',
  lock_lptoken: 'Khóa LP Tokens',
  lptoken_locker: 'Người khóa LP Token',
  filter: 'Bộ lọc',
  bonus: 'Thưởng',
  your_shares_ve: 'Phần Shares của bạn',
  voted: 'Đã vote',
  my_voting_power: 'Lực bình chọn của tôi',
  new_voting_ratio: 'Tỉ lệ bình chọn mới',
  REF_allocation: 'Phân phối REF',
  vote: 'Vote',
  preview: 'Xem trước',
  options: 'Lựa chọn',
  ratio: 'Tỉ lệ',
  forum_discussion: 'Diễn đàn thảo luận',
  voted_veLPT: 'Đã vote veLPT',
  your_contribution: 'Đóng góp của bạn',
  delete: 'Xóa',
  not_start: 'Không bắt đầu',
  no_veLPT: 'Không có veLPT',
  claim_bonus: 'Nhận phần cộng thêm',
  ended_ve: 'Đã kết thúc',
  proposal_details: 'Đề xuất chi tiết',
  back: 'Quay lại',
  pending_ve: 'Đang xét duyệt',
  you_voted: 'Bình chọn của bạn',
  add_bonus: 'Thêm phần cộng thêm',
  you_vote: 'Bạn bình chọn',
  Creator: 'Người tạo',
  turn_out: 'Tạo ra',
  top_answer: 'Câu trả lời hàng đầu',
  your_bonus: 'Phần cộng thêm của bạn',
  gauge_weight_vote: 'Đo lực bình chọn',
  governance: 'Quản lý',
  confirm: 'xác nhận',
  voting_period: 'Gian đoạn bình chọn',
  applying_period: 'Giai đoạn ứng cự',
  community_gauge_share: 'Đo sự chia sẻ của cộng đồng',
  qualified_pools: 'Chất lượng Poola',
  total: 'Tổng',
  supply_voted: 'Cung bình chọn',
  proposed: 'Đề xuất',
  farm_reward: 'Phần thưởng Farm',
  check_last_round: 'Kiểm tra vòng cuối',
  last_round_voting_result: 'Kết quả bình chọn vòng cuối',
  create_proposal: 'tạo đề xuất',
  type: 'Loại',
  add_option: 'Thêm lựa chọn',
  lock_lp_terms:
    'Tôi hiểu rằng Tôi sẽ không thể xóa LP Tokens của tôi trong thời gian hết hạn của thời gian đồng ý khóa',
  connect_to_near_wallet: 'Kết nối tới NEAR Wallet',
  only_for_whitelist_address: 'Chỉ dành cho địa chỉ whitelist',
  v1Legacy: 'Legacy',
  v2New: 'Classic-New',
  v2_new_farms: 'Classic Farms mới',
  migrate_now: 'Migrate ngay bây giờ!',
  v2_boost_tip:
    'Classic farms sẽ hỗ trợ boosted farms. Legacy farms sẽ chạy phần thưởng <span class="font-bold">1st. Tháng 8, 2022.</span>',
  v2_boost_tip2: 'Classic farms sẽ hỗ trợ boosted farms.',
  v2_boost_no_tip:
    'Legacy farms sẽ chạy phần thưởng <span class="font-bold">1st. Tháng 8, 2022.</span>',
  no_farm_need_to_migrate: 'Không có farm nào cần Migrate',
  v1_farms: 'Legacy Farms',
  has_rewards_tip:
    'Bạn vẫn có phần thưởng đã nhận để rút trong <a rel="noopener noreferrer nofollow" class="text-greenColor text-base cursor-pointer underline hover:text-senderHot"  href="/farms" >Legacy Farms</a>. Phần thuowgnr của bạn sẽ không biến mất, bạn có thể rút bất kì lúc nào.</div>',
  love: 'LOVE',
  Available_to_stake: 'Stake khả dụng',
  you_staked: 'Bạn đã stake',
  has_rewards_tip_in_v2:
    'Tải sản là phần thưởng sẽ được rút trong <a rel="noopener noreferrer nofollow" href="/farms" class="text-sm text-greenColor cursor-pointer underline ml-1 hover:text-senderHot"> Legacy Farms</a>.',
  how_to_get: 'Làm sao để nhận?',
  get_booster: 'Nhận Booster',
  ended_farms: 'kết thúc farms',
  boosted_farms: 'Boosted farms',
  your_farm: 'Farm của bạn',
  your_boosted_farms: 'Boosted farms của bạn',
  search_farms_by_token: 'Tìm farms bằng token',
  search_farms: 'Tìm kiếm farms',
  more_lock_ve_popup: 'thêm ',
  more_lock_ve_popup2: 'thêm',
  more_in: 'thêm vào',
  starts: 'bắt đầu',
  left: 'bên trái',
  proposal_title: 'Tiêu đề của đề xuất',
  share_forum_discussion_link_here: 'Chia sẻ link diễn đàn tạiđây',
  required_field: '‘Phần ‘yêu cầu',
  voting_power_and_extra_earnings: '‘Lực bình chọn & Thưởng thêm',
  bonus_tip:
    'Phần thưởng vote cộng thêm được thiết kế để khuyến khích người dùng vote. Phần thưởng thêm của bạn phụ thuộc vào lượng đóng góp',
  yes_continue: 'Yes, continue',
  unlocking_tip:
    'Mở khoá LP Token sẽ tự động huỷ bình chọn của bạn (và phần thưởng liên quan) trong đề xuất đang diễn ra',
  create_pool: 'Tạo lập Pool',
  search_by_token: 'Tìm kiếm bằng token',
  your_power: 'Lực của bạn',
  farm_has_boost_tip:
    'Lực của bạn = Staked LP Tokens của bạn * booster (bằng staking LOVE)',
  farm_no_boost_tip: 'Lực của bạn = Staked LP Tokens của bạn',
  more_than_a_simple_pool: 'Nhiều hơn một pool đơn giản',
  ended_Farms: 'Kết thúc Farms',
  vote_capital: 'Bình chọn',
  starting_time: 'Starting Time',
  ending_time: 'Ending Time',
  please_choose_voting_duration: 'Hãy chọn khoảng thời gian bình chọn diễn ra',
  please_choose_proposal_voting_duration:
    'Please choose proposal voting duration',
  days_ve: 'days',
  vote_mobile_capital: 'VOTE',
  risks_capital: 'Rủi ro',
  view_on: 'xem trên',
  boostFarmTip: 'Tăng phần thưởng bằng cách staking LOVE',
  boosted: 'Đã boost',
  love_staked: 'Staked LOVE',
  your_apr: 'APR của bạn',
  range_apr: 'Xếp loại hoặc tham khảo APR',
  no_pair_found: 'Không tìm thấy cặp',
  found_stable_pool_in_sauce: 'Đã tìm thấy nhóm ổn định trong SAUCE',
  lp_tokens_locking: ' LP Tokens ',
  you_currently_have: 'Bạn rất muốn ',
  scheduled_to_be_unlocked: 'dự kiến sẽ được mở khóa',
  locking_more_lp_tokens: 'Khóa thêm mã thông báo LP ',
  will_mean_these: 'sẽ có nghĩa là những ',
  lp_tokens_will_not_be_unlocked_until:
    ' Mã thông báo LP sẽ không được mở khóa cho đến khi',
  "I_understand_that_I_won't_be_able_to_remove_my":
    'Tôi hiểu rằng tôi sẽ không thể xóa ',
  until: 'cho đến khi',
  continue: 'Tiếp tục',
  then_enter_a_derivation_path_to_connect:
    'sau đó nhập một đường dẫn xuất để kết nối',
  account_identification_failed:
    'Không xác định được tài khoản! Vui lòng cung cấp thủ công',
  input_account_id: 'Nhập ID tài khoản',
  we_found: 'Chúng tôi đã tìm thấy',
  accounts_on_your_device: 'tài khoản trên thiết bị của bạn',
  select_the_accounts_you_wish_to_connect: 'Chọn tài khoản bạn muốn kết nối',
  can_not_find_any_accounts:
    "Can't found any account associated with this Ledger. Please create a new NEAR account on",
  or_connect_another_ledger: 'or connect an another Ledger',
  add: 'cộng',
  finish: 'Kết thúc',
  add_custom_network: 'Thêm mạng tùy chỉnh',
  network_name: 'Tên mạng',
  rpc_name_taken_tip: 'Tên mạng đã được sử dụng',
  network_invalid: 'Mạng không hợp lệ',
  fobidden_testnet_rpc_tip:
    'Mạng của máy chủ RPC (testnet) khác với mạng này (mainnet)',
  no_support_testnet_rpc_tip: 'Testnet không hỗ trợ thêm RPC tùy chỉnh',
  stable: 'Stable',
  trade_capital: 'TRAO ĐỔI',
  trade_capital_hump: 'Trao đổi',
  pro: 'Chuyên nghiệp',
  limit: 'Giới hạn',
  make_an_order: 'Tạo một lệnh',
  your_active_orders_will_appear_here:
    'Lệnh đang hoạt động của bạn xuất hiện ở đây',
  your_history_orders_will_appear_here:
    'Lịch sử lệnh của bạn sẽ xuất hiện ở đây',
  liquidity_capital: 'Thanh khoản',
  goes_to_limit_order: 'đi tới giới hạn lệnh.',
  farm_only: 'Chỉ farm',
  please_confirm_this_transaction_on_ledger:
    'Vui lòng xác nhận giao dịch này trên Ledger',
  limit_order: 'Giới hạn lệnh',
  newText: 'Mới',
  farm_stop_tip: 'Farm này đã bị dừng.',
  pool_stop_tip: 'Pool này đã bị dừng.',
  volume_24h: 'Volume (24h)',
  add_to_watchlist: 'Thêm vào danh sách xem',
  remove_from_watchlist: 'Xoá khỏi danh sách xem ',
  create_order: 'Tạo lệnh',
  filled: 'Đã đầy',
  canceled: 'Đã huỷ',
  partially_filled: 'Đã đầy một phần',
  pool_composition: 'Cấu tạo của pool',
  amount: 'Số lượng',
  h24_volume_bracket: 'Khối lượng(24h)',
  fee_24h: 'Phí(24h)',
  cross_chain_swap: 'Chuyển đổi qua chain khác',
  orders: 'mệnh lệnh',
  your_orders: 'đơn đặt hàng của bạn',
  active: 'Hoạt động',
  history: 'Lịch sử',
  you_sell: 'Bạn bán',
  you_buy: 'Bạn mua',
  fee_tiers: 'Phí các bậc',
  order_rates: 'Tỉ lệ lệnh',
  created: 'Đã tạo',
  executed: 'Đã thực hiện',
  actions: 'Các hành động',
  claimed_upper: 'Đã nhận',
  open_my_order: 'Mở',
  initial_order: 'Lệnh ban đầu',
  this_order_has_been_partially_filled: 'Lệnh này đã được làm đầy một phần',
  filled_via_swap: 'Làm đầy thông qua chuyển đổi',
  state: 'Tình trạng',
  partially: 'Một phần',
  near_validation_error: 'Must have 0.5N or more left in wallet for gas fee.',
  near_min_validation_error:
    'Must have 0.2N or more left in wallet for gas fee.',
  no_positons_in_this_pool_yet: 'No positons in this pool yet',
  no_claimed_rewards_yet: 'No claimed rewards yet',
  out_of: 'out of',
  value_must_be_less_than_or_equal_to: 'Value must be less than or equal to',
  select_tokens: 'Chọn Tokens',
  fee_Tiers: 'Phí cấc bậc',
  select_s: 'Chọn',
  no_pool: 'Không có pool',
  input_amount: 'Nhập số lượng',
  update_range: 'Cập nhật phạm vị',
  not_enough_balance: 'Không đủ số dư',
  set_price_range: 'Đặt phạm vi giá',
  current_price: 'Giá hiện tại',
  current_leverage: 'Đòn bẩy hiện tại',

  pool_creation: 'Tạo pool',
  pool_creation_tip:
    'Không có pool tồn tại cho tokens đã chọn. Để tạo pool, bạn phải đặt tỉ lệ pool, bằng cách cung cấp một số lượng phù hợp',
  starting_price: 'Bắt đầu đinh giá',
  create_a_pool: 'Tạo một pool',
  add_single_price_tip:
    'Vị thế của bạn sẽ không tính phí hoặc được sử dụng trong trao đổi cho tới khi giá thị trường chuyển tới phạm vi của bạn',
  invalid_range_tip:
    'Phạm vi đã chọn không hợp lệ. Giá nhỏ nhất phải thấp hơn giá lớn nhất',
  maket_price_outside_tip: 'Giá thị trường nằm ngoài phạm vi giá của bạn.',
  maket_price_outside_single_only_tip:
    'Giá thị trường nằm ngoài phạm vi giá của bạn. Chỉ đặt vào được tài sản đơn.',
  min_price: 'Giá nhỏ nhất',
  max_price: 'Giá lớn nhất',
  min: 'Nhỏ nhất',
  out_of_range: 'Ngoài phạm vi',
  in_range: 'Trong phạm vi',
  unclaimed_fees: 'Unclaimed Fees',
  your_position_will_be: 'Vị thế của bạn sẽ là',
  at_this_price: 'ở giá này',
  price_range: 'Phạm vi giá',
  positions: 'Các vị thế ',
  position: 'Position',
  add_new_position: 'Thêm vị thế mới',
  range: 'Phạm vi',
  unclaimed_fee: 'Phí chưa nhận',
  login_risk_tip:
    'Bằng cách chọn vào ô này và tiếp tục, bạn xác nhận rằng bạn hoàn toàn hiểu rõ các <a rel="noopener noreferrer nofollow" target="_blank" class="text-greenColor text-sm font-bold cursor-pointer hover:underline" href="/risks")">rủi ro</a> khi sử dụng Ref Finance.',
  no_pool_tip: 'Oops! The pool does not exist.',
  total_ref_week: 'Tổng số REF/week',
  ref_week_you_will_get: 'Số REF/week bạn sẽ nhận',
  stable_pool: 'Stable Pool',
  market_rate: 'Tỷ giá thị trường',
  limit_price_tip:
    'Để nâng cao hiệu suất giao dịch, giá của bạn nên được tự động đặt trong một khe gần nhau.',
  no_token_found: 'Không tìm thấy token.',
  search_name_or_address: 'Tìm kiếm tên hoặc dán địa chỉ...',
  token_address_invalid: 'Địa chỉ token không hợp lệ',
  price_impact_warning: 'Cảnh báo Ảnh hưởng Giá',
  confirm_order: 'Xác nhận Giao dịch',
  you_Sell: 'Bạn Bán',
  to_Buy: 'để Mua',
  at_Price: 'với Giá',
  insufficient_balance: 'Số dư không đủ',
  MORE: 'XEM THÊM',
  limitTip: 'Giá của bạn được tự động đặt vào khe giá gần nhất',
  v2PoolTip: 'Bạn có thể có tối đa 16 vị trí trong các pool DCL',
  orderTip: 'Bạn có thể có tối đa 16 lệnh giới hạn hoạt động/ lịch sử',
  v2_pools: 'Các Pool DCL',
  minimum_received_dot: 'Tối thiểu nhận',
  v2_your_pool_introduction: 'Lưu lượng tập trung được phân đoạn của bạn',
  v1_your_pool_introduction: 'Lưu lượng của bạn trong các pool cổ điển',
  estimate_value: 'Ước tính Giá trị',
  price: 'Giá',
  '@price': '@Giá',
  h24_high: 'Cao trong 24h',
  h24_low: 'Thấp trong 24h',
  h24_Volume: 'Khối lượng 24h',
  orderbook: 'Sổ lệnh',
  orderbook_mobile: 'Sổ lệnh',
  trades: 'Giao dịch',
  qty: 'Số lượng',
  total_orderly: 'Tổng',
  time: 'Thời gian',
  balances: 'Số dư',
  asset: 'Tài sản',
  wallet_up: 'Ví',
  account_in_order: 'Tài khoản: đang thực hiện đặt lệnh',
  source_address: 'Địa chỉ nguồn',
  action: 'Hành động',
  market: 'Thị trường',
  order_type: 'Loại lệnh',
  order_type_limit_tip:
    'Lệnh giới hạn là lệnh mua hoặc bán với giá cụ thể hoặc tốt hơn. Lệnh giới hạn không đảm bảo thực hiện giao dịch.',
  order_type_market_tip:
    'Lệnh thị trường được phối hợp ngay lập tức với giá thị trường tốt nhất và thực hiện giao dịch.',
  quantity: 'Số lượng',
  advanced: 'Nâng cao',
  tip_fok:
    'Fill-Or-Kill là lệnh mua hoặc bán phải được thực hiện ngay lập tức toàn bộ; nếu không, toàn bộ lệnh sẽ bị hủy.',
  tip_post_only:
    'Chỉ Đăng Bài đảm bảo rằng người giao dịch chỉ có thể đặt lệnh nếu nó sẽ được đăng vào sổ lệnh như một lệnh Maker. Một lệnh sẽ được đăng như một lệnh Taker sẽ bị hủy bỏ.',
  tip_ioc:
    'Immediate-Or-Cancel là lệnh mua hoặc bán phải được thực hiện ngay lập tức. Bất kỳ phần nào của lệnh IOC không thể được thực hiện sẽ bị hủy.',
  Taker: 'Taker',
  Maker: 'Maker',
  see_all: 'Xem tất cả',
  h24Vol: 'Khối lượng 24h',
  h24Range: 'Biên độ 24h',
  chart: 'Biểu đồ',
  deposit_assets_to_begin_your_trading_journey:
    'Nạp tài sản để bắt đầu hành trình giao dịch của bạn.',
  open_orders: 'Lệnh Mở',
  ref_order_only: 'Chỉ Lệnh trên REF',
  ref_order: 'Chỉ trên Sàn REF',
  orderly_portfolio_table_tips:
    'Dữ liệu được cung cấp ở đây bao gồm tất cả tài sản và ghi chép trong tài khoản của bạn, không giới hạn trong việc tạo ra thông qua REF.',
  orderly_ws_refresh: 'Vui lòng làm mới dữ liệu để tiếp tục',
  refresh: 'Làm mới',
  settled_balance: 'Số dư đã thanh toán',
  settled_amount: 'Số tiền đã thanh toán',
  Side: 'Mặt',
  all_side: 'Tất cả các Mặt',
  both_side: 'Cả hai Mặt',
  both: 'Cả hai',
  filled_qty: 'Filled / Qty',
  fill_qty: 'Fill / Qty',
  est_total: 'Est.Total',
  avg_price: 'Avg.Price',
  create_time: 'Thời gian Tạo',
  spot: 'Spot',
  futures: 'Futures',
  All: 'Tất cả',
  Filled: 'Đã Thực Hiện',
  Fees: 'Phí',
  Cancelled: 'Đã Hủy',
  Rejected: 'Đã Từ chối',
  all_instrument: 'Tất cả Công cụ',
  available_orderly: 'Khả dụng',
  history_orderly: 'Lịch sử',
  settle_pnl: 'Settle PnL',
  settle_pnl_tips:
    'Khi làm điều này, chúng tôi sẽ chuyển lãi hoặc lỗ từ thị trường perp vào số dư token USDC. Điều này không ảnh hưởng đến các vị trí mở hoặc tình trạng tài khoản của bạn.',
  total_unsettled_pnl: 'Tổng lãi/lỗ chưa thanh toán',
  fut_unreal_pnl: 'Lãi/lỗ không thực tế HĐTL',
  fut_daily_real: 'Lãi thực tế HĐTL hàng ngày',
  fut_notional: 'Giá trị HĐTL',
  fut_unsettle_pnl: 'Lãi/lỗ chưa thanh toán HĐTL',
  settle: 'Thanh toán',
  mark_orderly: 'Đánh dấu',
  avg_open: 'Avg. Open',
  liq_price: 'Liq. Price',
  unreal_pnl: 'Unreal. PnL',
  daily_real: 'Daily Real',
  market_price: 'Giá thị trường',
  NEW: 'Mới',
  PARTIAL_FILLED: 'Đã thực hiện một phần',
  limit_price: 'Giá giới hạn',
  unsettled_pnl: 'Lãi/lỗ chưa thanh toán',
  notional: 'Giá trị',
  assets: 'Tài sản',
  limit_orderly: 'Giới hạn',
  'qty.': 'Số lượng',
  no_orders_found: 'Không tìm thấy lệnh',
  no_records_found: 'Không tìm thấy hồ sơ',
  its_empty: 'Nó trống!',
  current_orderly: 'Hiện tại',
  cancel_order: 'Hủy Lệnh',
  post_only: 'Chỉ Đăng Bài',
  fok: 'FOK',
  ioc: 'IOC',
  instrument: 'Công cụ',
  open_order_detail: 'Chi tiết Lệnh Mở',
  history_order_detail: 'Chi tiết Lịch sử Lệnh',
  select_orderly: 'Chọn ',
  change_orderly: 'Thay đổi',
  token_orderly: 'Token',
  user_foot_tip:
    '* Trang Orderbook này là giao diện đồ họa cho giao dịch trên Orderly Network và được cung cấp như một tiện ích cho người dùng của Ref Finance.',
  learn_more_orderly: 'Tìm hiểu thêm',
  more_order_book_page_detail:
    'Trang Orderbook này được cung cấp bởi Orderly Network. Người dùng được khuyến khích mạnh mẽ thực hiện nghiên cứu của riêng mình trước khi kết nối ví và/hoặc đặt lệnh giao dịch. Ref Finance không đảm bảo tính bảo mật của các hệ thống, hợp đồng thông minh và bất kỳ tài sản nào được gửi hoặc gửi đến các hệ thống và hợp đồng đó. Cả Ref Finance và Orderly Network đều không chịu trách nhiệm về bất kỳ lợi nhuận hoặc thua lỗ nào mà người dùng có thể đạt được thông qua trang Orderbook này.',
  learn_more_about: 'Tìm hiểu thêm về',
  learn_more_about_zh: ' ',
  by_click_confirm:
    'Bằng cách nhấp vào "Xác nhận", bạn xác nhận rằng bạn đã đọc và hiểu rõ nội dung đã nêu ở trên.',
  connect_to_orderly_account:
    'Bạn cần (re)kết nối tài khoản Orderly để sử dụng Orderbook của Ref.',
  first_register_orderly_tip:
    'Ví của bạn phải được đăng ký trước với Orderly để sử dụng Orderbook.',
  must_register_tip:
    'Ví của bạn phải được đăng ký với Orderly để giao dịch trên hệ thống của họ.',
  register_deposit_tip: 'Đăng ký sẽ yêu cầu một khoản tiền gửi lưu trữ.',
  increase_storage_deposit:
    'Bạn có thể cần tăng khoản tiền gửi lưu trữ trên tài khoản Orderly của mình.',
  deposit_storage_fee: 'Phí tiền gửi lưu trữ',
  register_orderly_account: 'Đăng ký Tài khoản Orderly',
  register: 'Đăng ký',
  connect_to_orderly: 'Kết nối với Orderly',
  order_cancelled: 'Lệnh đã được hủy.',
  order_edit_success: 'Lệnh đã được chỉnh sửa thành công!',
  created_orderly: 'Đã tạo',
  open_orderly: 'Mở',
  dex: 'Dex',
  min_price_should_be_higher_than_or_equal_to:
    'Giá tối thiểu phải cao hơn hoặc bằng',
  price_should_be_lower_than_or_equal_to: 'Giá phải thấp hơn hoặc bằng',
  price_should_be_a_multiple_of: 'Giá phải là bội số của',
  price_should_be_greater_than_or_equal_to: 'Giá phải lớn hơn hoặc bằng',
  the_order_value_should_be_be_greater_than_or_equal_to:
    'Giá trị lệnh phải lớn hơn hoặc bằng',
  quantity_to_buy_should_be_greater_than_or_equal_to:
    'Số lượng mua phải lớn hơn hoặc bằng',
  quantity_to_sell_should_be_greater_than_or_equal_to:
    'Số lượng bán phải lớn hơn hoặc bằng',
  quantity_to_buy_should_be_less_than_or_equal_to:
    'Số lượng mua phải nhỏ hơn hoặc bằng',
  quantity_to_sell_should_be_less_than_or_equal_to:
    'Số lượng bán phải nhỏ hơn hoặc bằng',
  quantity_should_be_a_multiple_of: 'Số lượng phải là bội số của',
  at_least_one_of_order_quantity_or_price_has_to_be_changed:
    'Ít nhất một trong số số lượng hoặc giá lệnh phải được thay đổi',
  quantity_should_be_higher_than: 'Số lượng phải lớn hơn',
  price_should_be_higher_than: 'Giá phải lớn hơn',
  insufficient_en: 'Không đủ',
  the_all_data_orderly_tip:
    'Dữ liệu được cung cấp ở đây bao gồm tất cả tài sản và ghi chép trong tài khoản của bạn, không giới hạn trong việc tạo ra thông qua REF.',
  at_orderly: 'tại',
  change_the_quantity_of: 'Thay đổi số lượng của',
  change_the_price_of: 'Thay đổi giá của',
  market_order_filled: 'Lệnh thị trường đã được thực hiện',
  limit_order_created: 'Lệnh giới hạn đã được tạo',
  limit_order_filled: 'Lệnh giới hạn đã được thực hiện',
  open: 'Mở',
  to_buy: 'Mua',
  to_sell: 'Bán',
  in_open_orders: 'In Open Orders',
  your_investment: 'Đầu tư của bạn',
  your_investment_tip:
    'Giá trị USD của đầu tư của bạn trên các dòng dự trữ Ref: Dòng cổ điển + Dòng DCL (bao gồm đã đặt cược trong các trang trại) + xREF',
  classic_pools: 'Dòng cổ điển',
  xref_staking: 'Staking xREF',
  token_balances: 'Số dư Token',
  unclaimed_earnings: 'Lợi nhuận chưa được nhận',
  unclaimed_earnings_tip:
    'Giá trị USD của các khoản phí chưa được nhận từ các dòng DCL và phần thưởng trang trại chưa được nhận.',
  unclaimed_pool_fees: 'Phí trang trại chưa được nhận',
  unclaimed_farm_rewards: 'Phần thưởng trang trại chưa được nhận',
  active_orders: 'Lệnh hoạt động',
  yield_farming: 'farming sinh lời',
  execute_status: 'Trạng thái thực hiện',
  your_liquidity_usd_value: 'Giá trị thanh khoản của bạn (theo USD)',
  usage: 'Sử dụng',
  holding: 'Giữ',
  your_lp_tokens_and_shares: "Token LP của bạn (Phần')",
  usd_value_staked: 'Giá trị USD đặt cược',
  instant_swap: 'Trao đổi tức thì',
  swapped: 'Đã trao đổi',
  created_s: 'đã tạo',
  order_progress: 'Tiến độ đặt hàng',
  dcl_pool: 'Dòng DCL',
  classic: 'Cổ điển',
  classic_farms: 'Trang trại cổ điển',
  unstaked: 'Chưa đặt cược',
  staked_in_farm: 'Đã đặt cược trong trang trại',
  farm_reward_range: 'Phạm vi phần thưởng trang trại',
  your_positions: 'Vị trí của bạn',
  your_price_range: 'Phạm vi giá của bạn',
  locked_in: 'đã khóa trong',
  near_locked_in_wallet_for_covering:
    '0.5 NEAR bị khóa trong ví để bù lệ phí giao dịch',
  account_assets: 'Tài sản tài khoản',
  open_qty: 'Số lượng mở',
  actions_orderly: 'Hành động',
  records: 'Bản ghi',
  buy_pop: 'Mua',
  sell_pop: 'Bán',
  fee_orderly: 'Phí',
  executing: 'Đang thực hiện',
  from_2: 'từ',
  to_2: 'đến',
  your_orders_2: 'Các lệnh của bạn',
  in_farm_2: 'trong trang trại',
  your_liquidity_2: 'Thanh khoản của bạn',
  welcome: 'Chào mừng',
  welcome_mobile: 'Kết nối ví của bạn để bắt đầu',
  select_token_orderly: 'Chọn Token',
  overview: 'Tổng quan',
  positions_2: 'Vị trí',
  'deposit_near_tip_0.5':
    'Để gửi tiền, hãy giữ ít nhất 0.5 NEAR để đảm bảo chi phí gas',
  trade: 'Giao dịch',
  earn_2: 'Kiếm',
  portfolio: 'Tài sản',
  more_2: 'Thêm',
  liquidity_pools_2: 'Các Dòng thanh khoản',
  business_inquiries: 'Liên hệ Kinh doanh',
  stable_pools: 'Dòng ổn định',
  pool_detail: 'Chi tiết Dòng',
  new_contract_deploy_tip:
    'Một hợp đồng mới đã được triển khai! Vui lòng rút thanh khoản của bạn khỏi hợp đồng cũ',
  remove_will_automatically_claim:
    'Việc rút thanh khoản sẽ tự động nhận phí chưa nhận của bạn.',
  legacy_tip: 'Tìm thấy LP token chưa đặt cược hoặc phần thưởng trong',
  legacy_farms: 'Trang trại cũ',
  all_farms: 'Tất cả trang trại',
  your_farms_2: 'Trang trại của bạn',
  dcl_farms: 'Trang trại DCL',
  farm_type: 'Loại trang trại',
  farm_2: 'Trang trại',
  to_claim: 'Nhận',
  liquidity_staked: 'Thanh khoản đã đặt cược',
  dcl_pool_detail: 'Chi tiết Dòng DCL',
  reward_range: 'Phạm vi phần thưởng',
  reward_range_tip: 'Phần thưởng trang trại trong phạm vi này',
  ending_soon: 'Sắp kết thúc',
  farm_ended_tip: 'Farm đã kết thúc.',
  farm_will_ended_tip: 'Farm sẽ kết thúc sớm.',
  new_farm: 'Farm mới',
  is_coming: 'đang sắp tới',
  est_apr: 'APR Ước tính',
  state_2: 'Trạng thái',
  farming: 'Đang farm',
  unavailable: 'Không khả dụng',
  liquidity_detail: 'Chi tiết Thanh khoản',
  hide: 'Ẩn',
  show: 'Hiện',
  unavailable_positions: 'vị trí không khả dụng',
  your_price_range_tip: 'Phạm vi giá của bạn nằm ngoài phạm vi thưởng',
  position_has_staked_tip: 'Vị trí này đã được đặt cược trong một farm khác',
  minimum_tip: 'Số tiền tối thiểu để đặt cược là',
  your_liquidity_3: 'thanh khoản của bạn',
  position_has_staked_pre: 'Vị trí này đã được đặt cược trong',
  another_farm: 'một farm khác',
  add_position: 'Thêm Vị trí',
  full_range: 'Toàn bộ phạm vi',
  your: 'Của bạn',
  max_apr: 'APR Tối đa',
  faming_positions: 'Vị trí đang farming',
  you_can_earn_tip:
    'Bạn có thể kiếm phần thưởng bằng cách farming, ước tính APR là',
  you_can_earn_current_tip:
    'farm bạn đang đặt cược đã kết thúc, và farm mới đang đến, ước tính APR là',
  go_new_farm: 'Đi đến farm mới',
  go_farm: 'Đi đến farm',
  earn_rewards: 'Bạn có thể kiếm phần thưởng',
  est_apr_is: 'APR ước tính là',
  new_farm_2: 'farm mới',
  you_can_earn_current_pre_tip: 'farm bạn đang đặt cược đã kết thúc và',
  in_ended_farm: 'trong farm đã kết thúc',
  staked: 'Đã đặt cược',
  in_farm_3: 'trong farm',
  estimation: 'Ước tính Giá trị',
  no_dcl_position_tip:
    "Hiện tại bạn không có bất kỳ vị trí Thanh khoản DCL nào, nhấp vào 'Thêm Vị trí' để bắt đầu farming.",
  price_on_slot_tip: 'Giá cần nằm trong một khe gần nhất.',
  position_will_appear_here: 'Vị trí thanh khoản của bạn sẽ xuất hiện ở đây.',
  by_farming: 'bằng cách farming',
  this_staked_tip: 'NFT này đã được đặt cược',
  dcl_liquidity: 'Thanh khoản DCL',
  classic_liquidity: 'Thanh khoản Cổ điển',
  will_appear_here_tip: 'Vị trí thanh khoản của bạn sẽ xuất hiện ở đây.',
  dcl_will_appear_here_tip:
    'Vị trí thanh khoản DCL của bạn sẽ xuất hiện ở đây.',
  classic_will_appear_here_tip:
    'Vị trí thanh khoản Cổ điển của bạn sẽ xuất hiện ở đây.',
  yield_farming_appear_here_tip: 'farming thu nhập của bạn sẽ xuất hiện ở đây.',
  active_order_appear_here_tip:
    'Lệnh đang hoạt động của bạn sẽ xuất hiện ở đây.',
  account_appear_here_tip: 'Tài sản ví/tài khoản của bạn sẽ xuất hiện ở đây.',
  farm_state: 'Trạng thái farm',
  farm_detail: 'Chi tiết farm',
  unstaked_2: 'Rút cược',
  unstaked_positions: 'Vị trí Rút cược',
  price_range_overlap_ratio: 'Tỷ lệ Phạm vi Giá chồng chéo',
  swapped_history: 'lịch sử trao đổi',
  real_time_executed_orders: 'lệnh thực hiện thời gian thực',
  real_time_executed_orders_tip:
    'Khi đặt một lệnh bán với giá cao hơn giá thị trường hoặc lệnh mua với giá thấp hơn giá thị trường bằng chức năng lệnh giới hạn, Ref sẽ cố gắng thực hiện hành động trao đổi ngừng điểm trước tiên, và nếu còn bất kỳ số lượng nào, một lệnh giới hạn sẽ được tạo ra để điền vào phần còn lại. Lệnh thực hiện thời gian thực liên quan đến lệnh được đặt bằng chức năng lệnh giới hạn nhưng được thực hiện thông qua giao dịch trao đổi. Trong phần "Lịch sử", bạn có thể xem các lệnh thực hiện thời gian thực giữa lệnh giới hạn hiển thị sớm nhất trong Lịch sử đến thời điểm hiện tại.',
  swapped_history_order: 'Đã trao đổi',
  ledger_guide_tip_1:
    'Để sử dụng ví Ledger của bạn cho các giao dịch một cách thành công, tính năng ',
  ledger_guide_tip_2: 'đã được kích hoạt tự động.',
  ledger_guide_tip_3: 'Vui lòng lưu ý',
  ledger_guide_tip_quote: '"',
  support_ledger_tip_new: '"Hỗ trợ Ledger"',
  ledger_guide_tip_4:
    'rằng do hạn chế của ví Ledger, tỷ lệ hiện tại được cung cấp bởi chức năng trao đổi',
  ledger_guide_tip_5: 'có thể không phải là giá tốt nhất.',
  ledger_guide_tip_6: '.',
  view_dcl_pool: 'Xem DCL Pool',
  your_trade_route: 'Lộ trình Giao dịch của bạn',
  markets: 'Thị trường',
  exchanges: 'Sàn giao dịch',
  output_est: 'Đầu ra (ước tính)',
  go_to_orderbook: 'Đi đến Orderbook',
  new_swap_order_tip:
    'Giá được cung cấp bởi ứng dụng AMM của Ref và chỉ để tham khảo. Không có đảm bảo rằng lệnh giới hạn của bạn sẽ được điền ngay lập tức.',
  instants_swap: 'Trao đổi Ngay lập tức',
  swappped: 'Đã trao đổi',
  claimed: 'Đã yêu cầu',
  executing_capital: 'Đang thực hiện',
  record: 'Hồ sơ',
  trade_route: 'Lộ trình Giao dịch',
  settings: 'Cài đặt',
  cross_chain_options: 'Tùy chọn Liên chuỗi',
  no_trade_routes: 'Không có Lộ trình Giao dịch nào',
  steps_in_the_route: 'Bước trong Lộ trình',
  swap_chart_no_data: 'Không đủ dữ liệu cho biểu đồ lúc này.',
  go_to_orderbook_tip:
    'Giá này chỉ để tham khảo. {br} Vui lòng tiếp tục đến <strong>Orderbook</strong> để đặt lệnh.',
  last_updated: 'Cập nhật lần cuối',
  has_tri_pool_tip:
    ' trong Ref. Bật Trisolaris trong Tùy chọn Liên chuỗi để tìm thêm.',
  Overview: 'Tổng quan',
  RefFinance: 'Ref.finance',
  Orderly: 'Orderly',
  Burrow: 'Burrow',
  Supplied: 'Được cung cấp',
  Borrowed: 'Được mượn',
  NetAPY: 'Net APY',
  UnclaimedRewards: 'Phần thưởng chưa được yêu cầu',
  YouSupplied: 'Bạn đã cung cấp',
  SupplyMarket: 'Thị trường cung cấp',
  YouBorrowed: 'Bạn đã mượn',
  BorrowMarket: 'Thị trường vay',
  NetAPYTip:
    'Net APY của tất cả vị trí cung cấp và vay cấp, bao gồm cả APY cơ bản và khuyến mãi',
  Market: 'Thị trường',
  TotalSupplied: 'Tổng cung cấp',
  TotalBorrowed: 'Tổng vay',
  AvailableLiquidities: 'Khả năng thanh khoản có sẵn',
  DailyRewards: 'Phần thưởng hàng ngày',
  SupplyAPY: 'APY cung cấp',
  BorrowAPY: 'APY vay',
  Collateral: 'Tài sản đảm bảo',
  Adjust: 'Điều chỉnh',
  Supply: 'Cung cấp',
  Repay: 'Trả nợ',
  Borrow: 'Vay',
  HealthFactor: 'Yếu tố sức khỏe',
  CollateralFactor: 'Tỷ lệ Tài sản đảm bảo',
  AdjustCollateral: 'Điều chỉnh Tài sản đảm bảo',
  UseAsCollateral: 'Sử dụng như Tài sản đảm bảo',
  RemainingCollateral: 'Tài sản đảm bảo còn lại',
  RemainingBorrow: 'Nợ còn lại',
  NoSupplyTip: 'Tài sản đã cung cấp của bạn sẽ xuất hiện ở đây.',
  NoBorrowTip: 'Tài sản đã vay của bạn sẽ xuất hiện ở đây.',
  APY: 'APY',
  CF: 'C.F.',
  burrowErrorTip:
    'Số dư hiện tại thấp hơn số thập phân tối thiểu của token, vì vậy không thể được xử lý bởi hợp đồng.',
  healthErrorTip:
    'Yếu tố sức khỏe của bạn sẽ rất thấp và bạn có nguy cơ bị thanh lý.',
  Summary: 'Tóm tắt',
  Welcome: 'Chào mừng!',
  ConnectView: 'Kết nối ví của bạn để xem',
  TotalSuppliedMarket: 'Tổng cung cấp',
  NetWorth: 'Giá trị ròng',
  Claimable: 'Có thể yêu cầu',
  TotalDebts: 'Tổng nợ',
  WalletAssets: 'Tài sản Ví',
  TotalInvested: 'Tổng đầu tư',
  TotalAssets: 'Tổng tài sản',
  TokenAllocation: 'Phân bổ Token',
  perpTip:
    'Chào mừng bạn đến với giao dịch Hợp đồng tương lai vĩnh viễn! Vui lòng đọc {perpTipLink} để bắt đầu!',
  perpTipLink: '[Giới thiệu Hợp đồng tương lai vĩnh viễn]',
  perpetual: 'Perpetual',
  pred_funding_rate: 'Tỷ lệ Dự định đòn bẩy',
  open_interest: 'Lãi ngầm cửa mở',
  bridge_pure: 'Cầu nối',
  pool_refresh: 'Có sự cố với máy chủ, vui lòng thử lại sau.',
  netWorthTip:
    'Tổng giá trị của đầu tư trong Ref (bao gồm phần thưởng có thể yêu cầu) + Tổng giá trị tài sản Orderly + Tổng giá trị tài sản Burrow + Tổng tài sản trong ví',
  top_bin_apr: 'Top Bin APR (24h)',
  mark_price: 'Giá đánh dấu',
  last_price: 'Giá cuối cùng',
  mark_price_tip: 'Giá đánh dấu được sử dụng để tính toán PnL và thanh lý.',
  total_collateral: 'Tổng tài sản đảm bảo',

  free_collateral: 'Tài sản đảm bảo tự do',
  total_upnl: 'Tổng uPnL',
  perps: 'Perps',
  margin_ratio: 'Tỷ lệ Đòn bẩy',
  total_npnl_tip:
    'Lợi nhuận và lỗ chưa thực hiện ước tính trên tất cả các vị trí mở',
  low_risk: 'Rủi ro thấp',
  mid_risk: 'Rủi ro trung bình',
  high_risk: 'Rủi ro cao',
  liquidations: 'Thanh lý',
  leverage_max_leverage: 'Đòn bẩy tài khoản tối đa',
  leverage_max_leverage_raw: 'Đòn bẩy tài khoản tối đa',

  marker_taker_fee_rate: 'Tỷ lệ Phí Đặt lệnh/Tạo lệnh',
  liquidation_price: 'Giá thanh lý',
  margin_required: 'Yêu cầu Tiền đảm bảo',
  perp_buy_limit_order_range: 'Giá lệnh giới hạn mua phải nhỏ hơn hoặc bằng',
  perp_sell_limit_order_range: 'Giá lệnh giới hạn bán phải lớn hơn hoặc bằng',
  perp_buy_limit_order_scope: 'Giá lệnh giới hạn mua không thể thấp hơn',
  perp_sell_limit_order_scope: 'Giá lệnh giới hạn bán không thể cao hơn',
  liquidations_fee: 'Phí thanh lý',
  more_trading_price: 'Giá giao dịch thêm',
  unsettle_pnl_tip:
    'Chuyển lợi nhuận hoặc lỗ từ thị trường hợp đồng tương lai vào số dư token USDC. Điều này không ảnh hưởng đến các vị trí mở hoặc tình trạng sức khỏe của bạn.',
  margin_ratio_tip:
    'Tỷ lệ đòn bẩy của một tài khoản bằng tổng giá trị tài sản đảm bảo (tức số dư USDC + bất kỳ lợi nhuận/lỗ chưa thực hiện nào) chia cho tổng giá trị không gian mở của tài khoản (tổng tổng giá trị không gian tuyệt đối của tất cả các vị trí)',
  portfolio_total_est_tip: 'Giá trị tổng hợp trong USD của tài sản.',
  size: 'Kích thước',
  closing_1: 'Đóng',
  closing_2: 'vị trí với giá {type}.',
  buy_long: 'Mua / Dài',
  sell_short: 'Bán / Ngắn',
  unsettle_pnl: 'Unsettle PnL',
  the_margin_will_be_insufficient: 'Tài khoản sẽ không đủ tiền',
  exceed_rate_limit:
    'Bạn đã vượt quá hạn mức tốc độ, vui lòng thử lại sau 60 giây',
  est_liquidation_price: 'Giá thanh lý ước tính',
  liquidation_price_tip:
    'Giá này chỉ để tham khảo. Bạn có thể thấy giá thanh lý trong danh mục Orderly của bạn sau khi đơn hàng của bạn được điền.',
  futures_leverage_saved: 'Lưu đòn bẩy tương lai',
  maintenance_margin_ratio: 'Tỷ lệ Biên bảo trì',
  liquidation_fee: 'Phí thanh lý',
  ins_fund_transfer: 'Chuyển quỹ Ins.',
  no_liquidation_yet: 'Chưa có thanh lý nào',
  funding_fee: 'Phí Dự định',
  settlements: 'Định cư',
  funding_annual_rate: 'Tỷ lệ Dự định / Tỷ lệ hàng năm',
  more_expensive_than_best_rate_zh_cn: '',
  admin_sentence_1:
    'Ref Finance được quản lý bởi <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near">Ref Finance Sputnik DAO</a> và sẽ dần chuyển sang một hình thức hoàn toàn phi tập trung.',
  admin_sentence_2:
    'Để biết thêm thông tin liên quan đến hợp đồng và địa chỉ đã quản lý trực tiếp hoặc đang quản lý công việc của Ref Finance, vui lòng kiểm tra <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/contracts">Tài liệu</a> của chúng tôi.',
  insufficient_margin: 'Insufficient Margin',
  taker_maker_fee: 'Taker/Maker Fee',
  funding: 'Funding',
  taker_maker_fee_rate: 'Taker/Maker Fee Rate',
  spot_nav: 'Spot',
  perps_nav: 'Perps',
  set: 'Set',
  max_leverage_tip:
    'Max account leverage cannot be lower than your current leverage.',
  to_begin_your_trading_journey: 'để bắt đầu hành trình giao dịch của bạn',

  orderly_new_usr_spot_tip:
    'Gửi tài sản để bắt đầu hành trình giao dịch của bạn.',
  orderly_new_user_tip:
    'Kiểm tra số dư và gửi <strong>USDC</strong> để bắt đầu hành trình giao dịch của bạn.',
  deposit_on_tip: 'Gửi',
  display_all_orders_dexes:
    'Display orders placed through all channels on Orderly.',
};
export default Object.assign(vi, vi_in_risks_page);
