import React from 'react';

export const WatchListStartEmpty = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="watchlist-star" stroke="#7B8A94">
          <path
            d="M9.83942653,1.78603677 C10.3198892,1.85984854 10.3997029,1.9396623 10.448368,2.03826861 L10.448368,2.03826861 L12.6068935,6.41191891 L17.4335032,7.11326635 C17.5701394,7.13312075 17.6857927,7.20647831 17.7623233,7.30903132 C17.838854,7.41158434 17.8762619,7.5433328 17.8564075,7.67996902 C17.8405953,7.78878753 17.7893517,7.88935874 17.7106099,7.96611304 L17.7106099,7.96611304 L14.2180423,11.370525 L15.0425257,16.1776319 C15.0658659,16.313716 15.0318376,16.4463775 14.9579531,16.5508531 C14.8840687,16.6553287 14.7703281,16.7316183 14.634244,16.7549586 C14.5258652,16.773547 14.4143812,16.7558897 14.3170509,16.7047201 L14.3170509,16.7047201 L10,14.4351121 L5.68294907,16.7047201 C5.56073792,16.7689703 5.42405395,16.7776021 5.30186022,16.7396186 C5.17966649,16.701635 5.07196299,16.6170361 5.00771279,16.494825 C4.95654326,16.3974947 4.93888592,16.2860107 4.95747434,16.1776319 L4.95747434,16.1776319 L5.7819577,11.370525 L2.28939013,7.96611304 C2.19051924,7.86973774 2.14007225,7.74241093 2.13843682,7.61446022 C2.13680138,7.48650951 2.18397749,7.35793491 2.2803528,7.25906401 C2.3571071,7.18032219 2.4576783,7.12907861 2.56649682,7.11326635 L2.56649682,7.11326635 L7.3931065,6.41191891 L9.55163196,2.03826861 C9.61273753,1.91445519 9.71824355,1.82713111 9.83942653,1.78603677 Z"
            id="star"
          />
        </g>
      </g>
    </svg>
  );
};

export const WatchListStartFull = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="页面-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="add-watchlist-star" fill="#00C6A2" stroke="#00C6A2">
          <path
            d="M9.83942653,1.78603677 C10.3198892,1.85984854 10.3997029,1.9396623 10.448368,2.03826861 L10.448368,2.03826861 L12.6068935,6.41191891 L17.4335032,7.11326635 C17.5701394,7.13312075 17.6857927,7.20647831 17.7623233,7.30903132 C17.838854,7.41158434 17.8762619,7.5433328 17.8564075,7.67996902 C17.8405953,7.78878753 17.7893517,7.88935874 17.7106099,7.96611304 L17.7106099,7.96611304 L14.2180423,11.370525 L15.0425257,16.1776319 C15.0658659,16.313716 15.0318376,16.4463775 14.9579531,16.5508531 C14.8840687,16.6553287 14.7703281,16.7316183 14.634244,16.7549586 C14.5258652,16.773547 14.4143812,16.7558897 14.3170509,16.7047201 L14.3170509,16.7047201 L10,14.4351121 L5.68294907,16.7047201 C5.56073792,16.7689703 5.42405395,16.7776021 5.30186022,16.7396186 C5.17966649,16.701635 5.07196299,16.6170361 5.00771279,16.494825 C4.95654326,16.3974947 4.93888592,16.2860107 4.95747434,16.1776319 L4.95747434,16.1776319 L5.7819577,11.370525 L2.28939013,7.96611304 C2.19051924,7.86973774 2.14007225,7.74241093 2.13843682,7.61446022 C2.13680138,7.48650951 2.18397749,7.35793491 2.2803528,7.25906401 C2.3571071,7.18032219 2.4576783,7.12907861 2.56649682,7.11326635 L2.56649682,7.11326635 L7.3931065,6.41191891 L9.55163196,2.03826861 C9.61273753,1.91445519 9.71824355,1.82713111 9.83942653,1.78603677 Z"
            id="star_bak"
          />
        </g>
      </g>
    </svg>
  );
};

export const WatchListStartEmptyMobile = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="watchlist-star-mobie" stroke="#7B8A94">
          <path
            d="M9.83942653,1.78603677 C10.3198892,1.85984854 10.3997029,1.9396623 10.448368,2.03826861 L10.448368,2.03826861 L12.6068935,6.41191891 L17.4335032,7.11326635 C17.5701394,7.13312075 17.6857927,7.20647831 17.7623233,7.30903132 C17.838854,7.41158434 17.8762619,7.5433328 17.8564075,7.67996902 C17.8405953,7.78878753 17.7893517,7.88935874 17.7106099,7.96611304 L17.7106099,7.96611304 L14.2180423,11.370525 L15.0425257,16.1776319 C15.0658659,16.313716 15.0318376,16.4463775 14.9579531,16.5508531 C14.8840687,16.6553287 14.7703281,16.7316183 14.634244,16.7549586 C14.5258652,16.773547 14.4143812,16.7558897 14.3170509,16.7047201 L14.3170509,16.7047201 L10,14.4351121 L5.68294907,16.7047201 C5.56073792,16.7689703 5.42405395,16.7776021 5.30186022,16.7396186 C5.17966649,16.701635 5.07196299,16.6170361 5.00771279,16.494825 C4.95654326,16.3974947 4.93888592,16.2860107 4.95747434,16.1776319 L4.95747434,16.1776319 L5.7819577,11.370525 L2.28939013,7.96611304 C2.19051924,7.86973774 2.14007225,7.74241093 2.13843682,7.61446022 C2.13680138,7.48650951 2.18397749,7.35793491 2.2803528,7.25906401 C2.3571071,7.18032219 2.4576783,7.12907861 2.56649682,7.11326635 L2.56649682,7.11326635 L7.3931065,6.41191891 L9.55163196,2.03826861 C9.61273753,1.91445519 9.71824355,1.82713111 9.83942653,1.78603677 Z"
            id="star_mobile"
          />
        </g>
      </g>
    </svg>
  );
};

export const WatchListStartFullMobile = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="页面-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="add-watchlist-star-mobile" fill="#00C6A2" stroke="#00C6A2">
          <path
            d="M9.83942653,1.78603677 C10.3198892,1.85984854 10.3997029,1.9396623 10.448368,2.03826861 L10.448368,2.03826861 L12.6068935,6.41191891 L17.4335032,7.11326635 C17.5701394,7.13312075 17.6857927,7.20647831 17.7623233,7.30903132 C17.838854,7.41158434 17.8762619,7.5433328 17.8564075,7.67996902 C17.8405953,7.78878753 17.7893517,7.88935874 17.7106099,7.96611304 L17.7106099,7.96611304 L14.2180423,11.370525 L15.0425257,16.1776319 C15.0658659,16.313716 15.0318376,16.4463775 14.9579531,16.5508531 C14.8840687,16.6553287 14.7703281,16.7316183 14.634244,16.7549586 C14.5258652,16.773547 14.4143812,16.7558897 14.3170509,16.7047201 L14.3170509,16.7047201 L10,14.4351121 L5.68294907,16.7047201 C5.56073792,16.7689703 5.42405395,16.7776021 5.30186022,16.7396186 C5.17966649,16.701635 5.07196299,16.6170361 5.00771279,16.494825 C4.95654326,16.3974947 4.93888592,16.2860107 4.95747434,16.1776319 L4.95747434,16.1776319 L5.7819577,11.370525 L2.28939013,7.96611304 C2.19051924,7.86973774 2.14007225,7.74241093 2.13843682,7.61446022 C2.13680138,7.48650951 2.18397749,7.35793491 2.2803528,7.25906401 C2.3571071,7.18032219 2.4576783,7.12907861 2.56649682,7.11326635 L2.56649682,7.11326635 L7.3931065,6.41191891 L9.55163196,2.03826861 C9.61273753,1.91445519 9.71824355,1.82713111 9.83942653,1.78603677 Z"
            id="star_bak_mobile"
          />
        </g>
      </g>
    </svg>
  );
};

export const StartPoolIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: 'scale(1.5)',
      }}
    >
      <path
        d="M5.52057 3.61956C5.6619 3.14213 6.3381 3.14213 6.47943 3.61956L7.55633 7.25741C7.59715 7.39529 7.69535 7.50892 7.82586 7.56929L11.0189 9.04619C11.4057 9.2251 11.4057 9.7749 11.0189 9.95381L7.82586 11.4307C7.69535 11.4911 7.59715 11.6047 7.55633 11.7426L6.47943 15.3804C6.3381 15.8579 5.6619 15.8579 5.52057 15.3804L4.44367 11.7426C4.40285 11.6047 4.30465 11.4911 4.17414 11.4307L0.981115 9.95381C0.594321 9.7749 0.59432 9.2251 0.981115 9.04619L4.17414 7.56929C4.30465 7.50892 4.40285 7.39529 4.44367 7.25741L5.52057 3.61956Z"
        fill="#00FFF1"
      />
      <path
        d="M12.0195 1.66953C12.1583 1.18725 12.8417 1.18724 12.9805 1.66953L13.383 3.06814C13.4226 3.20578 13.5194 3.31982 13.6487 3.38136L15.051 4.04849C15.4312 4.2294 15.4312 4.7706 15.051 4.95151L13.6487 5.61864C13.5194 5.68018 13.4226 5.79422 13.383 5.93186L12.9805 7.33047C12.8417 7.81275 12.1583 7.81276 12.0195 7.33047L11.617 5.93186C11.5774 5.79422 11.4806 5.68018 11.3513 5.61864L9.94903 4.95151C9.56877 4.7706 9.56877 4.2294 9.94903 4.04849L11.3513 3.38136C11.4806 3.31982 11.5774 3.20578 11.617 3.06814L12.0195 1.66953Z"
        fill="#00FFF1"
      />
    </svg>
  );
};
