import React from 'react';

export const FarmMiningIcon = (props: any & { color: string }) => {
  const { color } = props;

  return (
    <svg
      width={props.w || '25'}
      height={props.h || '23'}
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6526 0.342393C12.7175 -0.281913 11.4533 -0.029946 10.829 0.905178L10.3597 1.60824C9.24838 1.1106 8.05856 0.756933 6.81394 0.570958L4.51309 4.71265C5.64563 4.83927 6.73637 5.10418 7.76805 5.49012L0.342881 16.612C-0.281425 17.5471 -0.0294572 18.8113 0.905667 19.4356C1.84079 20.0599 3.10496 19.8079 3.72926 18.8728L11.3362 7.47865C13.2725 8.97606 14.7961 10.9814 15.7072 13.295H18.4296C18.2111 9.517 16.4591 6.15032 13.7868 3.80804L14.2154 3.16599C14.8397 2.23087 14.5878 0.966699 13.6526 0.342393Z"
        fill={color ? color : '#00C6A2'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1185 21.981C20.5094 21.3203 21.471 19.9026 21.471 18.2603C21.471 16.618 20.5094 15.2003 19.1185 14.5395C19.6533 14.2855 20.2515 14.1433 20.883 14.1433C23.1567 14.1433 24.9999 15.9865 24.9999 18.2603C24.9999 20.534 23.1567 22.3772 20.883 22.3772C20.2515 22.3772 19.6533 22.2351 19.1185 21.981Z"
        fill={color ? color : '#00C6A2'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4135 21.9808C15.8044 21.3201 16.7661 19.9024 16.7661 18.26C16.7661 16.6177 15.8044 15.1999 14.4135 14.5392C14.9482 14.2852 15.5464 14.1431 16.1777 14.1431C18.4515 14.1431 20.2947 15.9863 20.2947 18.26C20.2947 20.5338 18.4515 22.377 16.1777 22.377C15.5464 22.377 14.9482 22.2349 14.4135 21.9808Z"
        fill={color ? color : '#00C6A2'}
      />
      <circle
        cx="11.4276"
        cy="18.2145"
        r="4.07172"
        fill={color ? color : '#00C6A2'}
      />
    </svg>
  );
};
