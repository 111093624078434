const ru_in_risks_page = {
  risks: 'Риски',
  of_using_ref_finance: 'при использовании Ref Finance',
  introduction:
    'Предоставление ликвидности и/или торговля на Ref Finance не обходятся без рисков. Прежде чем взаимодействовать с протоколом, пожалуйста, проведите собственное расследование и осознайте все риски.',
  general_risks_quiz: 'Викторина "Общие риски"',
  amm_core_design: 'Проект ядра AMM',
  audits: 'Аудиты',
  audits_paragraph_2:
    'Аудит безопасности не устраняет риски полностью. Пожалуйста, не предоставляйте свои сбережения или активы, которые вы не можете позволить себе потерять, в Ref Finance, особенно в качестве поставщика ликвидности.',
  admin_keys: 'Административные ключи',
  admin_keys_paragraph_2:
    'В настоящее время DAO состоит из 26 членов. Ref Finance переходит к полностью децентрализованному DAO.',
  admin_keys_paragraph_3:
    'Ниже приведен список контрактов и адресов, которые непосредственно управляли или в настоящее время управляют делами Ref Finance.',
  address: 'Адрес',
  type: 'Тип',
  mission: 'Миссия',
  exchange_contract: 'Биржевой контракт',
  table_body_tr_1:
    'Управление функциями автоматизированного маркет-мейкера; обмен и обеспечение ликвидности',
  farming_contract: 'Контракт фарминга',
  table_body_tr_2: 'Управление стимулами ликвидности',
  staking_contract: 'Контракт стейкинга',
  table_body_tr_3:
    'Чеканка и сжигание xREF, а также распределение наград согласно срокам',
  sputnik_dao_contract: 'Контракт Sputnik DAO',
  table_body_tr_4:
    'Гарантия успеха Ref путем принятия стратегических решений (в т.ч. внесение изменений в смарт-контракты)',
  table_body_tr_5:
    'Управление и распределение средств среди определенных участников сообщества',
  table_body_tr_6: 'Реализация стратегии и дорожной карты',
  vesting_contract: 'Контракт вестинга',
  table_body_tr_7: 'Управление REF вестинг-контрактами для участников Dev DAO',
  airdrop_contract: 'Контракт эирдропа',
  table_body_tr_8: 'Организация первого эирдропа REF',
  near_address: 'NEAR Address',
  table_body_tr_9: 'Manage one-time bug bounty payments',
  fungible_token_contract: 'Fungible Token Contract',
  table_body_tr_10: 'Чеканка токена REF',
  rug_pull: 'Rug pull',
  rug_pull_paragraph_1:
    'Если команда, стоящая за токеном, внесенным в белый список или нет, решит отказаться от своего проекта и заберет деньги инвесторов, токен проекта, скорее всего, будет стоить 0 долларов.',
  rug_pull_paragraph_2:
    'Если токен включен в белый список Ref Finance, это не означает, что проект будет успешным. Вы несете ответственность за проведение собственной оценки проекта и должны понимать, что криптовалюты — это очень рискованные, спекулятивные инвестиции.',
  rug_pull_paragraph_3:
    'Вы должны быть осведомлены и готовы к тому, что можете частично или полностью потерять вложенные деньги.',
  divergence_loss: 'Потери от дивергенции',
  divergence_loss_paragraph_1:
    'Если вы предоставляете ликвидность, учтите, что вы можете заработать больше денег, не предоставляя ликвидность.',
  divergence_loss_paragraph_2:
    'Потери от дивергенции часто, но не совсем уместно называют "непостоянными потерями". Прилагательное (непостоянный) может предполагать или создавать маркетинговое ощущение, что потери только непостоянны, что означает, что потери гарантированно будут обращены вспять, а это не так.',
  divergence_loss_paragraph_3: 'Узнайте больше о потерях при дивергенции',
  staking_risks: 'Риски стейкинга',
  staking_risks_paragraph_1:
    'При стейкинге вы используете несколько смарт-контрактов, каждый из которых имеет свои собственные риски.',
  permanent_loss_of_a_peg: 'Перманентная потеря PEG',
  permanent_loss_of_a_peg_paragraph_1:
    'Если один из стейблкоинов в пуле опустится значительно ниже привязки 1,0 и уже никогда не сможет вернуться к ней, это будет означать, что провайдеры ликвидности пула держат почти всю свою ликвидность в этой валюте.',
  systemic_issues: 'Системные проблемы',
  systemic_issues_paragraph_1:
    'В целом, DeFi или денежные "лего" очень связаны между собой, что означает, когда отказ одного компонента может вызвать целую череду неполадок.',
  systemic_issues_paragraph_2:
    'Систематический риск означает, что вы можете потерять деньги, даже если эта ошибка не касается непосредственно вашей инвестиции/объекта.',
  systemic_issues_paragraph_3:
    'Следующие риски могут оказать влияние на пулы ликвидности:',
  systemic_issues_paragraph_4:
    'Проблемы смарт-контрактов с протоколами кредитования',
  systemic_issues_paragraph_5:
    'Проблемы смарт-контрактов с протоколами стейкинга',
  systemic_issues_paragraph_6:
    'Системные проблемы со стейблкоинами в этих пулах',
  systemic_issues_paragraph_7:
    'Системные проблемы с нативными токенами ERC20 в этих пулах',
  crypto_trading_addiction: 'Зависимость от криптотрейдинга',
  crypto_trading_addiction_paragraph_1:
    'Торговля криптовалютами может вызывать сильное привыкание и, согласно многим источникам, быть одной из форм игровой зависимости, которая может разрушать жизни.',
  crypto_trading_addiction_paragraph_2:
    'Ниже представлена подборка историй, связанных с этим вопросом.',
  crypto_trading_addiction_paragraph_3:
    "'Торговля - это азартная игра, вне всякого сомнения'",
  crypto_trading_addiction_paragraph_4:
    "'Я потерял полмиллиона фунтов, торгуя Bitcoin'",
  crypto_trading_addiction_paragraph_5:
    "'Мы беседовали с психотерапевтом, который помогает лечить зависимость от трейдинга криптовалютами'",
  crypto_trading_addiction_paragraph_6:
    "'Я потерял миллионы из-за зависимости от трейдинга криптовалютами'",
  Locked: 'Locked',
  no_in_progress: 'No (In Progress)',
  yes: 'Yes',
  no: 'No',
  NA: 'N/A',
  ref_finance_near: 'ref-finance.near',
  ref_finance_near_mission:
    'v1 (depreciated)- Manage the Automated Market Maker functions; Swap and Provide Liquidity',
  token_ref_finance_near: 'token.ref-finance.near',
  token_ref_finance_near_type: 'Fungible Token Contract',
  token_ref_finance_near_mission: 'v1 (depreciated) - Mint REF token',
  refchef_near: 'refchef.near',
  simple_address: 'Simple Address',
  refchef_near_mission: 'Manage inter-account transactions',
  ref_dev_teller_near: 'ref-dev-teller.near',
  Locked_title: 'Locked? information box: when hovering to display:',
  Locked_paragraph_1:
    'Заблокированный контракт означает, что не существует ключей доступа, позволяющих повторно развернуть код контракта.',
  Locked_paragraph_2:
    'В большинстве случаев код может быть повторно развернут:',
  Locked_paragraph_3: '1. транзакция с помощью действия deploy-code',
  Locked_paragraph_4:
    '2. сам контракт может реализовать вызов функции, которая вызовет действие deploy-code',
  Locked_paragraph_5:
    'Для того, чтобы повторно развернуть код с помощью транзакции, транзакция должна быть подписана ключом полного доступа. Если в контракте нет такого ключа, то нет возможности повторно развернуть код, если только в самом коде контракта нет специальной поддержки, и поэтому мы отмечаем такие контракты как заблокированные.',
  Locked_paragraph_6:
    'Если в учетной записи контракта зарегистрирован хотя бы один ключ полного доступа, контракт считается не заблокированным.',
  audited_first_sentence:
    'Смарт-контракты Ref Finance прошли <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/audits"' +
    '>аудит</a>.',
  admin_first_sentence:
    'Ref Finance управляется <a crel="noopener noreferrer nofollow" target="_blank" lass="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>Ref Finance Sputnik DAO</a>. Существуют <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://gov.ref.finance/t/introducing-the-guardians/253"' +
    '>Хранители</a>— это  определенные адреса NEAR, которые могут приостановить контракт. Только DAO может в любой момент возобновить контракт.',
  risks_of_using_ref_finance:
    '<label class="text-greenColor">Риски</label> использования Ref. finance',
  admin_sentence_1:
    'Ref Finance управляется <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://app.astrodao.com/dao/ref-finance.sputnik-dao.near"' +
    '>DAO Ref Finance Sputnik</a> и в ближайшее время перейдет к полноценному децентрализованному DAO.',
  admin_sentence_2:
    'Для получения дополнительной информации, касающейся контрактов и адресов, которые непосредственно управляли или в настоящее время управляют делами Ref Finance, пожалуйста, ознакомьтесь с нашей <a rel="noopener noreferrer nofollow" target="_blank" class="underline cursor-pointer text-riskTextColor hover:text-white" href="https://guide.ref.finance/developers/contracts"' +
    '>Документацией</a>.',
  third_party_wallet: 'Сторонний кошелек',
  third_party_wallet_1: 'Ref Finance интегрирует сторонние кошельки.',
  third_party_wallet_2:
    'Несмотря на то, что мы приняли меры для выбора надежных поставщиков кошельков, мы не можем гарантировать их безопасность или производительность. Вам следует ознакомиться с рисками, связанными с конкретным поставщиком кошельков, и понять их условия обслуживания, политику конфиденциальности и меры безопасности.',
  third_party_wallet_3:
    'Пожалуйста, используйте эти сервисы на свой страх и риск и соблюдайте осторожность.',
};
const ru = {
  deposit: 'Депозит',
  Deposit: 'Депозит',
  withdraw: 'Вывод',
  Withdraw: 'Вывод',
  withdraw_token: 'Вывести токен',
  swap: 'Обмен',
  Swap: 'Обмен',
  exchange_tokens: 'Токены для обмена',
  pool: 'Пул',
  Pool: 'Пул',
  pools: 'Пулы',
  Pools: 'Пулы',
  Quiz: 'Викторина',
  no_tokens_deposited: 'Нет внесенных токенов',
  view_pools: 'Обзор пулов',
  view_pool: 'Обзор пула',
  add_token: 'Добавить токен',
  Add_Token: 'Добавить токен',
  create_new_pool: 'Создать новый пул',
  Create_New_Pool: 'Создать новый пул',
  your_liquidity: 'Ваша ликвидность',
  Your_Liquidity: 'Ваша ликвидность',
  farms: 'Фермы',
  Farms: 'Фермы',
  airdrop: 'Эирдроп',
  Airdrop: 'Эирдроп',
  docs: 'Документы',
  Docs: 'Документы',
  community: 'Комьюнити',
  Community: 'Комьюнити',
  more: 'Подробнее',
  More: 'Подробнее',
  account: 'Аккаунт',
  Account: 'Аккаунт',
  New_ui: 'Новый UI',
  Risks: 'Риски',
  Forum: 'Форум',
  Discord: 'Discord',
  Telegram: 'Telegram',
  Twitter: 'Twitter',
  Medium: 'Medium',
  move_assets_to_from_ethereum: 'Перемещение активов из/в Ethereum',
  rainbow_bridge: 'Rainbow Bridge',
  deposit_to_swap: 'Депозит для обмена',
  connect_to_near: 'Подключитесь к NEAR',
  slippage: 'Допустимое проскальзывание',
  slippage_title: 'Настройки транзакции',
  minimum_received: 'Минимальная сумма, которую вы получите',
  swap_rate: 'Курс обмена',
  swap_rate_pre: 'Курс обмена',
  pool_fee: 'Комиссия пула',
  balance: 'Баланс',
  from: 'От',
  to: 'До',
  submit: 'Отправить',
  save: 'Save',
  wrapnear: 'Обернуть NEAR',
  wrapnear_tip_one:
    'Обернутые NEAR позволят вам торговать на REF. Обязательно ',
  wrapnear_tip_two: 'оставьте 0.5 NEAR',
  wrapnear_tip_three: 'на оплату газа.',
  wrap_error_msg: 'Недостаточный баланс',
  sign_out: 'Выйти',
  view_account: 'Обзор аккаунта',
  deposit_to_swap_and_add_liquidity:
    'Депозит для обмена и добавления ликвидности',
  small_storage_fee_is_applied_of:
    'Небольшая плата за хранение взимается в размере',
  liquidity: 'Ликвидность',
  tokens: 'Токены',
  fee: 'Комиссия',
  fees: 'Комиссии',
  view_detail: 'Подробная информация',
  pool_details: 'Информация о пуле',
  details: 'Подробности',
  detail: 'Подробнее',
  liquidity_pools: 'Пулы ликвидности',
  minimum_tokens_out: 'Минимальная сумма, которую вы получите',
  pair: 'Пара',
  tvl: 'TVL',
  search_pools: 'Найти Пул',
  add_liquidity: 'Добавить ликвидность',
  remove_liquidity: 'Удалить ликвидность',
  total_liquidity: 'Общая ликвидность',
  coming_soon: 'Совсем скоро',
  accumulated_volume: 'Накопленный объем',
  underlying_liquidity: 'Базовая ликвидность',
  total_shares: 'Общая доля',
  my_shares: 'Моя доля',
  token: 'Токен',
  enter_token_address: 'Введите адрес токена',
  you_are_not_providing_liquidity_to_any_pools:
    'Вы не обеспечиваете ликвидностью ни один пул.',
  remove: 'Удалить',
  select: 'Выбрать',
  select_token: 'Выбрать токен',
  basis_points: 'Базисные показатели',
  total_fee: 'Итоговая комиссия % ',
  protocol_fee_is: 'комиссия протокола составляет ',
  start_in: 'Запуск в',
  start_at: 'Запустится с',
  started_at: 'Запустился в',
  start_date: 'Дата запуска',
  end_at: 'Закончится в',
  end_date: 'Дата завершения',
  earn: 'Заработок',
  ended: 'ЗАВЕРШЕНО',
  pending: 'ОЖИДАЕТСЯ',
  pending_cap: 'Pending',
  pending_orders_title: 'Pending Close Orders',
  rewards_per_week: 'Награды за неделю',
  apr: 'APR',
  total_staked: 'Всего застейкано',
  unclaimed_rewards: 'Невостребованные награды',
  claim: 'Запросить',
  claim_all: 'Запросить все',
  stake: 'Застейкать',
  unstake: 'Забрать из стейкинга',
  your_rewards: 'Ваши награды',
  your_shares: 'Ваша доля',
  shares_owned: 'Принадлежащая доля',
  stake_your_liquidity_provider_LP_tokens:
    'Застейкайте ваши токены провайдера ликвидности (LP)',
  go_to_wallet: 'Перейти к NEAR кошельку',
  recent_activity: 'Последние действия',
  view_all: 'Просмотреть все',
  cancel: 'Отменить',
  claim_ref_token: 'Запросить токены Ref',
  has_no_available_claim: 'не доступны токены для запроса',
  sorry: 'Извините',
  total_ref_token: 'Всего токенов Ref',
  locking_ref_token: 'Заблокированные токены Ref',
  unclaim_ref_token: 'Невостребованные токены Ref',
  unlocked: 'Разблокирово',
  locking: 'Заблокировано',
  you_can_claim_from: 'Вы можете запросить с',
  you_have_claimed_all_your_rewards: 'Вы забрали все ваши награды',
  ends_in: 'Заканчивается в',
  days: 'дни',
  swap_successful_click_to_view: 'Обмен прошел успешно. Кликните для просмотра',
  claim_successful_click_to_view:
    'Запрос прошел успешно. Кликните для просмотра',
  claim_failed_click_to_view: 'Запрос не прошел. Кликните для просмотра',
  slippageCopyForMobile:
    'Проскальзывание означает разницу между тем, что вы ожидаете получить, и тем, что вы фактически получаете из-за других действий',
  slippageCopy:
    'Проскальзывание означает разницу между тем, что вы ожидаете получить, и тем,что вы фактически получаете из-за других действий',
  depositCopy:
    'В результате выбранные вами токены поступают на <br> биржу для обмена или добавления в пул ликвидности',
  nearDepositCopy:
    'Это первое обертывание, затем вы можете депонировать ваши Ⓝ на <br> бирже для обмена или добавления в пул ликвидности',
  nearWithdrawCopy:
    'Это сначала развернет ваши Ⓝ, затем снимет их с <br> биржи и переведет токены в ваш кошелек',
  withdrawCopy:
    'Это выведет выбранные вами токены с <br> биржи и зачислит их в ваш кошелек',
  registerToken:
    'Это зарегистрирует на бирже <br> новый токен, который еще не залистен',
  whitelistTokenCopy: 'Добавьте любой токен NEP-141',
  addLiquidityPoolCopy:
    'Это создаст новый пул ликвидности для двух выбранных токенов. Комиссия — это процент, который пул берет с каждой транзакции',
  swapCopy:
    'Обмен первого выбранного токена на второй <br> выбранный токен. Будет использован пул с наибольшей доступной ликвидностью и наименьшей комиссией за обмен',
  addTokenCopy: 'Добавьте любой токен NEP-141',
  getLPTokenCopy: 'Нажмите сюда, чтобы перейти к соответствующему пулу',
  airdropCopy:
    'Пожалуйста, запросите свой эирдроп хотя бы один раз до окончания срока действия, иначе ваш баланс будет пожертвован в казну',
  farmRewardsCopy:
    'Ориентировочная стоимость, основанная на цене, а не на фактическом исполнении',
  totalValueLockedCopy: 'Совокупная сумма заблокированных средств',
  topPoolsCopy: 'Для каждой пары отображается только пул с наибольшим TVL',
  you_do_not_have_enough: 'У вас недостаточно',
  must_provide_at_least_one_token_for:
    'Должны обеспечить больше чем 0 токен для',
  is_not_exist: 'отсутствует',
  must_input_a_value_greater_than_zero: 'Необходимо ввести значение больше 0',
  amount_must_be_not_greater_your_balance:
    'Сумма не должна быть больше, чем ваш баланс',
  no_pool_available_to_make_a_swap_from: 'Нет доступного пула для обмена',
  for_the_amount: 'на сумму',
  no_pool_eng_for_chinese: ' ',
  is_not_a_valid_swap_amount: 'не соответствует сумме обмена',
  not_nep_address:
    'Введенный вами адрес не является NEP-141 адресом, пожалуйста, сверьте и введите его снова. Если это другой контракт основной сети, зайдите в Rainbow Bridge для осуществления процесса переноса',
  not_correct_address:
    'Адрес, введенный вами, неправильный, проверьте и введите правильный',
  popular_tokens: 'Популярные токены',
  asset_label: 'Актив',
  account_label: 'Аккаунт',
  total_label: 'Итого',
  top_pools: 'Лучшие пулы',
  pool_id: 'ID пула',
  more_pools: 'Больше пулов',
  my_watchlist: 'Мой список отслеживания',
  h24_volume: '24ч объем',
  volume: 'Объем',
  hide_low_tvl_pools: 'Скрыть с низким TVL',
  hide_low_tvl_pools_mobile: 'Скрыть с низким TVL',
  watchlist_title: 'Мой список отслеживания сверху',
  add_watchlist: 'Добавить в список отслеживания',
  remove_watchlist: 'Удалить из списка отслеживания',
  my_watchlist_copy: 'Отмеченные пулы будут отображаться здесь',
  search_token: 'Найти токен',
  language: 'Язык',
  deposit_to_add_liquidity: 'Депозит для добавления ликвидности',
  slip_warn:
    'Пожалуйста, будьте внимательны, проверьте минимальную сумму, которую вы можете получить',
  slip_invalid: 'Допустимое проскальзывание некорректно',
  input_to_search: 'Введите для поиска',
  click_search_bar_to_search: 'Кликните на строку поиска для поиска',
  reward_tokens: 'Награда в токенах',
  detail_tip: 'Подробнее',
  no_data: 'Нет данных',
  stable_swap: 'StableSwap',
  StableSwap: 'StableSwap',
  exchange_rate: 'Курс обмена',
  including_fees: '(включая комиссию)',
  standard_gas: 'Стандартная плата за газ:',
  rst_token: 'Ваш RST токен',
  remove_tip: 'Комиссия за снятие ликвидности с доли не взимается',
  share_liquidity: 'Доля ликвидности',
  remove_token_confirm: 'Вы удалите RUST токен',
  remove_as_one_token: 'Удалить как один токен',
  flexible_tip: 'Удалите токены, которые вы хотите',
  add_type_all: 'Добавить все токены в равной пропорции',
  add_type_max: 'Использовать максимальное количество имеющихся токенов',
  select_balance: 'Подбор баланса',
  selectBalanceCopy:
    'Выберите [Кошелек NEAR], чтобы обменять непосредственно токены, имеющиеся в вашем кошельке NEAR',
  near_wallet: 'NEAR кошелек',
  ref_account: 'REF аккаунт',
  swap_tip:
    'Произведите обмен с вашего аккаунта REF, выбрав аккаунт REF в настройках',
  attention: 'Внимание',
  live: 'Действующие',
  ended_search: 'Завершенные',
  my_farms: 'Мои фермы',
  staked_only: 'Застейкано',
  sort_by: 'Сортировать по',
  your_farms_rewards: 'Награды ваших ферм',
  your_farms: 'Ваши фермы',
  new: 'Новые',
  unstake_tip_t: 'у вас есть невостребованные награды...',
  unstake_tip_m:
    'Если заберете из стейкинга, то это уберет его со всех активных и отложенных ферм этой пары.',
  unstake_tip_b: 'Вы хотите забрать из стейкинга?',
  value_rewards: 'Полученные награды',
  stake_unstake_tip:
    'Стейкинг или отмена стейкинга автоматически начислит ваши награды.',
  over_tip:
    'В связи с ограничением комиссии за газ, за один раз можно выбрать только 4 позиций',
  no_token_tip: 'Нет награды',
  getToken_tip:
    'Вы можете получить награду или застейкать свои LP токены прямо сейчас!',
  in_farm: 'в ферме',
  swap_rate_including_fee: 'Курс обмена (включая комиссию)',
  by_share: 'За долю',
  by_token: 'За токен',
  your_positions_will_be_displayed_here:
    'Your positions will be displayed here.',
  price_impact: 'Влияние на цену',
  shares_left: 'Оставшиеся доли',
  total_stable_coins: 'Всего стейблкоинов',
  daily_volume: 'Дневной объем',
  liquidity_utilisation: 'Использование ликвидности',
  token_reserves: 'Cтатистика',
  roi_calculator: 'Калькулятор ROI',
  lp_staked: 'LP застейкано',
  usd: 'USD',
  lp_token: 'LP токен',
  stake_for: 'Срок',
  day_1: '1Д',
  day_2: '7Д',
  day_3: '30Д',
  day_4: '90Д',
  day_5: '1Г',
  cur_apr: 'ROI',
  reward_token: 'Награда в токенах',
  get_lp_token: 'Получить LP доли',
  calculate_roi: 'Калькулятор ROI',
  rewards_claimed: 'Полученные награды',
  value_rewards_token: 'Стоимость наград',
  all: 'Все',
  all_5: '4 токенов за раз',
  insufficient_shares: 'Недостаток долей',
  shares_removed: 'Доли',
  shares_avaliable: 'Доступные доли',
  minimum_shares: 'Минимальные доли',
  shares_tip: 'Доступные акции / Всего акций',
  stablecoin_only: 'Стейблкоин только',
  more_than: 'Пожалуйста, введите число, которое больше или равно 0,01',
  less_than: 'Пожалуйста, введите число, которое меньше 20',
  no_valid: 'Пожалуйста, введите корректное число',
  lp_fee: 'Комиссия LP',
  protocol_fee: 'Комиссия протокола',
  referral_fee: 'Реферальная комиссия',
  protocol_fee_referral_fee: 'Плата за протокол и реферальная плата',
  total_fee_create: 'Общая комиссия',
  xref_title: 'ЗАРАБАТЫВАЙТЕ БОЛЬШЕ С ПОМОЩЬЮ',
  xref_title1: 'Зарабатывайте больше',
  xref_title2: 'стейкая REF в разделе xREF',
  xref_introdution:
    'Стейкая в REF, вы получаете возможность заработать на комиссионных, генерируемых протоколом. Любой держатель REF сможет получить долю от доходов, получаемых Ref Finance.',
  staking_apr: 'APR стейкинга',
  view_stats: 'Просмотр статистики',
  xref: 'xREF',
  ref: 'REF',
  more_than_general_seed:
    'Пожалуйста, введите число, которое больше или равно 0,000001',
  more_than_stable_seed:
    'Пожалуйста, введите число, которое больше или равно 1',
  are_you_sure: 'Вы уверены',
  price_impact_is_about: 'Влияние на цену составляет около',
  yes_swap: 'Да, обменять',
  amount_must_be_greater_than_0: 'сумма должна быть больше 0',
  volume_ratio: 'Суточное соотношение объема/ликвидности',
  go_to_near_wallet: 'Перейти к NEAR кошельку',
  into_ref_account: 'на вашем REF аккаунте.',
  deposit_into_ref_account: 'на вашем REF аккаунте, чтобы добавить ликвидности',
  deposit_near_tip:
    'Для пополнения счета храните не менее 1 NEAR для оплаты комиссий за газ',
  input_greater_than_available_shares: 'Введите больше чем доступно долей',
  number_of_unique_stakers: 'Количество уникальных стейкеров',
  total_ref_staked: 'Всего REF застейкано',
  total_xref_minted: 'Всего xREF отчеканено',
  protocol_projected_revenue: 'Доходы, распределяемые между владельцами xREF',
  total_fee_Revenue_shared_with_xref_holders:
    'Общий доход от комиссии, распределяемый между держателями xREF',
  provision_treasury: 'Резерв казначейства',
  protocol_projected_revenue_tip:
    'Эта сумма соответствует совокупному доходу от комиссионных за трейдинг для держателей xREF. Она равна 75% от общего дохода комиссионных сборов платформы. И может отличаться от фактической суммы выкупа токенов REF из-за колебания цены.',
  stake_ref_to_xref_for_earning_more:
    'Застейкайте REF в xREF, чтобы зарабатывать больше!',
  auto_router: 'Автоматический роутер',
  auto_router_detail_sub_1:
    'Этот маршрут оптимален для обмена благодаря тому, что',
  auto_router_detail_sub_2: 'учитывает все пулы для одной пары.',
  auto_router_detail_sub_3: 'Подробности:',
  introducing_parallel_swap: 'Представляем Parallel Swap',
  at_least: 'В лучшем случае',
  xref_start_time: 'Стартует в 1am UTC, 18 января 2022.',
  xref_to_receive: 'xREF для получения',
  ref_to_receive: 'REF для получения',
  more_expensive_than_best_rate_en: 'гораздо дороже лучшего курса',
  optimal_path_found_by_our_solution:
    'Оптимальный путь, найденный нашим решением',
  check_to_stable_banner: 'Проверьте',
  rates_to_stable_banner: 'Курсы',
  make_sure_you_understand_what_you_do:
    'Убедитесь, что вы хорошо понимаете, что вы делаете',
  go_to_your_account_to: 'Зайдите в свой аккаунт для',
  deposit_l: 'депозит',
  withdraw_l: 'вывод',
  got_it: 'Понимаю',
  check_the_transaction_settings: 'Проверьте настройки транзакции',
  filter_by: 'Отфильтровать по',
  allOption: 'Все',
  stablecoin: 'Стейблкоин',
  near_ecosystem: 'Экосистема NEAR',
  bridged_tokens: 'Токены других сетей',
  gaming: 'Игровые проекты',
  nft: 'NFT',
  bridge: 'Перенос из/в сеть',
  from_ethereum: 'Ethereum',
  from_aurora: 'Aurora',
  from_solana: 'Solana',
  from_terra: 'Terra',
  from_celo: 'Celo',
  no_result: 'К сожалению, мы не смогли найти ни одной подходящей фермы',
  multiple: 'Несколько',
  default: 'По умолчанию',
  vi_go_live: 'Вьетнамский язык стал доступен!',
  do_not_need_to_deposit_in_ref:
    "You don't need to deposit in Ref Finance anymore!",
  you_can_add_liquidity_directly:
    'You can now add liquidity directly using your NEAR wallet.',
  do_not_need_deposit_anymore: "You don't need to deposit anymore!",
  lightning_swaps: 'Lightning Swaps',
  frictionless_add_remove_liquidity:
    'Frictionless Add/Remove Liquidity Actions',
  create: 'Создайте',
  select_a: 'Select a',
  wallet: 'wallet',
  Wallet: 'Wallet',
  to_use_ref_finance: 'to use Ref.Finance',
  not_supported: 'not supported',
  installed: 'installed',
  installe_now: 'install now',
  extension: 'extension',
  first_time_using_ref: 'First time using Ref',
  learn_more: 'Узнать подробности',
  install_sender_now: 'Install Sender Now',
  connect_to_dapps_with_one_click: 'Connect to dApps with one click',
  install: 'install',
  Connecting: 'Connecting',
  check_sender_wallet_extension: 'Please check Sender wallet extension',
  having_trouble: 'Having trouble?',
  go_back: 'Go back',
  web: 'web',
  Type: 'Type',
  click_to_view: 'Нажмите, чтобы просмотреть',
  transaction_failed: 'Transaction failed',
  wallet_vi: ' ',
  value: 'ценность',
  usd_value: 'USD Value',
  sauce_note:
    'SAUCE разработан для пулов ликвидности с фиксированными активами, обеспечивая оптимальные цены.',
  support_ledger: 'Поддержка Ledger',
  initialize_account_tip: 'Please initialize your account.',
  support_ledger_tip:
    'По своей конструкции Ledger не может обрабатывать большие транзакции (Auto Router: торговля, что использует несколько пулов одновременно) из-за  небольшого объема памяти. Функция Support Ledger  позволит уменьшить транзакции до их простейшей  формы (в ущерб потенциально оптимальным ценам,  найденным Auto Router), в результате чего объем  tx будет небольшим, что позволит работать вам с Ledger.',
  start: 'Start',
  aprTip: 'Indicative value based on all rewards (pending and running)',
  half: 'Половина',
  max: 'Макc',
  ref_account_balance_tip:
    'Похоже, произошла ошибка при добавлении/удалении ликвидности в пул',
  ref_account_tip_2: 'У вас есть токены в вашей учетной записи REF',
  ref_account_tip_top:
    'ваши токены могут сейчас находиться на вашем внутреннем счете REF',
  click_here: 'Кликните по этой ссылке',
  to_recover_them: 'чтобы восстановить их',
  ref_account_tip_3:
    'Чтобы вывести токен(ы) с вашего счета REF на ваш кошелек NEAR, пожалуйста, выберите и выведите',
  aurora_account_tip_4:
    'Чтобы вывести токен(ы) с Вашего Картографического Счета на Ваш КОШЕЛЕК NEAR, пожалуйста, выберите и выведите',
  pool_fee_cross_swap: 'Плата за пул/кросс-цепь',
  mapping_account: 'Картографическая учетная запись',
  mapping_account_tip: 'У вас есть маркер(ы) в картографической учетной записи',
  cumulative_ref_buyback: 'Cumulative REF Buyback',
  yearly_revenue_booster: 'Yearly Revenue Booster',
  Confirm: 'Подтверждать',
  Request_for_Quote: 'Запрос на квоту',
  mapping_account_explanation:
    'Mapping" адрес — это "прокси-адрес", который связывает учетную запись пользователя NEAR с соответствующим ETH-адресом на Aurora.',
  buy: 'Купить',
  sell: 'Продать',
  buy_nsn: 'Купить USN',
  buy_nsn_tip: 'USN — это нативный стейблкоин, поддерживаемый экономикой NEAR.',
  usn_tip_one: 'USN доступен для торговли на REF. Убедитесь, что  ',
  rate: 'Курс',
  name: 'Имя',
  diff: 'Диф',
  best: 'Лучший',
  trading_fee: 'Торговая комиссия',
  usn_successful_click_to_view: 'Сделка прошла успешно. Кликните для просмотра',
  awesomeNear_verified_token: 'AwesomeNear верифицированный токен',
  usn_fee_tip:
    'Нулевая комиссия, взимаемая Ref, пользователи платят только комиссию USN!',
  total_bitcoins: 'Всего биткойнов',
  total_usd_value: 'Общая стоимость в долларах США',
  bitcoin_value: 'Стоимость биткойнов',
  stable_coin_value: 'Стоимость стейблкоина',
  pool_fee_apr: 'Pool fee APY',
  ref_account_balance_tip_mobile:
    'У вас есть токены в вашей учетной записи REF.',
  click: 'Нажмите',
  to_recover: 'выздороветь.',
  rewards_week: 'Rewards/week',
  rewards: 'Rewards',
  claimed_Rewards: 'Полученные награды',
  POOL: 'Пулы',
  STAKE: 'STAKE',
  lp_Token: 'LP Tokens',
  booster: 'Booster',
  slash_policy: 'Slash Policy',
  stake_period: 'Stake Period',
  append: 'Append',
  append_staking: 'Append Staking',
  unstake_time: 'Unstake Time',
  farm: 'Farm',
  reward_Token: 'Reward Token',
  status: 'Status',
  value_of_rewards: 'Value of Rewards',
  Rewards: 'Rewards',
  free: 'Free',
  lp_tokens: 'LP токены',
  connect_to_wallet: 'Подключить кошелек',
  anytime: 'Anytime',
  slash_policy_content:
    'Each seed has its own slash rate. And it will decreases linearly over time until be 0 at the unlock time of this CD Account.',
  not_expired: 'Not expired',
  accept_pay_slash_tip: 'I accept to pay slash',
  i_will_pay: 'I will pay',
  lp_token_slash: 'LP token exit fee.',
  booster_change_reason: 'Booster will change due to appending time',
  initial_booster: 'Initial Booster',
  append_timing: 'Append timing',
  cd_limit: 'more than 16 cd account limit',
  expired: 'Expired',
  all_5_v2: '5 токенов за раз',
  all_4_v2: '4 токенов за раз',
  more_than_seed: 'Input must be greater than or equal to ',
  how_to_earn_more: 'Как можно заработать больше?',
  you_shares: 'Your Shares',
  lock_your_lp_tokens_with_booster: 'Lock your LP tokens for boosted rewards',
  unstaked_anytime_no_booster: 'Unstaked anytime, no booster',
  ordinary_stake: 'Ordinary Stake',
  locking_stake: 'Lock-up staking',
  change_to_lock: 'Change to Lock-up staking',
  estimated_rewards: 'Estimated rewards',
  final_booster: 'Final Booster',
  existing_amount: 'Existing amount',
  append_amount: 'Append amount',
  will_be_able_to_unstaked_after: 'will be able to unstaked after',
  expected_reward: 'Expected reward',
  you_have: 'У вас есть',
  tokens_small: 'tokens',
  reward_apr: 'Rewards APR',
  price_board: 'Price board',
  close: 'Close',
  stake_min_deposit: 'Введеное количество должно быть больше или равно',
  unlock: 'Unlock',
  exit_fee: 'Exit fee',
  end_locking_period: 'End of locking period',
  add_lp_tokens_tip:
    'Вам нужны LP токены, чтобы застейкать их в соответствующей ферме. Для получения LP-токенов сначала добавьте ликвидность в пул.',
  slash_tip: 'Exit fee = Exit fee rate * End of locking period * Unlock amount',
  nonBoosted: 'Без буста',
  exit_fee_tip: 'Estimated amount you would pay to break your lock-up period',
  forbiddenTip: 'This farm does not support this duration option',
  unstakeTip:
    'Стейкинг или отмена стейкинга автоматически начислит ваши награды.',
  endedFarmsUnstakeTip:
    'Подобным действим вы отмените стейкинг со всех активных и предстоящих ферм данной пары',
  comimg: 'Ожидается',
  appendTip: 'Невозможно выполнить это раньше текущего времени блокировки',
  week: 'в неделю',
  yours: 'Ваши пулы',
  boost: 'Буст',
  near: 'NEAR',
  eth: 'ETH',
  others: 'Другие',
  stakeLove: 'Застейкать LOVE',
  unstakeLove: 'Забрать из стейкинга LOVE',
  get_love: 'Получить LOVE',
  swap_successful: 'Обмен прошел успешно',
  trading_successfull: 'Сделка прошла успешно',
  total_near_amount: 'Общее количество NEAR',
  near_value: 'Стоимость NEAR',
  borrow: 'Занимать',
  burrow_usn_tip: 'Вы можете одолжить USN на Burrow.',
  migrate: 'Перенести',
  go_to_new_farm: 'Перейти к новой ферме',
  voting_gauge_share_tip:
    'Пропорция токенов REF, используемая для распределения голосов, к общей сумме наград в токенах REF в этот период',
  swap_capital: 'Обмен',
  farm_capital: 'Фермы',
  sauce_capital: 'SAUCE',
  dao_capital: 'ДАО',
  available_to_stake: 'доступно для стейкинга',
  recent_one_mounth_activity: 'Активность в течение месяца',
  your_assets: 'Ваши активы',
  connect_wallet: 'Подключить кошелек',
  disconnect: 'Отключить',
  change: 'Поменять',
  wallets_below_supports_on_PC: 'Указанные ниже кошельки поддерживаются на ПК',
  connect: 'Подключиться',
  make_sure_device_plugged_in: 'Убедитесь, что ваше устройство подключено',
  then_enter_an_account_id_to_connect:
    'Укажите идентификатор учетной записи для подключения',
  veTip:
    'токены veLP не могут быть переданы, veLPT представляют собой ваше право голоса ',
  loveTip:
    'LOVE означает ликвидность veToken. LOVE, как и любой другой токен NEP-141, является доступным для передачи и может быть обменен, добавлен в пул или застейкан на бирже, а также задействован в фермах. LOVE представляет собой базовую ликвидность ваших veTokens.',
  lock_your_lp_tokens: 'Заблокируйте ваши LP токены',
  unlock_your_defi_power: 'Разблокируйте вашу DeFi-силу',
  unlock_tip_mobile: 'Разблокирование уже доступно!',
  unlock_tip_top:
    'Период блокировки истек, теперь вы сможете разблокировать ваши токены!',
  bonus_to_be_withdraw: 'бонус для снятия',
  keep_ve: 'сохраните',
  months: 'месяцы',
  month: 'месяц',
  lock_lp_tokens: 'Заблокируйте ваши LP токены',
  get_lptoken: 'Получите LP токены',
  duration: 'Продолжительность',
  ve_lock_tip: 'Невозможно выполнить это раньше текущего времени блокировки',
  get: 'Получить',
  locking_ve: 'Блокировка',
  in_addition_to_the: 'В дополнение к',
  already_locked: 'уже заблокировано',
  unlocking_is: 'Разблокирование — это',
  you_have_no_lp_share: 'У вас нет LP токенов',
  lock: 'Заблокировать',
  unlock_lptoken: 'Разблокировать LP токены',
  locked: 'Заблокировано',
  available: 'Доступно',
  balance_lowercase: 'Баланс',
  voting_power: 'Право голоса',
  lock_lp_tokens_first: 'Сначала заблокируйте ваши LP токены!',
  farm_booster: 'Бустер для фермы',
  go_to_farm: 'Перейти к ферме',
  in: 'в',
  more_lowercase: 'больше',
  avaliable_to_lock: 'доступно для блокировки',
  lock_lptoken: 'Заблокировать LP токены',
  lptoken_locker: 'Хранилище LP токенов',
  filter: 'Фильтровать',
  bonus: 'Бонус',
  your_shares_ve: 'Ваша доля',
  voted: 'Проголосовали',
  my_voting_power: 'Ваше право голоса',
  new_voting_ratio: 'Новый коэффициент голосов',
  REF_allocation: 'REF аллокация',
  vote: 'Проголосовать',
  preview: 'Предпросмотр',
  options: 'Опции',
  ratio: 'Рейтинг',
  forum_discussion: 'Дискуссионный форум',
  voted_veLPT: 'Проголосовало veLPT',
  your_contribution: 'Ваш вклад',
  delete: 'Удалить',
  not_start: 'Не запускать',
  no_veLPT: 'Без veLPT',
  claim_bonus: 'Запросить бонус',
  ended_ve: 'Завершено',
  proposal_details: 'Детали предложения',
  back: 'Вернуться',
  pending_ve: 'Ожидается',
  you_voted: 'Вы проголосовали',
  add_bonus: 'Добавить бонус',
  you_vote: 'Ваш голос',
  Creator: 'Автор',
  turn_out: 'Явка',
  top_answer: 'Лучший ответ',
  your_bonus: 'Ваш бонус',
  gauge_weight_vote: 'Оценка результатов голосования',
  governance: 'Менеджмент',
  confirm: 'Подтвердить',
  voting_period: 'Срок голосования',
  applying_period: 'Срок подачи заявки',
  community_gauge_share: 'Доля участия сообщества',
  qualified_pools: 'Квалифицированные пулы',
  total: 'Итого',
  supply_voted: 'Проголосовало за предложение',
  proposed: 'Предложено',
  farm_reward: 'Награды за ферму',
  check_last_round: 'Проверьте последний раунд',
  last_round_voting_result: 'Результат последнего раунда голосования',
  create_proposal: 'Составьте предложение',
  type: 'Вид',
  add_option: 'Добавьте опцию',
  lock_lp_terms:
    'Я понимаю, что не смогу забрать свои LP токены в течение указанного срока блокировки.',
  connect_to_near_wallet: 'Подключите NEAR кошелек',
  only_for_whitelist_address: 'Только для белого списка адресов',
  v1Legacy: 'Legacy',
  v2New: 'Classic-New',
  v2_new_farms: 'Classic Новые фермы',
  migrate_now: 'Выполнить миграцию!',
  v2_boost_tip:
    'Classic-фарминг будет работать для ферм с бустом. Legacy-фарминг будет лишен наград после <span class="font-bold">1 августа 2022 года</span>',
  v2_boost_tip2: 'Classic-фарминг будет работа для ферм с бустом.',
  v2_boost_no_tip:
    'Legacy-фарминг будет лишен наград после <span class="font-bold">1 августа 2022 года</span>',
  no_farm_need_to_migrate: 'Не требуется перенос фермы',
  v1_farms: 'Classic Фермы',
  has_rewards_tip:
    'У вас еще есть награды, которые вы должны вывести из <a rel="noopener noreferrer nofollow" class="text-greenColor text-base cursor-pointer underline hover:text-senderHot"  href="/farms" >Legacy Ферм</a>. Эти награды никуда не денутся, вы можете снять их в любой момент.</div>',
  love: 'LOVE',
  Available_to_stake: 'Доступно для стейкинга',
  you_staked: 'Вы застейкали',
  has_rewards_tip_in_v2:
    'Ваши обычные награды, которые вы заберете из <a rel="noopener noreferrer nofollow" href="/farms" class="text-sm text-greenColor cursor-pointer underline ml-1 hover:text-senderHot"> Legacy Ферм</a>.',
  how_to_get: 'Как получить?',
  get_booster: 'Получите бустер',
  ended_farms: 'завершенные фермы',
  boosted_farms: 'Фермы с бустом',
  your_farm: 'Ваша ферма',
  your_boosted_farms: 'Ваши фермы с бустом',
  search_farms_by_token: 'Найти фермы по токену',
  search_farms: 'Искать фермы',
  more_lock_ve_popup: 'больше',
  more_lock_ve_popup2: 'больше',
  more_in: 'больше в',
  starts: 'начало',
  left: 'левый',
  proposal_title: 'Наименование предложения',
  share_forum_discussion_link_here:
    'Поделитесь ссылкой для обсуждения на форуме здесь',
  required_field: 'Обязательное поле',
  voting_power_and_extra_earnings: 'Право голоса и дополнительный доход',
  bonus_tip:
    'Премия за голосование разработна для того, чтобы стимулировать пользователей голосовать. Ваш бонус зависит от количества принадлежащих вам долей',
  yes_continue: 'Yes, continue',
  unlocking_tip:
    'Разблокировка LP токенов автоматически аннулирует ваш голос (и соответствующий бонус) в рамках следующего предложения(й)',
  create_pool: 'Создать пул',
  search_by_token: 'Поиск по токены',
  your_power: 'Ваша DeFi-сила',
  farm_has_boost_tip:
    'Ваша DeFi-сила = Ваши застейканные LP токены * бустер (от стейкинга LOVE)',
  farm_no_boost_tip: 'Ваша DeFi-сила = Ваши застейканные LP токены',
  more_than_a_simple_pool: 'Больше, чем обычный пул',
  ended_Farms: 'Завершенные фермы',
  vote_capital: 'Голосование',
  starting_time: 'Starting Time',
  ending_time: 'Ending Time',
  please_choose_voting_duration: 'Пожалуйста, выберите срок голосования',
  please_choose_proposal_voting_duration:
    'Please choose proposal voting duration',
  days_ve: 'days',
  vote_mobile_capital: 'VOTE',
  risks_capital: 'Риски',
  view_on: 'посмотреть в Nearblocks',
  boostFarmTip: 'Получить буст, застейкав LOVE',
  boosted: 'Применен буст',
  love_staked: 'Застейканные LOVE',
  your_apr: 'Ваш APR',
  range_apr: 'В пределах диапазона или базового APR',
  no_pair_found: 'Пара не найдена',
  found_stable_pool_in_sauce: 'Найден стабильный пул в SAUCE',
  lp_tokens_locking: ' LP токены ',
  you_currently_have: 'В настоящее время у вас есть ',
  scheduled_to_be_unlocked: 'планируется разблокировать',
  locking_more_lp_tokens: 'Блокировка большего количества токенов LP ',
  will_mean_these: 'будет означать эти ',
  lp_tokens_will_not_be_unlocked_until:
    ' Токены LP не будут разблокированы до тех пор, пока',
  "I_understand_that_I_won't_be_able_to_remove_my":
    'Я понимаю, что не смогу удалить свой ',
  until: 'до того как',
  continue: 'Продолжать',
  then_enter_a_derivation_path_to_connect:
    'затем введите путь вывода для подключения',
  account_identification_failed:
    'Ошибка идентификации аккаунта! Пожалуйста, укажите вручную',
  input_account_id: 'Введите идентификатор учетной записи',
  we_found: 'Мы нашли',
  accounts_on_your_device: 'аккаунты на вашем устройстве',
  select_the_accounts_you_wish_to_connect:
    'Выберите учетную запись, которую вы хотите подключить',
  can_not_find_any_accounts:
    "Can't found any account associated with this Ledger. Please create a new NEAR account on",
  or_connect_another_ledger: 'or connect an another Ledger',
  add: 'Добавлять',
  finish: 'Заканчивать',
  add_custom_network: 'Добавить сеть',
  network_name: 'Имя сети',
  rpc_name_taken_tip: 'Сетевое имя уже занято',
  network_invalid: 'Сеть недействительна',
  fobidden_testnet_rpc_tip:
    'Сеть сервера RPC (тестовая сеть) отличается от этой сети (основной сети)',
  no_support_testnet_rpc_tip:
    'Testnet не поддерживает добавление пользовательских RPC',
  stable: 'Stable',
  trade_capital: 'ТОРГОВЛЯ',
  trade_capital_hump: 'Торговля',
  pro: 'Про',
  limit: 'Лимит',
  make_an_order: 'Разместить ордер',
  your_active_orders_will_appear_here:
    'Здесь будут отображаться ваши активные ордера',
  your_history_orders_will_appear_here:
    'Здесь будет отображаться история ваших ордеров',
  liquidity_capital: 'ЛИКВИДНОСТЬ',
  goes_to_limit_order: 'перейти к лимитному ордеру.',
  farm_only: 'Только ферма',
  please_confirm_this_transaction_on_ledger:
    'Пожалуйста, подтвердите эту транзакцию в Ledger',
  limit_order: 'Лимитный ордер',
  newText: 'новый',
  farm_stop_tip: 'Эта ферма приостановлена.',
  pool_stop_tip: 'Этот пул приостановлен.',
  volume_24h: 'Объем (24ч)',
  add_to_watchlist: 'Добавить в список отслеживания',
  remove_from_watchlist: 'Удалить из списка отслеживания',
  create_order: 'Создать ордер',
  filled: 'Исполнен',
  canceled: 'Отменен',
  partially_filled: 'Частично исполнен',
  pool_composition: 'Композитный пул',
  amount: 'Количество',
  h24_volume_bracket: 'Объем (24ч)',
  fee_24h: 'Комиссия (24ч)',
  cross_chain_swap: 'Кроссчейн обмен',
  orders: 'заказы',
  your_orders: 'Ваши заказы',
  active: 'Активный',
  history: 'История',
  you_sell: 'Вы продаете',
  you_buy: 'Вы покупаете',
  fee_tiers: 'Размер комиссионных',
  order_rates: 'Показатели ордеров',
  created: 'Создан',
  executed: 'Исполнен',
  actions: 'Активность',
  claimed_upper: 'Заявлено',
  open_my_order: 'Открыто',
  initial_order: 'Первичный ордер',
  this_order_has_been_partially_filled: 'Этот ордер был частично исполнен',
  filled_via_swap: 'Заполнен за счет обмена',
  state: 'Состояние',
  partially: 'Частично',
  near_validation_error: 'Must have 0.5N or more left in wallet for gas fee.',
  near_min_validation_error:
    'Must have 0.2N or more left in wallet for gas fee.',
  no_positons_in_this_pool_yet: 'No positons in this pool yet',
  no_claimed_rewards_yet: 'No claimed rewards yet',
  out_of: 'out of',
  value_must_be_less_than_or_equal_to: 'Value must be less than or equal to',
  select_tokens: 'Выберите токены',
  fee_Tiers: 'Размер комиссионных',
  select_s: 'выбрать',
  no_pool: 'Нет пула',
  input_amount: 'Введите сумму',
  update_range: 'Обновить диапазон',
  not_enough_balance: 'Недостаточный баланс',
  set_price_range: 'Установите диапазон цен',
  current_price: 'Текущая цена',
  pool_creation: 'Создание пула',
  pool_creation_tip:
    'Для выбранных токенов не существует пула. Для создания пула необходимо установить курс пула, указав для этого соответствующие суммы.',
  starting_price: 'Начальная цена',
  create_a_pool: 'Создание пула',
  add_single_price_tip:
    'Ваша позиция не будет приносить доход в виде комиссионных и не будет включена в торговлю до тех пор, пока рыночная цена не перейдет в указанный вами диапазон.',
  invalid_range_tip:
    'Неверно выбран диапазон. Минимальная цена должна быть ниже максимальной.',
  maket_price_outside_tip:
    'Рыночная цена выходит за рамки вашего ценового диапазона.',
  maket_price_outside_single_only_tip:
    'Рыночная цена находится вне вашего ценового диапазона. Депозит только для одного актива.',
  min_price: 'Мин. цена',
  max_price: 'Макс. цена',
  min: 'Мин.',
  out_of_range: 'Вне диапазона',
  in_range: 'В диапазоне',
  unclaimed_fees: 'Невостребованные комиссии',
  your_position_will_be: 'Ваша позиция будет',
  at_this_price: 'по такой цене',
  price_range: 'Ценовой диапазон',
  positions: 'Позиции',
  position: 'Position',
  add_new_position: 'Добавить новую позицию',
  range: 'Диапазон',
  unclaimed_fee: 'Невостребованная комиссия',
  login_risk_tip:
    'By checking this box and moving forward, you confirm that you fully understand the <a class="text-greenColor text-sm font-bold cursor-pointer hover:underline" href="/risks")">risks</a> of using Ref Finance.',
  no_pool_tip: 'Oops! The pool does not exist.',
  total_ref_week: 'Total REF/week',
  ref_week_you_will_get: 'REF/week you will get',
  stable_pool: 'Stable Pool',
  market_rate: 'Market Rate',
  limit_price_tip:
    'To improve deal the efficiency, your price should be in one slot nearby automatically',
  no_token_found: 'No token found',
  search_name_or_address: 'Search name or paste address...',
  token_address_invalid: 'The token address was invalid',
  price_impact_warning: 'Price Impact Warning',
  confirm_order: 'Confirm Order',
  you_Sell: 'You Sell',
  to_Buy: 'to Buy',
  at_Price: 'at Price',
  insufficient_balance: 'Insufficient Balance',
  MORE: 'MORE',
  limitTip: 'Your price is automatically set to the closest price slot',
  v2PoolTip: 'You can have up to 16 positions in DCL pools',
  orderTip: 'You can have up to 16 active/history limit orders',
  v2_pools: 'DCL Pools',
  minimum_received_dot: 'Минимальная сумма, которую вы получите',
  v2_your_pool_introduction: 'Your discretized concentrated liquidity',
  v1_your_pool_introduction: 'Your liquidity in classic pools',
  estimate_value: 'Estimate Value',
  price: 'Price',
  '@price': '@Price',
  h24_high: '24h High',
  h24_low: '24h Low',
  h24_Volume: '24h Volume',
  orderbook: 'Order Book',
  orderbook_mobile: 'Orderbook',
  trades: 'Trades',
  qty: 'Qty',
  total_orderly: 'Total',
  time: 'Time',
  balances: 'Balances',
  asset: 'Asset',
  wallet_up: 'Wallet',
  account_in_order: 'Account: in Order',
  source_address: 'Source Address',
  action: 'Action',
  market: 'Market',
  order_type: 'Order Type',
  order_type_limit_tip:
    'A limit order is an order to buy or sell at a specific price, or better. Limit orders are not guaranteed to execute',
  order_type_market_tip:
    'A market order is immediately matched to the best available market price, and executed',
  quantity: 'Quantity',
  advanced: 'Advanced',
  tip_fok:
    'Fill-Or-Kill is an order to buy or sell that must be executed immediately in its entirety; otherwise, the entire order will be cancelled.',
  tip_post_only:
    'Post Only ensures that traders can only place an order if it would be posted to the orderbook as a Maker order. An order which would be posted as a Taker order will be cancelled.',
  tip_ioc:
    'Immediate-Or-Cancel is an order to buy or sell that must be filled immediately. Any portion of an IOC order that cannot be filled will be cancelled.',
  Taker: 'Taker',
  Maker: 'Maker',
  see_all: 'See all',
  h24Vol: '24h Vol',
  h24Range: '24h Range',
  chart: 'Chart',
  deposit_assets_to_begin_your_trading_journey:
    'Deposit assets to begin your {br} trading journey.',
  open_orders: 'Open Orders',
  ref_order_only: 'Order on REF only',
  ref_order: 'REF Dex only',
  orderly_portfolio_table_tips:
    'The data provided herein includes all assets and records in your account, not limited to those generated through REF.',
  orderly_ws_refresh: 'Please refresh the data to continue',
  refresh: 'Refresh',
  settled_balance: 'Settled Balance',
  settled_amount: 'Settlement Amount',
  Side: 'Side',
  all_side: 'All Side',
  both_side: 'Both Side',
  both: 'Both',
  filled_qty: 'Filled / Qty',
  fill_qty: 'Fill / Qty',
  est_total: 'Est.Total',
  avg_price: 'Avg.Price',
  create_time: 'Create Time',
  spot: 'Spot',
  futures: 'Futures',
  All: 'All',
  Filled: 'Filled',
  Fees: 'Fees',
  Cancelled: 'Cancelled',
  Rejected: 'Rejected',
  all_instrument: 'All Instrument',
  available_orderly: 'Available',
  history_orderly: 'History',
  settle_pnl: 'Settle PnL',
  settle_pnl_tips:
    'By doing this, we’ll move your profit or loss from perp markets into the USDC.e token balance. This has no impact on your open positions or health.',
  total_unsettled_pnl: 'Total unsettled PnL',
  fut_unreal_pnl: 'Fut. Unreal. PnL',
  fut_daily_real: 'Fut. Daily Real.',
  fut_notional: 'Fut. Notional',
  fut_unsettle_pnl: 'Unsettle PnL',
  settle: 'Settle',
  mark_orderly: 'Mark',
  avg_open: 'Avg. Open',
  liq_price: 'Liq. Price',
  unreal_pnl: 'Unreal. PnL',
  daily_real: 'Daily Real',
  market_price: 'Market Price',
  NEW: 'New',
  PARTIAL_FILLED: 'Partial Filled',
  limit_price: 'Limit Price',
  unsettled_pnl: 'Unsettled PnL',
  notional: 'Notional',
  assets: 'Assets',
  limit_orderly: 'Limit',
  'qty.': 'Qty.',
  no_orders_found: 'No orders found',
  no_records_found: 'No records found',
  its_empty: "It's Empty!",
  current_orderly: 'Current',
  cancel_order: 'Cancel Order',
  post_only: 'Post Only',
  fok: 'FOK',
  ioc: 'IOC',
  instrument: 'Instrument',
  open_order_detail: 'Open Order Detail',
  history_order_detail: 'History Order Detail',
  select_orderly: 'Select ',
  change_orderly: 'Change',
  token_orderly: 'Token',
  user_foot_tip:
    '* This Orderbook page is a graphical user interface for trading on Orderly Network, and is provided as a convenience to users of Ref Finance.',
  learn_more_orderly: 'Learn more',
  more_order_book_page_detail:
    'This Orderbook page is powered by Orderly Network, users are strongly encouraged to do their own research before connecting their wallet and/or placing any orders.{br} Ref Finance does not guarantee the security of the systems, smart contracts, and any funds deposited or sent to those systems and contracts.{br} Neither Ref Finance nor Orderly Network is responsible for any profit or loss of investment users made through this Orderbook page.',
  learn_more_about: 'Learn more about',
  learn_more_about_zh: ' ',
  by_click_confirm:
    'By clicking "Confirm", you confirm that you have comprehensively reviewed and comprehended the contents aforementioned',
  connect_to_orderly_account:
    "You need to (re)connect your Orderly account to use Ref's Orderbook.",
  first_register_orderly_tip:
    'Your wallet must first be registered with Orderly in order to use the Orderbook.',
  must_register_tip:
    'Your wallet must be registered with Orderly to trade on their system.',
  register_deposit_tip: 'Registering will require a storage deposit',
  increase_storage_deposit:
    'You may need to increase the storage deposit on your Orderly account.',
  deposit_storage_fee: 'Deposit storage fee',
  register_orderly_account: 'Register Orderly Account',
  register: 'Register',
  connect_to_orderly: 'Connect to Orderly',
  order_cancelled: 'Order Cancelled.',
  order_edit_success: 'Order Edit Successfully!',
  created_orderly: 'Created',
  open_orderly: 'Open',
  dex: 'Dex',
  min_price_should_be_higher_than_or_equal_to:
    'Min price should be higher than or equal to',
  price_should_be_lower_than_or_equal_to:
    'Price should be lower than or equal to',
  price_should_be_a_multiple_of: 'Price should be a multiple of',
  price_should_be_greater_than_or_equal_to:
    'Price should be greater than or equal to',
  the_order_value_should_be_be_greater_than_or_equal_to:
    'The order value should be greater than or equal to',
  quantity_to_buy_should_be_greater_than_or_equal_to:
    'Quantity to buy should be greater than or equal to',
  quantity_to_sell_should_be_greater_than_or_equal_to:
    'Quantity to sell should be greater than or equal to',
  quantity_to_buy_should_be_less_than_or_equal_to:
    'Quantity to buy should be less than or equal to',
  quantity_to_sell_should_be_less_than_or_equal_to:
    'Quantity to sell should be less than or equal to',
  quantity_should_be_a_multiple_of: 'Quantity should be a multiple of',
  at_least_one_of_order_quantity_or_price_has_to_be_changed:
    'At least one of order quantity or price has to be changed',
  quantity_should_be_higher_than: 'Quantity should be higher than',
  price_should_be_higher_than: 'Price should be higher than',
  insufficient_en: 'Insufficient',
  the_all_data_orderly_tip:
    'The data provided herein includes all assets and records in your account, not limited to those generated through REF.',
  at_orderly: 'at',
  change_the_quantity_of: 'Change the quantity of',
  change_the_price_of: 'Change the price of',
  market_order_filled: 'Market Order Filled',
  limit_order_created: 'Limit Order Created',
  limit_order_filled: 'Limit Order Filled',
  open: 'Open',
  to_buy: 'To Buy',
  to_sell: 'To Sell',
  in_open_orders: 'In Open Orders',
  your_investment: 'Your Investment',
  your_investment_tip:
    'USD value of your investment on Ref:Classic pools + DCL pools (including staked in farms) + xREF',
  classic_pools: 'Classic Pools',
  xref_staking: 'xREF Staking',
  token_balances: 'Token Balances',
  unclaimed_earnings: 'Unclaimed Earnings',
  unclaimed_earnings_tip:
    'USD value of unclaimed fees from DCL pools, and unclaimed farm rewards.',
  unclaimed_pool_fees: 'Unclaimed Pool Fees',
  unclaimed_farm_rewards: 'Unclaimed Farm Rewards',
  active_orders: 'Active Orders',
  yield_farming: 'Yield Farming',
  execute_status: 'Execute Status',
  your_liquidity_usd_value: 'Your Liquidity (USD value)',
  usage: 'Usage',
  holding: 'Holding',
  your_lp_tokens_and_shares: 'Your LP Tokens(Shares)',
  usd_value_staked: 'USD Value Staked',
  instant_swap: 'Instant Swap',
  swapped: 'Swapped',
  created_s: 'created',
  order_progress: 'Order Progress',
  dcl_pool: 'DCL Pool',
  classic: 'Classic',
  classic_farms: 'Classic Farms',
  unstaked: 'Unstaked',
  staked_in_farm: 'Staked in farm',
  farm_reward_range: 'Farm Reward Range',
  your_positions: 'Your Positions',
  your_price_range: 'Your Price Range',
  locked_in: 'locked in',
  near_locked_in_wallet_for_covering:
    '0.5 NEAR locked in wallet for covering transaction fees',
  account_assets: 'Account Assets',
  open_qty: 'Open Qty.',
  actions_orderly: 'Actions',
  records: 'Records',
  buy_pop: 'Buy',
  sell_pop: 'Sell',
  fee_orderly: 'Fee',
  executing: 'Executing',
  from_2: 'from',
  to_2: 'to',
  your_orders_2: 'Your Orders',
  in_farm_2: 'in farm',
  your_liquidity_2: 'Your Liquidity',
  welcome: 'Welcome',
  welcome_mobile: 'Connect your wallet to start',
  select_token_orderly: 'Select Token',
  overview: 'Overview',
  positions_2: 'Positions',
  'deposit_near_tip_0.5': 'To deposit, keep at least 0.5 NEAR to cover gas fee',
  trade: 'Trade',
  earn_2: 'Earn',
  portfolio: 'Portfolio',
  more_2: 'More',
  liquidity_pools_2: 'Liquidity Pools',
  business_inquiries: 'Business Inquiries',
  stable_pools: 'Stable Pools',
  pool_detail: 'Pool Detail',
  new_contract_deploy_tip:
    'A new contract has been deployed! Please remove your liquidity from the old contract',
  remove_will_automatically_claim:
    'Removing will automatically claim your unclaimed fees.',
  legacy_tip: 'Found unstaked LP tokens or rewards in',
  legacy_farms: 'Legacy Farms',
  all_farms: 'All Farms',
  your_farms_2: 'Your Farms',
  dcl_farms: 'DCL Farms',
  farm_type: 'Farm type',
  farm_2: 'Farm',
  to_claim: 'To Claim',
  liquidity_staked: 'Liquidity staked',
  dcl_pool_detail: 'DCL Pool Detail',
  reward_range: 'Reward Range',
  reward_range_tip: 'Farm reward within this range',
  ending_soon: 'Ending soon',
  farm_ended_tip: 'This farm has ended.',
  farm_will_ended_tip: 'This farm will end soon.',
  new_farm: 'New Farm',
  is_coming: 'is coming',
  est_apr: 'Est. APR',
  state_2: 'State',
  farming: 'Farming',
  unavailable: 'Unavailable',
  liquidity_detail: 'Liquidity Detail',
  hide: 'Hide',
  show: 'Show',
  unavailable_positions: 'unavailable positions',
  your_price_range_tip: 'Your price range is out of reward range',
  position_has_staked_tip: 'This position has been staked in another farm',
  minimum_tip: 'The minimum staking amount is',
  your_liquidity_3: 'your liquidity',
  position_has_staked_pre: 'This position has been staked in',
  another_farm: 'another farm',
  add_position: 'Add Position',
  full_range: 'Full Range',
  your: 'Your',
  max_apr: 'Max.APR',
  faming_positions: 'Farming Positions',
  you_can_earn_tip: 'Farm available, farm APR up to',
  you_can_earn_current_tip:
    'Your current staked farm ended, and new farm is coming, est. APR is',
  go_new_farm: 'Go New Farm',
  go_farm: 'Go Farm',
  earn_rewards: 'You can earn rewards',
  est_apr_is: 'est. APR is',
  new_farm_2: 'new farm',
  you_can_earn_current_pre_tip: 'Your current staked farm ended, and',
  in_ended_farm: 'in ended farm',
  staked: 'Staked',
  in_farm_3: 'in farm',
  estimation: 'Estimation Value',
  no_dcl_position_tip:
    "You don't have any DCL Liquidity position for now, click 'AddPosition' to start farming.",
  price_on_slot_tip: 'The price should be in one slot nearby.',
  position_will_appear_here: 'Your liquidity positions will appear here.',
  by_farming: 'by farming',
  this_staked_tip: 'This NFT has been staked',
  dcl_liquidity: 'DCL Liquidity',
  classic_liquidity: 'Classic Liquidity',
  will_appear_here_tip: 'Your liquidity positions will appear here.',
  dcl_will_appear_here_tip: 'Your DCL liquidity positions will appear here.',
  classic_will_appear_here_tip:
    'Your Classic liquidity positions will appear here.',
  yield_farming_appear_here_tip: 'Your yield farming will appear here.',
  active_order_appear_here_tip: 'Your active order(s) will appear here.',
  account_appear_here_tip: 'Your wallet/account assets will appear here.',
  farm_state: 'Farm State',
  farm_detail: 'Farm Detail',
  unstaked_2: 'Unstaked',
  unstaked_positions: ' Unstaked Positions',
  price_range_overlap_ratio: ' Price Range Overlap Ratio',
  swapped_history: 'swap history',
  real_time_executed_orders: 'real-time executed orders',
  real_time_executed_orders_tip:
    'When placing a sell order above market price or a buy order below market price using limit order function, Ref will attempt to execute a swap-with-stop-point action first, and if there is any remaining amount, a limit order will be created to fill the rest.<br> Real-time executed orders refer to orders placed using the limit order function but executed through swapping. In the "History" section, you can view real-time executed orders between the earliest displayed limit order in History up to the present time.',
  swapped_history_order: 'Swapped',
  ledger_guide_tip_1:
    'To successfully use your Ledger wallet for transactions, the ',
  ledger_guide_tip_2: 'feature has been automatically activated.',
  ledger_guide_tip_3: 'Please be aware',
  ledger_guide_tip_quote: '"',
  support_ledger_tip_new: '"Support Ledger"',
  ledger_guide_tip_4:
    'that due to Ledger wallet constraints, the current rate provided by the swap function',
  ledger_guide_tip_5: 'may not be the best price.',
  ledger_guide_tip_6: '.',
  view_dcl_pool: 'View DCL Pool',
  your_trade_route: 'Your Trade Route',
  markets: 'Markets',
  exchanges: 'Exchanges',
  output_est: 'Output (est.)',
  go_to_orderbook: 'Go to Orderbook',
  new_swap_order_tip:
    'The price is from the Ref AMM offer and for reference only. There is no guarente that your limit order will be immediately filled. ',
  instants_swap: 'Instant Swap',
  swappped: 'Swapped',
  claimed: 'Claimed',
  executing_capital: 'Executing',
  record: 'Record',
  trade_route: 'Trade Route',
  settings: 'Settings',
  cross_chain_options: 'Cross-chain Options',
  no_trade_routes: 'No Trade Routes',
  steps_in_the_route: 'Steps in the Route',
  swap_chart_no_data: 'Not enough data for the chart right now.',
  go_to_orderbook_tip:
    'This price is for reference only. {br} Please proceed to <strong>Orderbook</strong> to place the order.',
  last_updated: 'Last Updated',
  steps_in_the_route_zh: '',
  has_tri_pool_tip:
    ' in Ref. Enable Trisolaris in Cross-chain Options to find more.',
  Overview: 'Overview',
  RefFinance: 'Ref.finance',
  Orderly: 'Orderly',
  Burrow: 'Burrow',
  Supplied: 'Supplied',
  Borrowed: 'Borrowed',
  NetAPY: 'Net APY',
  UnclaimedRewards: 'Unclaimed Rewards',
  YouSupplied: 'You Supplied',
  SupplyMarket: 'Supply Market',
  YouBorrowed: 'You Borrowed',
  BorrowMarket: 'Borrow Market',
  NetAPYTip:
    'Net APY of all supply and borrow positions, including base APYs and incentives',
  Market: 'Market',
  TotalSupplied: 'Total Supplied',
  TotalBorrowed: 'Total Borrowed',
  AvailableLiquidities: 'Available Liquidity',
  DailyRewards: 'Daily Rewards',
  SupplyAPY: 'Supply APY',
  BorrowAPY: 'Borrow APY',
  Collateral: 'Collateral',
  Adjust: 'Adjust',
  Supply: 'Supply',
  Repay: 'Repay',
  Borrow: 'Borrow',
  HealthFactor: 'Health Factor',
  CollateralFactor: 'Collateral Factor',
  AdjustCollateral: 'Adjust Collateral',
  UseAsCollateral: 'Use as Collateral',
  RemainingCollateral: 'Remaining Collateral',
  RemainingBorrow: 'Remaining Borrow',
  NoSupplyTip: 'Your supplied assets will apear here.',
  NoBorrowTip: 'Your borrowed assets will apear here.',
  APY: 'APY',
  CF: 'C.F.',
  burrowErrorTip:
    'The current balance is below the minimum token decimals, so that it cannot be processed by the contract.',
  healthErrorTip:
    "Your health factor will be dangerously low and you're at risk of liquidation.",
  Summary: 'Summary',
  Welcome: 'Welcome!',
  ConnectView: 'Connect your wallet to view',
  TotalSuppliedMarket: 'Total Supplied',
  NetWorth: 'Net Worth',
  Claimable: 'Claimable',
  TotalDebts: 'Total Debt',
  WalletAssets: 'Wallet Assets',
  TotalInvested: 'Total Invested',
  TotalAssets: 'Total Assets',
  TokenAllocation: 'Token Allocation',
  perpTip:
    'Welcome to Perpetual Futures trading! Please read {perpTipLink} to get started!',
  perpTipLink: '[Perpetual Futures Introduction]  ',
  perpetual: 'Perpetual',
  pred_funding_rate: 'Pred. Funding Rate',
  open_interest: 'Open Interest',
  bridge_pure: 'Перенос',
  pool_refresh: 'Something wrong with the server, please try again later.',
  netWorthTip:
    'Total value of investments in Ref (including claimable rewards) + Total value of Orderly assets + Total value of Burrow assets + Total assets in wallet',
  mark_price: 'Mark Price',
  last_price: 'Last Price',
  mark_price_tip: 'Mark price is used for PnL calculating and liquidation.',
  total_collateral: 'Total Collateral',
  free_collateral: 'Free Collateral',
  total_upnl: 'Total uPnL',
  perps: 'Perps',
  margin_ratio: 'Margin Ratio',
  total_npnl_tip:
    'Estimated unrealized profit and loss across all open positions',
  low_risk: 'Low Risk',
  mid_risk: 'Medium Risk',
  high_risk: 'High Risk',
  liquidations: 'Liquidations',
  leverage_max_leverage: 'Max Account Leverage:',
  marker_taker_fee_rate: 'Maker/Taker Fee Rate',
  liquidation_price: 'Liquidation Price',
  margin_required: 'Margin Required',
  perp_buy_limit_order_range:
    'The price of buy limit orders should be less than or equal to',
  perp_sell_limit_order_range:
    'The price of sell limit orders should be greater than or equal to',
  perp_buy_limit_order_scope:
    'The price of a buy limit order cannot be lower than',
  perp_sell_limit_order_scope:
    'The price of a sell limit order cannot be higher than',
  liquidations_fee: 'Liquidations Fee',
  more_trading_price: 'More Trading Price',
  unsettle_pnl_tip:
    'Move a profit or loss from perp markets into the USDC.e token balance. This has no impact on your open positions or health.',
  margin_ratio_tip:
    'The margin ratio of an account is equal to the total collateral value (ie the USDC.e balance + any unrealized profit/loss) divided by the total open notional of the account (the sum of the absolute notional value of all positions)',
  portfolio_total_est_tip: 'Portfolio value in USD.',
  size: 'Size',
  closing_1: 'Closing',
  closing_2: 'position at {type} price.',
  buy_long: 'Buy / Long',
  sell_short: 'Sell / Short',
  unsettle_pnl: 'Unsettle PnL',
  the_margin_will_be_insufficient: 'The margin will be insufficient',
  exceed_rate_limit:
    'You have exceeded the rate limit, please try again in 60 seconds',
  est_liquidation_price: 'Est. Liquidation Price',
  liquidation_price_tip:
    'This price is for reference only. You can see the liquidation price in your Orderly portfolio after your order is filled.',
  futures_leverage_saved: 'Futures leverage saved',
  maintenance_margin_ratio: 'Maintenance Margin Ratio',
  liquidation_fee: 'Liquidation Fee',
  ins_fund_transfer: 'Ins. Fund Transfer',
  no_liquidation_yet: 'No liquidation yet',
  funding_fee: 'Funding Fee',
  settlements: 'Settlements',
  funding_annual_rate: 'Funding Rate / Annual Rate',
  your_liquidity_positions_will_appear_here:
    'Ваши ликвидные позиции будут отображаться здесь.',
  more_expensive_than_best_rate_zh_cn: '',
  insufficient_margin: 'Insufficient Margin',
};
export default Object.assign(ru, ru_in_risks_page);
