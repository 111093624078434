import React from 'react';

export const ModalClose = (props: any) => {
  const { className, width, height, fillColor, ...rest } = props;
  return (
    <svg
      className={className}
      {...rest}
      width={width || '12'}
      height={height || '12'}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99973 5.10533L1.07987 0.185461C0.960569 0.0702397 0.800789 0.00648358 0.63494 0.00792476C0.469091 0.00936594 0.310444 0.0758891 0.193166 0.193166C0.0758891 0.310444 0.00936596 0.469092 0.00792478 0.63494C0.0064836 0.800789 0.0702397 0.960569 0.185461 1.07987L5.10469 5.99973L0.185461 10.9196C0.126692 10.9783 0.0800658 11.0481 0.0482443 11.1248C0.0164228 11.2015 2.94086e-05 11.2838 3.95314e-08 11.3669C-2.93295e-05 11.45 0.0163059 11.5322 0.0480731 11.609C0.0798403 11.6858 0.126417 11.7555 0.185145 11.8143C0.243872 11.8731 0.3136 11.9197 0.390347 11.9515C0.467094 11.9834 0.549358 11.9997 0.63244 11.9998C0.715523 11.9998 0.797798 11.9835 0.874567 11.9517C0.951337 11.9199 1.0211 11.8734 1.07987 11.8146L5.99973 6.89414L10.9196 11.8146C10.9784 11.8734 11.0481 11.92 11.1249 11.9518C11.2017 11.9836 11.284 12 11.3671 12C11.4502 12 11.5325 11.9836 11.6093 11.9518C11.6861 11.92 11.7559 11.8734 11.8146 11.8146C11.8734 11.7559 11.92 11.6861 11.9518 11.6093C11.9836 11.5325 12 11.4502 12 11.3671C12 11.284 11.9836 11.2017 11.9518 11.1249C11.92 11.0481 11.8734 10.9784 11.8146 10.9196L6.89414 5.99973L11.8146 1.07987C11.9332 0.961177 11.9998 0.800233 11.9998 0.63244C11.9997 0.464648 11.933 0.30375 11.8143 0.185145C11.6956 0.0665393 11.5347 -5.92694e-05 11.3669 3.95789e-08C11.1991 5.93485e-05 11.0382 0.0667717 10.9196 0.185461L5.99973 5.10469V5.10533Z"
        fill={fillColor || '#7E8A93'}
      />
    </svg>
  );
};
export const ModalCloseAuto = (props: any) => {
  const { className, width, height, ...rest } = props;
  return (
    <svg
      className={className}
      {...rest}
      width={width || '12'}
      height={height || '12'}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99973 5.10533L1.07987 0.185461C0.960569 0.0702397 0.800789 0.00648358 0.63494 0.00792476C0.469091 0.00936594 0.310444 0.0758891 0.193166 0.193166C0.0758891 0.310444 0.00936596 0.469092 0.00792478 0.63494C0.0064836 0.800789 0.0702397 0.960569 0.185461 1.07987L5.10469 5.99973L0.185461 10.9196C0.126692 10.9783 0.0800658 11.0481 0.0482443 11.1248C0.0164228 11.2015 2.94086e-05 11.2838 3.95314e-08 11.3669C-2.93295e-05 11.45 0.0163059 11.5322 0.0480731 11.609C0.0798403 11.6858 0.126417 11.7555 0.185145 11.8143C0.243872 11.8731 0.3136 11.9197 0.390347 11.9515C0.467094 11.9834 0.549358 11.9997 0.63244 11.9998C0.715523 11.9998 0.797798 11.9835 0.874567 11.9517C0.951337 11.9199 1.0211 11.8734 1.07987 11.8146L5.99973 6.89414L10.9196 11.8146C10.9784 11.8734 11.0481 11.92 11.1249 11.9518C11.2017 11.9836 11.284 12 11.3671 12C11.4502 12 11.5325 11.9836 11.6093 11.9518C11.6861 11.92 11.7559 11.8734 11.8146 11.8146C11.8734 11.7559 11.92 11.6861 11.9518 11.6093C11.9836 11.5325 12 11.4502 12 11.3671C12 11.284 11.9836 11.2017 11.9518 11.1249C11.92 11.0481 11.8734 10.9784 11.8146 10.9196L6.89414 5.99973L11.8146 1.07987C11.9332 0.961177 11.9998 0.800233 11.9998 0.63244C11.9997 0.464648 11.933 0.30375 11.8143 0.185145C11.6956 0.0665393 11.5347 -5.92694e-05 11.3669 3.95789e-08C11.1991 5.93485e-05 11.0382 0.0667717 10.9196 0.185461L5.99973 5.10469V5.10533Z"
        fill="currentColor"
      />
    </svg>
  );
};
